import React, {ChangeEvent, MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import CurrentUser from "../../models/CurrentUser";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {adminRedirectByErrorResponseStatus, consentRedirectByErrorResponseStatus} from "../../helpers/RedirectHelper";
import {Box, Button, FormControl, Grid, Link, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../components/LoadingSpinner";
import LightTooltip from "../components/LightTooltip";
import InfoIcon from "@mui/icons-material/Info";
import FileItem from "../components/FileItem";
import {isAdmin} from "../../helpers/AuthUserHelper";
import Account from "../models/Account";
import Principal from "../models/Principal";

interface CdrClientsCertificatesViewProps {
    api: OpenBankingPlatformAPIInterface;
    currentUser?: CurrentUser,
    basePath: string
}

interface CdrClientsCertificatesViewState {
    id?: number;
    name?: string;
    has_ssl_cert: boolean;
    has_ssl_key: boolean;
    has_root_cert: boolean;
    has_public_key: boolean;
    ssl_cert?: File;
    ssl_key?: File;
    root_cert?: File;
    public_key?: File;
    identifier: string;
    data_recipient_id: string;
    deleted: boolean;
    downloadCdrClientsFile?: any;
    downloadCdrClientsFilename?: string;
    backgroundOperationInProgress: boolean;
    updateMessage: string;
    principalsListLoading: boolean;
    accounts: Array<Account>;
    principals: Array<Principal>;
    account_id: string
    principal_id: string;
    product_registrations: number;
    selectedPrincipalId: number;
    product_registrations_log?: string;
}

const CdrClientsCertificatesView = (props: CdrClientsCertificatesViewProps) => {
    let { id } = useParams<{ id: any }>();
    const [state, setState] = useState<CdrClientsCertificatesViewState>({
        backgroundOperationInProgress: true,
        has_ssl_cert: false,
        has_ssl_key: false,
        has_root_cert: false,
        has_public_key: false,
        deleted: false,
        updateMessage: '',
        principalsListLoading: false,
        accounts: [],
        principals: [],
        account_id: '0',
        principal_id: '0',
        identifier: '',
        data_recipient_id: '',
        selectedPrincipalId: 0,
        product_registrations:0,
    });
    const location = useLocation();
    useEffect(() => {
        if (location.state) {
            // @ts-ignore
            if (location.state.message) {
                // @ts-ignore
                const updateMessage = location.state.message;
                setState((state)=>({
                    ...state,
                    updateMessage: updateMessage
                }))
            }
            // @ts-ignore
            if (location.state.cdrClientsDetails) {
                // @ts-ignore
                const newCdrClientsCertiticateDetails = location.state.cdrClientsDetails;
                setState((state)=>({
                    ...state,
                    id: id,
                    name: newCdrClientsCertiticateDetails.name,
                    identifier: newCdrClientsCertiticateDetails.identifier,
                    data_recipient_id: newCdrClientsCertiticateDetails.data_recipient_id,
                    has_ssl_cert: newCdrClientsCertiticateDetails.has_ssl_cert,
                    has_ssl_key: newCdrClientsCertiticateDetails.has_ssl_key,
                    has_root_cert: newCdrClientsCertiticateDetails.has_root_cert,
                    has_public_key: newCdrClientsCertiticateDetails.has_public_key,
                    deleted: newCdrClientsCertiticateDetails.deleted,
                    product_registrations:newCdrClientsCertiticateDetails.product_registrations,
                    ssl_cert: undefined,
                    ssl_key: undefined
                }))
                setState((state)=>({
                    ...state,
                    backgroundOperationInProgress: false
                }))
            }
        }
    }, [location, id]);

    const sslCert = useRef() as MutableRefObject<HTMLInputElement>;
    const sslKey = useRef() as MutableRefObject<HTMLInputElement>;
    const rootCert = useRef() as MutableRefObject<HTMLInputElement>;
    const publicKey = useRef() as MutableRefObject<HTMLInputElement>;

    const handleCaptureUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) return
        var file = event.target.files[0]

        if (event.currentTarget.id === 'sslCert') {
            setState({
                ...state,
                ssl_cert: file
            })
        }

        if (event.currentTarget.id === 'sslKey') {
            setState({
                ...state,
                ssl_key: file
            })
        }

        if (event.currentTarget.id === 'rootCert') {
            setState({
                ...state,
                root_cert: file
            })
        }

        if (event.currentTarget.id === 'publicKey') {
            setState({
                ...state,
                public_key: file
            })
        }
    }

    const handleUploadClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        if (event.currentTarget.id === "linkUploadSslCert") sslCert.current.click();
        if (event.currentTarget.id === "linkUploadSslKey") sslKey.current.click();
        if (event.currentTarget.id === "linkUploadRootCert") rootCert.current.click();
        if (event.currentTarget.id === "linkUploadPublicKey") publicKey.current.click();
    }

    //eslint-disable-next-line
    const getCdrClientsCertificates = useCallback(props.api.getCdrClientsCertificates, []);
    useEffect(() => {
            (async () => {
                if (id) {
                    try {
                        setState({
                            ...state,
                            backgroundOperationInProgress: true
                        })
                        const loadCdrClientsCertificates = await getCdrClientsCertificates(id);
                        setState((state) => ({
                            ...state,
                            backgroundOperationInProgress: false,
                            id: loadCdrClientsCertificates.id,
                            account_id:''+loadCdrClientsCertificates.account_id,
                            principal_id:''+loadCdrClientsCertificates.principal_id,
                            identifier: ''+loadCdrClientsCertificates.identifier,
                            data_recipient_id: ''+loadCdrClientsCertificates.data_recipient_id,
                            name: loadCdrClientsCertificates.name,
                            has_ssl_cert: loadCdrClientsCertificates.has_ssl_cert,
                            has_ssl_key: loadCdrClientsCertificates.has_ssl_key,
                            has_root_cert: loadCdrClientsCertificates.has_root_cert,
                            has_public_key: loadCdrClientsCertificates.has_public_key,
                            deleted: loadCdrClientsCertificates.deleted,
                            product_registrations:loadCdrClientsCertificates.product_registrations
                        }))
                    } catch (error) {
                        adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                    }
                } else {
                    setState({
                        ...state,
                        backgroundOperationInProgress: false
                    })
                }
            })();
        },
        //eslint-disable-next-line
        []
    )

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`${props.basePath}/cdr-clients-certificates-list`);
    };

    const handleFileDelete = (name?: string) => {
        if (state.ssl_cert && name === state.ssl_cert?.name) {
            setState({
                ...state,
                ssl_cert: undefined
            })
        }
        if (state.ssl_key && name === state.ssl_key?.name) {
            setState({
                ...state,
                ssl_key: undefined
            })
        }
        if (state.root_cert && name === state.root_cert?.name) {
            setState({
                ...state,
                root_cert: undefined
            })
        }
        if (state.public_key && name === state.public_key?.name) {
            setState({
                ...state,
                public_key: undefined
            })
        }
    }

    // eslint-disable-next-line
    const saveUploadedCdrClientsFiles = useCallback(props.api.uploadCdrClientsCertificates, []);
    const handleUpdate = () => {
        (async() => {
            try {
                setState({
                    ...state,
                    backgroundOperationInProgress: true
                })

                let formData = new FormData();
                if (state.deleted) {
                    formData.append('deleted', state.deleted as unknown as string);
                }
                if (id) {
                    formData.append('id', id);
                }
                if (state.principal_id) {
                    formData.append('principal_id', state.principal_id);
                }
                if (state.name) {
                    formData.append('name', state.name);
                }
                if (state.deleted) {
                    formData.append('deleted', state.deleted as unknown as string);
                }
                if (state.ssl_cert) {
                    formData.append('ssl_cert', state.ssl_cert);
                }
                if (state.ssl_key) {
                    formData.append('ssl_key', state.ssl_key);
                }
                if (state.root_cert) {
                    formData.append('root_cert', state.root_cert);
                }
                if (state.public_key) {
                    formData.append('public_key', state.public_key);
                }
                if (state.identifier) {
                    formData.append('identifier', state.identifier);
                }
                if (state.data_recipient_id) {
                    formData.append('data_recipient_id', state.data_recipient_id);
                }
                const newCdrClientsCertificates = await saveUploadedCdrClientsFiles(formData);

                setState({
                    ...state,
                    backgroundOperationInProgress: false
                })

                if (id) {
                    navigate(props.basePath + `/cdr-clients-certificates/` + id, {
                        state: {
                            message: "Cdr Clients Certificates successfully updated.",
                            cdrClientsDetails: newCdrClientsCertificates
                        }
                    });
                } else {
                    navigate(props.basePath + `/cdr-clients-certificates/` + newCdrClientsCertificates.id, {
                        state: {
                            message: "Cdr Clients Certificates successfully updated.",
                            cdrClientsDetails: newCdrClientsCertificates
                        }
                    });
                }

            } catch (error) {
                setState({
                    ...state,
                    backgroundOperationInProgress: false
                })

                if ((error as any).response.status === 422) {
                    // @ts-ignore
                    var errorMessageArray = [];
                    var allErrors = (error as any).response.data.errors;
                    for (var i = 0; i < allErrors.length; i++) {
                        var errors = allErrors[i];
                        errorMessageArray.push(errors.error)
                    }

                    setState({
                        ...state,
                        updateMessage: "Update failed: " + errorMessageArray.join(", ")
                    })
                } else {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }
        })();
    }

    // eslint-disable-next-line
    const doRegistration = useCallback(props.api.doRegistration, []);
    const handleRegister = () => {
        (async() => {
            try {
                setState({
                    ...state,
                    backgroundOperationInProgress: true
                })
                const registration = await doRegistration(id);
                setState({
                    ...state,
                    backgroundOperationInProgress: false
                })
                if (registration.count) {
                    setState((state) => ({
                        ...state,
                        message: "Registration updated.",
                        product_registrations: registration.count,
                        product_registrations_log: registration.registration_log
                    }));
                }else{
                    setState((state) => ({
                        ...state,
                        updateMessage: "Registration failed. "+registration.registration_log,
                        product_registrations: registration.count,
                        product_registrations_log: registration.registration_log
                    }));
                }

            } catch (error) {
                setState({
                    ...state,
                    backgroundOperationInProgress: false
                })

                if ((error as any).response.status === 422) {
                    // @ts-ignore
                    var errorMessageArray = [];
                    var allErrors = (error as any).response.data.errors;
                    for (var i = 0; i < allErrors.length; i++) {
                        var errors = allErrors[i];
                        errorMessageArray.push(errors.error)
                    }

                    setState({
                        ...state,
                        updateMessage: "register failed: " + errorMessageArray.join(", ")
                    })
                } else {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }
        })();
    }

    //eslint-disable-next-line
    const getDownloadCdrClientsFile = useCallback(props.api.downloadCdrClientsFile, []);
    const handleDownloadCdrClientsFile = (cdr_clients_certificates_id: number, type: string) => {
        //Send to server
        (async (cdr_clients_certificates_id, type) => {
            try {
                const fileResponse = await getDownloadCdrClientsFile(cdr_clients_certificates_id, type);
                setState(state => ({
                    ...state,
                    downloadCdrClientsFile: fileResponse.data,
                    downloadCdrClientsFilename: fileResponse.headers['content-disposition'].split('filename=')[1]
                }));
            } catch (error) {
                //console.log(error);
                consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath)
            }
        })(cdr_clients_certificates_id, type);
    }
    useEffect(() => {
        if (state.downloadCdrClientsFile && state.downloadCdrClientsFilename) {
            let blob = new Blob([state.downloadCdrClientsFile], { type: 'application/x-x509-ca-cert' });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.setAttribute('download', state.downloadCdrClientsFilename);
            link.href = window.URL.createObjectURL(blob);
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }, [state.downloadCdrClientsFile, state.downloadCdrClientsFilename]);

    // try to get accounts and principals and render the list in two dropdowns;
    //eslint-disable-next-line
    const getAccounts = useCallback(props.api.getAccountsList, []);
    useEffect(() => {
            (async () => {
                try {
                    if (isAdmin(props.currentUser?.role_id)) {
                        setState((state)=>({
                            ...state,
                            backgroundOperationInProgress: true,
                        }));
                        const loadedAccounts = await getAccounts();
                        setState((state)=>({
                            ...state,
                            backgroundOperationInProgress: false,
                            accounts: loadedAccounts,
                        }))
                    }
                } catch (error) {
                    /*
                    // TODO Uncomment this code when fallback is removed
                    setState((state)=>({
                        ...state,
                        apiHasReturnedAnError: true,
                    }))
                    navigate("/token-error");*/
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            })();
        },
        //eslint-disable-next-line
        []
    )

    // eslint-disable-next-line
    const getPrincipals = useCallback(props.api.getPrincipalsByAccountId, []);
    useEffect(() => {
            (async () => {
                const account_id: number = state ? Number(state.account_id) : 0;
                if (account_id) {
                    try {
                        setState((state)=>({
                            ...state,
                            principalsListLoading: true,
                            principal_id: !state.principal_id?'0':state.principal_id
                        }));
                        const loadedPrincipals = await getPrincipals(account_id);
                        setState((state)=>({
                            ...state,
                            principalsListLoading: false,
                            principals: loadedPrincipals
                        }))
                    } catch (error) {
                        adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                    }
                } else {
                    setState((state)=>({
                        ...state,
                        principals: []
                    }));
                }
            })();
        },
        //eslint-disable-next-line
        [state.account_id]);


    return <div className="cdr-clients-certificates">
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
            </Grid>
        </Grid>

        <div className="update-mapping">
            <h2><strong>{id?'Update CDR Clients Certificates':'Create CDR Clients Certificates'}</strong></h2>

            {(state.backgroundOperationInProgress)
                ? <LoadingSpinner loadingText={""} />
                : <div>
                    <div>{(state.updateMessage !== '') ? <span className={"update-message-text"}>{state.updateMessage}</span> : ""}</div>

                    <br />

                    <form className={"admin-form cdr-to-ddc-mappings-form"} autoComplete={"off"}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Account</label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="name-input"
                                        name="name-input"
                                        value={state.account_id ? state.account_id : '0'}
                                        onChange={(event) => {
                                            setState({
                                                ...state,
                                                account_id: event.target.value
                                            })
                                        }}>
                                        <MenuItem value={0}>- Please select -</MenuItem>
                                        {state.accounts.map((item, i) => (
                                            <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Principal</label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <FormControl variant="outlined" className="w-100">
                                    {state.principalsListLoading ? <>
                                        <div>Please wait...</div>
                                    </> : <Select
                                        id="name-input"
                                        name="name-input"
                                        value={state.principal_id ? state.principal_id : '0'}
                                        onChange={(event) => {
                                            setState({
                                                ...state,
                                                principal_id: event.target.value
                                            })
                                        }}>
                                        <MenuItem value={0}>- Please select -</MenuItem>
                                        {state.principals.map((item, i) => (
                                            <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>}
                                </FormControl>
                            </Grid>
                        </Grid>


                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Name<LightTooltip title="Unique name for this certificates collection" placement="right-start"
                                                         arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <FormControl variant="outlined" className="w-100">
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Name"
                                        value={state.name ? state.name : ''}
                                        onChange={(event) => {
                                            setState({
                                                ...state,
                                                name: event.target.value
                                            })
                                        }}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Softwarre Product ID<LightTooltip title="Unique name for this certificates collection" placement="right-start"
                                                         arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <FormControl variant="outlined" className="w-100">
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Softwarre Product ID"
                                        value={state.identifier ? state.identifier : ''}
                                        onChange={(event) => {
                                            setState({
                                                ...state,
                                                identifier: event.target.value
                                            })
                                        }}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Data Recipient ID<LightTooltip title="Unique name for this certificates collection" placement="right-start"
                                                                         arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <FormControl variant="outlined" className="w-100">
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Data Recipient ID"
                                        value={state.data_recipient_id ? state.data_recipient_id : ''}
                                        onChange={(event) => {
                                            setState({
                                                ...state,
                                                data_recipient_id: event.target.value
                                            })
                                        }}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>SSL Certificate File<LightTooltip title="The SSL certificate file" placement="right-start"
                                                                         arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item md={"auto"} className={"grid-file-input"}>
                                <div className={"file-item-wrap"}>
                                    <Box className={"details-box"}>
                                        <FileItem type={"generic"} name={state.ssl_cert ? state.ssl_cert.name : 'No file chosen'} onDelete={handleFileDelete} />
                                    </Box>
                                    {state.has_ssl_cert && <div><a href="/#" onClick={(e) => {e.preventDefault(); handleDownloadCdrClientsFile(id, 'sslcert');}} >Download SSL Cert File</a></div>}
                                </div>
                                <Box mt={2} className={"input-file-box"}>
                                    <input
                                        color="primary"
                                        /*accept="application/pdf"*/
                                        type="file"
                                        onChange={e => {
                                            handleCaptureUploadFile(e);
                                            e.target.value = '';
                                        }}
                                        id="sslCert"
                                        style={{ display: 'none', }}
                                        ref={sslCert}
                                    />
                                    <a href={"/#"} id="linkUploadSslCert"
                                       onClick={handleUploadClick}
                                    >
                                        Choose file
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>SSL Key File<LightTooltip title="The SSL key file" placement="right-start"
                                                                 arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item md={"auto"} className={"grid-file-input"}>
                                <div className={"file-item-wrap"}>
                                    <Box className={"details-box"}>
                                        <FileItem type={"generic"} name={state.ssl_key ? state.ssl_key.name : 'No file chosen'} onDelete={handleFileDelete} />
                                    </Box>
                                    {state.has_ssl_key && <div><a href="/#" onClick={(e) => {e.preventDefault(); handleDownloadCdrClientsFile(id, 'sslkey');}} >Download SSL Key File</a></div>}
                                </div>
                                <Box mt={2} className={"input-file-box"}>
                                    <input
                                        color="primary"
                                        /*accept="application/pdf"*/
                                        type="file"
                                        onChange={e => {
                                            handleCaptureUploadFile(e);
                                            e.target.value = '';
                                        }}
                                        id="sslKey"
                                        style={{ display: 'none', }}
                                        ref={sslKey}
                                    />
                                    <a href={"/#"} id="linkUploadSslKey"
                                       onClick={handleUploadClick}
                                    >
                                        Choose file
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Server Root Certificate File<LightTooltip title="The Server Root certificate file" placement="right-start"
                                                                         arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item md={"auto"} className={"grid-file-input"}>
                                <div className={"file-item-wrap"}>
                                    <Box className={"details-box"}>
                                        <FileItem type={"generic"} name={state.root_cert ? state.root_cert.name : 'No file chosen'} onDelete={handleFileDelete} />
                                    </Box>
                                    {state.has_root_cert && <div><a href="/#" onClick={(e) => {e.preventDefault(); handleDownloadCdrClientsFile(id, 'rootcert');}} >Download Server Root Cert File</a></div>}
                                </div>
                                <Box mt={2} className={"input-file-box"}>
                                    <input
                                        color="primary"
                                        /*accept="application/pdf"*/
                                        type="file"
                                        onChange={e => {
                                            handleCaptureUploadFile(e);
                                            e.target.value = '';
                                        }}
                                        id="rootCert"
                                        style={{ display: 'none', }}
                                        ref={rootCert}
                                    />
                                    <a href={"/#"} id="linkUploadRootCert"
                                       onClick={handleUploadClick}
                                    >
                                        Choose file
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Public Key File<LightTooltip title="The Public key file" placement="right-start"
                                                                 arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item md={"auto"} className={"grid-file-input"}>
                                <div className={"file-item-wrap"}>
                                    <Box className={"details-box"}>
                                        <FileItem type={"generic"} name={state.public_key ? state.public_key.name : 'No file chosen'} onDelete={handleFileDelete} />
                                    </Box>
                                    {state.has_public_key && <div><a href="/#" onClick={(e) => {e.preventDefault(); handleDownloadCdrClientsFile(id, 'publickey');}} >Download Public Key File</a></div>}
                                </div>
                                <Box mt={2} className={"input-file-box"}>
                                    <input
                                        color="primary"
                                        /*accept="application/pdf"*/
                                        type="file"
                                        onChange={e => {
                                            handleCaptureUploadFile(e);
                                            e.target.value = '';
                                        }}
                                        id="publicKey"
                                        style={{ display: 'none', }}
                                        ref={publicKey}
                                    />
                                    <a href={"/#"} id="linkUploadPublicKey"
                                       onClick={handleUploadClick}
                                    >
                                        Choose file
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Deactivate<LightTooltip title="Select this if you want to flag this certificates collection as deleted." placement="right-start"
                                                               arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="name-input"
                                        name="name-input"
                                        value={state.deleted ? state.deleted : 0 as unknown as boolean}
                                        onChange={(event: SelectChangeEvent<boolean>) => {
                                            setState({
                                                ...state,
                                                deleted: event.target.value as boolean
                                            })
                                        }}>
                                        <MenuItem value={1}>Yes</MenuItem>
                                        <MenuItem value={0}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={7} md={5}><Button variant={"contained"} color={"secondary"} onClick={handleUpdate}>Save</Button>
                            </Grid>
                        </Grid>

                        {id && <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={7} md={5}>
                                <Button variant={"contained"} color={"secondary"} onClick={handleRegister}>{state.product_registrations === 0 ? 'Register Data Recipient With CDR' : 'Update Data Recipient CDR Registration'}</Button>

                                <p></p>

                                { state.product_registrations > 0 ? <p>Data Recipient is currently registered with {state.product_registrations} Data Holders.</p> : ""}

                                <pre className={"console-log"}>
                                    {state.product_registrations_log}
                                </pre>
                            </Grid>
                        </Grid>}
                    </form>
                </div>
            }
        </div>
    </div>
}

export default CdrClientsCertificatesView;