import { ThemeOptions } from "@mui/material/styles";

const themeValues = {
    largerFont: "1.1em",
    smallerFont: "0.9em",

    standardInterval: "1em",
    standardHalfInterval: "0.5em",
};

export const styles = {
    footer: {
        background: "#EFEFEF",
        padding: themeValues.standardInterval,
        fontSize: themeValues.smallerFont,
    },
    header: {
    }
};

export type ThemeId = "Default" | "Custom";

export const defaultTheme: ThemeOptions = {
    typography: {
        fontFamily: `"Nunito Sans", Helvetica, Arial, sans-serif`,
        fontSize: 15,

        button: {
            fontSize: 15,
            fontWeight: 700,
            textTransform: "none"
        }
    },
    palette: {
        primary: {
            main: "#404040",
            contrastText: "#fff",
        },
        secondary: {
            main: "#8c2980",
            contrastText: '#fff',
        },
        info: {
            main: "#F8F2F6",
            contrastText: '#fff',
        },
        background: {
            default: "#fff",
            paper: "#fff",
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontFamily: `"Nunito Sans", Helvetica, Arial, sans-serif`,
                    fontSize: 15
                },
                h1: {
                    fontFamily: `"Bitter", Helvetica, Arial, sans-serif`,
                    fontWeight: 500
                },
                h2: {
                    fontWeight: 400
                },
                h3: {
                    fontWeight: 700
                },
                h4: {
                    fontWeight: 700
                },
                h5: {
                    fontWeight: 400
                },
                h6: {
                    fontWeight: 700
                },
                a: {
                    color: "#8c2980",
                    textDecoration: "underline",

                    "&:hover": {
                        color: "#41083A"
                    },
                    "&:active": {
                        color: "#41083A"
                    }
                },
                ".loading-spinner": {
                    color: "#404040"
                }
            }
        }
    }
};
