import React, { PropsWithChildren, ReactElement } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, useTheme } from "@mui/material";

interface PopupProps {
    heading?: string;
    buttons?: ReactElement;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
    open?: boolean;
    textColor?: string;
    withCloseButton?: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
}

const Popup = (props: PropsWithChildren<PopupProps>) => {
    const theme = useTheme();
    const style = {
        dialogContent: {
            color: props.textColor ? props.textColor : theme.palette.info.contrastText
        }
    };

    return (
        <Dialog
            maxWidth={props.maxWidth || "sm"}
            open={props.open || false}
            onClose={props.onClose}
        >
            {props.withCloseButton && 
                <CloseIcon onClick={props.onClose} className="close-popup-icon"/>
            }
            <DialogContent sx={style.dialogContent}>
                {props.heading && props.heading !== "" && <h2>{props.heading}</h2>}
                {props.children}
            </DialogContent>
            {props.buttons &&
                <DialogActions>{props.buttons}</DialogActions>
            }
        </Dialog>
    );
};

export default Popup;
