import React from "react";
import { Link } from "react-router-dom";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";
import { isSuperAdmin } from "../../helpers/AuthUserHelper";
import CurrentUser from "../../models/CurrentUser";

interface UsersListItemProps {
    id: number;
    name: string;
    email: string;
    role?: string;
    userDetailsPath: string;
    currentUser?: CurrentUser;
}

const UsersListItem = (props: UsersListItemProps) => {

    /*
    const handleViewDetails = () => {
        navigate(props.userDetailsPath + `/${props.id}`);
    };*/

    return <Grid container className={"consent-list-item"}>
        <Grid item xs={10} md={4}>{props.name}</Grid>
        <Grid item xs={6} md={4}>{props.email}</Grid>
        <Grid item xs={6} md={2}>{props.role}</Grid>
        {(isSuperAdmin(props.currentUser?.role_id) || (props.role !== 'Illion Administrator' && props.role !== 'Super Administrator'))?
        <Grid item xs={6} md={2}><Link color={"secondary"} to={props.userDetailsPath+ `/${props.id}`}><b><u>View details</u> <FontAwesomeIcon icon={faChevronRight} /></b></Link></Grid>
        :''}
    </Grid>
}

export default UsersListItem;


