import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Hidden, Link } from "@mui/material";

import LinkButton from "../../components/LinkButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import PrimaryBlock from "../../components/PrimaryBlock";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import CdrToDdcMappingsListItem from "../components/CdrToDdcMappingsListItem";
import CdrToDdcBankMapping from "../models/CdrToDdcBankMapping";

interface CdrToDdcBankMappingsListState {
    data: Array<CdrToDdcBankMapping>;
    isLoading: boolean;
}

interface CdrToDdcBankMappingsListProps {
    api: OpenBankingPlatformAPIInterface;
    currentUser?: CurrentUser;
    basePath: string;
    detailsPath: string;
}

const CdrToDdcBankMappingsList = (props: CdrToDdcBankMappingsListProps) => {
    const [state, setState] = useState<CdrToDdcBankMappingsListState>({
        data: [],
        isLoading: false,
    });

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`${props.basePath}/home`);
    };

    //eslint-disable-next-line
    const getMappings = useCallback(props.api.getCdrToDdcBankMappingsList, []);
    useEffect(() => {
            (async () => {
                try {
                    setState((state)=>({
                        ...state,
                        isLoading: true,
                    }))
                    const loadedMappings = await getMappings();
                    setState((state)=>({
                        ...state,
                        data: loadedMappings,
                        isLoading: false,
                    }));
                } catch (error) {
                    // TODO Uncomment this code when fallback is removed
                    /*setState((state)=>({
                        ...state,
                        apiHasReturnedAnError: true,
                        isLoading: false,
                    }))
                    navigate("/token-error");*/
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            })();
        },
        //eslint-disable-next-line
        []
    )


    return <div className="cdr-to-ddc-bank-mappings">
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
            </Grid>
        </Grid>
        <PrimaryBlock>
            <h2>Manage CDR to DDC bank mappings</h2>
            <Grid container className={"dashboard-action-bar"}>
                <Grid item xs={12} md={5}>
                    <LinkButton targetlocation={props.detailsPath} variant={"contained"} color={"secondary"}>Add New Mapping</LinkButton>
                </Grid>
            </Grid>
            <div className={"institution-list paginated-table"}>
                <Hidden mdDown>
                    <Grid container className={"table-head"}>
                        <Grid item xs={1}>ID</Grid>
                        <Grid item xs={5}>CDR Institution</Grid>
                        <Grid item xs={3}>Equivalent DDC Institution</Grid>
                        <Grid item xs={1}>Status</Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Hidden>
                {!state.isLoading && state.data && (
                    state.data.map((item, i) =>
                        <CdrToDdcMappingsListItem detailsPath={props.detailsPath} key={i} {...item} />
                    )
                )}
                {state.isLoading &&
                <LoadingSpinner loadingText={""} />
                }
            </div>
        </PrimaryBlock>
    </div>
}

export default CdrToDdcBankMappingsList;