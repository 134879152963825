import { convertToRaw, EditorState } from "draft-js";
import React, { ChangeEvent, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import SwitchWithLabel from "../../components/SwitchWithLabel";
import { isAdmin } from "../../helpers/AuthUserHelper";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import AdditionalUsesOfData from "../../models/AdditionalUsesOfData";
import Consent from "../../models/Consent";
import CurrentUser from "../../models/CurrentUser";
import DataClusterOptionSet from "../../models/DataClusterOptionSet";
import Insight from "../../models/Insight";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import Popup from "../../popups/Popup";
import EditableText from "../components/EditableText";
import UploadFile from "../models/UploadFile";
import EditableRichText from "./EditableRichText";
import FileItem from "./FileItem";
import LightTooltip from "./LightTooltip";
import {
    checkIfUploadedFileHasImageMimeType,
    checkIfUploadedFileHasValidPdfSignature, checkIfUploadedFileSizeWithinLimit
} from "../../helpers/InputFieldValidationHelper";

interface EditInsightSettingsProps {
    basePath: string;
    insight: Insight,
    consent: Consent,
    currentUser?: CurrentUser,
    onInsightChange?: (newInsight: Insight, newFileUpload?: UploadFile) => void,
    onConsentClusterChange?: (updatedDataClusterWithOptions: DataClusterOptionSet) => void,
    onAdditionalUsesOfDataChange?: (updatedAdditionalUsesOfData: AdditionalUsesOfData) => void,
    adminObApi: OpenBankingPlatformAPIInterface,
    configurationPrincipalId?: number,
}

const EditInsightSettings = (props: EditInsightSettingsProps) => {

    const fileUploadLogo = useRef() as MutableRefObject<HTMLInputElement>;
    const fileUploadPolicyPDF = useRef() as MutableRefObject<HTMLInputElement>;

    const [status, setStatus] = useState('');
    const [openIVE, setOpenIVE] = React.useState(false);
    const [openIVE2, setOpenIVE2] = React.useState(false);
    const [openIVE3, setOpenIVE3] = React.useState(false);
    const [openPVE, setOpenPVE] = React.useState(false);

    const handleInsightChange = (newInsight: Insight, newFileUpload?: UploadFile) => {
        props.onInsightChange && props.onInsightChange(newInsight, newFileUpload);
    }

    const handleConsentClusterChange = (updatedDataClusterWithOptions: DataClusterOptionSet) => {
        props.onConsentClusterChange && props.onConsentClusterChange(updatedDataClusterWithOptions);
    }

    const handleAdditionalUsesOfDataChange = (updatedAdditionalUsesOfData: AdditionalUsesOfData) => {
        props.onAdditionalUsesOfDataChange && props.onAdditionalUsesOfDataChange(updatedAdditionalUsesOfData);
    }

    const navigate = useNavigate();

    const openIVEPopup = () => {
        setOpenIVE(true);
    };
    const closeIVEPopup = () => {
        setOpenIVE(false);
    };

    const openIVEPopup2 = () => {
        setOpenIVE2(true);
    };
    const closeIVEPopup2 = () => {
        setOpenIVE2(false);
    };

    const openIVEPopup3 = () => {
        setOpenIVE3(true);
    };
    const closeIVEPopup3 = () => {
        setOpenIVE3(false);
    };

    const openPVEPopup = () => {
        setOpenPVE(true);
    };
    const closePVEPopup = () => {
        setOpenPVE(false);
    };

    const handleUploadClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        if (event.currentTarget.id === "linkUploadPolicyPDF") fileUploadPolicyPDF.current.click();
        if (event.currentTarget.id === "linkUploadLogo") fileUploadLogo.current.click();
    }

    const handleCaptureUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) return
        var file = event.target.files[0]

        if (event.currentTarget.id === "privacyUploadPolicyPDF") {
            checkIfUploadedFileHasValidPdfSignature(file, function(isValidPdf:boolean) {
                if (!checkIfUploadedFileSizeWithinLimit(file)) {
                    openIVEPopup3();
                    return;
                }

                if (!isValidPdf) {
                    openPVEPopup();
                } else {
                    handleInsightChange(Object.assign({}, { ...props.insight, privacyPolicyPDF: file.name }),
                        {
                            name: event.target.id,
                            path: `/insights/privacy-policy/${props.configurationPrincipalId}`,
                            file: file
                        });
                }
            });
        }

        if (event.currentTarget.id === "uploadLogo") {
            var img = new Image()
            const objectUrl = URL.createObjectURL(file)

            checkIfUploadedFileHasImageMimeType(file, function(isValidImage:boolean) {
                if (!checkIfUploadedFileSizeWithinLimit(file)) {
                    openIVEPopup3();
                    return;
                }

                if (!isValidImage) {
                    openIVEPopup2();
                } else {
                    img.onload = function () {
                        if (img.width <= 250 && img.height <= 250) {
                            setLogoPreviewSrc(objectUrl)
                            handleInsightChange(Object.assign({}, { ...props.insight, uploadLogo: file.name }),
                                {
                                    name: '',
                                    path: `/insights/logos/${props.configurationPrincipalId}`,
                                    file: file
                                });
                            setStatus('updated');
                        }
                        else {
                            openIVEPopup()
                        }
                    };
                    img.src = objectUrl
                }
            });
        }
    }

    const logoUrl = `/insights/logos/${props.configurationPrincipalId}/${props.insight.uploadLogo}`;
    const [logoSrc, setLogoSrc] = useState('');
    const [logoPreviewSrc, setLogoPreviewSrc] = useState('');

    //eslint-disable-next-line
    const getFile = useCallback(props.adminObApi.getFile, []);
    useEffect(() => {
        (async (logoUrl) => {
            if (props.insight.uploadLogo && props.insight.uploadLogo !== '') {
                try {
                    const data = await getFile(logoUrl);
                    setLogoSrc(URL.createObjectURL(data));
                    setStatus('loaded');
                } catch (error) {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }
        })(logoUrl);
        //eslint-disable-next-line
    }, [status]);
    
    const onEditorStateChange = (field: string, editorState: EditorState) => {
        handleInsightChange({
            ...props.insight,
            [field]: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        });
    };
  
    const editable = isAdmin(props.currentUser?.role_id);

    const dataClusters = (props.consent)
        ? <Grid item xs={12} sm={7} md={5}>
            {props.consent.dataClustersWithOptions
                .filter(options => options.clusterId === 2 || options.clusterId === 8 || options.clusterId === 9)
                .map((dataClusterWithOptions, index) => {
                const detailsBox = 
                    <>
                        <Box className={`details-box background-grey`}>
                            <label>"Why we need it" text<LightTooltip
                                title="This text will be displayed to the customer" placement="right-start"
                                arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            <EditableText
                                editMode={editable}
                                name={"usageExplanatoryText"}
                                value={dataClusterWithOptions.purposeInformationInsight}
                                eventHandlers={{
                                    onChange: (e: any) => {
                                        handleConsentClusterChange({
                                            ...dataClusterWithOptions, 
                                            purposeInformationInsight: e.target.value
                                        })
                                    }
                                }}
                            />
                            <label className={`mt-20`}>List of data fields</label>
                            <ul>
                                {dataClusterWithOptions.clusterPermissionNames.map((permissionName: string, index) =>
                                    <li key={index}>{permissionName}</li>
                                )}
                            </ul>
                        </Box>
                    </>
                ;

                return <div className={"form-group"} key={index}>
                    <FormControlLabel
                            className={"disabled-checkbox-label-color"}
                            control={<Checkbox 
                                name={dataClusterWithOptions.authorisationScopeId} 
                                checked={dataClusterWithOptions.isInsightEnabled}
                                className={"disabled-checkbox-color"}
                                color={"primary"}
                                disabled={false}
                                               onChange={
                                                   (_, checked: any) => {
                                                    handleConsentClusterChange({
                                                           ...dataClusterWithOptions,
                                                           isInsightEnabled: checked,
                                                       })
                                                   }
                                               }/>}
                            label={dataClusterWithOptions.clusterName}
                        />
                    {detailsBox}
                </div>
            })}
        </Grid>
        : <LoadingSpinner position={"fixed"} overlay />;

    return <>
        <form className={"admin-form general-settings-form mt-50"} autoComplete={"off"}>
            <div>
            
                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <Box className={`details-box background-grey form-group`}>
                            <Box>
                                <label>Main Heading<LightTooltip title="Main heading for additional uses of data" placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                <EditableText
                                    editMode={editable}
                                    name={"additionalUsesOfData.mainHeading"}
                                    value={props.insight.mainHeading}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            handleInsightChange({
                                                ...props.insight,
                                                mainHeading: e.target.value
                                            })
                                        }
                                    }}
                                />
                            </Box>
                            <Box mt={4}>
                                <label>Subheading<LightTooltip title="Subheading for additional uses of data" placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                <EditableText
                                    editMode={editable}
                                    name={"additionalUsesOfData.subheading"}
                                    value={props.insight.subHeading}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            handleInsightChange({
                                                ...props.insight,
                                                subHeading: e.target.value
                                            })
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            
                {/* Third Party  */}

                <Grid container className={"mt-50"}>
                    <Box mb={2}>
                        <h4>Third Party</h4>
                    </Box>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Name</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            editMode={true}
                            name={"usageExplanatoryText"}
                            value={props.insight.name}
                            placeholder="Name"
                            eventHandlers={{
                                onChange: (e: any) => {
                                    handleInsightChange({
                                        ...props.insight,
                                        name: e.target.value as string
                                    })
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Logo</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <div className={"file-item-wrap"}>
                            <Box className={"details-box"}>
                                <FileItem type={"image"} name={props.insight.uploadLogo} url={logoPreviewSrc || logoSrc} />
                            </Box>
                        </div>
                        <Box mt={2} className={"input-file-box"}>
                            <input
                                color="primary"
                                accept="image/x-png"
                                type="file"
                                onChange={e => {
                                    handleCaptureUploadFile(e);
                                    e.target.value = '';
                                }}
                                id="uploadLogo"
                                style={{ display: 'none', }}
                                ref={fileUploadLogo}
                            />
                            <a href={"/#"} id="linkUploadLogo"
                                onClick={handleUploadClick}
                            >
                                Upload new
                            </a>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Business Description</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            editMode={true}
                            name={"usageExplanatoryText"}
                            value={props.insight.businessDescription}
                            placeholder="Business Description"
                            eventHandlers={{
                                onChange: (e: any) => {
                                    handleInsightChange({
                                        ...props.insight,
                                        businessDescription: e.target.value as string
                                    })
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Purpose for the insight being requested</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            editMode={true}
                            name={"purposeInsightWasRequested"}
                            value={props.insight.purposeInsightWasRequested}
                            placeholder="Purpose for the insight being requested"
                            eventHandlers={{
                                onChange: (e: any) => {
                                    handleInsightChange({
                                        ...props.insight,
                                        purposeInsightWasRequested: e.target.value as string
                                    })
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Privacy Policy URL</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            editMode={true}
                            name={"privacyPolicyURL"}
                            value={props.insight.privacyPolicyURL}
                            placeholder="Privacy Policy URL"
                            eventHandlers={{
                                onChange: (e: any) => {
                                    handleInsightChange({
                                        ...props.insight,
                                        privacyPolicyURL: e.target.value as string
                                    })
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Privacy Policy PDF</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <div className={"file-item-wrap"}>
                            <Box className={"details-box"}>
                                <FileItem type={"generic"} name={props.insight.privacyPolicyPDF} />
                            </Box>
                        </div>
                        <Box mt={2} className={"input-file-box"}>
                            <input
                                color="primary"
                                accept="application/pdf"
                                type="file"
                                onChange={e => {
                                    handleCaptureUploadFile(e);
                                    e.target.value = '';
                                }}
                                id="privacyUploadPolicyPDF"
                                style={{ display: 'none', }}
                                ref={fileUploadPolicyPDF}
                            />
                            <a href={"/#"} id="linkUploadPolicyPDF"
                                onClick={handleUploadClick}
                            >
                                Upload new
                            </a>
                        </Box>
                    </Grid>
                </Grid>

                {/* Insights */}

                <Grid container className={"mt-50"}>
                    <Box mb={2}>
                        <h4>Insights</h4>
                    </Box>
                </Grid>
                <Grid container spacing={10} className={"mb-20"}>    
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Verify your idenity</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={6}>
                        <FormControl>
                            <SwitchWithLabel
                                checked={props.insight.incVerifyIdentity || false}
                                onChange={value => {
                                    handleInsightChange({
                                        ...props.insight, 
                                        incVerifyIdentity: value
                                    })}}>
                                {props.insight.incVerifyIdentity ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                        {props.insight.incVerifyIdentity &&
                        <Box className={`details-box background-grey form-group`}>
                            <Grid item xs={12} sm={6} md={9}>
                                <EditableRichText
                                    editMode={editable}
                                    value={props.insight.incIdentityDescription}
                                    onChange={editorState => { onEditorStateChange("incIdentityDescription", editorState) }}
                                />
                                <FormControl>
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color mt-20"}
                                        control={<Checkbox 
                                        name={"incIdentityIncName"}
                                        checked={props.insight.incIdentityIncName || false} 
                                        className={"disabled-checkbox-color"}
                                        disabled={!editable}
                                        onChange={(e, checked: any) => {
                                            handleInsightChange({
                                                ...props.insight, 
                                                incIdentityIncName: checked
                                            })}
                                    }/>} label="Name" />
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color"}
                                        control={<Checkbox 
                                        name={"incIdentityAddress"}
                                        checked={props.insight.incIdentityIncAddress || false} 
                                        className={"disabled-checkbox-color"}
                                        disabled={!editable}
                                        onChange={(e, checked: any) => {
                                            handleInsightChange({
                                                ...props.insight, 
                                                incIdentityIncAddress: checked
                                            })}
                                    }/>} label="Address" />
                                </FormControl>
                            </Grid>
                        </Box>}
                    </Grid>
                </Grid>
                <Grid container spacing={10} className={"mb-20"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Verify your balance</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={6}>
                        <FormControl>
                            <SwitchWithLabel
                                checked={props.insight.incVerifyBalance}
                                onChange={value => {handleInsightChange({...props.insight, incVerifyBalance: value})}}>
                                {props.insight.incVerifyBalance ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                        {props.insight.incVerifyBalance &&
                        <Box className={`details-box background-grey form-group`}>
                            <Grid item xs={12} sm={6} md={9}>
                                <EditableRichText
                                    editMode={editable}
                                    value={props.insight.incBalanceDescription}
                                    onChange={editorState => { onEditorStateChange("incBalanceDescription", editorState) }}
                                />
                                <FormControl>
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color mt-20"}
                                        control={<Checkbox 
                                            name={"incBalanceIncBSBAndAccNumber"}
                                            checked={props.insight.incBalanceIncBSBAndAccNumber || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight,incBalanceIncBSBAndAccNumber: checked})}
                                        }/>} label="Account Details - BSB & Account Number or Masked Credit Card Number" />
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color mt-10"}
                                        control={<Checkbox 
                                            name={"incBalanceIncAccBalance"}
                                            checked={props.insight.incBalanceIncAccBalance || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight,incBalanceIncAccBalance: checked})}
                                        }/>} label="Account Balance" />
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color"}
                                        control={<Checkbox 
                                            name={"incBalanceIncAvgAccBalance"}
                                            checked={props.insight.incBalanceIncAvgAccBalance || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight, incBalanceIncAvgAccBalance: checked})}
                                        }/>} label="Average Account Balance" />
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color"}
                                        control={<Checkbox 
                                            name={"incBalanceIncDaysInNegative"}
                                            checked={props.insight.incBalanceIncDaysInNegative || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight, incBalanceIncDaysInNegative: checked})}
                                        }/>} label="Days in Negative" />
                                </FormControl>
                            </Grid>
                        </Box>}
                    </Grid>
                </Grid>
                <Grid container spacing={10} className={"mb-20"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Verify your income</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={6}>
                        <FormControl>
                            <SwitchWithLabel
                                    checked={props.insight.incVerifyIncome}
                                    onChange={value => {handleInsightChange({...props.insight, incVerifyIncome: value})}}>
                                    {props.insight.incVerifyIncome ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                        {props.insight.incVerifyIncome && 
                        <Box className={`details-box background-grey form-group`}>
                            <Grid item xs={12} sm={6} md={9}>
                                <EditableRichText
                                    editMode={editable}
                                    value={props.insight.incIncomeDescription}
                                    onChange={editorState => { onEditorStateChange("incIncomeDescription", editorState) }}
                                />
                                <FormControl>
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color mt-20"}
                                        control={<Checkbox 
                                            name={"incIncomeBusinessNameAndLegalName"}
                                            checked={props.insight.incIncomeBusinessNameAndLegalName || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight, incIncomeBusinessNameAndLegalName: checked})}
                                        }/>} label="Name (Business Name and Legal Name for business consumer)" />
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color mt-10"}
                                        control={<Checkbox 
                                            name={"incIncomeABN"}
                                            checked={props.insight.incIncomeABN || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight, incIncomeABN: checked})}
                                        }/>} label="ABN (for business consumer only)" />
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color"}
                                        control={<Checkbox 
                                            name={"incIncomeIncMonthlyAvgIncome"}
                                            checked={props.insight.incIncomeIncMonthlyAvgIncome || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight, incIncomeIncMonthlyAvgIncome: checked})}
                                        }/>} label="Monthly Average Income" />
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color"}
                                        control={<Checkbox 
                                            name={"incIncomeIncTotalIncomeOver6Months"}
                                            checked={props.insight.incIncomeIncTotalIncomeOver6Months || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight, incIncomeIncTotalIncomeOver6Months: checked})}
                                        }/>} label="Total Income over past 6 months" />
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color"}
                                        control={<Checkbox 
                                            name={"incIncomeIncTotalIncomeOver3Months"}
                                            checked={props.insight.incIncomeIncTotalIncomeOver3Months || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight, incIncomeIncTotalIncomeOver3Months: checked})}
                                        }/>} label="Total Income over last 3 months" />
                                </FormControl>
                            </Grid>
                        </Box>}
                    </Grid>
                </Grid>
                <Grid container spacing={10} className={"mb-20"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Verify your bank account</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={6}>
                        <FormControl>
                            <SwitchWithLabel
                                checked={props.insight.incVerifyBankAccount}
                                onChange={value => {handleInsightChange({...props.insight, incVerifyBankAccount: value})}}>
                                {props.insight.incVerifyBankAccount ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                        {props.insight.incVerifyBankAccount &&
                        <Box className={`details-box background-grey form-group`}>
                            <Grid item xs={12} sm={6} md={9}>
                                <EditableRichText
                                    editMode={editable}
                                    value={props.insight.incBankAccountDescription}
                                    onChange={editorState => { onEditorStateChange("incBankAccountDescription", editorState) }}
                                />
                                <FormControl>
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color mt-20"}
                                        control={<Checkbox 
                                            name={"incBankAccountBusinessNameAndLegalName"}
                                            checked={props.insight.incBankAccountBusinessNameAndLegalName || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight, incBankAccountBusinessNameAndLegalName: checked})}
                                        }/>} label="Name  (Business Name and Legal Name for business consumer)" />
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color mt-10"}
                                        control={<Checkbox 
                                            name={"incBankAccountABN"}
                                            checked={props.insight.incBankAccountABN || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight, incBankAccountABN: checked})}
                                        }/>} label="ABN (for business consumer only)" />
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color mt-10"}
                                        control={<Checkbox 
                                            name={"incBankAccountIncBSBAndAccNumber"}
                                            checked={props.insight.incBankAccountIncBSBAndAccNumber || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight, incBankAccountIncBSBAndAccNumber: checked})}
                                        }/>} label="Account Details - BSB & Account Number or Masked Credit Card Number" />
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color mt-10"}
                                        control={<Checkbox 
                                            name={"incBankAccountIncAccountName"}
                                            checked={props.insight.incBankAccountIncAccountName || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight, incBankAccountIncAccountName: checked})}
                                        }/>} label="Account Name" />
                                    <FormControlLabel
                                        className={"disabled-checkbox-label-color mt-10"}
                                        control={<Checkbox 
                                            name={"incBankAccountIncIsOwned"}
                                            checked={props.insight.incBankAccountIncIsOwned || false} 
                                            className={"disabled-checkbox-color"}
                                            disabled={!editable}
                                            onChange={(e, checked: any) => {handleInsightChange({...props.insight, incBankAccountIncIsOwned: checked})}
                                        }/>} label="Is Owned - `Yes` or `No`" />
                                </FormControl>
                            </Grid>
                        </Box>}
                    </Grid>
                </Grid>

                {/* Data request */}

                <Grid container className={"mt-50"}>
                    <Box mb={2}>
                        <h4>Data Request</h4>
                    </Box>
                </Grid>
                <Grid container className={"mt-50"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Active consent duration explanatory text</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            editMode={editable}
                            name={"usageExplanatoryText"}
                            value={props.insight.activeConsentDurationText}
                            placeholder="Name"
                            eventHandlers={{
                                onChange: (e: any) => {
                                    handleInsightChange({
                                        ...props.insight,
                                        activeConsentDurationText: e.target.value as string
                                    })
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>                            
                        <label>"Data we need" text</label>
                    </Grid>    
                    <Grid item xs={12} sm={7} md={5}>
                        <EditableText
                            textArea
                            editMode={editable}
                            name={"dataWeNeedText"}
                            value={props.insight.dataWeNeedText}
                            eventHandlers={{
                                onChange: (e: any) => {
                                    handleInsightChange({
                                        ...props.insight, 
                                        dataWeNeedText: e.target.value
                                    })}
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data types to collect &amp; usage explanatory text</label>
                    </Grid>
                    {dataClusters}
                </Grid>

                {/* Data handling */}
                
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Data Handling</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <Box className={`details-box background-grey form-group`}>
                            <Box>
                                <label>Heading<LightTooltip title="heading for data handling" placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                <EditableText
                                    editMode={editable}
                                    name={"dataHandlingMainHeading"}
                                    value={props.insight.dataHandlingMainHeading}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            handleInsightChange({
                                                ...props.insight,
                                                dataHandlingMainHeading: e.target.value
                                            })
                                        }
                                    }}
                                />
                            </Box>
                            <Box mt={4}>
                                <label>Subheading<LightTooltip title="Subheading for data handling" placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                <EditableRichText
                                    editMode={editable}
                                    value={props.insight.dataHandlingSubHeading}
                                    onChange={editorState => { onEditorStateChange("dataHandlingSubHeading", editorState) }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                {/* Additional uses of data */}

                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Additional uses of data</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <Box className={`details-box background-grey form-group`}>
                            <Box>
                                <label>Main Heading</label>
                                <EditableText
                                    editMode={editable}
                                    name={"additionalUsesOfData.mainHeading"}
                                    value={props.insight.additionalUsesOfData?.mainHeading}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            handleAdditionalUsesOfDataChange({
                                                ...props.insight.additionalUsesOfData,
                                                mainHeading: e.target.value
                                            })
                                        }
                                    }}
                                />
                            </Box>
                            <Box mt={4}>
                                <label>Subheading<LightTooltip title="Subheading for data handling" placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                <EditableText
                                    editMode={editable}
                                    name={"additionalUsesOfData.subheading"}
                                    value={props.insight.additionalUsesOfData?.subheading}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            handleAdditionalUsesOfDataChange({
                                                ...props.insight.additionalUsesOfData,
                                                subheading: e.target.value
                                            })
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box className={`details-box background-grey form-group`} mt={4}>
                            <Box display="flex" justifyContent={'space-between'}>
                                <label>Categorisation Improvements</label>
                                <FormControl>
                                    <SwitchWithLabel 
                                        checked={props.insight.additionalUsesOfData?.categorisation?.include || false}
                                        disabled={!editable}
                                        onChange={value => {
                                            handleAdditionalUsesOfDataChange({
                                                ...props.insight.additionalUsesOfData,
                                                categorisation: {
                                                    ...props.insight.additionalUsesOfData?.categorisation,
                                                    include: value
                                                }
                                            })
                                        }}>
                                    {props.insight.additionalUsesOfData?.categorisation?.include ? "Include" : "Exclude"}
                                    </SwitchWithLabel>
                                </FormControl>
                            </Box>
                            <Box mt={2}>
                                <label>Label</label>
                                <EditableText
                                    textArea
                                    editMode={editable}
                                    name={"additionalUsesOfData.subheading"}
                                    value={props.insight.additionalUsesOfData?.categorisation?.label}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            handleAdditionalUsesOfDataChange({
                                                ...props.insight.additionalUsesOfData,
                                                categorisation: {
                                                    ...props.insight.additionalUsesOfData?.categorisation,
                                                    label: e.target.value
                                                }
                                            })
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box className={`details-box background-grey form-group`} mt={4}>
                            <Box display="flex" justifyContent={'space-between'}>
                                <label>Spend Analytics</label>
                                <FormControl>
                                    <SwitchWithLabel 
                                        checked={props.insight.additionalUsesOfData?.spendAnalytics?.include || false}
                                        disabled={!editable}
                                        onChange={value => {
                                            handleAdditionalUsesOfDataChange({
                                                ...props.insight.additionalUsesOfData,
                                                spendAnalytics: {
                                                    ...props.insight.additionalUsesOfData?.spendAnalytics,
                                                    include: value
                                                }
                                            })
                                        }}>
                                    {props.insight.additionalUsesOfData?.spendAnalytics?.include ? "Include" : "Exclude"}
                                    </SwitchWithLabel>
                                </FormControl>
                            </Box>
                            <Box mt={2}>
                                <label>Label</label>
                                <EditableText
                                    textArea
                                    editMode={editable}
                                    name={"additionalUsesOfData.subheading"}
                                    value={props.insight.additionalUsesOfData?.spendAnalytics?.label}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            handleAdditionalUsesOfDataChange({
                                                ...props.insight.additionalUsesOfData,
                                                spendAnalytics: {
                                                    ...props.insight.additionalUsesOfData?.spendAnalytics,
                                                    label: e.target.value
                                                }
                                            })
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box className={`details-box background-grey form-group`} mt={4}>
                            <Box display="flex" justifyContent={'space-between'}>
                                <label>Marketing</label>
                                <FormControl>
                                    <SwitchWithLabel 
                                        checked={props.insight.additionalUsesOfData?.marketing?.include || false}
                                        disabled={!editable}
                                        onChange={value => {
                                            handleAdditionalUsesOfDataChange({
                                                ...props.insight.additionalUsesOfData,
                                                marketing: {
                                                    ...props.insight.additionalUsesOfData?.marketing,
                                                    include: value
                                                }
                                            })
                                        }}>
                                    {props.insight.additionalUsesOfData?.marketing?.include ? "Include" : "Exclude"}
                                    </SwitchWithLabel>
                                </FormControl>
                            </Box>
                            <Box mt={2}>
                                <label>Label</label>
                                <EditableText
                                    textArea
                                    editMode={editable}
                                    name={"additionalUsesOfData.subheading"}
                                    value={props.insight.additionalUsesOfData?.marketing?.label}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            handleAdditionalUsesOfDataChange({
                                                ...props.insight.additionalUsesOfData,
                                                marketing: {
                                                    ...props.insight.additionalUsesOfData?.marketing,
                                                    label: e.target.value
                                                }
                                            })
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Frequency of data collection text</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl>
                            <SwitchWithLabel
                                checked={props.insight.includeFrequencyOfDataCollectionText}
                                onChange={value => {handleInsightChange({...props.insight, includeFrequencyOfDataCollectionText: value})}}>
                                {props.insight.includeFrequencyOfDataCollectionText ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                        <Box className={`details-box background-grey form-group`}>
                            <Box>
                                <label>Main Heading</label>
                                <EditableText
                                    editMode={editable}
                                    name={"frequencyOfDataCollectionTextMainHeading"}
                                    value={props.insight.frequencyOfDataCollectionTextMainHeading}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            handleInsightChange({
                                                ...props.insight,
                                                frequencyOfDataCollectionTextMainHeading: e.target.value
                                            })
                                        }
                                    }}
                                />
                            </Box>
                            <Box mt={4}>
                                <label>Main Text</label>
                                <EditableText
                                    textArea
                                    editMode={editable}
                                    name={"frequencyOfDataCollectionTextMainText"}
                                    value={props.insight.frequencyOfDataCollectionTextMainText}
                                    eventHandlers={{
                                        onChange: (e: any) => {
                                            handleInsightChange({
                                                ...props.insight,
                                                frequencyOfDataCollectionTextMainText: e.target.value
                                            })
                                        }
                                    }}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>


                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Sharing period type</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl variant="outlined" className="w-100">
                        <Select
                            name="sharing-period-type"
                            value={props.insight.sharingPeriodType || "default"}
                            onChange={(event: SelectChangeEvent) => {
                                handleInsightChange({
                                    ...props.insight,
                                    sharingPeriodType: event.target.value as string,
                                    fixedSharingPeriod: props.insight.fixedSharingPeriod || ""
                                })
                            }}
                        >
                            <MenuItem value={"default"}>Default (selectable)</MenuItem>
                            <MenuItem value={"fixed"}>Fixed</MenuItem>
                        </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Display Data Deletion section</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl>
                            <SwitchWithLabel
                                checked={props.insight.includeDataDeletionStandardText}
                                onChange={value => {handleInsightChange({...props.insight, includeDataDeletionStandardText: value})}}>
                                {props.insight.includeDataDeletionStandardText ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Replace an existing consent text</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <FormControl>
                            <SwitchWithLabel
                                checked={props.insight.includeReplaceAnExistingConsentText}
                                onChange={value => {handleInsightChange({...props.insight, includeReplaceAnExistingConsentText: value})}}>
                                {props.insight.includeReplaceAnExistingConsentText ? "Include" : "Exclude"}
                            </SwitchWithLabel>
                        </FormControl>
                    </Grid>
                </Grid>

            </div>
        </form>   

        {/* Branding image validation error popup */}
        <Popup
            key={"image-validation-error-popup"}
            open={openIVE}
            withCloseButton
            onClose={closeIVEPopup}
            buttons={<Button onClick={closeIVEPopup} variant={"contained"} color={"secondary"}>Close</Button>}
        >
            <Typography color={"primary"}>
                <h2>Invalid image size</h2>
                <p>Your brandmark should be no larger than 250px by 250px.</p>
            </Typography>
        </Popup>

        {/* Accreditation icon image validation error popup */}
        <Popup
            key={"image-validation-error-popup-2"}
            open={openIVE2}
            withCloseButton
            onClose={closeIVEPopup2}
            buttons={<Button onClick={closeIVEPopup2} variant={"contained"} color={"secondary"}>Close</Button>}
        >
            <Typography color={"primary"}>
                <h2>Invalid Image File</h2>
                <p>Image to be uploaded must either be a JPG or PNG file.</p>
            </Typography>
        </Popup>

        {/* Accreditation icon image validation error popup */}
        <Popup
            key={"image-validation-error-popup-3"}
            open={openIVE3}
            withCloseButton
            onClose={closeIVEPopup3}
            buttons={<Button onClick={closeIVEPopup3} variant={"contained"} color={"secondary"}>Close</Button>}
        >
            <Typography color={"primary"}>
                <h2>Max Upload File Size Exceeded</h2>
                <p>File uploaded must be less than 5MB in size.</p>
            </Typography>
        </Popup>

        {/* CDR policy PDF validation error popup */}
        <Popup
            key={"pdf-validation-error-popup"}
            open={openPVE}
            withCloseButton
            onClose={closePVEPopup}
            buttons={<Button onClick={closePVEPopup} variant={"contained"} color={"secondary"}>Close</Button>}
        >
            <Typography color={"primary"}>
                <h2>Invalid PDF File</h2>
                <p>The file you are uploading does not appear to be a valid PDF file.</p>
            </Typography>
        </Popup>
    </>
}

export default EditInsightSettings;