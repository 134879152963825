import React, { PropsWithChildren } from "react";

import { Box, useTheme } from "@mui/material";

export interface SwitchProps {
    checked: boolean;
    onChange?: (newCheckedState: boolean) => void;
    disabled?: boolean;
    children?: React.ReactNode;
}

const Switch = (props: PropsWithChildren<SwitchProps>) => {
    const theme = useTheme();
    const style = {
        checked: {
            backgroundColor: theme.palette.warning.main
        },
        unchecked: {
            // backgroundColor: lighten(theme.palette.warning.main, 0.66)
            backgroundColor: "#bbbbbb"
        }
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter' && props.onChange) {
            props.onChange(!props.checked);
        }
    };

    return <div className="switch">
        <label className={"switch-content"}>
            {props.children}
            <input
                checked={props.checked}
                onChange={(event) => {
                    if (props.onChange) {
                        props.onChange(event.currentTarget.checked)
                    }
                }}
                disabled={props.disabled}
                className="switch-checkbox"
                type="checkbox"
            />
            {(!props.disabled) ?
                <Box component={"div"} className={"switch-background test-switch"} sx={props.checked ? style.checked : style.unchecked} tabIndex={0} onKeyDown={handleKeyDown}>
                    <div className={`switch-button`} />
                </Box>
                : ""}

        </label>
    </div>;
}

export default Switch;
