import React from "react";
import { Grid } from "@mui/material";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface AccountsListItemProps {
    id?: number;
    name?: string;
    reference_code?: string
    status?: string;
    accountDetailsPath: string;
}

const AccountsListItem = (props: AccountsListItemProps) => {
    return <Grid container className={"consent-list-item"}>
        <Grid item xs={6} md={1}>{props.id}</Grid>
        <Grid item xs={10} md={5}>{props.name}</Grid>
        <Grid item xs={6} md={3}>{props.reference_code}</Grid>
        <Grid item xs={6} md={1}>{props.status}</Grid>
        <Grid item xs={6} md={2}><Link color={"secondary"} to={props.accountDetailsPath+ `/${props.id}`}><b><u>View details</u> <FontAwesomeIcon icon={faChevronRight} /></b></Link></Grid>
    </Grid>
}

export default AccountsListItem;


