import React from "react";

import { Box, Button, Grid, Hidden } from "@mui/material";

import ButtonBlock from "../components/ButtonBlock";
import Card from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LinkButton from "../components/LinkButton";
import LoadingSpinner from "../components/LoadingSpinner";
import PrimaryBlock from "../components/PrimaryBlock";
import SecondaryBlock from "../components/SecondaryBlock";
import GeneralSettings from "../models/GeneralSettings";
import PreConsent from "../models/PreConsent";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import { sanitize } from "../helpers/HtmlSanitizerHelper";
import DeveloperTools from "../models/DeveloperTools";
import { useNavigate } from "react-router-dom";

interface ProductValuePropositionProps {
    next: string;
    preConsentConfig?: PreConsent;
    generalSettingsConfig?: GeneralSettings;
    developerToolsConfig?: DeveloperTools;
    hasApiError?: boolean;
    api: OpenBankingPlatformAPI;
    basePath: string;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
    pvpFeatureKeyVisualImageUrl?: string;
    pvpFeature1ImageUrl?: string;
    pvpFeature2ImageUrl?: string;
    pvpFeature3ImageUrl?: string;
    isTrustedAdviser?: boolean;
}


const ProductValueProposition = (props: ProductValuePropositionProps) => {

    const getColumnSize = (numberOfColumns: Number) => {
        if (numberOfColumns === 2) return 6;
        if (numberOfColumns === 3) return 4;
        return 12;
    };
    const columnSize = getColumnSize(props.preConsentConfig?.pvpNumberOfFeatures ?? 0);

    const incUnknownError = props.developerToolsConfig && props.developerToolsConfig?.incUnknownError;
    
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1);
    };

    return <div className={"page-wrapper product-value-proposition"}>
        <div className={"page-top"}>
            <main>
                <Header
                    generalSettings={props.generalSettingsConfig}
                    principalLogoUrl={props.principalLogoUrl}
                    headerBgImageUrl={props.headerBgImageUrl}
                    isTrustedAdviser={props.isTrustedAdviser}
                />
                {(incUnknownError) ?
                    <div className={"pager"}>
                        <h3 className={"error-title"}>Unknown Error</h3>
                        <p className={"error-text"}>We're sorry but our system has encountered an error. Please try again later or contact support.</p>
                    </div> :
                    (props.generalSettingsConfig)
                        ?
                        <>
                            <PrimaryBlock>
                                <Grid container spacing={2}>
                                    {props.preConsentConfig?.inclKeyVisual && props.pvpFeatureKeyVisualImageUrl &&
                                        <>
                                            <Grid item md={4} sm={12}>
                                                <div className={"hero-image"}>
                                                    <img src={props.pvpFeatureKeyVisualImageUrl} alt={""} />
                                                </div>
                                            </Grid>
                                            <Hidden smDown><Grid item md={1}></Grid></Hidden>
                                        </>
                                    }
                                    <Grid item md={7} sm={12} className="justify-content-center align-self-center">
                                        {props.preConsentConfig?.inclKeyText ? (
                                            <>
                                                <h2>{sanitize(props.preConsentConfig?.keyTextHeading)}</h2>
                                                <h1>{sanitize(props.generalSettingsConfig.principalName)}</h1>
                                                <Box mt={2} mb={3}>
                                                    {sanitize(props.preConsentConfig?.keyText)}
                                                </Box>
                                            </>
                                        ) : ""}
                                    </Grid>
                                </Grid>
                            </PrimaryBlock>

                            <SecondaryBlock header={props.preConsentConfig?.pvpFeatureHeading || "About " + props.generalSettingsConfig.principalName} textAlign={'center'}>
                                {(props.preConsentConfig && props.preConsentConfig?.pvpNumberOfFeatures > 0) &&
                                    <div className={"items"}>
                                        <Grid container spacing={2}>
                                            <Grid item md={columnSize} sm={12}>
                                                <Card image={props.pvpFeature1ImageUrl ?? ""}>
                                                    {sanitize(props.preConsentConfig?.pvpFeature1Text)}
                                                </Card>
                                            </Grid>
                                            {(props.preConsentConfig.pvpNumberOfFeatures >= 2) &&
                                                <Grid item md={columnSize} sm={12}>
                                                    <Card image={props.pvpFeature2ImageUrl ?? ""}>
                                                        {sanitize(props.preConsentConfig?.pvpFeature2Text)}
                                                    </Card>
                                                </Grid>
                                            }
                                            {(props.preConsentConfig.pvpNumberOfFeatures >= 3) &&
                                                <Grid item md={columnSize} sm={12}>
                                                    <Card image={props.pvpFeature3ImageUrl ?? ""}>
                                                        {sanitize(props.preConsentConfig?.pvpFeature3Text)}
                                                    </Card>
                                                </Grid>
                                            }
                                        </Grid>
                                    </div>
                                }
                            </SecondaryBlock>

                            <div style={{ paddingTop: 30, paddingBottom: 50, paddingLeft: 16, paddingRight: 16, textAlign: 'center' }}>
                                <ButtonBlock>
                                    <Button onClick={handleGoBack} variant={"outlined"} color={"secondary"}>Cancel</Button>
                                    <LinkButton targetlocation={props.next} variant={"contained"} color={"secondary"}>{sanitize(props.preConsentConfig?.ctaText)}</LinkButton>
                                </ButtonBlock>
                            </div>
                        </>
                        : (props.hasApiError)
                            ? <>
                                <h3 className={"error-title"}>Error</h3>
                                <p className={"error-text"}>We're sorry but our system has encountered an error. Please try
                                    again later or contact support.</p>
                            </>
                            : <LoadingSpinner position={"fixed"} overlay />
                }
            </main>
        </div>

        {!props.isTrustedAdviser && <Footer generalSettingsConfig={props.generalSettingsConfig} isTrustedAdvisor={props.isTrustedAdviser} />}

    </div>;
}

export default ProductValueProposition;