import React from "react";
import PrincipalBillingConfiguration from "../../models/PrincipalBillingConfiguration";
import {Grid} from "@mui/material";
import LightTooltip from "../LightTooltip";
import InfoIcon from "@mui/icons-material/Info";
import EditableText from "../EditableText";
import BillingModelHybrid6 from "../../models/billingmodels/BillingModelHybrid6";

interface BillingModelHybrid6ConfigProps {
    modelConfiguration?: PrincipalBillingConfiguration;
    onBillingModelUpdate: (config: PrincipalBillingConfiguration) => void;
}

const BillingModelHybrid6Config = (props: BillingModelHybrid6ConfigProps) => {
    const billingModelType = 'BillingModelHybrid6';

    const connectInstitutionAndFirstTimeDataPullCost = props.modelConfiguration?.billingModelHybrid6?.connect_user_and_first_time_data_pull_cost;
    const refreshDataCost = props.modelConfiguration?.billingModelHybrid6?.refresh_data_cost;

    return <>
        <h4>Model 6</h4>

        <Grid container className={"mt-10"}>
            <Grid item xs={12} sm={7} md={5}>
                <label>Connect an institution / First time data pull<LightTooltip title="Connect an institution" placement="right-start"
                                                   arrow><InfoIcon
                    color={"secondary"}/></LightTooltip></label>
            </Grid>
            <Grid item xs={12} sm={4} md={3} className={"background-grey"}>
                <EditableText
                    type={"number"}
                    editMode={true}
                    name={"connect_institution_cost"}
                    value={connectInstitutionAndFirstTimeDataPullCost !== undefined ? connectInstitutionAndFirstTimeDataPullCost as unknown as string : ""}
                    eventHandlers={{
                        onChange: (e: any) => {
                            const newBillingModel: BillingModelHybrid6 = {
                                ...props.modelConfiguration?.billingModelHybrid6,
                                connect_user_and_first_time_data_pull_cost: e.target.value
                            }
                            const newBillingModelConfiguration: PrincipalBillingConfiguration = {
                                ...props.modelConfiguration,
                                selectedBillingModelType: billingModelType,
                                billingModelHybrid6: newBillingModel
                            };
                            props.onBillingModelUpdate(newBillingModelConfiguration);
                        }
                    }}
                />
            </Grid>
        </Grid>
        <Grid container className={"mt-20"}>
            <Grid item xs={12} sm={7} md={5}>
                <label>Refresh data<LightTooltip title="Refresh data" placement="right-start"
                                                       arrow><InfoIcon
                    color={"secondary"}/></LightTooltip></label>
            </Grid>
            <Grid item xs={12} sm={4} md={3} className={"background-grey"}>
                <EditableText
                    type={"number"}
                    editMode={true}
                    name={"refresh_data_cost"}
                    value={refreshDataCost !== undefined ? refreshDataCost as unknown as string : ""}
                    eventHandlers={{
                        onChange: (e: any) => {
                            const newBillingModel: BillingModelHybrid6 = {
                                ...props.modelConfiguration?.billingModelHybrid6,
                                refresh_data_cost: e.target.value
                            }
                            const newBillingModelConfiguration: PrincipalBillingConfiguration = {
                                ...props.modelConfiguration,
                                selectedBillingModelType: billingModelType,
                                billingModelHybrid6: newBillingModel
                            };
                            props.onBillingModelUpdate(newBillingModelConfiguration);
                        }
                    }}
                />
            </Grid>
        </Grid>
    </>
}

export default BillingModelHybrid6Config;