import React from "react";

import { CircularProgress, Typography } from "@mui/material";

interface LoadingSpinnerProps {
    loadingText?: string;
    position?: 'default' | 'inline-block' | 'absolute' | 'fixed';
    overlay?: boolean;
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
    return <div className={`loading-spinner ${props.position || 'default'} ${props.overlay ? 'overlay' : ''}`}>
        <Typography className={"spinner-content"} component="div">
            <CircularProgress />
            {props.loadingText !== '' &&
                <h4>{props.loadingText !== undefined ? props.loadingText : 'Loading...'}</h4>
            }
        </Typography>
    </div>
}

export default LoadingSpinner;
