import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import React, {useCallback, useState} from "react";
import {isAdmin} from "../../helpers/AuthUserHelper";
import {Button, Grid, Link, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import CurrentUser from "../../models/CurrentUser";
import PrimaryBlock from "../../components/PrimaryBlock";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";

interface CdrConsumerReportingProps {
    api: OpenBankingPlatformAPIInterface;
    hasApiError?: boolean;
    currentUser?: CurrentUser;
    basePath: string;
}

interface CdrConsumerReportingState {
    customerId?: string;
    customerGivenName?: string;
    customerSurname?: string;
    customerEmail?: string;
    reportType?: string;
    exportFormat?: string;
    errorMessage?: string;
}

const CdrConsumerReporting = (props: CdrConsumerReportingProps) => {
    const DATE_FORMAT = "YYYY-MM-DD HH:mm:00 ZZ";

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`${props.basePath}/home`);
    };

    const [state, setState] = useState<CdrConsumerReportingState>({
        reportType: '1',
        exportFormat: 'csv'
    });

    const [selectedFromDate, handleFromDateChange] = useState<Date | null>();
    const [selectedToDate, handleToDateChange] = useState<Date | null>();

    //eslint-disable-next-line
    const downloadReport = useCallback(props.api.downloadCdrConsumerReport, []);
    const handleDownload = async () => {
        let fromDate = selectedFromDate ? moment(selectedFromDate).format(DATE_FORMAT) : undefined;
        let toDate = selectedToDate ? moment(selectedToDate).format(DATE_FORMAT) : undefined;

        setState({
            ...state,
            errorMessage: ""
        })

        await downloadReport(
            state.customerId,
            state.customerGivenName,
            state.customerSurname,
            state.customerEmail,
            fromDate,
            toDate,
            state.reportType,
            state.exportFormat
        ).then(response => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `cdr-consumer-report.${state.exportFormat}`
            );
            document.body.appendChild(link);
            link.click();
            // Clean up and remove the link
            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }
        }).catch((error) => {
            var errorMessageArray: any[] = [];

            // The error response has to be handled as a blob because
            // the original request is download request where the
            // Content-Type is application/json
            error.response.data.text().then((text: any) => {
                const res = JSON.parse(text);
                var allErrors = (res as any).errors;

                for (var i = 0; i < allErrors.length; i++) {
                    var errors = allErrors[i];
                    if ('error' in errors) {
                        errorMessageArray.push(errors.error);
                    }
                }

                setState({
                    ...state,
                    errorMessage: "Failed to download report: " + errorMessageArray.join(", ")
                })
            });
        });
    }

    const cdrConsumerReportingErrorMsg = state.errorMessage ? state.errorMessage : '';

    return <div className="cdr-consumer-reporting">
        {(isAdmin(props.currentUser?.role_id)) ?
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                </Grid>
            </Grid>
            : "" }

        <PrimaryBlock>
            <h2>CDR Consumer Reporting</h2>

            {(state.errorMessage !== '') ? <span className={"error-text"}>{cdrConsumerReportingErrorMsg}</span> : ""}

            <p />

            <form className={"admin-form user-settings-form"} autoComplete={"off"}>
                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Customer ID</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <TextField
                            required
                            variant="outlined"
                            id="customer-id-input"
                            name="customer-id-input"
                            className="w-100"
                            placeholder="Customer ID"
                            value={state.customerId}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    customerId: event.target.value
                                })
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Customer Given Name</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <TextField
                            required
                            variant="outlined"
                            id="customer-given-name-input"
                            name="customer-given-name-input"
                            className="w-100"
                            placeholder="Customer Given Name"
                            value={state.customerGivenName}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    customerGivenName: event.target.value
                                })
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Customer Surname</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <TextField
                            required
                            variant="outlined"
                            id="customer-surname-input"
                            name="customer-surname-input"
                            className="w-100"
                            placeholder="Customer Surname"
                            value={state.customerSurname}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    customerSurname: event.target.value
                                })
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Customer Email</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <TextField
                            required
                            variant="outlined"
                            id="customer-email-input"
                            name="customer-email-input"
                            className="w-100"
                            placeholder="Name"
                            value={state.customerEmail}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    customerEmail: event.target.value
                                })
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Date From</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                inputFormat="dd/MM/yyyy HH:mm"
                                renderInput={(props) => <TextField {...props} />}
                                value={selectedFromDate || null}
                                onChange={handleFromDateChange}
                                onError={console.log}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>

                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Date To</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                inputFormat="dd/MM/yyyy HH:mm"
                                renderInput={(props) => <TextField {...props} />}
                                value={selectedToDate || null}
                                onChange={handleToDateChange}
                                onError={console.log}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>

                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Report Type</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <Select
                            id="report-type-input"
                            name="report-type-input"
                            value={state.reportType}
                            onChange={(event: SelectChangeEvent) => {
                                setState({
                                    ...state,
                                    reportType: event.target.value as string
                                })
                            }}>
                            <MenuItem value={"1"}>Data Collections</MenuItem>
                            <MenuItem value={"2"}>Consents Provided</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid container className={"form-group"}>
                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                        <label>Export As</label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <Select
                            id="report-format-input"
                            name="report-format-input"
                            value={state.exportFormat}
                            onChange={(event: SelectChangeEvent) => {
                                setState({
                                    ...state,
                                    exportFormat: event.target.value as string
                                })
                            }}>
                            <MenuItem value={"csv"}>CSV</MenuItem>
                            <MenuItem value={"pdf"}>PDF</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Button variant={"contained"} color={"secondary"} onClick={handleDownload}>Download</Button>
            </form>
        </PrimaryBlock>
    </div>
}

export default CdrConsumerReporting;