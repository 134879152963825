import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoIcon from "@mui/icons-material/Info";
import { Button, FormControl, Grid, Link, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import DataHolderApi from "../../openbankingplatform/models/DataHolderApi";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import LightTooltip from "../components/LightTooltip";

interface CdrToDdcBankMappingViewProps {
    api: OpenBankingPlatformAPIInterface;
    currentUser?: CurrentUser;
    basePath: string;
}

interface CdrToDdcBankMappingViewState {
    /*cdrToDdcMapping?: CdrToDdcBankMapping;*/
    cdr_dh_id?: number;
    cdr_dh_name?: string;
    ddc_dh_id?: number;
    ddc_dh_name?: string;
    status?: boolean;
    dataHolders?: Array<DataHolderApi>
    backgroundOperationInProgress: boolean;
}

const CdrToDdcBankMappingView = (props: CdrToDdcBankMappingViewProps) => {
    let { id } = useParams<{ id: any }>();

    const [state, setState] = useState<CdrToDdcBankMappingViewState>({
        backgroundOperationInProgress: true
    });

    //eslint-disable-next-line
    const getMappingDetails = useCallback(props.api.getCdrToDdcBankMapping, []);
    useEffect(() => {
            (async () => {
                if (id) {
                    try {
                        setState({
                            ...state,
                            backgroundOperationInProgress: true
                        })
                        const loadMapping = await getMappingDetails(id);
                        setState((state) => ({
                            ...state,
                            backgroundOperationInProgress: false,
                            cdr_dh_id: loadMapping.cdr_dh_id,
                            cdr_dh_name: loadMapping.cdr_dh_name,
                            ddc_dh_id: loadMapping.ddc_dh_id,
                            ddc_dh_name: loadMapping.ddc_dh_name,
                            status: loadMapping.status
                        }))
                    } catch (error) {
                        adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                    }
                } else {
                    setState({
                        ...state,
                        backgroundOperationInProgress: false
                    })
                }
            })();
        },
        //eslint-disable-next-line
        []
    )

    //eslint-disable-next-line
    const getDataHolders = useCallback(props.api.getDataHolders, []);
    useEffect(() => {
            (async () => {
                try {
                    const loadDataHolders = (await getDataHolders()).sort((a,b) =>
                        a.name > b.name ? 1: -1
                    );

                    setState((state)=>({
                        ...state,
                        dataHolders: loadDataHolders,
                    }))

                } catch (error) {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            })();
        },
        //eslint-disable-next-line
        []
    )

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`${props.basePath}/cdr-to-ddc-bank-mappings`);
    };

    //eslint-disable-next-line
    const createMapping = useCallback(props.api.createCdrToDdcBankMapping, []);
    //eslint-disable-next-line
    const updateMapping = useCallback(props.api.updateCdrToDdcBankMapping, []);
    const handleUpdate = () => {
        (async () => {
            try {
                if (id) {
                    setState({
                        ...state,
                        backgroundOperationInProgress: true
                    })
                    await updateMapping({
                        id: id,
                        cdr_dh_id: state.cdr_dh_id,
                        ddc_dh_id: state.ddc_dh_id,
                        status: state.status
                    });
                    setState({
                        ...state,
                        backgroundOperationInProgress: false
                    })
                    navigate(props.basePath + `/cdr-to-ddc-bank-mapping/` + id, {
                        state: "Mapping successfully updated."
                    });
                } else {
                    setState({
                        ...state,
                        backgroundOperationInProgress: true
                    })
                    const newMapping = await createMapping({
                        cdr_dh_id: state.cdr_dh_id,
                        ddc_dh_id: state.ddc_dh_id,
                        status: state.status
                    });
                    setState({
                        ...state,
                        backgroundOperationInProgress: false
                    })
                    navigate(props.basePath + `/cdr-to-ddc-bank-mapping/` + newMapping.id, {
                        state: "Mapping successfully created."
                    });
                }
            } catch (error) {
                setState({
                    ...state,
                    backgroundOperationInProgress: false
                })
                // TODO Uncomment this code when fallback is removed
                setState((state)=>({
                    ...state,
                    apiHasReturnedAnError: true,
                }))
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }

    return <div className="cdr-to-ddc-bank-mappings">
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
            </Grid>
        </Grid>

        <div className="update-mapping">
            <h2><strong>{id?'Update Mapping':'Create Mapping'}</strong></h2>

            {(state.backgroundOperationInProgress)
                ? <LoadingSpinner loadingText={""} />
                : <div>
                    <form className={"admin-form cdr-to-ddc-mappings-form"} autoComplete={"off"}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>CDR Institution<LightTooltip title="Assign the user to an associated Account" placement="right-start"
                                                             arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="cdr_dh_id"
                                        name="cdr_dh_id"
                                        value={state.cdr_dh_id?state.cdr_dh_id:0}
                                        onChange={(event: SelectChangeEvent<number>) => {
                                            setState({
                                                ...state,
                                                cdr_dh_id: event.target.value as number
                                            })
                                        }}
                                    >
                                        <MenuItem value={0}>- Please select -</MenuItem>
                                        {state.dataHolders && state.dataHolders.filter(item => {
                                            return item.is_cdr
                                        }).map((item, i) =>
                                            <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>DDC Institution<LightTooltip title="Assign the user to an associated Account" placement="right-start"
                                                                     arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="cdr_dh_id"
                                        name="cdr_dh_id"
                                        value={state.ddc_dh_id?state.ddc_dh_id:0}
                                        onChange={(event: SelectChangeEvent<number>) => {
                                            setState({
                                                ...state,
                                                ddc_dh_id: event.target.value as number
                                            })
                                        }}
                                    >
                                        <MenuItem value={0}>- Please select -</MenuItem>
                                        {state.dataHolders && state.dataHolders.filter(item => {
                                            return !item.is_cdr
                                        }).map((item, i) =>
                                            <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Status <LightTooltip title="Assign the user to an associated Account" placement="right-start"
                                                                     arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="cdr_dh_id"
                                        name="cdr_dh_id"
                                        value={state.status ? state.status : 0 as unknown as boolean}
                                        onChange={(event: SelectChangeEvent<boolean>) => {
                                            setState({
                                                ...state,
                                                status: event.target.value as boolean
                                            })
                                        }}
                                    >
                                        <MenuItem value={1}>Active</MenuItem>
                                        <MenuItem value={0}>Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Button variant={"contained"} color={"secondary"} onClick={handleUpdate}>Save</Button>
                    </form>
                </div>
            }
        </div>
    </div>
}

export default CdrToDdcBankMappingView;