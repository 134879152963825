import React, { useCallback, useEffect, useState } from "react";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import CurrentUser from "../../models/CurrentUser";
import CdrClientsCertificates from "../models/CdrClientsCertificates";
import {useNavigate} from "react-router-dom";
import {adminRedirectByErrorResponseStatus} from "../../helpers/RedirectHelper";
import {Grid, Hidden, Link} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import PrimaryBlock from "../../components/PrimaryBlock";
import LinkButton from "../../components/LinkButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import CdrClientsCertificatesListItem from "../components/CdrClientsCertificatesListItem";

interface CdrClientsCertificatesListProps {
    api: OpenBankingPlatformAPIInterface;
    currentUser?: CurrentUser,
    basePath: string;
    detailsPath: string;
}

interface CdrClientsCertificatesListState {
    data: Array<CdrClientsCertificates>;
    isLoading: boolean;
}

const CdrClientsCertificatesList = (props: CdrClientsCertificatesListProps) => {
    const [state, setState] = useState<CdrClientsCertificatesListState>({
        data: [],
        isLoading: false,
    });

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`${props.basePath}/home`);
    };

    //eslint-disable-next-line
    const getCdrClientsCertificates = useCallback(props.api.listCdrClientsCertificates, []);
    useEffect(() => {
            (async() => {
                try {
                    setState((state)=>({
                        ...state,
                        isLoading: true,
                    }))
                    const loadedCdrClientsCertificates = await getCdrClientsCertificates();
                    setState((state)=>({
                        ...state,
                        data: loadedCdrClientsCertificates,
                        isLoading: false,
                    }));
                } catch (error) {
                    console.log(error);
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            })();
        },
        //eslint-disable-next-line
        []
    )

    return <div className="mtls-certificates-list">
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
            </Grid>
        </Grid>
        <PrimaryBlock>
            <h2>Manage CDR Clients Certificates</h2>
            <Grid container className={"dashboard-action-bar"}>
                <Grid item xs={12} md={5}>
                    <LinkButton targetlocation={props.detailsPath} variant={"contained"} color={"secondary"}>Add New Certificates</LinkButton>
                </Grid>
            </Grid>
            <div className={"institution-list paginated-table"}>
                <Hidden mdDown>
                    <Grid container className={"table-head"}>
                        <Grid item xs={2}>ID</Grid>
                        <Grid item xs={2}>Account Name</Grid>
                        <Grid item xs={2}>Principal Name</Grid>
                        <Grid item xs={3}>Name</Grid>
                        <Grid item xs={3}></Grid>
                    </Grid>
                </Hidden>
                {!state.isLoading && state.data && (
                    state.data.map((item, i) =>
                        <CdrClientsCertificatesListItem detailsPath={props.detailsPath} key={i} {...item} />
                    )
                )}
                {state.isLoading &&
                <LoadingSpinner loadingText={""} />
                }
            </div>
        </PrimaryBlock>
    </div>
}

export default CdrClientsCertificatesList;