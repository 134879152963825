import React, {ChangeEvent, MutableRefObject, useCallback, useEffect, useRef, useState} from "react";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import CurrentUser from "../../models/CurrentUser";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {adminRedirectByErrorResponseStatus, consentRedirectByErrorResponseStatus} from "../../helpers/RedirectHelper";
import {Box, Button, FormControl, Grid, Link, MenuItem, Select, SelectChangeEvent, TextField} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../../components/LoadingSpinner";
import LightTooltip from "../components/LightTooltip";
import InfoIcon from "@mui/icons-material/Info";
import FileItem from "../components/FileItem";

interface MtlsCertificatesViewProps {
    api: OpenBankingPlatformAPIInterface;
    currentUser?: CurrentUser,
    basePath: string
}

interface MtlsCertificatesViewState {
    id?: number;
    name?: string;
    has_ssl_cert: boolean;
    has_ssl_key: boolean;
    has_ca_info: boolean;
    ssl_cert?: File;
    ssl_key?: File;
    ca_info?: File;
    deleted: boolean;
    downloadMtlsFile?: any;
    downloadMtlsFilename?: string;
    backgroundOperationInProgress: boolean;
    updateMessage: string;
}

const MtlsCertificatesView = (props: MtlsCertificatesViewProps) => {
    let { id } = useParams<{ id: any }>();

    const [state, setState] = useState<MtlsCertificatesViewState>({
        backgroundOperationInProgress: true,
        has_ssl_cert: false,
        has_ssl_key: false,
        has_ca_info: false,
        deleted: false,
        updateMessage: ''
    });

    const location = useLocation();
    useEffect(() => {
        if (location.state) {
            // @ts-ignore
            if (location.state.message) {
                // @ts-ignore
                const updateMessage = location.state.message;
                setState((state)=>({
                    ...state,
                    updateMessage: updateMessage
                }))
            }
            // @ts-ignore
            if (location.state.mtlsCertificateDetails) {
                // @ts-ignore
                const newMtlsCertiticateDetails = location.state.mtlsCertificateDetails;
                setState((state)=>({
                    ...state,
                    id: id,
                    name: newMtlsCertiticateDetails.name,
                    has_ssl_cert: newMtlsCertiticateDetails.has_ssl_cert,
                    has_ssl_key: newMtlsCertiticateDetails.has_ssl_key,
                    has_ca_info: newMtlsCertiticateDetails.has_ca_info,
                    deleted: newMtlsCertiticateDetails.deleted,
                    ssl_cert: undefined,
                    ssl_key: undefined,
                    ca_info: undefined
                }))
                setState((state)=>({
                    ...state,
                    backgroundOperationInProgress: false
                }))
            }
        }
    }, [location, id]);

    const sslCert = useRef() as MutableRefObject<HTMLInputElement>;
    const sslKey = useRef() as MutableRefObject<HTMLInputElement>;
    const caInfo = useRef() as MutableRefObject<HTMLInputElement>;

    const handleCaptureUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) return
        var file = event.target.files[0]

        if (event.currentTarget.id === 'sslCert') {
            setState({
                ...state,
                ssl_cert: file
            })
        }

        if (event.currentTarget.id === 'sslKey') {
            setState({
                ...state,
                ssl_key: file
            })
        }

        if (event.currentTarget.id === 'caInfo') {
            setState({
                ...state,
                ca_info: file
            })
        }
    }

    const handleUploadClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        if (event.currentTarget.id === "linkUploadSslCert") sslCert.current.click();
        if (event.currentTarget.id === "linkUploadSslKey") sslKey.current.click();
        if (event.currentTarget.id === "linkUploadCaInfo") caInfo.current.click();
    }

    //eslint-disable-next-line
    const getMtlsCertificates = useCallback(props.api.getMtlsCertificates, []);
    useEffect(() => {
            (async () => {
                if (id) {
                    try {
                        setState({
                            ...state,
                            backgroundOperationInProgress: true
                        })
                        const loadMtlsCertificates = await getMtlsCertificates(id);
                        setState((state) => ({
                            ...state,
                            backgroundOperationInProgress: false,
                            id: loadMtlsCertificates.id,
                            name: loadMtlsCertificates.name,
                            has_ssl_cert: loadMtlsCertificates.has_ssl_cert,
                            has_ssl_key: loadMtlsCertificates.has_ssl_key,
                            has_ca_info: loadMtlsCertificates.has_ca_info,
                            deleted: loadMtlsCertificates.deleted
                        }))
                    } catch (error) {
                        adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                    }
                } else {
                    setState({
                        ...state,
                        backgroundOperationInProgress: false
                    })
                }
            })();
        },
        //eslint-disable-next-line
        []
    )

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`${props.basePath}/mtls-certificates-list`);
    };

    const handleFileDelete = (name?: string) => {
        if (state.ssl_cert && name === state.ssl_cert?.name) {
            setState({
                ...state,
                ssl_cert: undefined
            })
        }
        if (state.ssl_key && name === state.ssl_key?.name) {
            setState({
                ...state,
                ssl_key: undefined
            })
        }
        if (state.ca_info && name === state.ca_info?.name) {
            setState({
                ...state,
                ca_info: undefined
            })
        }
    }

    // eslint-disable-next-line
    const saveUploadedMtlsFiles = useCallback(props.api.uploadMtlsCertificates, []);
    const handleUpdate = () => {
        (async() => {
            try {
                setState({
                    ...state,
                    backgroundOperationInProgress: true
                })

                let formData = new FormData();
                if (id) {
                    formData.append('id', id);
                }
                if (state.name) {
                    formData.append('name', state.name);
                }
                if (state.deleted) {
                    formData.append('deleted', state.deleted as unknown as string);
                }
                if (state.ssl_cert) {
                    formData.append('ssl_cert', state.ssl_cert);
                }
                if (state.ssl_key) {
                    formData.append('ssl_key', state.ssl_key);
                }
                if (state.ca_info) {
                    formData.append('ca_info', state.ca_info);
                }
                const newMtlsCertificates = await saveUploadedMtlsFiles(formData);

                setState({
                    ...state,
                    backgroundOperationInProgress: false
                })

                if (id) {
                    navigate(props.basePath + `/mtls-certificates/` + id, {
                        state: {
                            message: "Mtls Certificates successfully updated.",
                            mtlsCertificateDetails: newMtlsCertificates
                        }
                    });
                } else {
                    navigate(props.basePath + `/mtls-certificates/` + newMtlsCertificates.id, {
                        state: {
                            message: "Mtls Certificates successfully updated.",
                            mtlsCertificateDetails: newMtlsCertificates
                        }
                    });
                }

            } catch (error) {
                setState({
                    ...state,
                    backgroundOperationInProgress: false
                })

                if ((error as any).response.status === 422) {
                    // @ts-ignore
                    var errorMessageArray = [];
                    var allErrors = (error as any).response.data.errors;
                    for (var i = 0; i < allErrors.length; i++) {
                        var errors = allErrors[i];
                        errorMessageArray.push(errors.error)
                    }

                    setState({
                        ...state,
                        updateMessage: "Update failed: " + errorMessageArray.join(", ")
                    })
                } else {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            }
        })();
    }

    //eslint-disable-next-line
    const getDownloadMtlsFile = useCallback(props.api.downloadMtlsFile, []);
    const handleDownloadMtlsFile = (mtls_certificates_id: number, type: string) => {
        //Send to server
        (async (mtls_certificates_id, type) => {
            try {
                const fileResponse = await getDownloadMtlsFile(mtls_certificates_id, type);
                setState(state => ({
                    ...state,
                    downloadMtlsFile: fileResponse.data,
                    downloadMtlsFilename: fileResponse.headers['content-disposition'].split('filename=')[1]
                }));
            } catch (error) {
                //console.log(error);
                consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath)
            }
        })(mtls_certificates_id, type);
    }
    useEffect(() => {
        if (state.downloadMtlsFile && state.downloadMtlsFilename) {
            let blob = new Blob([state.downloadMtlsFile], { type: 'application/x-x509-ca-cert' });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.setAttribute('download', state.downloadMtlsFilename);
            link.href = window.URL.createObjectURL(blob);
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }, [state.downloadMtlsFile, state.downloadMtlsFilename]);

    return <div className="mtls-certificates">
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
            </Grid>
        </Grid>

        <div className="update-mapping">
            <h2><strong>{id?'Update MTLS Certificates':'Create MTLS Certificates'}</strong></h2>

            {(state.backgroundOperationInProgress)
                ? <LoadingSpinner loadingText={""} />
                : <div>
                    <div>{(state.updateMessage !== '') ? <span className={"update-message-text"}>{state.updateMessage}</span> : ""}</div>

                    <br />

                    <form className={"admin-form cdr-to-ddc-mappings-form"} autoComplete={"off"}>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Name<LightTooltip title="Unique name for this certificates collection" placement="right-start"
                                                                    arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <FormControl variant="outlined" className="w-100">
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Last Name"
                                        value={state.name ? state.name : ''}
                                        onChange={(event) => {
                                            setState({
                                                ...state,
                                                name: event.target.value
                                            })
                                        }}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>SSL Certificate File<LightTooltip title="The SSL certificate file" placement="right-start"
                                                                    arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item md={"auto"} className={"grid-file-input"}>
                                <div className={"file-item-wrap"}>
                                    <Box className={"details-box"}>
                                        <FileItem type={"generic"} name={state.ssl_cert ? state.ssl_cert.name : 'No file chosen'} onDelete={handleFileDelete} />
                                    </Box>
                                    {state.has_ssl_cert && <div><a href="/#" onClick={(e) => {e.preventDefault(); handleDownloadMtlsFile(id, 'sslcert');}} >Download SSL Cert File</a></div>}
                                </div>
                                <Box mt={2} className={"input-file-box"}>
                                    <input
                                        color="primary"
                                        /*accept="application/pdf"*/
                                        type="file"
                                        onChange={e => {
                                            handleCaptureUploadFile(e);
                                            e.target.value = '';
                                        }}
                                        id="sslCert"
                                        style={{ display: 'none', }}
                                        ref={sslCert}
                                    />
                                    <a href={"/#"} id="linkUploadSslCert"
                                       onClick={handleUploadClick}
                                    >
                                        Choose file
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>SSL Key File<LightTooltip title="The SSL key file" placement="right-start"
                                                            arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item md={"auto"} className={"grid-file-input"}>
                                <div className={"file-item-wrap"}>
                                    <Box className={"details-box"}>
                                        <FileItem type={"generic"} name={state.ssl_key ? state.ssl_key.name : 'No file chosen'} onDelete={handleFileDelete} />
                                    </Box>
                                    {state.has_ssl_key && <div><a href="/#" onClick={(e) => {e.preventDefault(); handleDownloadMtlsFile(id, 'sslkey');}} >Download SSL Key File</a></div>}
                                </div>
                                <Box mt={2} className={"input-file-box"}>
                                    <input
                                        color="primary"
                                        /*accept="application/pdf"*/
                                        type="file"
                                        onChange={e => {
                                            handleCaptureUploadFile(e);
                                            e.target.value = '';
                                        }}
                                        id="sslKey"
                                        style={{ display: 'none', }}
                                        ref={sslKey}
                                    />
                                    <a href={"/#"} id="linkUploadSslKey"
                                       onClick={handleUploadClick}
                                    >
                                        Choose file
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>CA Info File<LightTooltip title="The CA info file" placement="right-start"
                                                                 arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item md={"auto"} className={"grid-file-input"}>
                                <div className={"file-item-wrap"}>
                                    <Box className={"details-box"}>
                                        <FileItem type={"generic"} name={state.ca_info ? state.ca_info.name : 'No file chosen'} onDelete={handleFileDelete} />
                                    </Box>
                                    {state.has_ca_info && <div><a href="/#" onClick={(e) => {e.preventDefault(); handleDownloadMtlsFile(id, 'cainfo');}} >Download CA Info File</a></div>}
                                </div>
                                <Box mt={2} className={"input-file-box"}>
                                    <input
                                        color="primary"
                                        /*accept="application/pdf"*/
                                        type="file"
                                        onChange={e => {
                                            handleCaptureUploadFile(e);
                                            e.target.value = '';
                                        }}
                                        id="caInfo"
                                        style={{ display: 'none', }}
                                        ref={caInfo}
                                    />
                                    <a href={"/#"} id="linkUploadCaInfo"
                                       onClick={handleUploadClick}
                                    >
                                        Choose file
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Deactivate<LightTooltip title="Select this if you want to flag this certificates collection as deleted." placement="right-start"
                                                                 arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        id="name-input"
                                        name="name-input"
                                        value={state.deleted ? state.deleted : 0 as unknown as boolean}
                                        onChange={(event: SelectChangeEvent<boolean>) => {
                                            setState({
                                                ...state,
                                                deleted: event.target.value as boolean
                                            })
                                        }}>
                                        <MenuItem value={1}>Yes</MenuItem>
                                        <MenuItem value={0}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Button variant={"contained"} color={"secondary"} onClick={handleUpdate}>Save</Button>
                    </form>
                </div>
            }
        </div>
    </div>
}

export default MtlsCertificatesView;