import React from "react";
import { useNavigate } from "react-router-dom";
import CurrentUser from "../../models/CurrentUser";
import {isAdmin} from "../../helpers/AuthUserHelper";
import {sanitize} from "../../helpers/HtmlSanitizerHelper";
import GeneralSettings from "../../models/GeneralSettings";

interface ConfigurationListItemProps {
    id: number,
    name: string;
    description: string;
    generalSettings: GeneralSettings;
    basePath: string;
    accountId?: number;
    principal_id: number;
    currentUser?: CurrentUser;
}

const DashboardConfigurationCard = (props: ConfigurationListItemProps) => {
    const navigate = useNavigate();

    const handleViewEdit = () => {
        if (isAdmin(props.currentUser?.role_id)) {
            navigate(`${props.basePath}/account/${props.accountId}/configuration/${props.id}`);
        } else {
            navigate(`${props.basePath}/account/configuration/${props.id}`);
        }
    }

    return <div className={"configuration-item"}>
        <h6>{sanitize(props.name)}</h6>
        {sanitize(props.description) && <p>{sanitize(props.description)}</p>}
        { !props.generalSettings.isActive ?
            <p style={{ color: "red" }}>Inactive</p> : ""
        }
        <a href="/#" onClick={(e) => {e.preventDefault(); handleViewEdit();}} >View / Edit</a>
    </div>;
}

export default DashboardConfigurationCard;
