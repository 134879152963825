import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import React from "react";

interface FileItemProps {
    type: "image" | "generic";
    name?: string;
    url?: string;
    onDelete?: (name?: string) => void;
}

const FileItem = (props: FileItemProps) => {

    const handleClick = () => {
        props.onDelete && props.onDelete(props.name);
    }

    return <div className={"file-item"}>
        {props.url && props.type === "image" ? <div><img src={props.url} alt="" style={{maxWidth:120, marginBottom:8}} /></div> : <div/>}
        {props.type === "image" ? (!props.url ? <ImageOutlinedIcon/> : "") : <InsertDriveFileOutlinedIcon/>}
        {props.name && <span>{props.name}</span>}
        {props.name !== 'No file chosen' && props.onDelete ? <IconButton size="small" onClick={handleClick}><ClearIcon fontSize="small" /></IconButton> : ""}
    </div>;
}

export default FileItem;
