export const USER_ROLE_ILLION_ADMINISTRATOR = 1;
export const USER_ROLE_CLIENT_MANAGER = 2;
export const USER_ROLE_STANDARD_USER = 3;
export const USER_ROLE_DEVELOPER = 4;
export const USER_ROLE_MORTGAGE_BROKER_AGGREGATOR = 5;
export const USER_ROLE_SUPER_ADMINISTRATOR = 99;

export function isAdmin(role_id: number|undefined) {
    return isSuperAdmin(role_id) || isIllionAdmin(role_id)
}

export function isSuperAdmin(role_id: number|undefined) {
    return role_id === USER_ROLE_SUPER_ADMINISTRATOR;
}

export function isIllionAdmin(role_id: number|undefined) {
    return role_id === USER_ROLE_ILLION_ADMINISTRATOR;
}

export function isClientManager(role_id: number|undefined) {
    return role_id === USER_ROLE_CLIENT_MANAGER;
}

export function isStandardUser(role_id: number|undefined) {
    return role_id === USER_ROLE_STANDARD_USER;
}

export function isAdminOrClientManager(role_id: number|undefined) {
    return isAdmin(role_id) || isClientManager(role_id);
}

export function isAdminOrMortgageBroker(role_id: number|undefined) {
    return isAdmin(role_id) || isMortgageBroker(role_id);
}

export function isClientManagerOrStandardUser(role_id: number|undefined) {
    return isClientManager(role_id) || isStandardUser(role_id) || isDeveloper(role_id);
}

export function isDeveloper(role_id: number|undefined) {
    return role_id === USER_ROLE_DEVELOPER;
}

export function isMortgageBroker(role_id: number|undefined) {
    return role_id === USER_ROLE_MORTGAGE_BROKER_AGGREGATOR;
}