import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Grid, Hidden, InputBase, Link } from "@mui/material";

import LinkButton from "../../components/LinkButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import AccountsListItem from "../components/AccountsListItem";
import Account from "../models/Account";

interface AccountsListState {
    data: Array<Account>;
    isLoading: boolean;
    keyword: string;
}

interface AccountsListProps {
    api: OpenBankingPlatformAPIInterface;
    accountDetailsPath: string;
    hasApiError?: boolean;
    basePath: string;
    currentUser?: CurrentUser,
}

const AccountsList = (props: AccountsListProps) => {
    const [state, setState] = useState<AccountsListState>({
        data: [],
        isLoading: false,
        keyword: '',
    });

    const navigate = useNavigate();

    const handleGoBack = () => {
        //navigate(-1);
        navigate(`${props.basePath}/home`);
    };

    //eslint-disable-next-line
    const getAccounts = useCallback(props.api.getAccountsList, []);
    useEffect(() => {
            (async () => {
                try {
                    setState((state)=>({
                        ...state,
                        isLoading: true,
                    }))
                    const loadedAccounts = await getAccounts();
                    setState((state)=>({
                        ...state,
                        data: loadedAccounts,
                        isLoading: false,
                    }));
                } catch (error) {
                    // TODO Uncomment this code when fallback is removed
                    /*setState((state)=>({
                        ...state,
                        apiHasReturnedAnError: true,
                        isLoading: false,
                    }))
                    navigate("/token-error");*/
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            })();
        },
        //eslint-disable-next-line
        []
    )

    return <div className="users-list">
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
            </Grid>
        </Grid>
        <h2>Manage Accounts</h2>
        <Grid container className={"dashboard-action-bar"}>
            <Grid item xs={12} md={5}>
                <LinkButton targetlocation={props.accountDetailsPath} params={ { state: { isNewAccount: true } } } variant={"contained"} color={"secondary"}>Add New Account</LinkButton>
            </Grid>
            <Grid item xs={12} md={7}>
                <Grid container className={"filters-bar"}>
                    <Grid item xs={10}>
                        <InputBase
                            id={"keyword-input"}
                            placeholder={"Enter search"}
                            inputProps={{ "aria-label": "Enter search" }}
                            onChange={event => {
                                setState({ ...state, keyword: event.target.value.toLowerCase() });
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button id={"search-btn"} variant={"contained"} color={"secondary"}>
                            <Hidden xsDown>Search</Hidden>
                            <Hidden smUp><SearchIcon/></Hidden>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <div className={"institution-list paginated-table"}>
            <Hidden mdDown>
                <Grid container className={"table-head"}>
                    <Grid item xs={1}>ID</Grid>
                    <Grid item xs={5}>Name</Grid>
                    <Grid item xs={3}>Reference Code</Grid>
                    <Grid item xs={1}>Status</Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </Hidden>
            {!state.isLoading && state.data && (
                state.data.filter(item => {
                    return (item.name?.toLowerCase().includes(state.keyword) || state.keyword === "")
                }).map((item, i) =>
                    <AccountsListItem accountDetailsPath={props.accountDetailsPath} key={i} {...item} />
                )
            )}
            {state.isLoading &&
                <LoadingSpinner loadingText={""} />
            }
        </div>
    </div>
}

export default AccountsList;