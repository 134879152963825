import Insight from "../models/Insight";

export const VERIFY_IDENTITY = "verify_identity";
export const VERIFY_IDENTITY_HOW_WAS_GENERATED = "To generate this insight we used ‘Name, occupation, contact details’ that we collected from [Data holder name].";
export const VERIFY_IDENTITY_NOT_VALID_ERROR_MSG = "This insight requires the 'Name, occupation, contact details’ data cluster to be shared with us. First, select the data cluster above, then select this insight."

export const VERIFY_ACCOUNT_BALANCE = "verify_account_balance";
export const VERIFY_ACCOUNT_BALANCE_HOW_WAS_GENERATED = "To generate this insight we used ‘Account balance and details’ that we collected from [Data holder name].";
export const VERIFY_ACCOUNT_BALANCE_NOT_VALID_ERROR_MSG = "This insight requires the ‘Account balance and details’ data cluster to be shared with us. First, select the data cluster above, then select this insight.";

export const VERIFY_INCOME = "verify_income";
export const VERIFY_INCOME_HOW_WAS_GENERATED = "To generate this insight we used the data clusters that we collected from [Data holder name].";
export const VERIFY_INCOME_NOT_VALID_ERROR_MSG = "This insight requires all data clusters to be shared with us. First, select the data clusters above, then select this insight.";

export const VERIFY_BANK_ACCOUNT = "verify_bank_account";
export const VERIFY_BANK_ACCOUNT_HOW_WAS_GENERATED = "To generate this insight we used ‘Account balance and details’ that we collected from [Data holder name].";
export const VERIFY_BANK_ACCOUNT_NOT_VALID_ERROR_MSG = "This insight requires the 'Transaction details’ data cluster to be shared with us. First, select the data cluster above, then select this insight.";

export const MACRO_DATA_HOLDER_NAME = "[Data holder name]";
export const MACRO_NON_ACCREDITED_3RD_PARTY_NAME = "[Non-accredited Third Party Name]";
export const MACRO_PURPOSE = "[purpose]";
export const MACRO_NON_ACCREDIRED_PERSON = "[Non-accredited person]";
export const MACRO_SELECTED_TIME_PERIOD = "[Selected time period]";

export function getInsightConfig(insightOptionIdentifier: string, insight?: Insight) {
    if (insight) {
        switch (insightOptionIdentifier) {
            case VERIFY_IDENTITY:
                return {
                    'name': insight.verifyIdentityName,
                    'description': insight.incIdentityDescription,
                };
                
            case VERIFY_ACCOUNT_BALANCE:
                return {
                    'name': insight.verifyBalanceName,
                    'description': insight.incBalanceDescription,
                };

            case VERIFY_INCOME:
                return {
                    'name': insight.verifyIncomeName,
                    'description': insight.incIncomeDescription,
                };

            case VERIFY_BANK_ACCOUNT:
                return {
                    'name': insight.verifyBankAccountName,
                    'description': insight.incBankAccountDescription,
                };

        }
    } else {
        return {
            'name': '',
            'description': ''
        }
    }
}

