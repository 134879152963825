import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, ButtonProps, FormControl, MenuItem, Select, Grid } from "@mui/material";
import OpenBankingPlatformAPIInterface from "../openbankingplatform/OpenBankingPlatformAPIInterface";
import Papa from "papaparse";
import Principal from "../admin/models/Principal";
import { adminRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import Popup from "../popups/Popup";
import ButtonBlock from "./ButtonBlock";


interface LinkButtonProps extends ButtonProps {
    api: OpenBankingPlatformAPIInterface;
    children?: React.ReactNode;
    accountid?: any;
    basepath: string;
    msghandler(msg:any): any;
    principalidhandler(principalId:number): any;
};

const ImportBrokersCSV = (props: PropsWithChildren<LinkButtonProps>) => {

    const [open, setOpen] = React.useState(false);
    const openPopup = () => {
        setOpen(true);
    };
    const closePopup = () => {
        setPrincipalId('0');
        setMatrixInvalidValues([]);
        setOpen(false);
    };

    const [principals, setPrincipals] = useState<Principal[]>();
    const [principalId, setPrincipalId] = useState('0');
    const [csvFile, setCsvFile] = useState();
    const fileInput = React.useRef<HTMLInputElement>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [matrixInvalidValues, setMatrixInvalidValues] = useState<string[]>([]);
    const [failedAttempts, setFailedAttempts] = useState<number>(parseInt(sessionStorage.getItem('brokersCSVImportFailedAttempts') || process.env.REACT_APP_CSV_IMPORT_FAILED_ATTEMPTS || '2'));

    //eslint-disable-next-line
    const importBrokers = useCallback(props.api.doBrokersImport, []);
    const handleOnChange = (e: any) => {
        Papa.parse(e, {
            header: false, // colums are customisable, so we need which columns are imported
            complete: function (results: any) {
                (async () => {
                    await importBrokers(results.data, props.accountid, principalId).then(response => {                        
                        if (response.status === 'Success') {
                            closePopup();
                            props.msghandler(response.message);
                        } else {
                            if (failedAttempts > 0) {
                                setFailedAttempts(failedAttempts-1);
                            }
                            setMatrixInvalidValues(response.matrixInvalidValues);
                        }
                    })
                })();
            }
        })
    }

    const handleOpenPopup = () => {
        openPopup();
    }

    const navigate = useNavigate();

    // eslint-disable-next-line
    const getPrincipals = useCallback(props.api.getPrincipalsByAccountId, []);
    useEffect(() => {
        (async () => {
            try {    
                const loadedPrincipals = await getPrincipals(props.accountid);
                setPrincipals(loadedPrincipals);
            } catch (error) {
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basepath);
            }
        })();
    },
    //eslint-disable-next-line
    [props.accountid]);


    const handleImport = (e: any) => {
        props.principalidhandler(parseInt(principalId));
        if (fileInput.current) {
            handleOnChange(csvFile);
        }
    }

    const allMandatoryFieldsArePopulated = principalId !== '0' && csvFile && errorMessage === '';

    const CSVExtensionTypes = [
        "text/plain",
        "text/x-csv",
        "application/vnd.ms-excel",
        "application/csv",
        "application/x-csv",
        "text/csv",
        "text/comma-separated-values",
        "text/x-comma-separated-values",
        "text/tab-separated-values"
    ];
    
    const handleChangeFile = (e: any) => {
        if (e.target.files) {
            if (CSVExtensionTypes.includes(e.target.files[0].type)) {
                setErrorMessage('');
                setCsvFile(e.target.files[0]);
            } else {
                setErrorMessage('Invalid file format.');
            }
        }
    }

    return (
        <>
            <Button {...props} onClick={handleOpenPopup}>Import Brokers List</Button>

            <Popup
                heading={"Import Brokers List"}
                open={open}
                onClose={closePopup}
                textColor={'#000000'}
                buttons={
                    <ButtonBlock>
                        <Button onClick={closePopup} variant={"outlined"} color={"secondary"}>Back</Button>        
                        <Button onClick={handleImport} variant={"contained"} color={"secondary"} disabled={!allMandatoryFieldsArePopulated || failedAttempts===0}>Import</Button>
                    </ButtonBlock>
                }
                maxWidth={"xl"}
                >
                    <Grid container className={"form-group"}>
                        <Grid item className={"align-self-center"}>
                            <label>Principal</label>
                        </Grid>
                        <FormControl variant="outlined" className="w-100">
                            <Select
                                id="name-input"
                                name="name-input"
                                defaultValue={0}
                                onChange={(event) => {
                                    setPrincipalId(''+event.target.value);
                                }}>
                                <MenuItem value={0}>- Please select -</MenuItem>
                                {principals && principals.map((item, i) => (
                                    <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item className={"align-self-center"}>
                            <label>Select CSV file</label>
                        </Grid>
                        <FormControl variant="outlined" className="w-100">
                            <input type={"file"} 
                                accept={".csv"} 
                                ref={fileInput}
                                onChange={handleChangeFile}
                            />
                        </FormControl>
                    </Grid>
                    {errorMessage && 
                    <Grid container className={"form-group"}>
                        <span className={"error-text"}>{errorMessage}</span>
                    </Grid>}

                    {matrixInvalidValues.length > 0 && failedAttempts > 0 && <>
                        <br/>
                        <h5>Error information as follows</h5>
                        <Grid container className={"form-group import-table"}>
                            <table className={"error-table"}>
                            <thead>
                            <tr className={"table-head"}>
                                <th>Missing Column</th>
                                <th>Duplicate Rows in CSV</th>
                                <th>Missing Values</th>
                                <th>Invalid Values</th>
                                <th>ACL/ACR Number not in ASIC</th>
                                <th>Banned or Disqualified</th>
                                <th>Broker Already Exists</th>
                                </tr>
                            </thead>
                            <tbody>
                                {matrixInvalidValues.map((value) => (
                                <>
                                    <tr>
                                        <td>{value[0]}</td>
                                        <td>{value[1]}</td>
                                        <td>{value[2]}</td>
                                        <td>{value[3]}</td>
                                        <td>{value[4]}</td>
                                        <td>{value[5] && <>{value[4]} <a href="https://data.gov.au/search?q=banned">check at ASIC website</a></>}</td>
                                        <td>{value[6]}</td>
                                    </tr>
                                </>
                                ))}
                            </tbody>
                            </table>
                        </Grid>
                        <p>You have <b>{failedAttempts}</b> more attempt{failedAttempts > 1 ? 's' : ''} left.</p>
                    </>}

                    {failedAttempts===0 && <div className={"error-text"}>
                        You have reached the limit on the unsuccessful attempts, kindly contact the PAC team to proceed.
                    </div>}

            </Popup>
        </>
    );

}

export default ImportBrokersCSV;


