import React from "react";
import { Container } from "@mui/material";
import PrimaryBlock from "../components/PrimaryBlock";
import GeneralSettings from "../models/GeneralSettings";
import PreConsent from "../models/PreConsent";
import Header from "../components/Header";

interface NotFoundProps {
    generalSettingsConfig?: GeneralSettings;
    preConsentConfig?: PreConsent;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
    isTrustedAdviser?: boolean
}

const NotFound = (props: NotFoundProps) => {
    return <>
        <div className={"page-top"}>
            <Header
                generalSettings={props.generalSettingsConfig}
                principalLogoUrl={props.principalLogoUrl}
                headerBgImageUrl={props.headerBgImageUrl}
                isTrustedAdviser={props.isTrustedAdviser}
            />
            <main>
                <PrimaryBlock>
                    <Container maxWidth="sm" className="success-confirmation">
                        <h2 className="mt-0">404 Page Not Found</h2>
                        <div>
                            <p>Sorry, that page doesn't exist. Please check that the URL was typed correctly.</p>
                        </div>
                    </Container>
                </PrimaryBlock>
            </main>
        </div>
    </>;
}

export default NotFound;