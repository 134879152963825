import React, { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchIcon from "@mui/icons-material/Search";
import { Button, FormControl, Grid, Hidden, InputBase, Link, MenuItem, Select } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import ConsentsListItem from "../components/ConsentsListItem";
import Consent from "../models/Consent";

interface ConsentsListState {
    data: Array<Consent>;
    isLoading: boolean;
    searchSection: string;
    searchKeyword: string;
    offset: number;
    currentPage: number;
    pageCount: number;
}

interface ConsentsListProps {
    api: OpenBankingPlatformAPIInterface;
    consentDetailsPath: string;
    hasApiError?: boolean;
    basePath: string;
    currentUser?: CurrentUser;
}

const ConsentsList = (props: ConsentsListProps) => {

    let perPage: number = 10;

    const [state, setState] = useState<ConsentsListState>({
        data: [],
        isLoading: false,
        searchSection: 'customer',
        searchKeyword: '',
        offset: 0,
        currentPage: 0,
        pageCount: 0,
    });

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`${props.basePath}/home`);
    };

    const handlePageClick = (e: any) => {
        const selectedPage = e.selected;
        const newOffset = selectedPage * perPage;
        setState((state)=>({
            ...state,
            currentPage: selectedPage,
            offset: newOffset,
        }));
    }

    const doSearch = () => {
        (async () => {
            try {
                setState((state)=>({
                    ...state,
                    isLoading: true,
                }));
                const loadedConsents = await getConsents(state.currentPage+1, state.searchKeyword, state.searchSection);
                perPage = loadedConsents.pagination;
                setState((state)=>({
                    ...state,
                    data: loadedConsents.dataset,
                    isLoading: false,
                    pageCount: Math.ceil(loadedConsents.total / perPage),
                }));
                if (state.currentPage > Math.ceil(loadedConsents.total / perPage)-1) {
                    setState((state)=>({
                        ...state,
                        currentPage: 0,
                    }));
                }
            } catch (error) {
                /*
                // TODO Uncomment this code when fallback is removed
                setState((state)=>({
                    ...state,
                    apiHasReturnedAnError: true,
                    isLoading: false,
                }));
                navigate("/token-error");*/
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }

    useEffect(() => {
        doSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.currentPage]);

    //eslint-disable-next-line
    const getConsents = useCallback(props.api.getConsentsList, []);


    return <div className="users-list">
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
            </Grid>
        </Grid>
        <h2>Manage Consents</h2>
        <Grid container className={"dashboard-action-bar"}>
            <Grid item xs={12} md={5}></Grid>
            <Grid item xs={12} md={7}>
                <Grid container className={"filters-bar"}>
                    <Grid item xs={6}>
                        <InputBase
                            id={"keyword-input"}
                            placeholder={"Enter search"}
                            inputProps={{ "aria-label": "Enter search" }}
                            onChange={event => {
                                setState({ ...state, searchKeyword: event.target.value.toLowerCase() });
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl variant="outlined">
                            <Select
                                id="section"
                                name="section"
                                value={state.searchSection}
                                onChange={event => { setState({ ...state, searchSection: event.target.value as string}); }} >
                                <MenuItem value={"customer"}>Customer</MenuItem>
                                <MenuItem value={"principal"}>Principal</MenuItem>
                                <MenuItem value={"data_holder"}>Data Holder Name</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Button id={"search-btn"} variant={"contained"} color={"secondary"} 
                            disabled={state.searchKeyword.length < 3 && state.searchKeyword.length > 0} 
                            onClick={() => doSearch()}
                        >
                            <Hidden xsDown>Search</Hidden>
                            <Hidden smUp><SearchIcon/></Hidden>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <div className={"institution-list paginated-table"}>
            <Hidden mdDown>
                <Grid container className={"table-head"}>
                    <Grid item xs={1}>ID</Grid>
                    <Grid item xs={2}>Customer</Grid>
                    <Grid item xs={2}>Principal</Grid>
                    <Grid item xs={2}>Data Holder</Grid>
                    <Grid item xs={1}>Confirmation PDF</Grid>
                    <Grid item xs={1}>Withdrawal PDF</Grid>
                    <Grid item xs={1}>Status</Grid>
                    <Grid item xs={1}>Created</Grid>
                    <Grid item xs={1}> </Grid>
                </Grid>
            </Hidden>
            {!state.isLoading && state.data && state.data.map((item, i) => {
                return <ConsentsListItem basePath={props.basePath} consentDetailsPath={props.consentDetailsPath} key={i} api={props.api} {...item} />
            })}
            {state.isLoading &&
                <LoadingSpinner loadingText={""} />
            }
            {state.pageCount > 1 &&
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    pageCount={state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            }
        </div>
    </div>
}

export default ConsentsList;