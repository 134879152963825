import React from "react";
import { Grid } from "@mui/material";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface CdrToDdcMappingsListItemProp {
    id?: number;
    cdr_dh_id?: number;
    cdr_dh_name?: string;
    ddc_dh_id?: number;
    ddc_dh_name?: string;
    status?: boolean;
    detailsPath: string;
}

const CdrToDdcMappingsListItem = (props: CdrToDdcMappingsListItemProp) => {
    return <Grid container className={"consent-list-item"}>
        <Grid item xs={6} md={1}>{props.id}</Grid>
        <Grid item xs={10} md={5}>{props.cdr_dh_name}</Grid>
        <Grid item xs={6} md={3}>{props.ddc_dh_name}</Grid>
        <Grid item xs={6} md={1}>{props.status ? 'Active' : 'Inactive'}</Grid>
        <Grid item xs={6} md={2}><Link color={"secondary"} to={props.detailsPath+ `/${props.id}`}><b><u>View details</u> <FontAwesomeIcon icon={faChevronRight} /></b></Link></Grid>
    </Grid>
}

export default CdrToDdcMappingsListItem;