import React from "react";
import { Box, FormControl, Grid } from "@mui/material";
import SwitchWithLabel from "../../components/SwitchWithLabel";
import DeveloperTools from "../../models/DeveloperTools";

interface EditDeveloperToolsProps {
    developerTools: DeveloperTools;
    onDeveloperToolsChange?: (newDeveloperTools: DeveloperTools) => void,
}

const EditDeveloperToolsSettings = (props: EditDeveloperToolsProps) => {

    const handleDeveloperToolsChange = (newDeveloperTools: DeveloperTools) => {
        props.onDeveloperToolsChange && props.onDeveloperToolsChange(newDeveloperTools);
    }

    return <>
        <form className={"admin-form general-settings-form mt-50"} autoComplete={"off"}>
            
            <Grid container className={"mt-50"}>
                <Box mb={2}>
                    <h4>Consumer Consent Flow Errors</h4>
                </Box>
            </Grid>
            
            <Grid container className={"mt-30"}>
                <Grid item xs={12} sm={4} md={3}>
                    <label>Consumer consent Session timed out</label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                        <SwitchWithLabel
                            checked={props.developerTools.incConsumerConsentSessionTimedOut}
                            onChange={value => {handleDeveloperToolsChange({...props.developerTools, incConsumerConsentSessionTimedOut: value})}}>
                            {props.developerTools.incConsumerConsentSessionTimedOut ? "Include" : "Exclude"}
                        </SwitchWithLabel>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container className={"mt-30"}>
                <Grid item xs={12} sm={4} md={3}>
                <label>Cannot load consumer consent flow when accessing the one time link due to expiry of the link token</label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                        <SwitchWithLabel
                            checked={props.developerTools.incCannotLoadConsumerConsentFlow}
                            onChange={value => {handleDeveloperToolsChange({...props.developerTools, incCannotLoadConsumerConsentFlow: value})}}>
                            {props.developerTools.incCannotLoadConsumerConsentFlow ? "Include" : "Exclude"}
                        </SwitchWithLabel>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container className={"mt-30"}>
                <Grid item xs={12} sm={4} md={3}>
                <label>Unexpected error encountered during the Authorisation process with the bank failed/errored</label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                        <SwitchWithLabel
                            checked={props.developerTools.incUnexpectedErrorEncounteredDuringTheAuth}
                            onChange={value => {handleDeveloperToolsChange({...props.developerTools, incUnexpectedErrorEncounteredDuringTheAuth: value})}}>
                            {props.developerTools.incUnexpectedErrorEncounteredDuringTheAuth ? "Include" : "Exclude"}
                        </SwitchWithLabel>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container className={"mt-30"}>
                <Grid item xs={12} sm={4} md={3}>
                <label>Unknown Error</label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                        <SwitchWithLabel
                            checked={props.developerTools.incUnknownError}
                            onChange={value => {handleDeveloperToolsChange({...props.developerTools, incUnknownError: value})}}>
                            {props.developerTools.incUnknownError ? "Include" : "Exclude"}
                        </SwitchWithLabel>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container className={"mt-50"}>
                <Box mb={2}>
                    <h4>Open Banking API Errors</h4>
                </Box>
            </Grid>

            <Grid container className={"mt-30"}>
                <Grid item xs={12} sm={4} md={3}>
                <label>CDR service unavailable (for data retrieval)</label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                        <SwitchWithLabel
                            checked={props.developerTools.incCdrServiceUnavailable}
                            onChange={value => {handleDeveloperToolsChange({...props.developerTools, incCdrServiceUnavailable: value})}}>
                            {props.developerTools.incCdrServiceUnavailable ? "Include" : "Exclude"}
                        </SwitchWithLabel>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container className={"mt-30"}>
                <Grid item xs={12} sm={4} md={3}>
                <label>ODS service unavailable (for data retrieval)</label>
                </Grid>
                <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                        <SwitchWithLabel
                            checked={props.developerTools.incOdsServiceUnavailable}
                            onChange={value => {handleDeveloperToolsChange({...props.developerTools, incOdsServiceUnavailable: value})}}>
                            {props.developerTools.incOdsServiceUnavailable ? "Include" : "Exclude"}
                        </SwitchWithLabel>
                    </FormControl>
                </Grid>
            </Grid>
            
        </form>
    </>
}

export default EditDeveloperToolsSettings;