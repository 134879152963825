import React, { PropsWithChildren } from "react";

interface ButtonBlockProps {
    className?: string;
    children?: React.ReactNode;
}

const ButtonBlock = (props: PropsWithChildren<ButtonBlockProps>) => {
    return <div className={(props.className ? props.className + " " : "") + "button-block"}>
        {props.children}
    </div>
}

export default ButtonBlock;