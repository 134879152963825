import React from "react";
import { Link } from 'react-router-dom';
import GeneralSettings from "../models/GeneralSettings";
import { Box } from "@mui/material";

interface HeaderProps {
    title?: string;
    generalSettings?: GeneralSettings;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
    isTrustedAdviser?: boolean;
}

const Header = (props: HeaderProps) => {
    const imageStyle = {
        maxWidth: 200,
        maxHeight: 45
    }

    return <>
        {(props.generalSettings?.provideConsentViaApi) ? "" :
            <header className={"header"}>
                <div className={"top-bar"}
                    style={{
                        backgroundColor: props.generalSettings?.headerBackgroundType === "Solid colour" ? (props.generalSettings?.headerBackgroundColor ?? "transparent") : "transparent",
                        backgroundImage: props.generalSettings?.headerBackgroundType === "Image background" ? (props.headerBgImageUrl ? `url(${props.headerBgImageUrl})` : "none") : "none"
                    }}>
                    <Link id="logo" to={"#"}>
                        <Box>
                            {props.principalLogoUrl
                                ? <img src={props.principalLogoUrl} alt="" style={imageStyle} />
                                : <img src={`/images/${props.isTrustedAdviser ? "logo-illion-bank-statements.png": "logo-illion.png"}`} alt="" style={imageStyle} />
                            }
                        </Box>
                    </Link>
                </div>
                {props.title &&
                    <div className="text-secondary">
                        <h2 className={"page-title"}>{props.title}</h2>
                    </div>
                }
            </header>
        }
    </>
}

export default Header;
