import {Button, Grid} from "@mui/material";
import React, {MutableRefObject, useEffect, useRef, useCallback, useState} from "react";
import {validateIfTargetsAreValidEmails, validateIfTargetsAreValidUrls} from "../../helpers/InputFieldValidationHelper";
import { useNavigate } from "react-router-dom";

import ButtonBlock from "../../components/ButtonBlock";
import Configuration from "../models/Configuration";
import CurrentUser from "../../models/CurrentUser";
import EditGeneralSettings from "./EditGeneralSettings";
import GeneralSettings from "../../models/GeneralSettings";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import ViewGeneralSettings from "./ViewGeneralSettings";
import {isAdminOrClientManager} from "../../helpers/AuthUserHelper";
import UploadFile from "../models/UploadFile";
import EditGeneralSettingsDevMode from "./EditGeneralSettingsDevMode";
import {USER_ROLE_DEVELOPER} from "../../helpers/AuthUserHelper";
import ImagePreviews from "../models/ImagePreviews";
import Account from "../models/Account";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";

interface EditGeneralSettingsProps
{
    basePath: string,
    configuration: Configuration,
    mode: "edit" | "view",
    onConfigurationUpdate?: (newConfiguration: Configuration, mode: 'view' | 'edit', viewScrollToTop:boolean, fileUploads?: Array<UploadFile>, imagePreviews?: ImagePreviews) => void,
    refreshConfigurationList?: () => void,
    onCancel?: () => void,
    currentUser?: CurrentUser,
    adminObApi: OpenBankingPlatformAPIInterface,
    mtlsCertOptions?: Array<any>,
    imagePreviews?: ImagePreviews;
    accountId: number;
}

interface EditGeneralSettingsState
{
    generalSettings: GeneralSettings;
    configurationName: string;
    configurationDescription: string;
    hasFailedValidationOnSave: boolean;
    previewSettings: boolean;
    fileUploads: Array<UploadFile>;
    imagePreviews?: ImagePreviews;
}

const ConfigurationGeneralSettings = (props: EditGeneralSettingsProps) => {
    const editMode = (props.mode === "edit");

    const [state, setState] = useState<EditGeneralSettingsState>({
        generalSettings: {...props.configuration.generalSettings},
        configurationName: props.configuration.name,
        configurationDescription: props.configuration.description,
        hasFailedValidationOnSave: false,
        previewSettings: false,
        fileUploads: [],
    });

    const [accountDetails, setAccount] = useState<Account>({
        is_for_trusted_adviser: 0
    });

    useEffect(() => {
        setState((state) => ({
            ...state,
            generalSettings: { ...props.configuration.generalSettings }
        }))
    }, [props.mode, props.configuration.generalSettings]);

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    }

    //eslint-disable-next-line
    const getAccountDetails = useCallback(props.adminObApi.getAccount, []);
    useEffect(() => {
            (async () => {
                if (props.accountId && props.currentUser) {
                    try {
                        var accountId = props.accountId;
                        const loadAccount = await getAccountDetails(accountId);
                        if (loadAccount) {
                            setAccount((userDetails)=>({
                                is_for_trusted_adviser: loadAccount.is_for_trusted_adviser,
                            }))
                        }
                    } catch (error) {
                        adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                    }
                }
            })();
        },
        //eslint-disable-next-line
        [props.accountId, props.currentUser]
    )
    const navigate = useNavigate();
    /*
    const timeout = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }*/

    const statementDeliveryEmailRef = useRef() as MutableRefObject<HTMLInputElement>;
    const statementDeliveryEndpointRef = useRef() as MutableRefObject<HTMLInputElement>;
    const consentExpiryEndpointRef = useRef() as MutableRefObject<HTMLInputElement>;

    const handleSave = () => {
        if (!validateOnSave(state.generalSettings)) {
            //console.log("FAILED EMAIL URL VALIDATION");
            //validateInputRef.current.scrollIntoView();
            return;
        }

        const newConfiguration: Configuration = {
            ...props.configuration,
            name: state.configurationName,
            description: state.configurationDescription,
            generalSettings: state.generalSettings,
        }

        props.onConfigurationUpdate && props.onConfigurationUpdate(newConfiguration, 'view', true, state.fileUploads, state.imagePreviews);

        /*
        (async () => {
            setState((state) => ({
                ...state,
                fileUploads: []
            }))
            await timeout(1500);
            props.refreshConfigurationList && props.refreshConfigurationList();
        })();*/
    };

    const handleGeneralSettingsChange = (newGeneralSettings: GeneralSettings, newFileUpload?: UploadFile, imagePreviews?: ImagePreviews) => {
        var fileUploads = state.fileUploads;
        if (newFileUpload) {
            fileUploads.push(newFileUpload)
        }

        setState({
            ...state,
            generalSettings: newGeneralSettings,
            fileUploads: fileUploads,
            imagePreviews: imagePreviews
        });
    }

    const handleConfigurationNameChange = (name: string) => {
        setState({
            ...state,
            configurationName: name,
        })
    }

    const handleConfigurationDescriptionChange = (description: string) => {
        setState({
            ...state,
            configurationDescription: description,
        })
    }

    const handlePreviewSettings = () => {
        setState({
            ...state,
            previewSettings: true
        })
    }

    const handleClosePreviewSettings = () => {
        setState({
            ...state,
            previewSettings: false
        })
    }

    const validateOnSave = (generalSettings:GeneralSettings): boolean => {
        if (generalSettings.statementDeliveryEmail) {
            let invalidEmails = validateIfTargetsAreValidEmails(generalSettings.statementDeliveryEmail);
            if (invalidEmails.length > 0) {
                statementDeliveryEmailRef.current.scrollIntoView();
                return false;
            }
        }

        if (generalSettings.statementDeliveryEndpoint) {
            let invalidUrls = validateIfTargetsAreValidUrls(generalSettings.statementDeliveryEndpoint);
            if (invalidUrls.length > 0) {
                statementDeliveryEndpointRef.current.scrollIntoView();
                return false;
            }
        }

        if (generalSettings.consentExpiryNotificationEndpoint) {
            let invalidUrls = validateIfTargetsAreValidUrls(generalSettings.consentExpiryNotificationEndpoint);
            if (invalidUrls.length > 0) {
                consentExpiryEndpointRef.current.scrollIntoView();
                return false;
            }
        }

        return true;
    }

    return <>
        <h2 className={"page-title"}>General Settings</h2>
        <h5 className="text-large">{state.generalSettings.principalName}</h5>

        {isAdminOrClientManager(props.currentUser?.role_id) ?
            <a
                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(props.configuration)
                )}`}
                download={props.configuration.name+".json"}
            >
            Download the configuration as an export file
            </a> : ""
        }

        {editMode
            ? <>
            {(props.currentUser?.role_id === USER_ROLE_DEVELOPER) ?
                <EditGeneralSettingsDevMode
                    basePath={props.basePath}
                    configurationDescription={state.configurationDescription}
                    configurationName={state.configurationName}
                    generalSettings={state.generalSettings}
                    onGeneralSettingsChange={handleGeneralSettingsChange}
                    onConfigurationNameChange={handleConfigurationNameChange}
                    onConfigurationDescriptionChange={handleConfigurationDescriptionChange}
                    statementDeliveryEmailRef={statementDeliveryEmailRef}
                    statementDeliveryEndpointRef={statementDeliveryEndpointRef}
                    consentExpiryEndpointRef={consentExpiryEndpointRef}
                    adminObApi={props.adminObApi}
                    configurationPrincipalId={props.configuration.principal_id}
                    previewSettings={state.previewSettings}
                    onClosePreviewSettings={handleClosePreviewSettings}
                    configuration={props.configuration}
                    currentUser={props.currentUser}
                />
                : <EditGeneralSettings
                    basePath={props.basePath}
                    configurationDescription={state.configurationDescription}
                    configurationName={state.configurationName}
                    generalSettings={state.generalSettings}
                    onGeneralSettingsChange={handleGeneralSettingsChange}
                    onConfigurationNameChange={handleConfigurationNameChange}
                    onConfigurationDescriptionChange={handleConfigurationDescriptionChange}
                    statementDeliveryEmailRef={statementDeliveryEmailRef}
                    statementDeliveryEndpointRef={statementDeliveryEndpointRef}
                    consentExpiryEndpointRef={consentExpiryEndpointRef}
                    adminObApi={props.adminObApi}
                    configurationPrincipalId={props.configuration.principal_id}
                    previewSettings={state.previewSettings}
                    onClosePreviewSettings={handleClosePreviewSettings}
                    currentUser={props.currentUser}
                    mtlsCertOptions={props.mtlsCertOptions}
                    imagePreviews={props.imagePreviews}
                    is_for_trusted_adviser={accountDetails.is_for_trusted_adviser}
                />}
                <Grid container className={"mt-20"}>
                    <Grid item xs={12} sm={4}>
                        {editMode &&
                            <ButtonBlock>
                                <Button className={"cta-btn w-100-xs"} onClick={handlePreviewSettings} variant={"contained"} color={"secondary"}>Preview</Button>
                            </ButtonBlock>
                        }
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <ButtonBlock className={"text-align-right"}>
                            <Button onClick={() => handleCancel()} variant={"outlined"} color={"secondary"}>Cancel</Button>
                            <Button onClick={() => handleSave()} variant={"contained"} color={"secondary"}>Save</Button>
                        </ButtonBlock>
                    </Grid>
                </Grid>
            </>
            : <ViewGeneralSettings
                adminObApi={props.adminObApi}
                currentUser={props.currentUser}
                configuration={props.configuration}
                generalSettings={state.generalSettings}
                mtlsCertOptions={props.mtlsCertOptions}
                imagePreviews={props.imagePreviews}
            />
        }
    </>;
}

export default ConfigurationGeneralSettings;