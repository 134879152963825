import * as EmailValidator from "email-validator";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoIcon from "@mui/icons-material/Info";
import { Button, FormControl, Grid, Link, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";

import { isClientManager, isClientManagerOrStandardUser, isMortgageBroker, isAdmin } from "../../helpers/AuthUserHelper";
import { checkPasswordStrength, PASSWORD_EMPTY, PASSWORD_STRENGTH_POOR, PASSWORD_STRENGTH_STRONG, PASSWORD_STRENGTH_WEAK } from "../../helpers/InputFieldValidationHelper";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import LightTooltip from "../components/LightTooltip";
import Account from "../models/Account";
import Principal from "../models/Principal";
import UpdateUserData from "../models/UpdateUserData";
import LoadingSpinner from "../../components/LoadingSpinner";

interface UpdateUserProps {
    basePath: string;
    api: OpenBankingPlatformAPIInterface;
    userUpdateError: boolean;
    currentUser?: CurrentUser;
}

interface UpdateUserState {
    backgroundOperationInProgress: boolean;
    accounts: Array<Account>;
    principals: Array<Principal>;
    updateMessage: string;
    passwordStrengthIndicator: string;
}

const UpdateUser = (props: UpdateUserProps) => {

    const { id } = useParams<{ id: any }>();

    const roleMenuMap: { [key: number]: { name: string; menu: number[] } }={
        1:{name:"Illion Administrator",menu:[2,3,4,5]},
        2:{name:"Client Manager",menu:[3]},
        3:{name:"Standard User",menu:[2,3,4,5]},
        4:{name:"Developer",menu:[2,3,4,5]},
        5:{name:"Mortgage Broker - Aggregator",menu:[2,3,4,5]},
        99:{name:"Super Administrator",menu:[1,2,3,4,5,99]},
    };
    const currentUserRoleId=props?.currentUser?.role_id;

    const [userDetails, setUser] = useState<UpdateUserData>({
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        is_active: false,
        role_id: 0
    });

    const [state, setState] = useState<UpdateUserState>({
        backgroundOperationInProgress: true,
        accounts: [],
        principals: [],
        updateMessage: '',
        passwordStrengthIndicator: ''
    })

    const navigate = useNavigate();

    //eslint-disable-next-line
    const getUserDetails = useCallback(props.api.getUserById, []);
    useEffect(() => {
        (async () => {
            if (id) {
                try {
                    const loadUser = await getUserDetails(id);
                    setUser((userDetails)=>({
                        ...userDetails,
                        id: id,
                        name: loadUser.name,
                        email: loadUser.email,
                        is_active: loadUser.is_active,
                        role_id: loadUser.role_id,
                        account_id: loadUser.account_id,
                    }))
                    setState({
                        ...state,
                        backgroundOperationInProgress: false
                    });
                } catch (error) {
                    /*
                    // TODO Uncomment this code when fallback is removed
                    setUser((userDetails)=>({
                        ...userDetails,
                        apiHasReturnedAnError: true,
                    }))
                    navigate("/token-error");*/
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            } else {
                setState({
                    ...state,
                    backgroundOperationInProgress: false
                });
            }
        })();
        },
        //eslint-disable-next-line
        []
    )

    //eslint-disable-next-line
    const getAccounts = useCallback(props.api.getAccountsList, []);
    useEffect(() => {
            (async () => {
                try {
                    if (isAdmin(props.currentUser?.role_id)) {
                        const loadedAccounts = await getAccounts();
                        setState((state)=>({
                            ...state,
                            accounts: loadedAccounts,
                        }))
                    }
                } catch (error) {
                    /*
                    // TODO Uncomment this code when fallback is removed
                    setState((state)=>({
                        ...state,
                        apiHasReturnedAnError: true,
                    }))
                    navigate("/token-error");*/
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            })();
        },
        //eslint-disable-next-line
        []
    )

    /*
    //eslint-disable-next-line
    const getPrincipals = useCallback(props.api.getPrincipalsByAccountId, []);
    useEffect(() => {
            (async () => {
                if (userDetails.account_id) {
                    try {
                        const loadedPrincipals = await getPrincipals(userDetails.account_id);
                        setState((state)=>({
                            ...state,
                            principals: loadedPrincipals,
                        }))

                    } catch (error) {
                        adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                    }
                }
            })();
        },
        //eslint-disable-next-line
        [userDetails.account_id]
    )*/

    const location = useLocation();
    useEffect(() => {
        if (location.state) {
            // @ts-ignore
            if (location.state.message) {
                // @ts-ignore
                const updateMessage = location.state.message;
                setState((state)=>({
                    ...state,
                    updateMessage: updateMessage
                }))
            }
            // @ts-ignore
            if (location.state.userDetails) {
                // @ts-ignore
                const newUser = location.state.userDetails;
                setUser((userDetails)=>({
                    ...userDetails,
                    id: id,
                    name: newUser.name,
                    email: newUser.email,
                    is_active: newUser.is_active,
                    role_id: newUser.role_id,
                    account_id: newUser.account_id,
                    principal_id: newUser.principal_id,
                }))
                setState((state)=>({
                    ...state,
                    backgroundOperationInProgress: false
                }))
            }
        }
    }, [location, id]);

    const refreshPasswordStrengthIndicator = (passwordStrength:string) => {
        switch (passwordStrength) {
            case PASSWORD_STRENGTH_POOR:
            case PASSWORD_STRENGTH_WEAK:
                setState((state)=>({
                    ...state,
                    passwordStrengthIndicator: 'The password must be at least 10 characters long and contain at least 1 upper case (a-z) and 1 lower case character (A-Z), 1 numeric character (0-9), and 1 special character (#, ?, !, @, $, %, ^, &, *, -).'
                }));
                break;
            case PASSWORD_STRENGTH_STRONG:
            default:
                setState((state)=>({
                    ...state,
                    passwordStrengthIndicator: ''
                }));
                break;
        }
    }

    /*useEffect(() => {
        refreshPasswordStrengthIndicator(passwordStrength)
    }, [userDetails.password]);*/

    const isCurrentUserValid = (typeof userDetails.role_id !== "undefined" && typeof props.currentUser !== "undefined");

    const shouldRenderAccessLevelSelection = ():boolean => {
        if (isCurrentUserValid) {
            return isAdmin(props.currentUser?.role_id) || isClientManager(props.currentUser?.role_id);
        }

        return false;
    }

    const shouldRenderAccountSelection = ():boolean => {
        if (isCurrentUserValid) {
            if (isAdmin(props.currentUser?.role_id)) {
                if (isClientManagerOrStandardUser(userDetails.role_id) || isMortgageBroker(userDetails.role_id)) {
                    return true;
                }
            }
        }
        return false;
    }

    /*
    const shouldRenderPrincipalSelection = () => {
        if (isCurrentUserValid && state.principals.length !== 0) {
            if (isStandardUser(userDetails.role_id)) {
                return true;
            }
        }
        return false;
    }*/

    const handleGoBack = () => {
        navigate(`${props.basePath}/users-list`);
    };

    //const passwordInputRequired = (typeof id !== 'undefined' && id ? false: true);
    const passwordInputAlreadyPopulated = (typeof id !== 'undefined' ? true : userDetails.password !== '' && userDetails.password_confirmation !== '');
    const userRoleRequired = isAdmin(props.currentUser?.role_id) ? true : false;
    const allMandatoryFieldsArePopulated = passwordInputAlreadyPopulated
        && (userDetails.current_password !== '' && userDetails.current_password !== undefined)
        && userDetails.name !== ''
        && userDetails.email !== ''
        && (userRoleRequired ? userDetails.role_id !== 0 : true);

    const passwordStrength = checkPasswordStrength(userDetails.password);
    const isPasswordStrengthSufficient = (passwordStrength:string) => {
        return (passwordInputAlreadyPopulated && passwordStrength === PASSWORD_EMPTY) || passwordStrength === PASSWORD_STRENGTH_STRONG;
    }
    const isPasswordStrongEnough = isPasswordStrengthSufficient(passwordStrength);

    const validateEmail = (newEmail?: string) => {
        if (newEmail) {
            return EmailValidator.validate(newEmail);
        }
        return false;
    }
    const hasValidEmail = validateEmail(userDetails.email);

    //eslint-disable-next-line
    const createNewUser = useCallback(props.api.doUserSignUp, []);
    //eslint-disable-next-line
    const updateExistingUser = useCallback(props.api.doUserUpdate, []);
    const handleUserUpdate = () => {
        if (!allMandatoryFieldsArePopulated) {
            return;
        }

        if (!isPasswordStrongEnough) {
            return;
        }

        if (!validateEmail(userDetails.email)) {
            return;
        }

        setState({
            ...state,
            backgroundOperationInProgress: true,
        });

        //Save and send to server
        (async () => {
            try {
                if (isClientManager(props.currentUser?.role_id)) {
                    await setUser((userDetails)=>({
                        ...userDetails,
                        role_id: props.currentUser?.role_id,
                        account_id: props.currentUser?.account_id
                    }));
                }

                if (userDetails.id) {
                    const updatedUser = await updateExistingUser(userDetails);
                    setUser((userDetails)=>({
                        ...userDetails,
                        errorMessage: "",
                    }))
                    setState({
                        ...state,
                        backgroundOperationInProgress: false
                    })
                    //navigate(props.basePath + `/user/` + id);
                    navigate(props.basePath + `/user/` + id, {
                        /*state: "User successfully updated."*/
                        state: {
                            message: "User successfully updated.",
                            userDetails: updatedUser
                        }
                    });
                } else {
                    const newUser = await createNewUser(userDetails);
                    setUser((newUser)=>({
                        ...userDetails,
                        errorMessage: "",
                    }))
                    setState({
                        ...state,
                        backgroundOperationInProgress: false
                    })
                    navigate(props.basePath + `/user/` + newUser.id, {
                        state: {
                            message: "User successfully created.",
                            userDetails: newUser
                        }
                    });
                }
            } catch (error) {
                setState({
                    ...state,
                    backgroundOperationInProgress: false
                })
                if ((error as any).response.status === 422) {
                    // @ts-ignore
                    var errorMessageArray = [];
                    var allErrors = (error as any).response.data.errors;
                    for (var i = 0; i < allErrors.length; i++) {
                        var errors = allErrors[i];
                        if ('name' in errors) {
                            errorMessageArray.push(errors.password);
                        }
                        if ('email' in errors) {
                            errorMessageArray.push(errors.password);
                        }
                        if ('password' in errors) {
                            errorMessageArray.push(errors.password);
                        }
                        if ('error' in errors) {
                            errorMessageArray.push(errors.error);
                        }
                    }

                    setUser((userDetails)=>({
                        ...userDetails,
                        // @ts-ignore
                        errorMessage: "Failed to " + (userDetails.id ? 'update' : 'create') + " User - " + errorMessageArray.join(", "),
                    }))
                    setState({
                        ...state,
                        updateMessage:''
                    })
                } else {
                    adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
                /*
                // TODO Uncomment this code when fallback is removed
                setUser((userDetails)=>({
                    ...userDetails,
                    errorMessage: "Failed to " + (userDetails.id ? 'update' : 'create') + " User.",
                }))*/
            }
        })();
    }

    const userCreateErrorMsg = userDetails.errorMessage ? userDetails.errorMessage : '';

    let emailValidationError: any = null;
    const setEmail = (newEmail: string) => {
        setUser({
            ...userDetails,
            email: newEmail
        })
        emailValidationError.innerHTML = "";
        if (newEmail !== "") {
            if (!validateEmail(newEmail)) {
                emailValidationError.innerHTML = "Please enter a valid email address";
            }
        }
    }

    return <div>
                {(state.backgroundOperationInProgress)
                    ? <LoadingSpinner loadingText={""} />
                    : <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                            </Grid>
                        </Grid>
                        <div className="update-user">
                            <h2><strong>{id?'Update User':'Create New User'}</strong></h2>

                        {(userDetails.errorMessage !== '') ? <span className={"error-text"}>{userCreateErrorMsg}</span> : ""}
                        {(state.updateMessage !== '') ? <span className={"update-message-text"}>{state.updateMessage}</span> : ""}

                        <form className={"admin-form user-settings-form"} autoComplete={"off"}>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Name<LightTooltip title="Name of the User" placement="right-start"
                                                              arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="name-input"
                                        name="name-input"
                                        className="w-100"
                                        placeholder="Name"
                                        value={userDetails.name?userDetails.name:''}
                                        onChange={(event) => {
                                            setUser({
                                                ...userDetails,
                                                name: event.target.value
                                            })
                                        }}

                                    />
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Email<LightTooltip title="User Email" placement="right-start"
                                                               arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="email-input"
                                        name="email-input"
                                        className="w-100"
                                        placeholder="Email"
                                        value={userDetails.email?userDetails.email:''}
                                        onChange={(event) => {
                                            setEmail(event.target.value as string)
                                        }}
                                    />
                                    <div className="mandatory-fields-hint" ref={(message) => { emailValidationError = message; }}></div>
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Password<LightTooltip title="User Password" placement="right-start"
                                                                  arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="password-input"
                                        name="password-input"
                                        className="w-100"
                                        placeholder="Password"
                                        type="password"
                                        /*value={userDetails.password}*/
                                        onChange={(event) => {
                                            setUser({
                                                ...userDetails,
                                                password: event.target.value
                                            });
                                            refreshPasswordStrengthIndicator(checkPasswordStrength(event.target.value));
                                        }}
                                    />
                                    <div style={{ color: 'red', fontSize: '10pt' }}>{state.passwordStrengthIndicator}</div>
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Confirm Password<LightTooltip title="Confirm Password" placement="right-start"
                                                                   arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="password-confirm-input"
                                        name="password-confirm-input"
                                        className="w-100"
                                        placeholder="Confirm Password"
                                        type="password"
                                        /*value={userDetails.password}*/
                                        onChange={(event) => {
                                            setUser({
                                                ...userDetails,
                                                password_confirmation: event.target.value
                                            })
                                        }}

                                    />
                                </Grid>
                            </Grid>

                            {shouldRenderAccessLevelSelection() ?
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Access Level<LightTooltip title="Assign the Access Level for the User" placement="right-start"
                                                                          arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                            id="role-id"
                                            name="role-id"
                                            value={userDetails.role_id?userDetails.role_id:0}
                                            onChange={(event: SelectChangeEvent<number>) => {
                                                setUser({
                                                    ...userDetails,
                                                    role_id: event.target.value as number
                                                })
                                            }}
                                        >
                                            <MenuItem value={0}>- Please select -</MenuItem>
                                            {currentUserRoleId && roleMenuMap[currentUserRoleId].menu.map((menuId) => (
                                                <MenuItem key={menuId} value={Number(menuId)}>
                                                    {roleMenuMap[menuId].name}
                                                </MenuItem>
                                            ))

                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid> : ""}

                            {shouldRenderAccountSelection() ?
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Account<LightTooltip title="Assign the user to an associated Account" placement="right-start"
                                                                      arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <FormControl variant="outlined" className="w-100">
                                        <Select
                                            id="account-id"
                                            name="account-id"
                                            value={userDetails.account_id?userDetails.account_id:0}
                                            onChange={(event: SelectChangeEvent<number>) => {
                                                setUser({
                                                    ...userDetails,
                                                    account_id: event.target.value as number
                                                })
                                            }}
                                        >
                                            <MenuItem value={0}>- Please select -</MenuItem>
                                            {state.accounts && state.accounts.map((item, i) =>
                                                <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            : "" }

                            {shouldRenderAccessLevelSelection() ?
                                <Grid container className={"form-group"}>
                                    <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                        <label>Status<LightTooltip title="Set the current status of the User" placement="right-start"
                                                                          arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                    </Grid>
                                    <Grid item xs={12} sm={7} md={5}>
                                        <FormControl variant="outlined" className="w-100">
                                            <Select
                                                id="status"
                                                name="status"
                                                value={userDetails.is_active ? userDetails.is_active : 0 as unknown as boolean}
                                                onChange={(event: SelectChangeEvent<boolean>) => {
                                                    setUser({
                                                        ...userDetails,
                                                        is_active: event.target.value as boolean
                                                    })
                                                }}
                                            >
                                                <MenuItem value={1}>Active</MenuItem>
                                                <MenuItem value={0}>Inactive</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid> : ""}

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Authorisation Password <LightTooltip title="Please enter your current password to authorise update to user" placement="right-start"
                                                                         arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <TextField
                                        required
                                        variant="outlined"
                                        id="password-authorise-input"
                                        name="password-authorise-input"
                                        className="w-100"
                                        placeholder="Enter current password to authorise user update"
                                        type="password"
                                        /*value={userDetails.password}*/
                                        onChange={(event) => {
                                            setUser({
                                                ...userDetails,
                                                current_password: event.target.value
                                            })
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Button variant={"contained"} color={"secondary"} onClick={handleUserUpdate}
                                    disabled={!allMandatoryFieldsArePopulated || !hasValidEmail || !isPasswordStrongEnough}>Save</Button>
                        </form>
                    </div>
                    </div>
                }
    </div>
}

export default UpdateUser;