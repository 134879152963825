import React, { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchIcon from "@mui/icons-material/Search";
import { Button, FormControl, Grid, Hidden, InputBase, Link, MenuItem, Select } from "@mui/material";

import LinkButton from "../../components/LinkButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import { isAdmin } from "../../helpers/AuthUserHelper";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import UsersListItem from "../components/UsersListItem";
import User from "../models/User";

interface UsersListState {
    data: Array<User>;
    isLoading: boolean;
    searchSection: string;
    searchKeyword: string;
    currentPage: number;
    pageCount: number;
}

interface UsersListProps {
    api: OpenBankingPlatformAPIInterface;
    userDetailsPath: string;
    hasApiError?: boolean;
    currentUser?: CurrentUser;
    basePath: string;
}

const UsersList = (props: UsersListProps) => {

    let perPage: number = 10;

    const [state, setState] = useState<UsersListState>({
        data: [],
        isLoading: false,
        searchSection: 'name',
        searchKeyword: '',
        currentPage: 0,
        pageCount: 0,
    });

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`${props.basePath}/home`);
    };

    const handlePageClick = (e: any) => {
        const selectedPage = e.selected;
        const newOffset = selectedPage * perPage;
        setState((state)=>({
            ...state,
            currentPage: selectedPage,
            offset: newOffset,
        }));
    }

    const doSearch = () => {
        (async () => {
            try {
                if (typeof props.currentUser !== "undefined") {
                    setState((state)=>({
                        ...state,
                        isLoading: true,
                    }));
                    const loadedUsers = await getUsers(state.currentPage+1, state.searchKeyword, state.searchSection, props.currentUser?.account_id);
                    perPage = loadedUsers.pagination;
                    setState((state)=>({
                        ...state,
                        data: loadedUsers.dataset,
                        isLoading: false,
                        pageCount: Math.ceil(loadedUsers.total / perPage),
                    }));
                    if (state.currentPage > Math.ceil(loadedUsers.total / perPage)-1) {
                        setState((state)=>({
                            ...state,
                            currentPage: 0,
                        }));
                    }
                }
            } catch (error) {
                // TODO Uncomment this code when fallback is removed
                /*
                setState((state)=>({
                    ...state,
                    apiHasReturnedAnError: true,
                    isLoading: false,
                }))
                navigate("/token-error");*/
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }


    useEffect(() => {
        doSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.currentPage]);

    //eslint-disable-next-line
    const getUsers = useCallback(props.api.getUsersList, []);

    return <div className="users-list">
        {(isAdmin(props.currentUser?.role_id)) ?
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                </Grid>
            </Grid>
            : "" }
        <h2>Manage Users</h2>
        <Grid container className={"dashboard-action-bar"}>
            <Grid item xs={12} md={5}>
                <LinkButton targetlocation={props.userDetailsPath} variant={"contained"} color={"secondary"}>Add new user</LinkButton>
            </Grid>
            <Grid item xs={12} md={7}>
                <Grid container className={"filters-bar"}>
                    <Grid item xs={6}>
                        <InputBase
                            id={"keyword-input"}
                            placeholder={"Enter search"}
                            inputProps={{ "aria-label": "Enter search" }}
                            onChange={event => {
                                setState({ ...state, searchKeyword: event.target.value.toLowerCase() });
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl variant="outlined">
                            <Select
                                id="section"
                                name="section"
                                value={state.searchSection}
                                onChange={event => { setState({ ...state, searchSection: event.target.value as string}); }} >
                                <MenuItem value={"name"}>Name</MenuItem>
                                <MenuItem value={"email"}>Email</MenuItem>
                                <MenuItem value={"role"}>Role</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <Button id={"search-btn"} variant={"contained"} color={"secondary"} 
                            disabled={state.searchKeyword.length < 3 && state.searchKeyword.length > 0} 
                            onClick={() => doSearch()}
                        >
                            <Hidden xsDown>Search</Hidden>
                            <Hidden smUp><SearchIcon/></Hidden>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <div className={"institution-list paginated-table"}>
            <Hidden mdDown>
                <Grid container className={"table-head"}>
                    <Grid item xs={4}>Name</Grid>
                    <Grid item xs={4}>Email</Grid>
                    <Grid item xs={2}>Role</Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </Hidden>
            {!state.isLoading && state.data && state.data.map((item, i) => {
                return <UsersListItem userDetailsPath={props.userDetailsPath} currentUser={props.currentUser} key={i} {...item} />
            })}
            {state.isLoading &&
                <LoadingSpinner loadingText={""} />
            }
            {state.pageCount > 1 &&
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    pageCount={state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            }
        </div>
    </div>
}

export default UsersList;