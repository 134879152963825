import React from "react";
import {Grid} from "@mui/material";
import LightTooltip from "../LightTooltip";
import InfoIcon from "@mui/icons-material/Info";
import EditableText from "../EditableText";
import PrincipalBillingConfiguration from "../../models/PrincipalBillingConfiguration";
import BillingModelPerBank4 from "../../models/billingmodels/BillingModelPerBank4";

interface BillingModelPerBank4ConfigProps {
    modelConfiguration?: PrincipalBillingConfiguration;
    onBillingModelUpdate: (config: PrincipalBillingConfiguration) => void;
}

const BillingModelPerBank4Config = (props: BillingModelPerBank4ConfigProps) => {
    const billingModelType = 'BillingModelPerBank4';

    const connectInstitutionCost = props.modelConfiguration?.billingModelPerBank4?.connect_institution_cost;
    const refreshDataCost = props.modelConfiguration?.billingModelPerBank4?.refresh_data_cost;

    return <>
        <h4>Model 4</h4>

        <Grid container className={"mt-10"}>
            <Grid item xs={12} sm={7} md={5}>
                <label>Connect an institution<LightTooltip title="Connect an institution" placement="right-start"
                                                           arrow><InfoIcon
                    color={"secondary"}/></LightTooltip></label>
            </Grid>
            <Grid item xs={12} sm={4} md={3} className={"background-grey"}>
                <EditableText
                    type={"number"}
                    editMode={true}
                    name={"connect_institution_cost"}
                    value={connectInstitutionCost !== undefined ? connectInstitutionCost as unknown as string : ""}
                    eventHandlers={{
                        onChange: (e: any) => {
                            const newBillingModel: BillingModelPerBank4 = {
                                ...props.modelConfiguration?.billingModelPerBank4,
                                connect_institution_cost: e.target.value
                            }
                            const newBillingModelConfiguration: PrincipalBillingConfiguration = {
                                ...props.modelConfiguration,
                                selectedBillingModelType: billingModelType,
                                billingModelPerBank4: newBillingModel
                            };
                            props.onBillingModelUpdate(newBillingModelConfiguration);
                        }
                    }}
                />
            </Grid>
        </Grid>
        <Grid container className={"mt-20"}>
            <Grid item xs={12} sm={7} md={5}>
                <label>Refresh data<LightTooltip title="Refresh data" placement="right-start"
                                                 arrow><InfoIcon
                    color={"secondary"}/></LightTooltip></label>
            </Grid>
            <Grid item xs={12} sm={4} md={3} className={"background-grey"}>
                <EditableText
                    type={"number"}
                    editMode={true}
                    name={"refresh_data"}
                    value={refreshDataCost !== undefined ? refreshDataCost as unknown as string : ""}
                    eventHandlers={{
                        onChange: (e: any) => {
                            const newBillingModel: BillingModelPerBank4 = {
                                ...props.modelConfiguration?.billingModelPerBank4,
                                refresh_data_cost: e.target.value
                            }
                            const newBillingModelConfiguration: PrincipalBillingConfiguration = {
                                ...props.modelConfiguration,
                                selectedBillingModelType: billingModelType,
                                billingModelPerBank4: newBillingModel
                            };
                            props.onBillingModelUpdate(newBillingModelConfiguration);
                        }
                    }}
                />
            </Grid>
        </Grid>
    </>
}

export default BillingModelPerBank4Config;