import React from "react";
import {Grid} from "@mui/material";
import LightTooltip from "../LightTooltip";
import InfoIcon from "@mui/icons-material/Info";
import EditableText from "../EditableText";
import PrincipalBillingConfiguration from "../../models/PrincipalBillingConfiguration";
import BillingModelPerBank2 from "../../models/billingmodels/BillingModelPerBank2";

interface BillingModelPerBank2ConfigProps {
    modelConfiguration?: PrincipalBillingConfiguration;
    onBillingModelUpdate: (config: PrincipalBillingConfiguration) => void;
}

const BillingModelPerBank2Config = (props: BillingModelPerBank2ConfigProps) => {
    const billingModelType = 'BillingModelPerBank2';

    const connectInstitutionCost = props.modelConfiguration?.billingModelPerBank2?.connect_institution_cost;
    const firstTimeDataPullCost = props.modelConfiguration?.billingModelPerBank2?.first_time_data_pull_cost;
    const refreshDataCost = props.modelConfiguration?.billingModelPerBank2?.refresh_data_cost;

    return <>
            <h4>Model 2</h4>

            <Grid container className={"mt-10"}>
                <Grid item xs={12} sm={7} md={5}>
                    <label>Connect an institution<LightTooltip title="Connect an institution" placement="right-start"
                                                         arrow><InfoIcon
                        color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={4} md={3} className={"background-grey"}>
                    <EditableText
                        type={"number"}
                        editMode={true}
                        name={"connect_institution_cost"}
                        value={connectInstitutionCost !== undefined ? connectInstitutionCost as unknown as string : ""}
                        eventHandlers={{
                            onChange: (e: any) => {
                                const newBillingModel: BillingModelPerBank2 = {
                                    ...props.modelConfiguration?.billingModelPerBank2,
                                    connect_institution_cost: e.target.value
                                }
                                const newBillingModelConfiguration: PrincipalBillingConfiguration = {
                                    ...props.modelConfiguration,
                                    selectedBillingModelType: billingModelType,
                                    billingModelPerBank2: newBillingModel
                                };
                                props.onBillingModelUpdate(newBillingModelConfiguration);
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container className={"mt-20"}>
                <Grid item xs={12} sm={7} md={5}>
                    <label>First time data pull<LightTooltip title="First time data pull" placement="right-start"
                                                               arrow><InfoIcon
                        color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={4} md={3} className={"background-grey"}>
                    <EditableText
                        type={"number"}
                        editMode={true}
                        name={"first_time_data_pull_cost"}
                        value={firstTimeDataPullCost !== undefined ? firstTimeDataPullCost as unknown as string : ""}
                        eventHandlers={{
                            onChange: (e: any) => {
                                const newBillingModel: BillingModelPerBank2 = {
                                    ...props.modelConfiguration?.billingModelPerBank2,
                                    first_time_data_pull_cost: e.target.value
                                }
                                const newBillingModelConfiguration: PrincipalBillingConfiguration = {
                                    ...props.modelConfiguration,
                                    selectedBillingModelType: billingModelType,
                                    billingModelPerBank2: newBillingModel
                                };
                                props.onBillingModelUpdate(newBillingModelConfiguration);
                            }
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container className={"mt-20"}>
                <Grid item xs={12} sm={7} md={5}>
                    <label>Refresh data<LightTooltip title="Refresh data" placement="right-start"
                                                               arrow><InfoIcon
                        color={"secondary"}/></LightTooltip></label>
                </Grid>
                <Grid item xs={12} sm={4} md={3} className={"background-grey"}>
                    <EditableText
                        type={"number"}
                        editMode={true}
                        name={"refresh_data"}
                        value={refreshDataCost !== undefined ? refreshDataCost as unknown as string : ""}
                        eventHandlers={{
                            onChange: (e: any) => {
                                const newBillingModel: BillingModelPerBank2 = {
                                    ...props.modelConfiguration?.billingModelPerBank2,
                                    refresh_data_cost: e.target.value
                                }
                                const newBillingModelConfiguration: PrincipalBillingConfiguration = {
                                    ...props.modelConfiguration,
                                    selectedBillingModelType: billingModelType,
                                    billingModelPerBank2: newBillingModel
                                };
                                props.onBillingModelUpdate(newBillingModelConfiguration);
                            }
                        }}
                    />
                </Grid>
            </Grid>
    </>
}

export default BillingModelPerBank2Config;