import ModalPopUpData from "../models/ModalPopUpData";
import GeneralSettings from "../models/GeneralSettings";
import PreConsent from "../models/PreConsent";
import Consent from "../models/Consent";
import { convertToHTML } from "draft-convert";
import { convertFromRaw } from "draft-js";
import {hasRichTextSpecifiedForField} from "../helpers/InputFieldValidationHelper";

const modalPopUpContents = (generalSettingsConfig?: GeneralSettings, preconsentSettingsConfig?: PreConsent, consentSettingsConfig?: Consent, principalName?: string, institutionName?: string, thirdParties?: {heading: string,content: string}[], thirdPartiesEmail?: string) : ModalPopUpData[] => {
    return [
        {
            popUpHeading: "Supporting third parties",
            popUpGeneralText: [
                {
                    content: `Supporting third parties are companies that work with us to help provide our service.`,
                },
                {
                    content: `They will have access to the data you’ve agreed to share with us, but can only use it for the purpose(s) you consented to.`
                }
            ],
            popupBoxContents: thirdParties ? thirdParties : [],
            popupBlockContents: [
                {
                    heading: "Want more information?",
                    content: [
                        {
                            text : "Refer to our Consumer Data Right policy for more information on supporting third parties."
                        },
                        {
                            text : `You can also request more information by writing to <a href="mailto:${thirdPartiesEmail}">${thirdPartiesEmail}</a>.`
                        }
                    ]
                    
                }
            ],
        },
        {
            popUpHeading: consentSettingsConfig?.dataDeletionPopupHeading ? consentSettingsConfig?.dataDeletionPopupHeading : "Deidentification of Data",
            popUpGeneralText: consentSettingsConfig?.dataDeidentificationPopupContents && hasRichTextSpecifiedForField(consentSettingsConfig?.dataDeidentificationPopupContents) ? [{
                content: convertToHTML(convertFromRaw(
                    JSON.parse(consentSettingsConfig.dataDeidentificationPopupContents)
                ))
            }] : [],
            popupBoxContents: [],
            popupBlockContents: !consentSettingsConfig?.dataDeidentificationPopupContents || !hasRichTextSpecifiedForField(consentSettingsConfig?.dataDeidentificationPopupContents) ? [
                {
                    heading: "What does it mean to deidentify data?",
                    content: [
                        {
                            text: "Deidentification is a means of enhancing privacy protection. It’s a process that removes personal information so that any data that is retained ensures the individual is not readily able to be identified, either from the data itself, or if it were to be combined with other information held by any person."
                        }
                    ]
                },
                {
                    heading: "How will we deidentify your data?",
                    content: [
                        {
                            text: "We apply CDR Deidentification process (as set out in Chapter 12: Privacy Safeguard 12 — Security of CDR data, and destruction or de-identification of redundant data) to deidentify the data. We do this by removing direct identifiers, removing or altering other information that could potentially be used to re-identify an individual and establishing controls and safeguards in the data access environment to prevent re-identification."
                        }
                    ]
                },
                {
                    heading: "How will we use your deidentified data?",
                    content: [
                        {
                            text: "<ul><li>We will use the deidentified data to identify the categorisation gaps and refine our categorisation engine to continuously improve our categorisation service to our Customers, this will only occur when the consumer has expressly provided their consent for their deidentified data to be used for this purpose.</li><li>We will also use the deidentified data to provide anonymised spend analytics trends for research to our Customers for understanding spending patterns, this will only occur when the consumer has expressly provided their consent for their deidentified data to be used for this purpose.</li></ul>"
                        }
                    ]
                }
            ] : [],
        },
        {
            popUpHeading: consentSettingsConfig?.dataDeletionPopupHeading ? consentSettingsConfig?.dataDeletionPopupHeading : "Data deletion",
            popUpGeneralText: consentSettingsConfig?.dataDeletionPopupContents && hasRichTextSpecifiedForField(consentSettingsConfig?.dataDeletionPopupContents) ? [{
                content: convertToHTML(convertFromRaw(
                    JSON.parse(consentSettingsConfig.dataDeletionPopupContents)
                ))
            }] : [],
            popupBoxContents: [],
            popupBlockContents: !consentSettingsConfig?.dataDeletionPopupContents || !hasRichTextSpecifiedForField(consentSettingsConfig?.dataDeletionPopupContents) ? [
                {
                    heading: "When will you delete my data?",
                    content: [
                        {
                            text: "If we’re not required to retain data for legal purposes and you haven’t allowed de-identification, we will delete your data when:<ul><li>You elect to stop sharing data with us,</li><li>The sharing consent period expires or</li><li>Whenever we deem data held to be unnecessary for providing our service.</li></ul>"
                        }
                    ]
                },
                {
                    heading: "How will you delete my data?",
                    content: [
                        {
                            text: "We will remove any CDR data related to you in any shape or form and record evidence of deletion."
                        }
                    ]
                }
            ] : [],
        },
        {
            popUpHeading: consentSettingsConfig?.dataDeletionPopupHeading ? consentSettingsConfig?.dataDeletionPopupHeading : "Data deletion",
            popUpGeneralText: consentSettingsConfig?.dataDeletionPopupContents && hasRichTextSpecifiedForField(consentSettingsConfig?.dataDeletionPopupContents) ? [{
                content: convertToHTML(convertFromRaw(
                    JSON.parse(consentSettingsConfig.dataDeletionPopupContents)
                ))
            }] : [
                {
                    content: `illion does not retain CDR information in an identifiable form. If individuals need to access, correct or delete their CDR information they will need to make this request to either the Data Holder, the Data Recipient or the Trusted Adviser (depending on who holds the information requiring access/correction/deletion). If an access, correction or deletion request about CDR information is made to illion then illion may need to direct the individual to the organisation that is best able to assist with the request.`,
                },
                {
                    content: `illion invests heavily in data and information security controls that allow it to monitor and protect the information it holds. While this is the case, if a data breach were to occur, illion will enact its Data Breach Response Plan and notify relevant parties in accordance with its notification obligations. illion will retain records that allow it to track activities such as consents, consent withdrawal and data sharing in accordance with its obligations. These are referred to as service logs and record various actions but do not contain the actual CDR information.`
                }
            ],
            popupBoxContents: [],
            popupBlockContents: [],
        }
    ];
}

export default modalPopUpContents;
