import { darken, lighten } from "@mui/material/styles";

interface ThemePreset {
	backgroundColor: string,
	textColor: string,

    primaryColor: string,
    primaryBGTextColor: string,
    
    secondaryColor: string,
    secondaryBGTextColor: string,
    
    tertiaryColor: string,
	tertiaryBGTextColor: string,
	
	successColor: string,
	successBGTextColor: string,

	primaryButtonTextStyle: string,
	inverseButtonStyle: string,
	inverseButtonTextStyle: string,
	buttonHoverEffect: string,
	linkHoverEffect: string
}

/*export const LightMode: ThemePreset = {
	backgroundColor: "#FFFFFF",
	textColor: "#404040",

    primaryColor: "#8C2980",
    primaryBGTextColor: "#FFFFFF",
    
    secondaryColor: "#F8F2F6",
    secondaryBGTextColor: "#000000",
    
    tertiaryColor: "#FF9843",
	tertiaryBGTextColor: "#000000",
	
	successColor: "#008000",
	successBGTextColor: "#FFFFFF"

	primaryButtonTextStyle: "#FFFFFF",
	inverseButtonStyle: "transparent",
	inverseButtonTextStyle: "#8C2980",
	buttonHoverEffect: "Lighten (10%)",
	linkHoverEffect: "Lighten (10%)"
};*/

export const LightMode: ThemePreset = {
	backgroundColor: "#FFFFFF",
	textColor: "#404040",

    primaryColor: "#121212",
    primaryBGTextColor: "#FFFFFF",
    
    secondaryColor: "#F4F4F4",
    secondaryBGTextColor: "#000000",
    
    tertiaryColor: "#333333",
	tertiaryBGTextColor: "#FFFFFF",
	
	successColor: "#008000",
	successBGTextColor: "#FFFFFF",

	primaryButtonTextStyle: "#FFFFFF",
	inverseButtonStyle: "transparent",
	inverseButtonTextStyle: "#121212",
	buttonHoverEffect: "Lighten (10%)",
	linkHoverEffect: "Lighten (10%)"
};

export const DarkMode: ThemePreset = {
	backgroundColor: "#121212",
	textColor: "#FFFFFF",

    primaryColor: "#F4F4F4",
    primaryBGTextColor: "#000000",
    
    secondaryColor: "#404040",
    secondaryBGTextColor: "#FFFFFF",
    
    tertiaryColor: "#666666",
	tertiaryBGTextColor: "#FFFFFF",
	
	successColor: "#008000",
	successBGTextColor: "#FFFFFF",

	primaryButtonTextStyle: "#000000",
	inverseButtonStyle: "transparent",
	inverseButtonTextStyle: "#F4F4F4",
	buttonHoverEffect: "Darken (10%)",
	linkHoverEffect: "Darken (10%)"
};

export const blendColor = (color: string, mode?: string) => {
	switch(mode) {
		case "Darken (10%)":
			return darken(color, 0.1);
		
		case "Darken (30%)":
			return darken(color, 0.3);
		
		case "Darken (50%)":
			return darken(color, 0.5);
		
		case "Lighten (10%)":
			return lighten(color, 0.1);
		
		case "Lighten (30%)":
			return lighten(color, 0.3);

		case "Lighten (50%)":
			return lighten(color, 0.5);
		
		default:
			return color;
	}
};
