import React from "react";
import PrincipalBillingConfiguration from "../../models/PrincipalBillingConfiguration";
import {Grid} from "@mui/material";
import LightTooltip from "../LightTooltip";
import InfoIcon from "@mui/icons-material/Info";
import EditableText from "../EditableText";
import BillingModelHybrid5 from "../../models/billingmodels/BillingModelHybrid5";

interface BillingModelHybrid5ConfigProps {
    modelConfiguration?: PrincipalBillingConfiguration;
    onBillingModelUpdate: (config: PrincipalBillingConfiguration) => void;
}

const BillingModelHybrid5Config = (props: BillingModelHybrid5ConfigProps) => {
    const billingModelType = 'BillingModelHybrid5';

    const connectUserFirstTimeDataPullAndSubsequentRefreshCost = props.modelConfiguration?.billingModelHybrid5?.connect_user_first_time_data_pull_and_subsequent_refresh_cost;
    const refreshUserCost = props.modelConfiguration?.billingModelHybrid5?.refresh_user_cost;

    return <>
        <h4>Model 5</h4>

        <Grid container className={"mt-10"}>
            <Grid item xs={12} sm={7} md={5}>
                <label>Connect a user / First time data pull / Subsequent refresh<LightTooltip title="Connect a user" placement="right-start"
                                                   arrow><InfoIcon
                    color={"secondary"}/></LightTooltip></label>
            </Grid>
            <Grid item xs={12} sm={4} md={3} className={"background-grey"}>
                <EditableText
                    type={"number"}
                    editMode={true}
                    name={"connect_user_cost"}
                    value={connectUserFirstTimeDataPullAndSubsequentRefreshCost !== undefined ? connectUserFirstTimeDataPullAndSubsequentRefreshCost as unknown as string : ""}
                    eventHandlers={{
                        onChange: (e: any) => {
                            const newBillingModel: BillingModelHybrid5 = {
                                ...props.modelConfiguration?.billingModelHybrid5,
                                connect_user_first_time_data_pull_and_subsequent_refresh_cost: e.target.value
                            }
                            const newBillingModelConfiguration: PrincipalBillingConfiguration = {
                                ...props.modelConfiguration,
                                selectedBillingModelType: billingModelType,
                                billingModelHybrid5: newBillingModel
                            };
                            props.onBillingModelUpdate(newBillingModelConfiguration);
                        }
                    }}
                />
            </Grid>
        </Grid>
        <Grid container className={"mt-20"}>
            <Grid item xs={12} sm={7} md={5}>
                <label>Refresh user<LightTooltip title="Refresh iser" placement="right-start"
                                                    arrow><InfoIcon
                    color={"secondary"}/></LightTooltip></label>
            </Grid>
            <Grid item xs={12} sm={4} md={3} className={"background-grey"}>
                <EditableText
                    type={"number"}
                    editMode={true}
                    name={"refresh_user_cost"}
                    value={refreshUserCost !== undefined ? refreshUserCost as unknown as string : ""}
                    eventHandlers={{
                        onChange: (e: any) => {
                            const newBillingModel: BillingModelHybrid5 = {
                                ...props.modelConfiguration?.billingModelHybrid5,
                                refresh_user_cost: e.target.value
                            }
                            const newBillingModelConfiguration: PrincipalBillingConfiguration = {
                                ...props.modelConfiguration,
                                selectedBillingModelType: billingModelType,
                                billingModelHybrid5: newBillingModel
                            };
                            props.onBillingModelUpdate(newBillingModelConfiguration);
                        }
                    }}
                />
            </Grid>
        </Grid>
    </>
}

export default BillingModelHybrid5Config;