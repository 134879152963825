import React, { useEffect, useState } from "react";
import { Button, Container, Grid } from "@mui/material";
import EditDeveloperToolsSettings from "./EditDeveloperToolsSettings";
import ViewDeveloperToolsSettings from "./ViewDeveloperToolsSettings";
import Configuration from "../models/Configuration";
import DeveloperTools from "../../models/DeveloperTools";
import ButtonBlock from "../../components/ButtonBlock";
import CurrentUser from "../../models/CurrentUser";
import { isAdmin, isDeveloper } from "../../helpers/AuthUserHelper";

interface EditDeveloperToolsProps {
    configuration: Configuration,
    mode: "edit" | "view",
    currentUser?: CurrentUser,
    onConfigurationUpdate?: (newConfiguration: Configuration, mode: 'view' | 'edit', viewScrollToTop:boolean) => void,
    onCancel?: () => void,
}

interface DeveloperToolsSettingsState 
{
    developerToolsCopy: DeveloperTools;
}

const ConfigurationDeveloperToolsSettings = (props: EditDeveloperToolsProps) => {
    const editMode = props.mode === "edit" && (isAdmin(props.currentUser?.role_id) || isDeveloper(props.currentUser?.role_id));
    
    const [state, setState] = useState<DeveloperToolsSettingsState>({
        developerToolsCopy: { ...props.configuration.developerTools}
    });

    useEffect(() => {
        setState((state) => ({
            ...state,
            developerTools: { ...props.configuration.developerTools }
        }))
    }, [props.mode, props.configuration.developerTools]);

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    }

    const handleSave = () => {
        const newConfiguration: Configuration = {
            ...props.configuration,
            developerTools: state.developerToolsCopy,
        }
        props.onConfigurationUpdate && props.onConfigurationUpdate(newConfiguration, 'view', true);
    };

    const handleDeveloperToolsChange = (newDeveloperTools: DeveloperTools) => {
        setState({
            ...state,
            developerToolsCopy: newDeveloperTools
        })
    }

    return <Container>
        <h2 className={"page-title"}>Developer Tools</h2>
        <h5 className="text-large">{props.configuration.generalSettings.principalName}</h5>
        {editMode
        ? <>
                <EditDeveloperToolsSettings 
                    developerTools={state.developerToolsCopy}
                    onDeveloperToolsChange={handleDeveloperToolsChange}
                />
                <Grid container className={"mt-20"}>
                    <Grid item xs={12} sm={8}>
                        <ButtonBlock className={"text-align-right"}>
                            <Button onClick={() => handleCancel()} variant={"outlined"} color={"secondary"}>Cancel</Button>
                            <Button onClick={() => handleSave()} variant={"contained"} color={"secondary"}>Update</Button>
                        </ButtonBlock>
                    </Grid>
                </Grid>
            </>
        : <ViewDeveloperToolsSettings
            developerTools={state.developerToolsCopy} 
            />
        }
            
    </Container>;
}

export default ConfigurationDeveloperToolsSettings;