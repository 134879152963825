export const INITIAL_STATE = {
    loggedIn: false,
    currentUser: null,
};

const AuthReducer = (state = INITIAL_STATE, action:any) => {
    switch (action.type) {
        case 'login':
            return { ...state, loggedIn: action.loggedIn, currentUser: action.currentUser };
        case 'logout':
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default AuthReducer;