import React, { PropsWithChildren } from "react";

import { useTheme } from "@mui/material";

interface SecondaryBlockItemProps {
    image: string;
    className?: string;
    children?: React.ReactNode;
}

const Card = (props: PropsWithChildren<SecondaryBlockItemProps>) => {
    const theme = useTheme();
    const styles = {
        cardImage: {
            background: theme.palette.secondary.main
        }
    };

    const className = "card-item"
        + ((props.className) ? " " + props.className : "");

    return <div className={className}>
        {props.image && props.image !== '' &&
            <div className={`card-image ${styles.cardImage}`}><img src={props.image} alt={""} /></div>
        }
        <div className="card-content">{props.children}</div>
    </div>
}
export default Card;