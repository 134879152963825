import React, { PropsWithChildren, useState } from "react";

import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import HighlightBlock from "./HighlightBlock";
import SwitchWithLabel from "./SwitchWithLabel";
import {sanitize} from "../helpers/HtmlSanitizerHelper";

interface DataRequestCardProps
{
    clusterName: string,
    purposeInformation?: string | JSX.Element,
    incInsight: boolean,
    purposeInformationInsight?: string | JSX.Element,
    clusterPermissionNames?: Array<string>,
    onSelectionChange?: (newState: boolean) => void,
    isSelected: boolean;
    isMandatory?: boolean;
    children?: React.ReactNode;
    identifier: string;
}

const DataClusterCard = (props: PropsWithChildren<DataRequestCardProps>) => {
    const handleChange = (checked: boolean) => {
        if (props.onSelectionChange) {
            props.onSelectionChange(checked);
        }
    }

    return <HighlightBlock className="data-request-card">
        <label>
            <SwitchWithLabel checked={props.isSelected} onChange={handleChange}>
                <Typography component={"span"} color={"inherit"} className="typo-display">
                    <h5>{sanitize(props.clusterName)}</h5>
                    {(props.identifier==="data_cluster_6" || props.identifier==="data_cluster_5" || props.identifier==="data_cluster_4") && <>&nbsp;*</>}
                    {(props.isMandatory) &&
                        <p className="mandatory-field-indicator">
                            &nbsp;<small>required</small>
                        </p>
                    }
                </Typography>
            </SwitchWithLabel>
        </label>
        {(props.purposeInformation) &&
            <>
                <h6>Why we need it</h6>
                <p>{props.incInsight ? props.purposeInformationInsight : props.purposeInformation}</p>
            </>
        }
        {props.children}
        {(props.clusterPermissionNames && props.clusterPermissionNames.length > 0) &&
            <ClusterPermissionNames names={props.clusterPermissionNames}/>
        }
    </HighlightBlock>;
}

interface FullDataListToggleProps
{
    names: Array<string>;
}

const ClusterPermissionNames = ({names}: FullDataListToggleProps) => {
    const [isOpen, setState] = useState(false);

    const toggle = () => {
        const newState = !isOpen;
        setState(newState);
    }

    return <div className={"data-list-container"}>
        <Typography color={"inherit"} className={"data-list-toggle"} onClick={() => toggle()}>
            {(isOpen)
                ? <>Hide full data list <FontAwesomeIcon icon={faAngleUp} size={"lg"}/></>
                : <>Show full data list <FontAwesomeIcon icon={faAngleDown} size={"lg"}/></>
            }
        </Typography>
        <ul className={"data-list" + ((isOpen) ? " visible" : " hidden")}>
            {names.map((itemText, index) => <li key={index}>{sanitize(itemText)}</li>)}
        </ul>
    </div>
}

export default DataClusterCard;
