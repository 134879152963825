import React, {useCallback, useEffect, useState} from "react";
import {Navigate, Route, Routes as RouterSwitch, useLocation, useNavigate} from "react-router-dom";
import { matchPath } from "react-router";
import NotFound from "./NotFound";
import AppError from "../admin/pages/AppError";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import {consentRedirectByErrorResponseStatus} from "../helpers/RedirectHelper";
import CustomerDetailsInputs from "./CustomerDetailsInputs";
import GeneralSettings from "../models/GeneralSettings";
import Configuration from "../admin/models/Configuration";
import {Theme} from "@mui/material";
import ThemeBuilder from "../ThemeBuilder";
import DashboardSettings from "../models/DashboardSettings";
import PreConsent from "../models/PreConsent";
import Consent from "../models/Consent";
import {createTheme} from "@mui/material/styles";
import {defaultTheme} from "../Theme";
import LoadingSpinner from "../components/LoadingSpinner";
import DataHolderSelection from "./DataHolderSelection";
import DataHolder from "../models/DataHolder";
import PreConsentData from "../models/PreConsentData";
import SessionExpired from "./SessionExpired";
import DataRequest from "./DataRequest";
import CDRValueProposition from "./CDRValueProposition";
import PreviousDataDeleteConfirmation from "./PreviousDataDeleteConfirmation";
import DataHolderConnectConfirmation from "./DataHolderConnectConfirmation";
import MultiBankSelectionList from "./MultiBankSelectionList";
import Success from "./Success";
import MultibankSuccess from "./MultibankSuccess";
import Dashboard from "./Dashboard";
import ConsentDetails from "./ConsentDetails";
import ConsentHistory from "./ConsentHistory";
import WithdrawConsent from "./WithdrawConsent";
import WithdrawConsentConfirmation from "./WithdrawConsentConfirmation";
import WithdrawConsentSuccess from "./WithdrawConsentSuccess";
import {isJWT} from "../helpers/JwtHelper";
import Broker from "../admin/models/Broker";
import BankFeedsRequest from "./BankFeedsRequest";
import SessionFinished from "./SessionFinished";
//import ProductValueProposition from "./ProductValueProposition";

interface TrustedAdvisersProps
{
    basePath: string;
    obApi: OpenBankingPlatformAPI;
    getPrincipalHeaderLogoSrc: (generalSettings: GeneralSettings) => Promise<string | null>;
    getAccreditationIconSrc: (generalSettings: GeneralSettings) => Promise<string | null>;
    getHeaderBgImageSrc: (generalSettings: GeneralSettings) => Promise<string | null>;
    getPvrImageSrcUrls: (config: Configuration, i: number) => Promise<string | null>;
    getCdrImageSrcUrls: (config: Configuration, i: number) => Promise<string | null>;
    handleDataHolderSelectionChange: (newSelection: DataHolder, loadedPreConsentData?: PreConsentData) => void;
    handleWithdrawConsentDeleteDataOption: (newOption: boolean) => void;
    handleConsentButtonOnDataRequestScreen: (newPreConsentData?: PreConsentData) => void;
    dashboardPath: string;
    isMultiBank?: boolean;
    currentDeleteOption: boolean | undefined;
    consentExists?: boolean;
    loadedPreConsentData?: PreConsentData;
    currentDataHolderSelection?: DataHolder;
    onIUnderstandButtonClick: (newPreConsentData: PreConsentData) => void;
    onAuthorisationAccept?: (newSelection: DataHolder) => void;
    handleCallBackFromDataHolder: (storedDataHolder: DataHolder) => void;
    brokerCode: string;
    hasTokenUrl: boolean;
    setTheme: (theme: Theme) => void;
}

interface TrustedAdvisersState
{
    isLoading: boolean;
    apiError?: boolean;
    hasValidBrokerCode: boolean;
    loadedPreConsentConfig?: PreConsent;
    loadedGeneralSettingsConfig?: GeneralSettings;
    loadedDashboardSettingsConfig?: DashboardSettings;
    loadedConsentConfig?: Consent;
    loadedThemeOptions: Partial<Theme> | ((outerTheme: Theme) => Theme);
    principalLogoUrl: string;
    headerBgImageUrl?: string;
    accreditationIconUrl: string,
    pvpFeatureKeyVisualImageUrl?: string;
    pvpFeature1ImageUrl?: string;
    pvpFeature2ImageUrl?: string;
    pvpFeature3ImageUrl?: string;
    cdrSupportingImageUrl?: string;
    cdrFeature1ImageUrl?: string;
    cdrFeature2ImageUrl?: string;
    cdrFeature3ImageUrl?: string;
    broker?: Broker;
    isMultiBank: boolean;
    brokerCode?: string;
}

const TrustedAdvisers = (props: TrustedAdvisersProps) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState<TrustedAdvisersState>({
        isLoading: true,
        apiError: false,
        hasValidBrokerCode: false,
        loadedThemeOptions: createTheme(defaultTheme),
        principalLogoUrl: "",
        headerBgImageUrl: "",
        accreditationIconUrl: "",
        isMultiBank: false
    });

    const trustedAdviserConsentUiPath = props.basePath + '/consentui';

    const connectAnotherDataholderPath = trustedAdviserConsentUiPath + "/consent/data-holder-selection";

    const loadPrincipalConfiguration = () => {

    }

    //eslint-disable-next-line
    const validateToken = useCallback(props.obApi.validateToken, []);
    //eslint-disable-next-line
    const getSession = useCallback(props.obApi.getTrustedAdviserConsentSession, []);
    useEffect(() => {
        (async() => {
            try {
                var brokerCode:any = props.brokerCode;
                if (brokerCode === '') {
                    const dashboardTokenPathMatch = matchPath(props.basePath + '/dashboardui/:token', location.pathname);
                    const consentUiPathMatch = matchPath(props.basePath + '/:brokerCode', location.pathname);
                    if (dashboardTokenPathMatch) {
                        var token:any = location.pathname.split("/").pop();
                        if (isJWT(token)) {
                            await validateToken();
                            const loadedConfigurations = await getSession("");
                            loadConfigurations(loadedConfigurations);
                        } else {
                            navigate(props.basePath + "/notfound", {
                                state: {
                                    errorCode: 20029,
                                    errorText: 'Page Not Found',
                                }
                            });
                        }
                    } else if (consentUiPathMatch) {
                        brokerCode = location.pathname.split("/").pop();
                        const loadedConfigurations = await getSession(brokerCode);
                        loadConfigurations(loadedConfigurations);
                    } else {
                        const loadedConfigurations = await getSession('');
                        loadConfigurations(loadedConfigurations);
                    }
                } else {
                    const loadedConfigurations = await getSession('');
                    loadConfigurations(loadedConfigurations);
                }
            } catch (error) {
                setState(state => ({
                    ...state,
                    isLoading: false,
                    apiError: true,
                }))

                var response = (error as any).response;
                if (response.status === 419) {
                    setState(state => ({
                        ...state,
                        brokerCode: response.data.brokerCode
                    }))
                }

                setState(state => ({
                    ...state,
                    isLoading: false,
                    apiError: true,
                }))
                consentRedirectByErrorResponseStatus(navigate, response, trustedAdviserConsentUiPath);
            }
        })();
        //eslint-disable-next-line
    }, []);

    const loadConfigurations = (loadedConfigurations: Configuration) => {
        setState(state => ({
            ...state,
            hasValidBrokerCode: true
        }));
        sessionStorage.setItem('isTrustedAdviserSession', 'true');
        //sessionStorage.setItem('brokerCode', brokerCode);

        (async() => {
            const [
                principalLogoUrl,
                accreditationIconUrl,
                headerBgImageUrl,
                pvpFeatureKeyVisualImageUrl,
                cdrSupportingImageUrl,
                pvpFeature1ImageUrl,
                pvpFeature2ImageUrl,
                pvpFeature3ImageUrl,
                cdrFeature1ImageUrl,
                cdrFeature2ImageUrl,
                cdrFeature3ImageUrl,
            ] = await Promise.allSettled([
                props.getPrincipalHeaderLogoSrc(loadedConfigurations.generalSettings),
                props.getAccreditationIconSrc(loadedConfigurations.generalSettings),
                props.getHeaderBgImageSrc(loadedConfigurations.generalSettings),
                props.getPvrImageSrcUrls(loadedConfigurations, 0),
                props.getCdrImageSrcUrls(loadedConfigurations, 0),
                ...new Array(3)
                    .fill(0)
                    .map((_, i) => ++i)
                    .map(i => i <= loadedConfigurations.preConsent.pvpNumberOfFeatures ? props.getPvrImageSrcUrls(loadedConfigurations, i) : Promise.resolve('')),
                ...new Array(3)
                    .fill(0)
                    .map((_, i) => ++i)
                    .map(i => i <= loadedConfigurations.preConsent.cdrNumberOfFeatures ? props.getCdrImageSrcUrls(loadedConfigurations, i) : Promise.resolve('')),
            ]).then(result => {
                return result.map(item => item.status === 'fulfilled' ? item.value : '');
            }).catch(() => {
                return new Array(11).fill('');
            });

            const loadedTheme = ThemeBuilder({
                //@ToDo : It is better if we have an option to choose which theme to be applied from admin
                theme: 'Custom',
                generalSettings: loadedConfigurations.generalSettings,
            });

            setState(state => ({
                ...state,

                principalLogoUrl,
                accreditationIconUrl,
                headerBgImageUrl,
                pvpFeatureKeyVisualImageUrl,
                cdrSupportingImageUrl,
                pvpFeature1ImageUrl,
                pvpFeature2ImageUrl,
                pvpFeature3ImageUrl,
                cdrFeature1ImageUrl,
                cdrFeature2ImageUrl,
                cdrFeature3ImageUrl,

                loadedPreConsentConfig: loadedConfigurations.preConsent,
                loadedGeneralSettingsConfig: loadedConfigurations.generalSettings,
                loadedDashboardSettingsConfig: loadedConfigurations.dashboard,
                loadedConsentConfig: loadedConfigurations.consent,
                isMultiBank: loadedConfigurations.generalSettings.multibankEnabled,
                broker: loadedConfigurations.broker,
                //loadedInsightConfig: loadedConfigurations.insight,
                //loadedDeveloperTools: loadedConfigurations.developerTools,
                //isUpdateConsentFromDashboard: loadedConfigurations.isConsentUpdateFromDashboard,
                loadedThemeOptions: loadedTheme,
                //customerDetails: customerDetailsCheck.customerDetails,
                //customerDetailsRequired: customerDetailsCheck.customerDetails !== null ? false : true,
                isLoading: false,
                apiError: false
            }));

            props.setTheme(loadedTheme);
        })();
    }

    return <>

        {(state.isLoading)
            ? <LoadingSpinner position={"fixed"} overlay /> :
            <RouterSwitch>
                <Route path={":brokerCode"} element={
                    <>
                        {(state.hasValidBrokerCode ?
                                <Navigate to={trustedAdviserConsentUiPath + "/customer-details"} /> : <Navigate to={trustedAdviserConsentUiPath + "/notfound"} />
                            )}
                        <CustomerDetailsInputs
                            basePath={trustedAdviserConsentUiPath}
                            api={props.obApi}
                            next={props.basePath + "/preconsent"}
                            generalSettingsConfig={state.loadedGeneralSettingsConfig}
                            preConsentConfig={state.loadedPreConsentConfig}
                            hasApiError={state.apiError}
                            principalLogoUrl={state.principalLogoUrl}
                            headerBgImageUrl={state.headerBgImageUrl}
                            isTrustedAdviser={true}
                            broker={state.broker}
                        />
                    </>
                } />

                <Route path={"consentui/customer-details"} element={
                    <CustomerDetailsInputs
                        basePath={trustedAdviserConsentUiPath}
                        api={props.obApi}
                        next={trustedAdviserConsentUiPath + "/consent/data-holder-selection"}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        preConsentConfig={state.loadedPreConsentConfig}
                        hasApiError={state.apiError}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        isTrustedAdviser={true}
                        broker={state.broker}
                    />
                } />

                {/*
                <Route path={"consentui/preconsent"} element={
                    <>
                        {!state.loadedPreConsentConfig?.inclPreConsentStage && (
                            <Navigate to={trustedAdviserConsentUiPath + "/consent/data-holder-selection"} />
                        )}
                        <ProductValueProposition
                            basePath={trustedAdviserConsentUiPath}
                            api={props.obApi}
                            preConsentConfig={state.loadedPreConsentConfig}
                            generalSettingsConfig={state.loadedGeneralSettingsConfig}
                            hasApiError={state.apiError}
                            next={trustedAdviserConsentUiPath + "/consent/data-holder-selection"}
                            principalLogoUrl={state.principalLogoUrl}
                            headerBgImageUrl={state.headerBgImageUrl}
                            pvpFeatureKeyVisualImageUrl={state.pvpFeatureKeyVisualImageUrl}
                            pvpFeature1ImageUrl={state.pvpFeature1ImageUrl}
                            pvpFeature2ImageUrl={state.pvpFeature2ImageUrl}
                            pvpFeature3ImageUrl={state.pvpFeature3ImageUrl}
                            isTrustedAdviser={true}
                        />
                    </>
                } />
                */}

                <Route path={"consentui/consent/data-holder-selection"} element={
                    <DataHolderSelection
                        basePath={trustedAdviserConsentUiPath}
                        onSelectionChange={props.handleDataHolderSelectionChange}
                        next={trustedAdviserConsentUiPath + "/preconsent/cdr-value"}
                        api={props.obApi}
                        preConsentConfig={state.loadedPreConsentConfig}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        isTrustedAdviser={true}
                    />
                } />

                <Route path={"consentui/preconsent/cdr-value"} element={
                    <CDRValueProposition
                        prev={trustedAdviserConsentUiPath + "/preconsent"}
                        next={trustedAdviserConsentUiPath + "/consent/data-request"}
                        preConsentConfig={state.loadedPreConsentConfig}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        dashboardPath={props.dashboardPath}
                        isMultiBank={state.isMultiBank}
                        currentDataHolderSelection={props.currentDataHolderSelection}
                        api={props.obApi}
                        basePath={trustedAdviserConsentUiPath}
                        consentExists={props.consentExists}
                        principalLogoUrl={state.principalLogoUrl}
                        accreditationIconUrl={state.accreditationIconUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        cdrSupportingImageUrl={state.cdrSupportingImageUrl}
                        cdrFeature1ImageUrl={state.cdrFeature1ImageUrl}
                        cdrFeature2ImageUrl={state.cdrFeature2ImageUrl}
                        cdrFeature3ImageUrl={state.cdrFeature3ImageUrl}
                        isTrustedAdviser={true}
                        broker={state.broker}
                    />
                } />

                <Route path={"consentui/consent/data-request"} element={
                    <DataRequest
                        api={props.obApi}
                        prev={trustedAdviserConsentUiPath + "/preconsent/cdr-value"}
                        next={trustedAdviserConsentUiPath + "/consent/previous-data"}
                        basePath={trustedAdviserConsentUiPath}
                        sharingPeriodIsMandatory={true}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        onDeleteOptionChange={props.handleWithdrawConsentDeleteDataOption}
                        onConsentButtonClick={props.handleConsentButtonOnDataRequestScreen}
                        currentDeleteOption={props.currentDeleteOption}
                        consentConfig={state.loadedConsentConfig}
                        loadedPreConsentData={props.loadedPreConsentData}
                        /*insightConfig={state.loadedInsightConfig}*/
                        dashboardPath={props.dashboardPath}
                        isMultiBank={state.isMultiBank}
                        consentExists={props.consentExists}
                        preConsentConfig={state.loadedPreConsentConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        accreditationIconUrl={state.accreditationIconUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        currentDataHolderSelection={props.currentDataHolderSelection}
                        isTrustedAdviser={true}
                        broker={state.broker}
                    />
                } />

                <Route path={"consentui/consent/previous-data"} element={
                    <PreviousDataDeleteConfirmation
                        next={trustedAdviserConsentUiPath + '/data-holder-connect-confirmation'}
                        prev={trustedAdviserConsentUiPath + "/consent/data-holder-selection"}
                        onCancel={() => navigate(trustedAdviserConsentUiPath + "/preconsent")}
                        onDeleteOptionChange={props.handleWithdrawConsentDeleteDataOption}
                        onIUnderstandButtonClick={props.onIUnderstandButtonClick}
                        currentDeleteOption={props.currentDeleteOption}
                        consentConfig={state.loadedConsentConfig}
                        loadedPreConsentData={props.loadedPreConsentData}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        preconsentConfig={state.loadedPreConsentConfig}
                        api={props.obApi}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        /*insightConfig={state.loadedInsightConfig}*/
                        isTrustedAdviser={true}
                    />
                } />

                <Route path={"consentui/data-holder-connect-confirmation"} element={
                    <DataHolderConnectConfirmation
                        onAuthorisationAccept={props.onAuthorisationAccept}
                        currentDataHolderSelection={props.currentDataHolderSelection}
                        next={trustedAdviserConsentUiPath + "/consent/success"}
                        basePath={trustedAdviserConsentUiPath}
                        api={props.obApi}
                        consentExists={props.consentExists}
                        isMultiBank={state.isMultiBank}
                        loadedPreConsentData={props.loadedPreConsentData}
                        preConsentConfig={state.loadedPreConsentConfig}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        isTrustedAdviser={true}
                    />
                } />

                {(state.isMultiBank /*&& !isConsentUpdateFromDashboard*/ ?
                        <Route path={"consentui/consent/success"} element={
                            <MultiBankSelectionList
                                basePath={trustedAdviserConsentUiPath}
                                api={props.obApi}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                connectAnotherDataHolderPath={connectAnotherDataholderPath}
                                currentDataHolderSelection={props.currentDataHolderSelection}
                                onRedirectBack={props.handleCallBackFromDataHolder}
                                next={trustedAdviserConsentUiPath + "/consent/confirmation"}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                                isTrustedAdviser={true}
                            />
                        } /> :
                        <Route path={"consentui/consent/success"} element={
                            <Success
                                basePath={trustedAdviserConsentUiPath}
                                dashboardPath={props.dashboardPath}
                                connectAnotherDataHolderPath={connectAnotherDataholderPath}
                                currentDataHolderSelection={props.currentDataHolderSelection}
                                onRedirectBack={props.handleCallBackFromDataHolder}
                                generalSettingsConfig={state.loadedGeneralSettingsConfig}
                                api={props.obApi}
                                principalLogoUrl={state.principalLogoUrl}
                                headerBgImageUrl={state.headerBgImageUrl}
                                loadPrincipalConfiguration={loadPrincipalConfiguration}
                                isTrustedAdviser={true}
                                consentConfig={state.loadedConsentConfig}
                            />
                        } />
                )}

                <Route path={"consentui/consent/session-finished"} element={
                    <SessionFinished
                        api={props.obApi}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        isTrustedAdviser={true}
                        consentConfig={state.loadedConsentConfig}
                        /*insightConfig={state.loadedInsightConfig}*/
                    />
                } />

                {state.isMultiBank &&
                    <Route path={"consentui/consent/confirmation"} element={
                        <MultibankSuccess
                            api={props.obApi}
                            generalSettingsConfig={state.loadedGeneralSettingsConfig}
                            principalLogoUrl={state.principalLogoUrl}
                            headerBgImageUrl={state.headerBgImageUrl}
                            isTrustedAdviser={true}
                            consentConfig={state.loadedConsentConfig}
                            /*insightConfig={state.loadedInsightConfig}*/
                        />
                    } />
                }

                <Route path={'consentui/bank-feeds-request'} element={
                    <BankFeedsRequest
                        basePath={trustedAdviserConsentUiPath}
                        api={props.obApi}
                        isMultiBank={state.isMultiBank}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        isTrustedAdviser={true}
                    />
                } />

                <Route path={"dashboardui"} element={
                    <>
                        <Dashboard
                            basePath={props.basePath}
                            api={props.obApi}
                            connectDataHolderPath={connectAnotherDataholderPath}
                            dashboardPath={props.dashboardPath}
                            generalSettingsConfig={state.loadedGeneralSettingsConfig}
                            hasApiError={state.apiError}
                            principalLogoUrl={state.principalLogoUrl}
                            headerBgImageUrl={state.headerBgImageUrl}
                            /*isAccount={Boolean(state.customerDetails?.account_id)}*/
                            loadPrincipalConfiguration={loadPrincipalConfiguration}
                            dashboardSettingsConfig={state.loadedDashboardSettingsConfig}
                            isTrustedAdviser={true}
                            broker={state.broker}
                        />
                    </>
                } />

                <Route path={"dashboardui/:token"} element={
                    <>
                        <Navigate to={props.basePath + "/dashboardui"} />
                    </>
                } />

                <Route path={"dashboardui/consent-details/:id"} element={
                    <ConsentDetails
                        basePath={props.basePath}
                        api={props.obApi}
                        dashboardPath={props.dashboardPath}
                        onDeleteOptionChange={props.handleWithdrawConsentDeleteDataOption}
                        currentDeleteOption={props.currentDeleteOption}
                        preconsentConfig={state.loadedPreConsentConfig}
                        consentConfig={state.loadedConsentConfig}
                        /*insightConfig={state.loadedInsightConfig}*/
                        loadedPreConsentData={props.loadedPreConsentData}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        onSelectionChange={props.handleDataHolderSelectionChange}
                        loadPrincipalConfiguration={loadPrincipalConfiguration}
                        isTrustedAdviser={true}
                        broker={state.broker}
                    />
                } />

                <Route path={"dashboardui/consent-history/:id/*"} element={
                    <ConsentHistory
                        basePath={props.basePath}
                        api={props.obApi}
                        dashboardPath={props.dashboardPath}
                        consentConfig={state.loadedConsentConfig}
                        loadedPreConsentData={props.loadedPreConsentData}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        loadPrincipalConfiguration={loadPrincipalConfiguration}
                        isTrustedAdviser={true}
                        broker={state.broker}
                    />
                } />

                <Route path={"dashboardui/consent-details/:id/withdraw"} element={
                    <WithdrawConsent
                        basePath={props.basePath}
                        api={props.obApi}
                        dashboardPath={props.dashboardPath}
                        onDeleteOptionChange={props.handleWithdrawConsentDeleteDataOption}
                        currentDeleteOption={props.currentDeleteOption}
                        preconsentConfig={state.loadedPreConsentConfig}
                        consentConfig={state.loadedConsentConfig}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        loadPrincipalConfiguration={loadPrincipalConfiguration}
                        isTrustedAdviser={true}
                        /*insightConfig={state.loadedInsightConfig}*/
                    />
                } />

                <Route path={"dashboardui/consent-details/:id/withdraw-confirmation"} element={
                    <WithdrawConsentConfirmation
                        basePath={props.basePath}
                        principalName={state.loadedGeneralSettingsConfig?.principalName}
                        api={props.obApi}
                        dashboardPath={props.dashboardPath}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        loadPrincipalConfiguration={loadPrincipalConfiguration}
                        isTrustedAdviser={true}
                    />
                } />

                <Route path={"dashboardui/consent-details/:id/withdraw-success"} element={
                    <WithdrawConsentSuccess
                        basePath={props.basePath}
                        api={props.obApi}
                        dashboardPath={props.dashboardPath}
                        preconsentConfig={state.loadedPreConsentConfig}
                        consentConfig={state.loadedConsentConfig}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        loadPrincipalConfiguration={loadPrincipalConfiguration}
                        isTrustedAdviser={true}
                    />
                } />

                <Route path="consentui/error" element={
                    <AppError />
                } />

                <Route path={""} element={
                    <NotFound
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        preConsentConfig={state.loadedPreConsentConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        isTrustedAdviser={true}
                    />
                } />

                <Route path={"consentui"} element={
                    <NotFound
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        preConsentConfig={state.loadedPreConsentConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        isTrustedAdviser={true}
                    />
                } />

                <Route path="consentui/notfound" element={
                    <NotFound
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        preConsentConfig={state.loadedPreConsentConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        isTrustedAdviser={true}
                    />
                } />

                <Route path="consentui/session-expired" element={
                    <SessionExpired
                        basePath={props.basePath}
                        api={props.obApi}
                        generalSettingsConfig={state.loadedGeneralSettingsConfig}
                        preConsentConfig={state.loadedPreConsentConfig}
                        principalLogoUrl={state.principalLogoUrl}
                        headerBgImageUrl={state.headerBgImageUrl}
                        isTrustedAdviser={true}
                        brokerCode={state.brokerCode}
                    />
                } />
            </RouterSwitch>
        }
    </>
}

export default TrustedAdvisers;