import React, { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Grid, Hidden, InputBase, Link } from "@mui/material";

import LinkButton from "../../components/LinkButton";
import ImportBrokersCSV from "../../components/ImportBrokersCSV";
import ExportBrokersCSV from "../../components/ExportBrokersCSV";
import LoadingSpinner from "../../components/LoadingSpinner";
import {isAdmin, isSuperAdmin} from "../../helpers/AuthUserHelper";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import BrokersListItem from "../components/BrokersListItem";
import User from "../models/User";

interface BrokersListState {
    data: Array<User>;
    isLoading: boolean;
    searchKeyword: string;
    currentPage: number;
    pageCount: number;
    updateMessage: string;
    currentPrincipalId: number;
}

interface BrokersListProps {
    api: OpenBankingPlatformAPIInterface;
    brokerDetailsPath: string;
    hasApiError?: boolean;
    currentUser?: CurrentUser;
    basePath: string;
}

const BrokersList = (props: BrokersListProps) => {

    let perPage: number = 10;

    const [state, setState] = useState<BrokersListState>({
        data: [],
        isLoading: false,
        searchKeyword: '',
        currentPage: 0,
        pageCount: 0,
        updateMessage: '',
        currentPrincipalId: 0
    });

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`${props.basePath}/home`);
    };

    const handlePageClick = (e: any) => {
        const selectedPage = e.selected;
        const newOffset = selectedPage * perPage;
        setState((state)=>({
            ...state,
            currentPage: selectedPage,
            offset: newOffset,
        }));
    }

    const msgHandler = (msg: string) => {
        setState((state) =>({
            ...state,
            updateMessage: msg
        }));
        doSearch();
    }

    const principalIdHandler = (principalId: number) => {
        setState((state) =>({
            ...state,
            currentPrincipalId: principalId
        }));
    }

    const doSearch = () => {
        (async () => {
            try {
                if (typeof props.currentUser !== "undefined") {
                    setState((state)=>({
                        ...state,
                        isLoading: true,
                    }));
                    var accountId = props.currentUser?.account_id;
                    if (isAdmin(props.currentUser.role_id)) {
                        accountId = undefined;
                    }
                    const loadedBrokers = await getBrokers(state.currentPage+1, state.searchKeyword, accountId);

                    perPage = loadedBrokers.pagination;
                    setState((state)=>({
                        ...state,
                        data: loadedBrokers.dataset,
                        isLoading: false,
                        pageCount: Math.ceil(loadedBrokers.total / perPage),
                    }));
                    if (state.currentPage > Math.ceil(loadedBrokers.total / perPage)-1) {
                        setState((state)=>({
                            ...state,
                            currentPage: 0,
                        }));
                    }
                }
            } catch (error) {
                // TODO Uncomment this code when fallback is removed
                /*
                setState((state)=>({
                    ...state,
                    apiHasReturnedAnError: true,
                    isLoading: false,
                }))
                navigate("/token-error");*/
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }


    useEffect(() => {
        doSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.currentPage]);

    //eslint-disable-next-line
    const getBrokers = useCallback(props.api.getBrokersList, []);

    return <div className="users-list">
        {(isSuperAdmin(props.currentUser?.role_id)) ?
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                </Grid>
            </Grid>
            : "" }
        
        <h2>Manage Brokers</h2>
        {(state.updateMessage !== '') && 
        <Grid item xs={12} md={7}> 
            <span className={"update-message-text"}>{state.updateMessage}</span>
        </Grid>}

        <Grid container className={"dashboard-action-bar"}>
            <Grid item xs={12} md={7}>
                <LinkButton targetlocation={props.brokerDetailsPath} variant={"contained"} color={"secondary"}>Add New Broker</LinkButton>&nbsp;&nbsp;
                {(!isAdmin(props.currentUser?.role_id) && props.currentUser) &&
                <>
                    <ImportBrokersCSV 
                        api={props.api} 
                        variant={"contained"} 
                        color={"secondary"} 
                        accountid={props.currentUser?.account_id} 
                        basepath={props.basePath}
                        msghandler={msgHandler}
                        principalidhandler={principalIdHandler}
                    />&nbsp;&nbsp;
                    <ExportBrokersCSV 
                        api={props.api} 
                        variant={"contained"} 
                        color={"secondary"} 
                        accountid={props.currentUser?.account_id} 
                        basepath={props.basePath}
                    />
                </>
                }                
            </Grid>
            <Grid item xs={12} md={5}>
                <Grid container className={"filters-bar"}>
                    <Grid item xs={10}>
                        <InputBase
                            id={"keyword-input"}
                            placeholder={"Enter search"}
                            inputProps={{ "aria-label": "Enter search" }}
                            onChange={event => {
                                setState({ ...state, searchKeyword: event.target.value.toLowerCase() });
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button id={"search-btn"} variant={"contained"} color={"secondary"} 
                            disabled={state.searchKeyword.length < 3 && state.searchKeyword.length > 0} 
                            onClick={() => doSearch()}
                        >
                            <Hidden xsDown>Search</Hidden>
                            <Hidden smUp><SearchIcon/></Hidden>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <div className={"institution-list paginated-table"}>
            <Hidden mdDown>
                <Grid container className={"table-head"}>
                    <Grid item xs={(isSuperAdmin(props.currentUser?.role_id)) ? 2 : 4}>Name / Email / Code</Grid>
                    <Grid item xs={2}>Mobile</Grid>
                    <Grid item xs={2}>Reg Number</Grid>
                    {(isSuperAdmin(props.currentUser?.role_id)) && <Grid item xs={2}>Account</Grid>}
                    <Grid item xs={2}>Principal</Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
            </Hidden>
            {!state.isLoading && state.data && state.data.map((item, i) => {
                return <BrokersListItem brokerDetailsPath={props.brokerDetailsPath} key={i} {...item} isSuperAdmin={isSuperAdmin(props.currentUser?.role_id)} />
            })}
            {state.isLoading &&
                <LoadingSpinner loadingText={""} />
            }
            {state.pageCount > 1 &&
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    pageCount={state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            }
        </div>
    </div>
}

export default BrokersList;