import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormControl, Grid, Link } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import SwitchWithLabel from "../../components/SwitchWithLabel";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import ConsentListItemData from "../../models/ConsentListItemData";
import DataCluster from "../../openbankingplatform/models/DataCluster";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";

interface ViewConsentProps {
    basePath: string;
    adminObApi: OpenBankingPlatformAPIInterface;
    // basePath: string,
    // currentUser?: CurrentUser,
}

interface ConsentDetailsState {
	consentDetails?: ConsentListItemData;
	dataClusters: Array<DataCluster>;
	sharingStartAt?: string;
	sharingEndAt?: string;
	consentStatus: string;
    backgroundOperationInProgress: boolean;
}

interface ConsentDto {
    delete_my_data_after_used: boolean;
}

const ViewConsent = (props: ViewConsentProps) => {

    const { id } = useParams<{ id: any }>();

    const [loading, setLoading] = useState<boolean>(false);
    const [saveError, setSaveError] = useState<string>('');
    const [editMode, setEditMode] = useState<boolean>(false);
    const toggleEditMode = (resetData: boolean = false) => {
        if(loading){
            return;
        }
        
        if(resetData){
            setEditConsentData({
                delete_my_data_after_used: state.consentDetails?.delete_my_data_after_used || false,
            });
        }
        setSaveError('');
        setEditMode(!editMode);
    }

    const saveConsent = (consentDto: ConsentDto) => {
        (async() => {
            if(state.consentDetails?.id && !loading){
                try {
                    setLoading(true);
                    setSaveError('');
                    await updateConsent(state.consentDetails.uuid, consentDto.delete_my_data_after_used);
                    setLoading(false);
                    setEditMode(false);
                    setState({
                        ...state,
                        consentDetails: {
                            ...state.consentDetails,
                            ...consentDto,
                        }
                    })
                } catch (error) {
                    setLoading(false);
                    setSaveError((error as any).response.data.error_message);
                }
            }
        })();
    }

    const [editConsentData, setEditConsentData] = useState<ConsentDto>({
        delete_my_data_after_used: false
    });

    const [state, setState] = useState<ConsentDetailsState>({
		dataClusters: [],
		consentStatus: '',
        backgroundOperationInProgress: true
	});

    const [withdrawFilename, updateWithdrawFilename] = useState('');
    const [PdfData, setPdfData]: any = useState();

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`${props.basePath}/consents-list`);
    };

    //eslint-disable-next-line
    const getConsent = useCallback(props.adminObApi.getConsentById, []);
    //eslint-disable-next-line
    const updateConsent = useCallback(props.adminObApi.updateConsent, []);
    //eslint-disable-next-line
    const getDataClusters = useCallback(props.adminObApi.getSelectedDataClustersForConsent, []);
    //eslint-disable-next-line
    const getInsights = useCallback(props.adminObApi.getSelectedInsightsForConsent, []);
    //eslint-disable-next-line
    const withdrawConsent = useCallback(props.adminObApi.withdrawConsentById, []);
    const handleWithdrawConsent = (consent_id: number) => {
        (async(consent_id, updateWithdrawFilename) => {
            try {
                setState({
                    ...state,
                    backgroundOperationInProgress: true
                })
               const withdrawResult = await withdrawConsent(consent_id);
               updateWithdrawFilename('true');

                setState((state)=>({
                    ...state,
                    backgroundOperationInProgress: false,
                    consentDetails: withdrawResult,
                }))
            } catch(error) {
                //console.log("error", error);
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })(consent_id, updateWithdrawFilename);
    }
    
    useEffect(() => {
        (async () => {
            try {
                setState({
                    ...state,
                    backgroundOperationInProgress: true
                })
                const loadedDataClusters = await getDataClusters(id);
                const loadConsent = await getConsent(id);
                setEditConsentData({
                    delete_my_data_after_used: loadConsent.delete_my_data_after_used,
                });
                setState((state)=>({
                    ...state,
                    backgroundOperationInProgress: false,
                    consentDetails: loadConsent,
                    dataClusters: loadedDataClusters,
                    sharingStartAt: loadConsent.sharing_start_at,
                    sharingEndAt: loadConsent.sharing_end_at,
                    consentStatus: loadConsent.status.toLowerCase() === 'active' ? 'Active' : 'Inactive'
                }))
            } catch (error) {
                /*
                // TODO Uncomment this code when fallback is removed
                setState((state)=>({
                    ...state,
                    apiHasReturnedAnError: true,
                }))
                // navigate("/token-error");*/
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    },
    //eslint-disable-next-line
    [])

    //eslint-disable-next-line
    const getDownloadPdfData = useCallback(props.adminObApi.getDownloadPdf, []);
    const handleDownloadConsentPdf = (consent_uuid: string, consent_type: number) => {
        //Send to server
        (async (consent_uuid, consent_type) => {
            try {
                const pdfData = await getDownloadPdfData(consent_uuid, consent_type)
                setPdfData(pdfData)
            } catch (error) {
                console.log(error);
                //adminRedirectByResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })(consent_uuid, consent_type);
    }

    useEffect(() => {
        if (PdfData) {
            let blob = new Blob([PdfData], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.setAttribute('download', 'consent.pdf');
            link.href = window.URL.createObjectURL(blob);
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }, [PdfData]);

    return <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                </Grid>
            </Grid>
            <div className="view-consent">
                <h2>View Consent</h2>

                {(state.backgroundOperationInProgress) ?
                    <LoadingSpinner loadingText={""} />
                    : <>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>ID</label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                {state.consentDetails?.uuid}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Customer</label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                {state.consentDetails?.personal_details}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Principal</label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                {state.consentDetails?.principal}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Data Holder</label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                {state.consentDetails?.institution_name}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Is CDR?</label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                {state.consentDetails?.is_cdr ? "Yes" : "No"}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Status</label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                {state.consentDetails?.status}
                            </Grid>
                        </Grid>
                        <Grid container className={"form-group"}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>Sharing Start At</label>
                            </Grid>
                            {(state.consentDetails?.is_cdr) ?
                                <Grid item xs={12} sm={7} md={5}>
                                    {state.consentDetails?.sharing_start_at_styled}
                                </Grid> :
                                <Grid item xs={12} sm={7} md={5}>
                                    {state.consentDetails?.effective_at_styled}
                                </Grid>
                            }
                        </Grid>
                        {state.consentDetails?.confirmation_pdf && (
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Confirmation PDF</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <a href={"/#"} onClick={(e) => {e.preventDefault(); handleDownloadConsentPdf(id, 1); }}>
                                        <FontAwesomeIcon icon={faDownload} />
                                    </a>
                                </Grid>
                            </Grid>
                        )}
                        {(withdrawFilename || state.consentDetails?.withdraw_pdf) && (<>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Withdrawn At</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    {state.consentDetails?.withdrawn_at_styled}
                                </Grid>
                            </Grid>
                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Withdrawal PDF</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    <a href={"/#"} onClick={(e) => {e.preventDefault(); handleDownloadConsentPdf(id, 2); }}>
                                        <FontAwesomeIcon icon={faDownload} />
                                    </a>
                                </Grid>
                            </Grid>
                        </>)}

                            <Grid container className={"form-group"}>
                                <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                    <label>Delete Data After Use</label>
                                </Grid>
                                <Grid item xs={12} sm={7} md={5}>
                                    
                                    <FormControl>
                                        <SwitchWithLabel 
                                            checked={editConsentData.delete_my_data_after_used}
                                            disabled={!editMode}
                                            onChange={value => {
                                                setEditConsentData({
                                                    ...editConsentData,
                                                    delete_my_data_after_used: value
                                                })
                                            }}
                                        >
                                            {(editMode ? editConsentData.delete_my_data_after_used : state.consentDetails?.delete_my_data_after_used) ? "Yes" : "No"}
                                        </SwitchWithLabel>
                                    </FormControl>
                                </Grid>
                            </Grid>

                        {(state.consentDetails?.status !== 'Archived') ?
                            <Grid container alignItems="center" className={"form-group"} spacing={2}>
                                <Grid item><Button variant={"contained"} color={"secondary"} onClick={(e: any) => {e.preventDefault(); handleWithdrawConsent(state.consentDetails?.id! as number)}}><b>Withdraw Consent</b></Button></Grid>
                                <Grid item><Button variant={"outlined"} color={"secondary"} disabled={editMode && loading} onClick={(e: any) => {e.preventDefault(); editMode ? saveConsent(editConsentData) : toggleEditMode(); }}>{editMode ? 'Save' : 'Edit'}</Button></Grid>
                                {editMode && <Grid item><a href="/#" onClick={(e: any) => {e.preventDefault(); toggleEditMode(true); }}>Cancel</a></Grid>}
                            </Grid> : ""
                        }

                        {saveError && editMode && <div><span className={"error-text"}>{saveError}</span></div>}
                        </>
                }
            </div>
    </div>
}

export default ViewConsent;