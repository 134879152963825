import React, {  } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";
import LightTooltip from "./LightTooltip";

const FontFamilyPicker = (
    { 
        title, 
        tooltipImageSrc, 
        fontFamilyValue, 
        onFontFamilyChange, 
        supportedFonts 
    }:{
        
        title: string, 
        tooltipImageSrc: string, 
        fontFamilyValue?: string, 
        onFontFamilyChange: (newFontFamily: string) => void, 
        supportedFonts: any[]
    }
) => (
    <Grid container className={"form-group"}>
        <Grid item xs={12} sm={6} className={"align-self-center"}>
            <label>{title}<LightTooltip
                title={<img className="tooltip-image" src={tooltipImageSrc} alt="" />}
                placement="right-start" arrow><InfoIcon color={"secondary"} /></LightTooltip>
            </label>
        </Grid>
        <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" className="w-100">
                <Select
                    name={`font-family-${title.toLowerCase().replace(/ /g, '-')}`}
                    value={fontFamilyValue}
                    onChange={(event) => onFontFamilyChange(event.target.value)}
                >
                    <MenuItem value={"none"}>- Choose a font family -</MenuItem>
                    {supportedFonts.map(item =>
                        <MenuItem value={item.fontFamily} key={item.fontFamily}>
                           <span style={{fontFamily: item.fontFamily, fontSize: '1.1em'}}>{item.fontFamily}</span>
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        </Grid>
    </Grid>
);

export default FontFamilyPicker;
