import React from "react";
import PrimaryBlock from "../../components/PrimaryBlock";
import { Container } from "@mui/material";

interface AppErrorProps {
}

const AppError = (props: AppErrorProps) => {
    return <>
        <div className={"page-top"}>
            <main>
                <PrimaryBlock>
                    <Container maxWidth="sm" className="success-confirmation">
                        <h2 className="mt-0">An unexpected error has occurred</h2>
                        <div>
                            <p>Please contact our support team for assistance.</p>
                        </div>
                    </Container>
                </PrimaryBlock>
            </main>
        </div>
    </>;
}

export default AppError;