import React from "react";
import { Link } from "react-router-dom";

import { ManageAccounts } from "@mui/icons-material";
import { Box } from "@mui/material";

interface AdminHomeProps {
    errorLogsListPath: string;
    brokersListPath: string;
}

const AdminBrokerAggregatorHome = (props: AdminHomeProps) => {

    return <div className="admin-home">
        <h2><strong>Brokers Aggregator Portal Home</strong></h2>
        <Box mt={4}>
            <ul className="admin-links">
                <li><Link color={"secondary"} to={props.brokersListPath}><ManageAccounts/> Manage Brokers</Link></li>
            </ul>
        </Box>
    </div>
}

export default AdminBrokerAggregatorHome;