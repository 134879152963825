import React from "react";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Link } from "@mui/material";

interface InvoicingListItemProps {
    id?: number;
    name?: string;
    reference_code?: string;
    account_id?: string;
    account_name?: string;
    invoicingDetailsPath: string;
}

const InvoicingListItem = (props: InvoicingListItemProps) => {

    /*
    const handleViewDetails = () => {
        navigate(props.userDetailsPath + `/${props.id}`);
    };*/


    return <Grid container className={"consent-list-item"}>
        <Grid item xs={1}>{props.id}</Grid>
        <Grid item xs={4}>{props.name}</Grid>
        <Grid item xs={3}>{props.reference_code}</Grid>
        <Grid item xs={2}>{props.account_name}</Grid>
        <Grid item xs={2}><Link color={"secondary"} href={props.invoicingDetailsPath+ `/${props.id}`}><b><u>View details</u> <FontAwesomeIcon icon={faChevronRight} /></b></Link></Grid>
    </Grid>
}

export default InvoicingListItem;

