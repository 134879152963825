import React from "react";
import PrimaryBlock from "../../components/PrimaryBlock";
import { Container } from "@mui/material";

interface NotFoundProps {
}

const NotFound = (props: NotFoundProps) => {
    return <>
        <div className={"page-top"}>
            <main>
                <PrimaryBlock>
                    <Container maxWidth="sm" className="success-confirmation">
                        <h2 className="mt-0">404 Page Not Found</h2>
                        <div>
                            <p>Sorry, that page doesn't exist. Please check that the URL was typed correctly.</p>
                        </div>
                    </Container>
                </PrimaryBlock>
            </main>
        </div>
    </>;
}

export default NotFound;