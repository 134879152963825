import {Grid, Hidden, Link} from "@mui/material";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import ConsentHistoryListItemData from "../models/ConsentHistoryListItemData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import moment from "moment";
import {sanitize} from "../helpers/HtmlSanitizerHelper";

interface ConsentHistoryListItemProps extends ConsentHistoryListItemData {
	downloadHistoryReceipt: (consent_uuid: string, consent_type: number) => void;
}

const escapeSpecialCharacters = (str: string) => {
	var html = str.replace(/\\n/g, "<br/>").replace(/\\r/g, "");
	return sanitize(html);
};

const ConsentHistoryListItem = (props: ConsentHistoryListItemProps) => {
	return <Grid container className={"consent-history-list-item"}>
		<Grid item xs={11} md={9}>
			<Grid container>
				<Grid item xs={12} md={3} className="col-update-date">{moment.utc(props.consent_update_date).local().format('DD MMM YYYY')}</Grid>
				<Grid item xs={12} md={3} className="col-description"><span dangerouslySetInnerHTML={{__html: escapeSpecialCharacters(props.consent_changed_description)}}></span></Grid>
				<Grid item xs={12} md={3} className="col-granted-date">{moment.utc(props.consent_granted_date).local().format('DD MMM YYYY')}</Grid>
				<Grid item xs={12} md={3} className="col-expiry-date">{moment.utc(props.consent_expire_at).local().format('DD MMM YYYY')}</Grid>
			</Grid>
		</Grid>
		<Grid item xs={1} md={3} className="col-actions">
			<Link color={"secondary"} href="/#" onClick={(e : any) => {e.preventDefault(); props.downloadHistoryReceipt(props.consent_uuid, 1); }}>
				<b><Hidden smDown><u>View details</u> </Hidden><FontAwesomeIcon icon={faChevronRight} /></b>
			</Link>
		</Grid>
	</Grid>
}

export default ConsentHistoryListItem;
