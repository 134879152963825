import React from "react";
import { Link } from "react-router-dom";

import { AccountBalance, Lock, ManageAccounts, PeopleAlt, Receipt, Report, Summarize, Settings, HelpCenter, Security } from "@mui/icons-material";
import { Box } from "@mui/material";

interface AdminHomeProps {
    usersListPath: string;
    accountsListPath: string;
    consentsListPath: string;
    invoicingListPath: string;
    errorLogsListPath: string;
    cdrToDdcMappingsPath: string;
    systemSettingsPath: string;
    documentationPath: string;
    brokersListPath: string;
    mtlsCertificatesListPath: string;
    cdrReportsPath: string;
    cdrClientsCertificatesListPath: string;
}

const AdminHome = (props: AdminHomeProps) => {

    return <div className="admin-home">
        <h2><strong>Admin Portal Home</strong></h2>
        <Box mt={4}>
            <ul className="admin-links">
                <li><Link color={"secondary"} to={props.usersListPath}><PeopleAlt/> Manage Users</Link></li>
                <li><Link color={"secondary"} to={props.accountsListPath}><ManageAccounts /> Manage Accounts</Link></li>
                <li><Link color={"secondary"} to={props.consentsListPath}><Lock/> Manage Consents</Link></li>
                <li><Link color={"secondary"} to={props.invoicingListPath}><Receipt/> Invoicing Management</Link></li>
                <li><Link color={"secondary"} to={props.errorLogsListPath}><Report/> Errors Reporting</Link></li>
                <li><Link color={"secondary"} to={props.cdrReportsPath}><Summarize/> CDR Consumer Reporting</Link></li>
                <li><Link color={"secondary"} to={props.cdrToDdcMappingsPath}><AccountBalance/> Manage Bank Mappings</Link></li>
                <li><Link color={"secondary"} to={props.systemSettingsPath}><Settings/> System Settings</Link></li>
                <li><a href={props.documentationPath} target="_blank" rel="noopener noreferrer"><HelpCenter/> Documentation</a></li>
                <li><Link color={"secondary"} to={props.brokersListPath}><ManageAccounts/> Manage Brokers</Link></li>
                <li><Link color={"secondary"} to={props.mtlsCertificatesListPath}><Security/> Manage MTLS Certificates</Link></li>
                <li><Link color={"secondary"} to={props.cdrClientsCertificatesListPath}><Security/> Manage CDR Client Certificates</Link></li>
            </ul>
        </Box>
    </div>
}

export default AdminHome;