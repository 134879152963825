import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import { Box, Button, TextField } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";

interface SendResetPasswordEmailProps {
    adminObApi: OpenBankingPlatformAPIInterface;
    basePath: string;
}

const SendResetPasswordEmail = (props: SendResetPasswordEmailProps) => {
    const [state, setState] = useState<any>({
        email: '',
        sendResetPasswordEmailErrorMsg: '',
        sendResetPasswordEmailMessage: '',
        isLoading: false
    });


    //eslint-disable-next-line
    const getCsrfToken = useCallback(props.adminObApi.getCsrfToken, []);
    // eslint-disable-next-line
    const doAdminForgotPassword = useCallback(props.adminObApi.doAdminForgotPassword, []);
    const handleSendResetPasswordEmail = () => {
        (async() => {
            try {
                setState({
                    ...state,
                    isLoading: true,
                    sendResetPasswordEmailErrorMsg:''
                });
                await getCsrfToken();
                const forgotPasswordResponse = await doAdminForgotPassword(state.email);
                if(forgotPasswordResponse.status === 'success'){
                    setState({
                        ...state,
                        isLoading: false,
                        sendResetPasswordEmailErrorMsg:'',
                        sendResetPasswordEmailMessage: 'A reset password email has been sent to the email address.'
                    });
                }/*else{
                    setState({
                        ...state,
                        isLoading: false,
                        sendResetPasswordEmailError: forgotPasswordResponse
                    });
                }*/
            } catch (error) {
                setState({
                    ...state,
                    isLoading: false,
                    sendResetPasswordEmailMessage:'',
                    sendResetPasswordEmailErrorMsg: (error as any).response.data.data.error
                })
            }
        })();
    }

    const allMandatoryFieldsArePopulated = state.email !== '';

    return (
        <div className="admin-login">
            <h2><strong>Forgot Your Password?</strong></h2>

            {state.isLoading ? <LoadingSpinner loadingText={""} /> :
                <>
                {!state.sendResetPasswordEmailErrorMsg && <span>{state.sendResetPasswordEmailMessage}</span>}
                {state.sendResetPasswordEmailErrorMsg && <div className={"error-text"}>{state.sendResetPasswordEmailErrorMsg}</div>}
                    
                    <Box
                        mt={4}
                        component="form"
                    >
                        <TextField
                            fullWidth
                            required
                            variant="outlined"
                            name="username-input"
                            id="username-input"
                            placeholder="Your Email Address"
                            value={state.email}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    email: event.target.value
                                })
                            }}
                        />

                        <Button variant={"contained"} color={"secondary"} onClick={handleSendResetPasswordEmail} disabled={!allMandatoryFieldsArePopulated}>Reset</Button>

                        <div style={{ paddingTop: '20px' }}><Link to={props.basePath + "/login"}>Back to login</Link></div>
                    </Box>
                </>
            }
        </div>);
}

export default SendResetPasswordEmail;