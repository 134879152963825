import { convertFromRaw, EditorState, RichUtils } from "draft-js";
import React, { useState } from "react";

import Editor from "@draft-js-plugins/editor";
import { FormatBold, FormatItalic, FormatListBulleted, FormatListNumbered, FormatUnderlined, Link } from "@mui/icons-material";

import addLinkPlugin from "../plugins/addLinkPlugin";

interface EditableRichTextProps {
	editMode?: boolean;
	className?: string;
	value?: string;
	onChange?: (state: EditorState) => void;
}

const EditableRichText = ({
	className = "",
	value = "",
	editMode = false,
	onChange = undefined
}: EditableRichTextProps) => {
	const [editorState, setEditorState] = useState(
		value ? EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
		: EditorState.createEmpty()
	);
	
	const updateEditorState = (editorState: EditorState) => {
		if (onChange !== undefined) {
			onChange(editorState);
		}
		setEditorState(editorState);
	};
	
	const onAddLink = () => {
		const selection = editorState.getSelection();
		const url = window.prompt("Please enter a URL:", "http");
		if (!url) {
		  updateEditorState(RichUtils.toggleLink(editorState, selection, null));
		  return;
		}
		// eslint-disable-next-line
		const pattern = new RegExp('^(https?:\\/\\/)?'+'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+'((\\d{1,3}\\.){3}\\d{1,3}))'+'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+'(\\?[;&a-z\\d%_.~+=-]*)?'+'(\\#[-a-z\\d_]*)?$','i');
		if (!!pattern.test(url)) {
		  const contentWithEntity = editorState.getCurrentContent().createEntity("LINK", "MUTABLE", { url });
		  const entityKey = contentWithEntity.getLastCreatedEntityKey();
		  updateEditorState(RichUtils.toggleLink(editorState, selection, entityKey));
		}
	}

	return (editMode
		? <div className={`wysiwyg-editor ${className}`}>
			<div className="wysiwyg-toolbar">
			  <button onClick={(e) =>  { e.preventDefault(); updateEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD")); }}>
				<FormatBold/>
			  </button>
			  <button onClick={(e) => { e.preventDefault(); updateEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC")); }}>
				<FormatItalic/>
			  </button>
			  <button onClick={(e) => { e.preventDefault(); updateEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE")); }}>
				<FormatUnderlined/>
			  </button>
			  <button onClick={(e) => { e.preventDefault(); updateEditorState(RichUtils.toggleBlockType(editorState, "ordered-list-item")); }}>
				<FormatListNumbered/>
			  </button>
			  <button onClick={(e) => { e.preventDefault(); updateEditorState(RichUtils.toggleBlockType(editorState, "unordered-list-item")); }}>
				<FormatListBulleted/>
			  </button>
			  <button onClick={(e) => { e.preventDefault(); onAddLink(); }}>
				<Link/>
			  </button>
			</div>
			<Editor editorState={editorState} onChange={updateEditorState} plugins={[addLinkPlugin]}/>
		  </div>
		: <Editor readOnly editorState={editorState} onChange={updateEditorState} plugins={[addLinkPlugin]}/>
	);
}

export default EditableRichText;
