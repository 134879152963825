import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Box, Button, TextField } from "@mui/material";

import UserLogin from "../models/UserLogin";
import {Buffer} from "buffer";

interface AdminLoginProps {
    onLogin: ((newCredentials: UserLogin) => void);
    onMfaSubmit: ((mfaCode: string) => void);
    basePath: string;
    loginStatusMessage: string;
    loginError: boolean;
    loginErrorMessage: string;
    loginResponse?: {
        status?: string;
        qrCode?: string;
        secret?: string;
    }
    mfaResponse?: {
        status?: string;
        error?: string;
    }
}

const AdminLogin = (props: AdminLoginProps) => {
    const [state, setState] = useState<UserLogin>({
        email: '',
        password: '',
    });

    const [mfaCode, setMfaCode] = useState<string>('');

    const handleOnLogin = () => {
        const newCredentials : UserLogin = {
            email: state.email,
            password: state.password,
        }
        props.onLogin(newCredentials);
    }

    const handleOnMfaSubmit = () => {
        props.onMfaSubmit(mfaCode);
    }

    const loginStatusMsg = props.loginStatusMessage;

    //Set the login error if log in failed
    const loginErrorMsg = props.loginError ? props.loginErrorMessage : '';

    const mfaErrorMsg = props.mfaResponse?.status === 'error' ? props.mfaResponse?.error || '' : '';

    const allMandatoryFieldsArePopulated = state.email !== '' && state.password !== '';

    let qrCode = props.loginResponse?.qrCode;
    let qrCodeString = ''
    if (!qrCode?.startsWith('data:image/png;base64')) {
        // If QR code is being returned as an svg element (instead of base64 encoded string) then lets convert it
        if (qrCode?.includes('<svg')) {
            qrCode?.replace(/<\?xml.+\?>/g, '');
            qrCode?.replace(/\n/g, '');

            const buff = new Buffer(qrCode);
            const base64data = buff.toString('base64');
            qrCodeString = 'data:image/svg+xml;base64,' + base64data;
        }
    } else {
        qrCodeString = qrCode;
    }

    const mfaForm = (setup: boolean = false) => {
        return (
            <Box
                mt={4}
                component="form"
                onSubmit={handleOnMfaSubmit}
            >
                <div><strong>MFA Required</strong></div>
                <p>MFA is required to keep your account secure. {setup && <span>Please scan the following QR code to setup MFA.</span>}</p>

                <span className={"error-text"}>{mfaErrorMsg}</span>

                {setup && (
                    <div>
                        <div>
                            <img src={qrCodeString} alt ="" />
                        </div>

                        <div>
                        If your 2FA mobile app does not support QR barcodes, enter in the following key:
                        <br />
                        <p><code>{props.loginResponse?.secret}</code></p>
                        <p>Once setup, please enter your verification code to confirm.</p>
                        </div>
                    </div>
                )}


                <TextField
                    required
                    variant="outlined"
                    name="mfa-input"
                    id="mfa-input"
                    className="w-100"
                    placeholder="MFA Code"
                    value={mfaCode}
                    onChange={event => setMfaCode(event.target.value)}
                />

                <Button variant={"contained"} color={"secondary"} onClick={handleOnMfaSubmit} disabled={mfaCode === ''} type="submit">Process MFA</Button>
            </Box>
        );
    }

    const loginPageContents = (status: string) => {
        if(status === 'mfa_setup'){
            return mfaForm(true);
        }else if(status === 'mfa'){
            return mfaForm(false);
        }

        return (<>
                <span>{loginStatusMsg}</span>
                <span className={"error-text"}>{loginErrorMsg}</span>
                <Box
                    mt={4}
                    component="form"
                    onSubmit={handleOnLogin}
                >
                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        name="username-input"
                        id="username-input"
                        placeholder="Username"
                        value={state.email}
                        onChange={(event) => {
                            setState({
                                ...state,
                                email: event.target.value
                            })
                        }}
                    />

                    <TextField
                        required
                        fullWidth
                        variant="outlined"
                        name="password-input"
                        id="password-input"
                        type="password"
                        placeholder="Password"
                        value={state.password}
                        onChange={(event) => {
                            setState({
                                ...state,
                                password: event.target.value
                            })
                        }}
                    />

                    <div style={{ paddingBottom: '20px' }}><Link to={props.basePath + "/forgot-password"}>Forgot your password?</Link></div>

                    <Button variant={"contained"} color={"secondary"} onClick={handleOnLogin} disabled={!allMandatoryFieldsArePopulated} type="submit">Login</Button>
                </Box>
                </>
        );
    }

    return (
        <div className="admin-login">
        <h2><strong>Admin Portal Login</strong></h2>
        
        {loginPageContents(props.loginResponse?.status || '')}
    </div>);
}

export default AdminLogin;