export function adminRedirectByErrorResponseStatus(navigate:any, response:any, basePath:string) {
    console.log('Admin error redirect handler', response);
    if (response && 'status' in response) {
        switch (response.status) {
            case 401: // Not logged in
            case 419: // Session expired
                navigate(basePath + '/login', {
                    state: {
                        errorMessage: 'Your current session has expired - please login again.'
                    }
                });
                sessionStorage.removeItem('sessionState');
                break;
            case 503: // Down for maintenance
                navigate(basePath + '/login', {
                    state: {
                        errorMessage: 'The service is currently unavailable. Please try again later.'
                    }
                });
                break;
            case 404: // Page not found
                navigate(basePath + "/notfound", {
                    state: {
                        errorCode: 20029,
                        errorText: 'Page Not Found',
                    }
                });
                break;
            default:
                navigate(basePath + "/error");
        }
    }
}

export function consentRedirectByErrorResponseStatus(navigate:any, response:any, baseUrl:string = '') {
    if (response && 'status' in response) {
        switch (response.status) {
            case 419: // Session expired
                navigate(`${baseUrl}/session-expired`, {
                    state: {
                        errorCode: 10008,
                        errorText: 'Session expired',
                    }
                });
                break;
            case 401: // Unauthorised access
                navigate(`${baseUrl}/unauthorised`, {
                    state: {
                        errorCode: 10011,
                        errorText: 'Unauthorised access',
                    }
                });
                break;
            case 404: // Page not found
                navigate(`${baseUrl}/notfound`, {
                    state: {
                        errorCode: 20029,
                        errorText: 'Page Not Found',
                    }
                });
                break;
            default:
                navigate(`${baseUrl}/error`, {
                    state: {
                        errorCode: response.data.error_code,
                        errorText: response.data.error_message,
                        uuid: response.data.uuid,
                        type: response.data.type,
                    }
                });
        }
    }
}