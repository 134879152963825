import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { convertFromRaw, EditorState } from "draft-js";
import Editor from "@draft-js-plugins/editor";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Container, FormControl, Grid, Link, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import AccreditationBadge from "../components/AccreditationBadge";
import DataClusterCard from "../components/DataClusterCard";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HighlightBlock from "../components/HighlightBlock";
import LoadingSpinner from "../components/LoadingSpinner";
import AdditionalUseChoices, { AdditionalUseChoiceModel } from "../components/MultipleChoicedialog";
import PrimaryBlock from "../components/PrimaryBlock";
import SecondaryBlock from "../components/SecondaryBlock";
import SwitchWithLabel from "../components/SwitchWithLabel";
import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import Consent from "../models/Consent";
import DataClusterOptionSet from "../models/DataClusterOptionSet";
import GeneralSettings from "../models/GeneralSettings";
import ModalPopUpData from "../models/ModalPopUpData";
import PreConsent from "../models/PreConsent";
import PreConsentData, { DataClustersPurposeInformation } from "../models/PreConsentData";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import modalPopUpContents from "../popups/ModalPopUpDataHelper";
import Popup from "../popups/Popup";
import { sanitize } from "../helpers/HtmlSanitizerHelper";
import Insight from "../models/Insight";
import DataInsightCard from "../components/DataInsightCard";
import DataHolder from "../models/DataHolder";
import {
    MACRO_NON_ACCREDIRED_PERSON,
    MACRO_NON_ACCREDITED_3RD_PARTY_NAME,
    MACRO_PURPOSE,
    MACRO_SELECTED_TIME_PERIOD,
    VERIFY_ACCOUNT_BALANCE, VERIFY_ACCOUNT_BALANCE_HOW_WAS_GENERATED,
    VERIFY_ACCOUNT_BALANCE_NOT_VALID_ERROR_MSG,
    VERIFY_BANK_ACCOUNT, VERIFY_BANK_ACCOUNT_HOW_WAS_GENERATED,
    VERIFY_BANK_ACCOUNT_NOT_VALID_ERROR_MSG,
    VERIFY_IDENTITY,
    VERIFY_IDENTITY_HOW_WAS_GENERATED,
    VERIFY_IDENTITY_NOT_VALID_ERROR_MSG,
    VERIFY_INCOME, VERIFY_INCOME_HOW_WAS_GENERATED, VERIFY_INCOME_NOT_VALID_ERROR_MSG
} from "../helpers/PreconsentInsightHelper";
import EditableRichText from "../admin/components/EditableRichText";
import {hasRichTextSpecifiedForField} from "../helpers/InputFieldValidationHelper";
import Broker from "../admin/models/Broker";
import {
    getTrustedAdviserBrokerName,
    getTrustedAdviserBrokerNumber, getTrustedAdviserBrokerSubType
} from "../helpers/TrustedAdvisersBrokerHelper";

interface DataRequestProps {
    api: OpenBankingPlatformAPI;
    prev: string;
    next: string;
    basePath: string;
    sharingPeriodIsMandatory: boolean;
    onDeleteOptionChange: (newOption: boolean) => void;
    currentDeleteOption: boolean | undefined;
    consentConfig?: Consent;
    loadedPreConsentData?: PreConsentData;
    onConsentButtonClick: (newPreConsentData: PreConsentData) => void;
    generalSettingsConfig?: GeneralSettings;
    dashboardPath: string;
    isMultiBank?: boolean;
    consentExists?: boolean;
    preConsentConfig?: PreConsent;
    principalLogoUrl?: string;
    accreditationIconUrl?: string;
    headerBgImageUrl?: string;
    insightConfig?: Insight;
    currentDataHolderSelection?: DataHolder;
    footerText?: string;
    isTrustedAdviser?: boolean;
    broker?: Broker;
}

interface DataRequestState {
    dataClustersWithOptions?: Array<DataClusterOptionSet>;
    additionalUseChoices: Array<AdditionalUseChoiceModel>;
    sharingPeriod?: string;
    deletionDate?: string;
    apiHasReturnedAnError: boolean;
    modalPopUpData: Array<ModalPopUpData>;
    returnToDashboard: boolean;

    incVerifyIdentity?: boolean;
    verifyIdentityIsSelected: boolean;
    verifyIdentityIsNotValid: boolean;
    incVerifyBalance?: boolean;
    verifyBalanceIsSelected: boolean;
    verifyBalanceIsNotValid: boolean;
    incVerifyIncome?: boolean;
    verifyIncomeIsSelected: boolean;
    verifyIncomeIsNotValid: boolean;
    incVerifyBankAccount?: boolean;
    verifyBankAccountIsSelected: boolean;
    verifyBankAccountIsNotValid: boolean;
    is_insight: boolean;
    ifInsightAllRequiredFieldsAreSet: boolean;
}

const DataRequest = (props: DataRequestProps) => {

    const incInsightStage = props.insightConfig && props.generalSettingsConfig?.incInsightStage;
    const sharingPeriodType = incInsightStage ? props.insightConfig?.sharingPeriodType : props.consentConfig?.sharingPeriodType;
    const fixedSharingPeriod = incInsightStage ? props.insightConfig?.fixedSharingPeriod : props.consentConfig?.fixedSharingPeriod;

    const loadedPreConsentData: any = {
        ...props.loadedPreConsentData,
        sharing_period: (sharingPeriodType === 'fixed'
            ? fixedSharingPeriod
            : props.loadedPreConsentData?.sharing_period
        )
    };
    const [preConsentDataOptions, setPreConsentDataOptions] = useState<PreConsentData>(loadedPreConsentData);

    var newDeleteOption = preConsentDataOptions?.delete_my_data_after_used === 1 ? true : false;
    if (props.currentDeleteOption) {
        newDeleteOption = props.currentDeleteOption;
    }
    var sharingPeriod = props.loadedPreConsentData?.sharing_period;
    var deletionDate = sharingPeriod && sharingPeriod !== '0' ? moment().add(sharingPeriod, 'month').format('DD MMM YYYY') : undefined;

    const [deleteDataOption, setDeleteDataOption] = useState(newDeleteOption);

    const additionalUsesOfData = incInsightStage ? props.insightConfig?.additionalUsesOfData : props.consentConfig?.additionalUsesOfData;
    const additionalUsesOfDataMainHeading = additionalUsesOfData?.mainHeading;
    const additionalUsesOfDataSubheading = additionalUsesOfData?.subheading;

    const showAdditionalUsesOfData =
        additionalUsesOfData?.categorisation?.include ||
        additionalUsesOfData?.spendAnalytics?.include ||
        additionalUsesOfData?.marketing?.include ||
        (additionalUsesOfData?.custom || []).length;

    const additionalUseChoices = [];
    if (additionalUsesOfData?.categorisation?.include) {
        const loadedUse = props.loadedPreConsentData?.additional_uses_of_data?.find(use => use.key === 'categorisation');
        additionalUseChoices.push({
            key: 'categorisation',
            label: additionalUsesOfData?.categorisation?.label || '',
            isSelected: loadedUse ? loadedUse.isSelected : false,
        });
    }
    if (additionalUsesOfData?.spendAnalytics?.include) {
        const loadedUse = props.loadedPreConsentData?.additional_uses_of_data?.find(use => use.key === 'spendAnalytics');
        additionalUseChoices.push({
            key: 'spendAnalytics',
            label: additionalUsesOfData?.spendAnalytics?.label || '',
            isSelected: loadedUse ? loadedUse.isSelected : false,
        });
    }
    if (additionalUsesOfData?.marketing?.include) {
        const loadedUse = props.loadedPreConsentData?.additional_uses_of_data?.find(use => use.key === 'marketing');
        additionalUseChoices.push({
            key: 'marketing',
            label: additionalUsesOfData?.marketing?.label || '',
            isSelected: loadedUse ? loadedUse.isSelected : false,
        });
    }
    (additionalUsesOfData?.custom || []).forEach(customItem => {
        const loadedUse = props.loadedPreConsentData?.additional_uses_of_data?.find(use => use.key === `custom_${customItem.key}`);
        additionalUseChoices.push({
            key: `custom_${customItem.key}`,
            label: customItem.label || '',
            isSelected: loadedUse ? loadedUse.isSelected : false,
        })
    });

    const thirdParties = props.consentConfig?.thirdParties?.map((el) => {
        return { heading: el.key || '', content: el.label || '' };
    });

    const [state, setState] = useState<DataRequestState>({
        sharingPeriod: sharingPeriod,
        deletionDate: deletionDate,
        additionalUseChoices: additionalUseChoices,
        modalPopUpData: modalPopUpContents(
            props.generalSettingsConfig,
            props.preConsentConfig,
            props.consentConfig,
            props.generalSettingsConfig?.principalName,
            props.loadedPreConsentData?.data_holder_brand_name,
            thirdParties,
            props.consentConfig?.thirdPartiesEmail),
        apiHasReturnedAnError: false,
        returnToDashboard: false,
        verifyIdentityIsSelected: false,
        verifyIdentityIsNotValid: false,
        verifyBalanceIsSelected: false,
        verifyBalanceIsNotValid: false,
        verifyIncomeIsSelected: false,
        verifyIncomeIsNotValid: false,
        verifyBankAccountIsSelected: false,
        verifyBankAccountIsNotValid: false,
        is_insight: false,
        ifInsightAllRequiredFieldsAreSet: true,
    });

    const [skipDataDeletion, setSkipDataDeletion] = useState(false);

    const [insights, setInsights] = useState([""]);

    const isExistingPreConsent = preConsentDataOptions.consent_exists;

    const location = useLocation();
    useEffect(() => {
        // @ts-ignore
        if (location.state) {
            // @ts-ignore
            if (location.state.returnToDashboard) {
                // @ts-ignore
                const returnToDashboard: boolean = location.state.returnToDashboard;
                setState((state) => ({
                    ...state,
                    returnToDashboard: returnToDashboard,
                }));
            }
            // @ts-ignore
            if (location.state.skipDataDeletionStep) {
                // @ts-ignore
                const skipDataDeletionStep: boolean = location.state.skipDataDeletionStep;
                setSkipDataDeletion(skipDataDeletionStep);
            }

            if (!props.consentExists) {
                setSkipDataDeletion(true);
            } else {
                /*
                setPreConsentDataOptions({
                    ...preConsentDataOptions,
                    sharing_period: 'no_change',
                })*/
                setSharingPeriod('no_change');
            }

        }
    }, // eslint-disable-next-line
        [location, props.consentExists]);

    const navigate = useNavigate();

    // Making sure getDataClusters function does not change (causing following useEffect to run again)
    //eslint-disable-next-line 
    const getDataClusters = useCallback(props.api.getDataClusters, []);

    useEffect(() => {
        (async () => {
            try {
                const loadedDataClusters = await getDataClusters();
                const newDataClustersWithOptions = loadedDataClusters.map((loadedDataCluster) => {
                    const principalConfiguredDataCluster: any = props.consentConfig?.dataClustersWithOptions.filter(item => {
                        return item.identifier.trim() === loadedDataCluster.identifier.trim();
                    })[0];
                    const isSelected = Array.isArray(preConsentDataOptions?.selected_data_clusters) ? preConsentDataOptions?.selected_data_clusters.indexOf(loadedDataCluster.identifier) !== -1 : false;
                    const authorisationScopeId = Array.isArray(loadedDataCluster.scopes) ? loadedDataCluster.scopes.join(",") : loadedDataCluster.scopes;
                    const newDataClusterOptionSet: DataClusterOptionSet = {
                        clusterId: loadedDataCluster.id,
                        clusterName: loadedDataCluster.title,
                        authorisationScopeId: authorisationScopeId,
                        clusterPermissionNames: loadedDataCluster.data_list,
                        purposeInformation: principalConfiguredDataCluster?.purposeInformation ?? loadedDataCluster.description,
                        isEnabled: principalConfiguredDataCluster?.isEnabled,
                        isMandatory: principalConfiguredDataCluster?.isMandatory,
                        isSelected: isSelected,
                        uuId: loadedDataCluster.uuid,
                        identifier: loadedDataCluster.identifier,
                        isInsightEnabled: principalConfiguredDataCluster?.isInsightEnabled,
                        purposeInformationInsight: principalConfiguredDataCluster?.purposeInformationInsight,
                    }

                    return newDataClusterOptionSet;
                }
                );

                setState((state) => ({
                    ...state,
                    dataClustersWithOptions: newDataClustersWithOptions,
                    is_insight: props.generalSettingsConfig?.incInsightStage || false,
                }));

                if (props.generalSettingsConfig?.incInsightStage) {
                    setState((state) => ({
                        ...state,
                        // verify insights
                        incVerifyIdentity: props.insightConfig?.incVerifyIdentity,
                        incVerifyBalance: props.insightConfig?.incVerifyBalance,
                        incVerifyIncome: props.insightConfig?.incVerifyIncome,
                        incVerifyBankAccount: props.insightConfig?.incVerifyBankAccount,
                    }));

                }

            } catch (error) {
                setState((state) => ({
                    ...state,
                    apiHasReturnedAnError: true,
                }))
                consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    },
        //eslint-disable-next-line 
        [getDataClusters, props.consentConfig?.dataClustersWithOptions, history]
    )


    const [open3, setOpen3] = React.useState(false);
    const [popUpModalDatas, setPopUpModalDatas] = React.useState<ModalPopUpData>();

    const openPopup3 = (currentPopUpData: ModalPopUpData) => {
        setOpen3(true);
        setPopUpModalDatas(currentPopUpData);
    };

    const closePopup3 = () => {
        setOpen3(false);
    };

    // eslint-disable-next-line
    const savePreConsentData = useCallback(props.api.savePreConsentData, []);
    const handleConsentButtonClick = () => {
        if (!state.dataClustersWithOptions) {
            return;
        }
        props.onConsentButtonClick(preConsentDataOptions);
        //Save and send to server
        (async () => {
            try {
                await savePreConsentData(preConsentDataOptions);
            } catch (error) {
                consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();

        // If the current session is for multibank then store the data types in session storage
        // so that any subsequent selected banks will default to the same data types without
        // having to direct the consumer to this page again
        if (props.isMultiBank) {
            //preConsentDataOptions.data_holder_brand_id = 0;
            //preConsentDataOptions.data_holder_brand_identifier = '';
            sessionStorage.setItem('preConsentDataOptions', JSON.stringify(preConsentDataOptions));
        }

        if (props.loadedPreConsentData) {
            if (skipDataDeletion) {
                //navigate(props.basePath + '/data-holder-connect-confirmation');
                navigate(props.basePath + '/data-holder-connect-confirmation', {
                    state: {
                        hideChangeDataRequestLink: true
                    }
                });
            } else {
                navigate(props.next);
            }
        } else {
            navigate(props.basePath + '/data-holder-connect-confirmation');
        }
    };

    const setSharingPeriod = (newSharingPeriod: string) => {
        if (newSharingPeriod === '') {
            setState({ ...state, sharingPeriod: undefined, deletionDate: undefined });
        } else {
            setState({
                ...state,
                sharingPeriod: newSharingPeriod,
                deletionDate: newSharingPeriod !== '0' ? moment().add(newSharingPeriod, 'month').format('DD MMM YYYY') : undefined
            });
        }
        setPreConsentDataOptions({
            ...preConsentDataOptions,
            sharing_period: newSharingPeriod,
            consent_exists: props.consentExists,
            is_insight: props.generalSettingsConfig?.incInsightStage,
        })
    }

    const handleDataClusterCheckboxChange = (newSelection: boolean, newSelectionClusterId: number) => {
        if (!state.dataClustersWithOptions) {
            return;
        }

        const newDataClusters = state.dataClustersWithOptions.map((dataCluster) => {
            if (dataCluster.clusterId === newSelectionClusterId) {
                const newDataClusterWithUpdatedSelection: DataClusterOptionSet = {
                    ...dataCluster,
                    isSelected: newSelection,
                }
                return newDataClusterWithUpdatedSelection;
            } else {
                return dataCluster;
            }
        })

        setState({
            ...state,
            dataClustersWithOptions: newDataClusters,
            verifyIdentityIsSelected: false,
            verifyIdentityIsNotValid: false,
            verifyBalanceIsSelected: false,
            verifyBalanceIsNotValid: false,
            verifyIncomeIsSelected: false,
            verifyIncomeIsNotValid: false,
            verifyBankAccountIsSelected: false,
            verifyBankAccountIsNotValid: false,
            ifInsightAllRequiredFieldsAreSet: true,
        })
        var selectedDataClusters: any = [];
        newDataClusters.forEach(function (cluster) {
            if (cluster.isSelected) {
                selectedDataClusters.push(cluster.identifier);
            }
        });

        var selectedDataClustersPurposeInformation: Array<DataClustersPurposeInformation> = [];
        newDataClusters.forEach(function (cluster) {
            if (cluster.isSelected) {
                selectedDataClustersPurposeInformation.push({ 'id': cluster.identifier, 'purpose': cluster.purposeInformation });
            }
        });

        setPreConsentDataOptions({
            ...preConsentDataOptions,
            selected_data_clusters: selectedDataClusters,
            selected_data_clusters_purpose_information: selectedDataClustersPurposeInformation,
            consent_exists: props.consentExists
        })
    }

    const handleAdditionalUseChange = (newSelection: boolean, newSelectionIndex: number) => {
        const newAdditionalUseChoices = state.additionalUseChoices.map((additionalUseChoice, index) => {
            if (index === newSelectionIndex) {
                const newAdditionalUseChoice: AdditionalUseChoiceModel = {
                    ...additionalUseChoice,
                    isSelected: newSelection,
                }

                return newAdditionalUseChoice;
            } else {
                return additionalUseChoice;
            }
        })

        setState({
            ...state,
            additionalUseChoices: newAdditionalUseChoices,
        })

        setPreConsentDataOptions({
            ...preConsentDataOptions,
            consent_exists: props.consentExists,
            additional_uses_of_data: newAdditionalUseChoices.map(choice => {
                return {
                    key: choice.key,
                    isSelected: choice.isSelected,
                }
            })
        })
    }

    const providerPopup = () => {
        const providerpopupopupdatas = state.modalPopUpData[0];
        openPopup3(providerpopupopupdatas);
    }

    const identifyDataPopup = () => {
        const identifypopupopupdatas = state.modalPopUpData[1];
        openPopup3(identifypopupopupdatas);
    }

    const deleteDataPopup = () => {
        const deletepopupopupdatas = state.modalPopUpData[props.isTrustedAdviser ? 3 : 2];
        openPopup3(deletepopupopupdatas);
    }

    const popUpgeneralTexts = popUpModalDatas?.popUpGeneralText.map((item, index) =>
        <p key={index} dangerouslySetInnerHTML={{ __html: sanitize(item.content) }}></p>
    );

    const popUpBoxTexts = popUpModalDatas?.popupBoxContents.map((item, index) =>
        <div key={index}>
            <h6>{item.heading}</h6>
            <p>{item.content}</p>
        </div>
    );

    const popUpBlockTexts = popUpModalDatas?.popupBlockContents.map((item, index) => {
        return (
            <div key={index}>
                <h6>{item.heading}</h6>
                <br />
                {
                    item.content?.map((word, index) => {
                        return (
                            <p key={index} dangerouslySetInnerHTML={{ __html: sanitize(word.text) }}></p>
                        )
                    })
                }
                <br />
            </div>
        )
    });

    const dataClusters = (state.dataClustersWithOptions)
        ? <>
            {state.dataClustersWithOptions.map((dataCluster, index) =>
                <div key={index}>
                    {(props.generalSettingsConfig?.incInsightStage && dataCluster.isInsightEnabled && (dataCluster.clusterId === 3 || dataCluster.clusterId === 9 || dataCluster.clusterId === 10))
                        || (props.generalSettingsConfig?.incInsightStage === false && dataCluster.isEnabled)
                        ? <DataClusterCard
                            {...dataCluster}
                            key={index}
                            incInsight={incInsightStage || false}
                            onSelectionChange={(newSelection: boolean) => {
                                handleDataClusterCheckboxChange(newSelection, dataCluster.clusterId);
                            }}
                        />
                        : ""
                    }
                </div>
            )}
        </>
        : "";

    useEffect(() => {
        setInsights([
            ...(state.verifyIdentityIsSelected ? [VERIFY_IDENTITY] : []),
            ...(state.verifyBalanceIsSelected ? [VERIFY_ACCOUNT_BALANCE] : []),
            ...(state.verifyIncomeIsSelected ? [VERIFY_INCOME] : []),
            ...(state.verifyBankAccountIsSelected ? [VERIFY_BANK_ACCOUNT] : [])
        ]);
    },// eslint-disable-next-line
        [state]);

    useEffect(() => {
        setPreConsentDataOptions({
            ...preConsentDataOptions,
            selected_insights: insights
        });
    }, // eslint-disable-next-line
        [insights]);

    const [selectedDataClusterIDs, setSelectedDataClusterIDs] = useState<string[]>([]);
    useEffect(() => {
        if (state.dataClustersWithOptions) {
            setSelectedDataClusterIDs(state.dataClustersWithOptions?.filter(data => data.isSelected).map(data => data.identifier));
        }
    }, [state.dataClustersWithOptions]);

    const secondArrayIsInFirst = (arr: Array<string>, target: Array<string>) => target.every(v => arr.includes(v));
    const isValidInsight = (dependsOnDataClusterIDs: Array<string>) => {
        return secondArrayIsInFirst(selectedDataClusterIDs, dependsOnDataClusterIDs);
    }

    const dataInsights = <>
        {props.insightConfig?.incVerifyIdentity &&
            <DataInsightCard
                insightName={props.insightConfig.verifyIdentityName}
                dataHolderName={props.currentDataHolderSelection?.title}
                insightInformation={props.insightConfig.incIdentityDescription}
                isSelected={state.verifyIdentityIsSelected}
                howWasThisInsightGeneratedText={VERIFY_IDENTITY_HOW_WAS_GENERATED}
                notValidErrorMessage={VERIFY_IDENTITY_NOT_VALID_ERROR_MSG}
                isNotValid={state.verifyIdentityIsNotValid}
                onSelectionChange={(newSelection: boolean) => {
                    setState({
                        ...state,
                        verifyIdentityIsSelected: newSelection,
                        verifyIdentityIsNotValid: (newSelection === true && !isValidInsight(["data_cluster_3"]))
                    });
                }}
            />
        }
        {props.insightConfig?.incVerifyBalance &&
            <DataInsightCard
                insightName={props.insightConfig.verifyBalanceName}
                dataHolderName={props.currentDataHolderSelection?.title}
                insightInformation={props.insightConfig.incBalanceDescription}
                isSelected={state.verifyBalanceIsSelected}
                howWasThisInsightGeneratedText={VERIFY_ACCOUNT_BALANCE_HOW_WAS_GENERATED}
                notValidErrorMessage={VERIFY_ACCOUNT_BALANCE_NOT_VALID_ERROR_MSG}
                isNotValid={state.verifyBalanceIsSelected && !isValidInsight(["data_cluster_9"])}
                onSelectionChange={(newSelection: boolean) => {
                    setState({
                        ...state,
                        verifyBalanceIsSelected: newSelection,
                        verifyBalanceIsNotValid: (newSelection === true && !isValidInsight(["data_cluster_9"]))
                    });
                }}
            />
        }
        {props.insightConfig?.incVerifyIncome &&
            <DataInsightCard
                insightName={props.insightConfig.verifyIncomeName}
                dataHolderName={props.currentDataHolderSelection?.title}
                insightInformation={props.insightConfig.incIncomeDescription}
                isSelected={state.verifyIncomeIsSelected}
                howWasThisInsightGeneratedText={VERIFY_INCOME_HOW_WAS_GENERATED}
                notValidErrorMessage={VERIFY_INCOME_NOT_VALID_ERROR_MSG}
                isNotValid={state.verifyIncomeIsSelected && !isValidInsight(["data_cluster_3", "data_cluster_9", "data_cluster_10"])}
                onSelectionChange={(newSelection: boolean) => {
                    setState({
                        ...state,
                        verifyIncomeIsSelected: newSelection,
                        verifyIncomeIsNotValid: (newSelection === true && !isValidInsight(["data_cluster_3", "data_cluster_9", "data_cluster_10"]))
                    });
                }}
            />
        }
        {props.insightConfig?.incVerifyBankAccount &&
            <DataInsightCard
                insightName={props.insightConfig.verifyBankAccountName}
                dataHolderName={props.currentDataHolderSelection?.title}
                insightInformation={props.insightConfig.incBankAccountDescription}
                isSelected={state.verifyBankAccountIsSelected}
                howWasThisInsightGeneratedText={VERIFY_BANK_ACCOUNT_HOW_WAS_GENERATED}
                notValidErrorMessage={VERIFY_BANK_ACCOUNT_NOT_VALID_ERROR_MSG}
                isNotValid={state.verifyBankAccountIsNotValid}
                onSelectionChange={(newSelection: boolean) => {
                    setState({
                        ...state,
                        verifyBankAccountIsSelected: newSelection,
                        verifyBankAccountIsNotValid: (newSelection === true && !isValidInsight(["data_cluster_10"]))
                    });
                }}
            />
        }
    </>;

    const allMandatoryDataClustersAreSelected: boolean =
        (!incInsightStage && state.dataClustersWithOptions)
            ? state.dataClustersWithOptions.every(dataCluster => !(dataCluster.isEnabled && dataCluster.isMandatory) || dataCluster.isSelected)
            : true

    const sharingPeriodIsSelectedOrNotRequired = !props.sharingPeriodIsMandatory || sharingPeriodType === "fixed" || (state.sharingPeriod !== undefined && state.sharingPeriod !== '');

    useEffect(() => {
        setState({
            ...state,
            ifInsightAllRequiredFieldsAreSet: !state.verifyIdentityIsNotValid && !state.verifyBalanceIsNotValid && !state.verifyIncomeIsNotValid && !state.verifyBankAccountIsNotValid
        })
    }, // eslint-disable-next-line
        [state.verifyIdentityIsNotValid, state.verifyBalanceIsNotValid, state.verifyIncomeIsNotValid, state.verifyBankAccountIsNotValid]);

    const allMandatoryFieldsAreSelected = allMandatoryDataClustersAreSelected && sharingPeriodIsSelectedOrNotRequired && (incInsightStage ? state.ifInsightAllRequiredFieldsAreSet : true);

    const anyDataClusterSelected: boolean =
        (state.dataClustersWithOptions)
            ? state.dataClustersWithOptions.filter(dataCluster => dataCluster.isSelected).length > 0
            : false;

    const handleDataDeleteOption = (currentOption: boolean) => {
        setDeleteDataOption(currentOption);
        if (props.onDeleteOptionChange) {
            props.onDeleteOptionChange(currentOption);
        }
        setPreConsentDataOptions({
            ...preConsentDataOptions,
            delete_my_data_after_used: currentOption ? 1 : 0,
            consent_exists: props.consentExists
        })
    }

    const handleGoBack = () => {
        navigate(-1);
    };

    const getSharingPeriodSelectOptionsDefaultValue = () => {
        //value={state.sharingPeriod !== undefined ? state.sharingPeriod : (isNewPreConsent ? '' : 'none')}
        /*if (isExistingPreConsent) {
            return 'no_change';
        }*/

        if (state.sharingPeriod === undefined) {
            return '';
        }

        return state.sharingPeriod;
    }

    const dataRecipientString = props.isTrustedAdviser ? props.generalSettingsConfig?.principalName : "us";
    const doYouConsentHeader = incInsightStage
        ? "Do you consent to share this data with us and the insights to [Non-accredited person]?".replace(MACRO_NON_ACCREDIRED_PERSON, props.insightConfig?.name || "")
        : "Do you consent to share this data with " + (props.isTrustedAdviser ? getTrustedAdviserBrokerName(props.broker) : dataRecipientString) + "?";

    const getAccreditationName = () => {
        return (props.isTrustedAdviser ? getTrustedAdviserBrokerName(props.broker) : props.generalSettingsConfig?.adrName)
    }

    const getAccreditationNumber = () => {
        return (props.isTrustedAdviser ? getTrustedAdviserBrokerSubType(props.broker) + " #: " + getTrustedAdviserBrokerNumber(props.broker) : props.generalSettingsConfig?.accreditationNumber)
    }

    const displayDataRequestedExplantoryText = () => {
        if (props.isTrustedAdviser) {
            return "We will share data with " + getTrustedAdviserBrokerName(props.broker) + ".";
        } else {
            return (incInsightStage ? sanitize(props.insightConfig?.activeConsentDurationText) : sanitize(props.consentConfig?.activeConsentDurationText));
        }
    }
    
    const displaySupportingThirdParties = props.consentConfig?.supportingThirdParties
        && props.consentConfig?.thirdParties && props.consentConfig.thirdParties.length > 0;

    return <div className={"page-wrapper data-request"}>
        <div className={"page-top"}>
            <main>
                <Header
                    generalSettings={props.generalSettingsConfig}
                    principalLogoUrl={props.principalLogoUrl}
                    headerBgImageUrl={props.headerBgImageUrl}
                    isTrustedAdviser={props.isTrustedAdviser}
                />
                <PrimaryBlock>
                    <Container maxWidth="sm" className="p-0">
                        <Grid item xs={12} pb={3}>
                            <Link className={"go-back-link py-1"} href="/#" onClick={(e: any) => { e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                        </Grid>
                        <h2><strong>Data requested</strong></h2>

                        <p>{displayDataRequestedExplantoryText()}</p>

                        <AccreditationBadge
                            companyName={getAccreditationName()}
                            showIcon={props.generalSettingsConfig?.showIconInAccreditationBadge}
                            iconUrl={props.accreditationIconUrl}
                            accreditationNumber={getAccreditationNumber()}
                            displayStyle={2}
                            isTrustedAdviser={props.isTrustedAdviser}
                        />

                        {(state.dataClustersWithOptions && props.consentConfig?.inclDataClusterPermissionText)
                            ? <>
                                <h3>Data we need</h3>
                                <div>{incInsightStage ? props.insightConfig?.dataWeNeedText : props.consentConfig?.dataWeNeedText}</div>
                                <form>
                                    {dataClusters}
                                </form>
                                {showAdditionalUsesOfData ? (
                                    <div>
                                        <h3>{additionalUsesOfDataMainHeading || 'Additional uses of your data'}</h3>
                                        <AdditionalUseChoices
                                            title={additionalUsesOfDataSubheading || 'Recommended for a better user experience'}
                                            choices={state.additionalUseChoices}
                                            onChoiceChange={handleAdditionalUseChange}
                                        />
                                    </div>
                                ) : ("")}

                                {incInsightStage ?
                                    <>
                                        <h3>{props.insightConfig?.mainHeading}</h3>
                                        <p>{props.insightConfig?.subHeading
                                            .replace(MACRO_NON_ACCREDITED_3RD_PARTY_NAME, props.insightConfig?.name || '')
                                            .replace(MACRO_PURPOSE, props.insightConfig?.purposeInsightWasRequested || '')
                                        }</p>
                                        {dataInsights}
                                    </>
                                    :
                                    displaySupportingThirdParties && <>
                                        <h3>Supporting third parties</h3>
                                        <p>Supporting third parties may help provide this service. They will be bound by the same terms
                                            of this arrangement.</p>
                                        <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                            e.preventDefault();
                                            providerPopup();
                                        }}>See list of providers</a></p>
                                    </>
                                }

                                <h3>Key dates</h3>
                                <HighlightBlock className="data-request-card">
                                    <h6>Sharing period
                                        {(sharingPeriodType === "default" && props.sharingPeriodIsMandatory) &&
                                            <span className="mandatory-field-indicator">
                                                &nbsp;*
                                            </span>
                                        }
                                    </h6>
                                    {sharingPeriodType !== "fixed" && <p>Please select the period of time you would like to share your data.</p>}
                                    <Box mb={3}>
                                        <FormControl variant="outlined">
                                            {sharingPeriodType === "fixed" ?
                                                <>
                                                    <input readOnly type="text" value={
                                                        fixedSharingPeriod === "0" ? "Once-off" :
                                                            fixedSharingPeriod + (fixedSharingPeriod === "1" ? " Month" : " Months")
                                                    } />
                                                    <input type="hidden" id="sharing-period" name="sharing-period" value={fixedSharingPeriod} />
                                                </>
                                                :
                                                <Select
                                                    id="sharing-period"
                                                    name="sharing-period"
                                                    sx={{ background: "white" }}
                                                    /*value={!isNewPreConsent && state.sharingPeriod !== undefined ? state.sharingPeriod : "none"}*/
                                                    /*value={state.sharingPeriod !== undefined ? state.sharingPeriod : (!isExistingPreConsent ? '' : 'none')}*/
                                                    value={getSharingPeriodSelectOptionsDefaultValue() || "none"}
                                                    onChange={(event: SelectChangeEvent) => {
                                                        let val = event.target.value as string;
                                                        if (val === "none") val = "";
                                                        setSharingPeriod(val);
                                                    }}
                                                >
                                                    {isExistingPreConsent ?
                                                        <MenuItem value={"no_change"}>No Change</MenuItem> :
                                                        <MenuItem value={"none"} disabled>- Please select -</MenuItem>
                                                    }
                                                    <MenuItem value={"0"}>Once-off</MenuItem>
                                                    <MenuItem value={"1"}>1 Month</MenuItem>
                                                    <MenuItem value={"3"}>3 Months</MenuItem>
                                                    <MenuItem value={"6"}>6 Months</MenuItem>
                                                    <MenuItem value={"12"}>12 Months</MenuItem>
                                                </Select>
                                            }
                                        </FormControl>
                                    </Box>
                                    {(incInsightStage ? props.insightConfig?.includeFrequencyOfDataCollectionText : props.consentConfig?.includeFrequencyOfDataCollectionText)
                                        && preConsentDataOptions.sharing_period !== ""
                                        && preConsentDataOptions.sharing_period !== undefined ? (
                                        <div>
                                            <h6>{incInsightStage ? props.insightConfig?.frequencyOfDataCollectionTextMainHeading : props.consentConfig?.frequencyOfDataCollectionTextMainHeading}</h6>
                                            {state.sharingPeriod !== '0' ? (
                                                <p>{((incInsightStage ? sanitize(props.insightConfig?.frequencyOfDataCollectionTextMainText) : sanitize(props.consentConfig?.frequencyOfDataCollectionTextMainText)) || "").replace(
                                                    MACRO_SELECTED_TIME_PERIOD,
                                                    isExistingPreConsent && state.sharingPeriod === 'no_change'
                                                        ? "The sharing period of the consent will remain unchanged"
                                                        : ' ' + state.sharingPeriod + ' month' + (state.sharingPeriod === '1' ? '' : 's')
                                                )}</p>
                                            ) : (
                                                <p>{props.isTrustedAdviser
                                                    ? "We will collect your data once."
                                                    : "We will do this once."
                                                }</p>
                                            )}
                                        </div>
                                    ) : ("")}

                                </HighlightBlock>

                                {/* Data handling */}
                                {incInsightStage &&
                                    <>
                                        <h3>{props.insightConfig?.dataHandlingMainHeading}</h3>
                                        {props.insightConfig?.dataHandlingSubHeading &&
                                            <Editor editorState={EditorState.createWithContent(convertFromRaw(
                                                JSON.parse(props.insightConfig?.dataHandlingSubHeading)
                                            ))} readOnly={true} onChange={() => { }} />
                                        }
                                    </>
                                }

                                {(props.consentConfig.includeDataDeidentificationStandardText || props.consentConfig.includeDataDeletionStandardText) ?
                                    <>
                                        {(props.consentConfig.dataDeidentificationAndDeletionHeading) ?
                                            <h3>{props.consentConfig.dataDeidentificationAndDeletionHeading}</h3> :
                                            <h3>What happens to your data after we've used it</h3>
                                        }
                                    </>
                                : ""}

                                {props.isTrustedAdviser
                                    ? <>
                                        <p>Once we have provided your Trusted Adviser with the data we will immediately delete it from all our systems.</p>
                                        <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                            e.preventDefault();
                                            deleteDataPopup()
                                        }}>More Information</a></p>
                                    </>
                                    : <>
                                        {(props.consentConfig.includeDataDeidentificationStandardText) ?
                                            <>
                                                {(props.consentConfig.dataDeidentificationText) ?
                                                <p>{props.consentConfig.dataDeidentificationText}</p> :
                                                <p>We will de-identify your data when we no longer need it to help you track your spending, and
                                                    there Is no legal obligation to retain it.</p>
                                                }

                                                {(props.consentConfig.dataDeidentificationPopupLinkText && props.consentConfig.dataDeidentificationPopupLinkText !== '') ?
                                                    <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                                        e.preventDefault();
                                                        identifyDataPopup();
                                                    }}>{props.consentConfig.dataDeidentificationPopupLinkText}</a></p> :
                                                    <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                                        e.preventDefault();
                                                        identifyDataPopup();
                                                    }}>How and why we de-identify your data</a></p>
                                                }

                                                <SwitchWithLabel
                                                    checked={deleteDataOption}
                                                    onChange={value => {
                                                        handleDataDeleteOption(value);
                                                    }}
                                                >
                                                    <p><strong> Delete my data instead </strong></p>
                                                </SwitchWithLabel>

                                            </> : ""
                                        }

                                        {(incInsightStage ? props.insightConfig?.includeDataDeletionStandardText : props.consentConfig?.includeDataDeletionStandardText) ? (
                                            <div>
                                                <br />

                                                {(props.consentConfig.dataDeletionText && hasRichTextSpecifiedForField(props.consentConfig.dataDeletionText)) ?
                                                    <p>
                                                        <EditableRichText
                                                            editMode={false}
                                                            value={props.consentConfig.dataDeletionText}
                                                        />
                                                    </p> :
                                                    <>
                                                        {(state.sharingPeriod !== '0') &&
                                                        <p>You can also tell us to delete your data by going to Menu {'>'} Data Sharing or by writing to
                                                            <a href={"mailto:odssupport@illion.com.au"} className="CDR-link">odssupport@illion.com.au</a>.</p>
                                                        }
                                                        {state.deletionDate ? (
                                                            <p>If you don't do this by {state.deletionDate}, your data will be de-identified.</p>
                                                        ) : (
                                                            <p>If you don’t do this, your data will be de-identified.</p>
                                                        )}
                                                    </>
                                                }

                                                {(props.consentConfig.dataDeletionPopupLinkText && props.consentConfig.dataDeletionPopupLinkText !== '') ?

                                                    <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                                        e.preventDefault();
                                                        deleteDataPopup()
                                                    }}>{props.consentConfig.dataDeletionPopupLinkText}</a></p> :

                                                    <p><a href="/#" className={"popup-link"} onClick={(e) => {
                                                        e.preventDefault();
                                                        deleteDataPopup()
                                                    }}>See how we delete your data</a></p>
                                                }
                                            </div>
                                        ) : ("")}
                                    </>
                                }

                                {(props.consentConfig.managingYourDataHeading) ?
                                    <h3>{props.consentConfig.managingYourDataHeading}</h3> :
                                    <h3>Managing your data</h3>
                                }

                                {(props.consentConfig.managingYourDataText && hasRichTextSpecifiedForField(props.consentConfig.managingYourDataText)) ?
                                    <p>
                                        <EditableRichText
                                            editMode={false}
                                            value={props.consentConfig.managingYourDataText}
                                        />
                                    </p> :
                                    <>
                                        <h6>Where to manage this arrangement</h6>
                                        <p>{props.isTrustedAdviser
                                            ? "All this information will be made available on your Data Sharing Dashboard. We will email you a confirmation of consent that will include a link to your Data Sharing Dashboard. There you can also manage and revoke consents you have granted."
                                            : "All this information will be made available on your Data Sharing dashboard. You can access this by going to Data Sharing via the navigation menu."
                                        }</p>
                                        <h6>If you want to stop sharing this data</h6>
                                        <p>You can stop us collecting and using your data on your Data Sharing dashboard or by writing to <a href={"mailto:odssupport@illion.com.au"} className="CDR-link">odssupport@illion.com.au</a></p>
                                    </>
                                }

                                {props.isTrustedAdviser && <>
                                    <h6>Complaints</h6>
                                    <p>If you think your data has been mishandled, you can make a complaint to <a href="mailto:complaints@illion.com.au">complaints@illion.com.au</a>.</p>
                                </>}

                                {(props.isTrustedAdviser) ?
                                    <>
                                        <br /><br />
                                        <AccreditationBadge
                                            companyName={"illion Open Data Solutions Pty Ltd"}
                                            showIcon={false}
                                            iconUrl={props.accreditationIconUrl}
                                            accreditationNumber={"ADRBNK000017"}
                                            displayStyle={2}
                                        />
                                    </>
                                : ""}

                            </>
                            : (state.apiHasReturnedAnError)
                                ? <>
                                    <h3 className={"error-title"}>Error</h3>
                                    <p className={"error-text"}>We're sorry but our system has encountered an error. Please try
                                        again later or contact support.</p>
                                </>
                                : <LoadingSpinner position={"fixed"} overlay />
                        }
                    </Container>
                </PrimaryBlock>

                <SecondaryBlock
                    header={doYouConsentHeader}
                    ctaButtons={[
                        <Button onClick={handleGoBack} variant={"outlined"} color={"secondary"}>Cancel</Button>,
                        <Button onClick={handleConsentButtonClick} variant={"contained"} color={"secondary"}
                            disabled={!anyDataClusterSelected || !allMandatoryFieldsAreSelected}>I consent</Button>
                    ]}
                    textAlign={'center'}
                >
                    <Container maxWidth={"sm"} className={"p-0"}>
                        <p>Selecting ‘I consent’ won’t give us access to your data just yet. We will need to connect you to your {" "}
                            {props.isTrustedAdviser ? "bank or institution" : "bank"} to confirm this decision.</p>
                        {allMandatoryFieldsAreSelected
                            ? (anyDataClusterSelected ?
                                ""
                                : <p className="mandatory-fields-hint">Please select data to consent.</p>)
                            : <p className="mandatory-fields-hint">Please check all mandatory form fields before proceeding.</p>
                        }
                    </Container>
                </SecondaryBlock>

                {/* Information popup */}
                <Popup
                    heading={popUpModalDatas?.popUpHeading}
                    open={open3}
                    withCloseButton
                    onClose={closePopup3}
                >
                    {popUpgeneralTexts && <>{popUpgeneralTexts}</>}

                    {popUpBoxTexts && popUpBoxTexts.length > 0 &&
                        <Box my={3} p={3} className={"background-grey"}>{popUpBoxTexts}</Box>
                    }
                    {popUpBlockTexts && <>{popUpBlockTexts}</>}
                    <a href={props.generalSettingsConfig?.incInsightStage ? 'https://www.illion.com.au/consumer-data-right-policy' : props.generalSettingsConfig?.cdrPolicyURL} target="_blank" rel="noopener noreferrer" >View Consumer Data Right policy</a>
                </Popup>
            </main>
        </div>

        {!props.isTrustedAdviser && <Footer generalSettingsConfig={props.generalSettingsConfig} isTrustedAdvisor={props.isTrustedAdviser} />}

    </div>;
}

export default DataRequest;