import { Theme, Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";

const LightTooltip = withStyles((theme: Theme) => ({
	arrow: {
		color: "#F7F7F7",
	},
	tooltip: {
		maxWidth: "calc(min(90vw, 660px))",
		padding: 10,
		backgroundColor: "#F7F7F7",
		color: "#333",
		boxShadow: "0 1px 2px #00000033",
		fontSize: 12
	},
}))(Tooltip);

export default LightTooltip;
