import React from "react";
import { Grid } from "@mui/material";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface MtlsCertificatesListItemListItemProp {
    id: number;
    name: string;
    detailsPath: string;
}

const MtlsCertificatesListItem = (props: MtlsCertificatesListItemListItemProp) => {
    return <Grid container className={"consent-list-item"}>
        <Grid item xs={6} md={3}>{props.id}</Grid>
        <Grid item xs={6} md={3}>{props.name}</Grid>
        <Grid item xs={6} md={3}><Link color={"secondary"} to={props.detailsPath+ `/${props.id}`}><b><u>View details</u> <FontAwesomeIcon icon={faChevronRight} /></b></Link></Grid>
    </Grid>
}

export default MtlsCertificatesListItem;