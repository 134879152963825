import React, { useEffect, useState } from "react";

import { Button, Container, Grid } from "@mui/material";

import Insight from "../../models/Insight";
import CurrentUser from "../../models/CurrentUser";
import Configuration from "../models/Configuration";
import EditInsightSettings from "./EditInsightSettings";
import ViewInsightSettings from "./ViewInsightSettings";
import ButtonBlock from "../../components/ButtonBlock";
import Consent from "../../models/Consent";
import DataClusterOptionSet from "../../models/DataClusterOptionSet";
import AdditionalUsesOfData from "../../models/AdditionalUsesOfData";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import UploadFile from "../models/UploadFile";
import {isAdminOrClientManager} from "../../helpers/AuthUserHelper";

interface EditInsightSettingsProps {
    basePath: string,
    configuration: Configuration,
    mode: "edit" | "view",
    onConfigurationUpdate?: (newConfiguration: Configuration, mode: 'view' | 'edit', viewScrollToTop:boolean, fileUploads?: Array<UploadFile>) => void,
    onCancel?: () => void,
    currentUser?: CurrentUser,
    adminObApi: OpenBankingPlatformAPIInterface
}

interface InsightSettingsState
{
    insightCopy: Insight,
    consentCopy: Consent,
    fileUploads: Array<UploadFile>;
}

const ConfigurationInsightSettings = (props: EditInsightSettingsProps) => {
    const editMode = props.mode === "edit" && isAdminOrClientManager(props.currentUser?.role_id);
    
    const [state, setState] = useState<InsightSettingsState>({
        insightCopy: { ...props.configuration.insight},
        consentCopy: { ...props.configuration.consent},
        fileUploads: []
    });

    useEffect(() => {
        setState((state) => ({
            ...state,
            insight: { ...props.configuration.insight }
        }))
    }, [props.mode, props.configuration.insight]);

    const setConsentSettings = (newConsent: Consent) => {
        setState({
            ...state,
            consentCopy: newConsent,
        });
    }

    const handleSetDataCluster = (updatedDataClusterWithOptions: DataClusterOptionSet) => {
        const newDataClustersWithOptions = state.consentCopy.dataClustersWithOptions.map((dataClusterWithOptions) => {
            if (dataClusterWithOptions.clusterId === updatedDataClusterWithOptions.clusterId) {
                return updatedDataClusterWithOptions;
            } else {
                return dataClusterWithOptions;
            }
        })

        setConsentSettings({
            ...state.consentCopy,
            dataClustersWithOptions: newDataClustersWithOptions,
        })
    }

    const setInsightSettings = (newInsight: Insight) => {
        setState({
            ...state,
            insightCopy: newInsight,
        })
    }
    
    const handleSetAdditionalUsesOfData = (updatedAdditionalUsesOfData: AdditionalUsesOfData) => {
        setInsightSettings({
            ...state.insightCopy,
            additionalUsesOfData: updatedAdditionalUsesOfData
        })
    }

    const handleSave = () => {
        const newConfiguration: Configuration = {
            ...props.configuration,
            insight: state.insightCopy,
            consent: state.consentCopy
        }
        props.onConfigurationUpdate && props.onConfigurationUpdate(newConfiguration, 'view', true, state.fileUploads);
    };

    const handleInsightChange = (newInsight: Insight, newFileUpload?: UploadFile) => {
        var fileUploads = state.fileUploads;
        if (newFileUpload) {
            fileUploads.push(newFileUpload)
        }
        setState({
            ...state,
            insightCopy: newInsight,
            fileUploads: fileUploads,
        })
    }

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    }

    return <Container>
        <h2 className={"page-title"}>Insight Configuration</h2>
        <h5 className="text-large">{props.configuration.generalSettings.principalName}</h5>
        {editMode
            ? <>
            <EditInsightSettings 
                basePath={props.basePath}
                onInsightChange={handleInsightChange}
                onConsentClusterChange={handleSetDataCluster}
                onAdditionalUsesOfDataChange={handleSetAdditionalUsesOfData}
                insight={state.insightCopy}
                consent={state.consentCopy}
                currentUser={props.currentUser}
                configurationPrincipalId={props.configuration.principal_id}   
                adminObApi={props.adminObApi}
            />
            <Grid container className={"mt-20"}>
                <Grid item xs={12}>
                    <ButtonBlock className={"text-align-center"}>
                        <Button onClick={() => handleCancel()} variant={"outlined"} color={"secondary"}>Cancel</Button>
                        <Button onClick={() => handleSave()} variant={"contained"} color={"secondary"}>Save</Button>
                    </ButtonBlock>
                </Grid>
            </Grid>
            </>
            
            : <ViewInsightSettings 
                insight={state.insightCopy}
                consent={state.consentCopy}
                adminObApi={props.adminObApi}
                configuration={props.configuration}
            />
        }

    </Container>;
}

export default ConfigurationInsightSettings;