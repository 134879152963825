import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Button, Container, Grid } from "@mui/material";

import ButtonBlock from "../components/ButtonBlock";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PrimaryBlock from "../components/PrimaryBlock";
import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import DataHolder from "../models/DataHolder";
import GeneralSettings from "../models/GeneralSettings";
import PreConsent from "../models/PreConsent";
import PreConsentData from "../models/PreConsentData";


interface DataHolderConnectConfirmationProps {
    currentDataHolderSelection?: DataHolder,
    onAuthorisationAccept?: (newSelection: DataHolder) => void;
    next: string;
    basePath: string;
    api: any;
    consentExists?: boolean;
    isMultiBank?: boolean;
    loadedPreConsentData?: PreConsentData;
    preConsentConfig?: PreConsent;
    generalSettingsConfig?: GeneralSettings;
    principalLogoUrl?: string;
    headerBgImageUrl?: string;
    footerText?: string;
    isTrustedAdviser?: boolean;
}

interface DataHolderConnectConfirmationState {
    hasDefaultDataRequestTypes: boolean;
}

const DataHolderConnectConfirmation = (props: DataHolderConnectConfirmationProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [disableContinueButton, setDisableContinueButton] = React.useState(false);
    const [continueButtonLabel, setContinueButtonLabel] = React.useState("Continue");
    const [state, setState] = useState<DataHolderConnectConfirmationState>({
        hasDefaultDataRequestTypes: false
    });

    useEffect(() => {
        let hasDefaultDataRequestTypes = sessionStorage.getItem('hasDefaultDataRequestTypes');
        if (props.isMultiBank) {
            if (hasDefaultDataRequestTypes) {
                setState((state) => ({
                    ...state,
                    hasDefaultDataRequestTypes: true,
                }))
            }

            // @ts-ignore
            if (location.state && location.state.hideChangeDataRequestLink) {
                setState((state) => ({
                    ...state,
                    hasDefaultDataRequestTypes: false,
                }))
            }
        }
    }, [props.isMultiBank, location]);


    // eslint-disable-next-line
    const savePreConsentData = useCallback(props.api.savePreConsentData, []);
    //eslint-disable-next-line
    const getInitialisePAR = useCallback(props.api.getInitialisePAR, []);
    //eslint-disable-next-line
    const getInitialiseAuth = useCallback(props.api.getInitialiseAuth, []);
    const handleContinue = () => {
        if (props.onAuthorisationAccept && props.currentDataHolderSelection) {
            props.onAuthorisationAccept(props.currentDataHolderSelection);
            setDisableContinueButton(true);
            setContinueButtonLabel("Connecting....");
            (async () => {
                try {
                    if (props.isMultiBank) {
                        let preConsentDataOptionsString = sessionStorage.getItem('preConsentDataOptions');
                        let preConsentDataOptions = preConsentDataOptionsString ? JSON.parse(preConsentDataOptionsString) as PreConsentData : null;
                        if (preConsentDataOptions) {
                            //let preConsentDataOptions = props.loadedPreConsentData;
                            const dataHolderBrand = sessionStorage.getItem('dataHolderBrand') ?? JSON.stringify(props.currentDataHolderSelection);
                            const storedDataHolderBrand = JSON.parse(dataHolderBrand) as DataHolder;
                            preConsentDataOptions.data_holder_brand_identifier = storedDataHolderBrand.id;
                            preConsentDataOptions.data_holder_brand_id = storedDataHolderBrand.data_holder_id;

                            await savePreConsentData(preConsentDataOptions);
                        }
                    }

                    //Call api based on consentExists property
                    //const loadedParRequest = props.consentExists ? await getInitialisePAR(props.currentDataHolderSelection?.id) : await getInitialiseAuth(props.currentDataHolderSelection?.id);
                    const loadedParRequest = await getInitialisePAR(props.currentDataHolderSelection?.id);
                    const authoriseUrl = loadedParRequest.authorization_endpoint + '?' + loadedParRequest.request_str;

                    if (window.location !== window.parent.location) {
                        // The page is in an iframe
                        document.location.href = authoriseUrl;
                    } else {
                        // The page is not in an iframe
                        window.location.assign(authoriseUrl);
                    }


                } catch (error) {
                    consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                }
            })()
        }
    }

    const handleGoBack = () => {
        navigate(-1);
    };

    return <div className="page-wrapper connection-confirmation">
        <div className={"page-top"}>
            <Header
                generalSettings={props.generalSettingsConfig}
                principalLogoUrl={props.principalLogoUrl}
                headerBgImageUrl={props.headerBgImageUrl}
                isTrustedAdviser={props.isTrustedAdviser}
            />
            <main>
                <PrimaryBlock>
                    <Container maxWidth={"sm"} className={"p-0"}>
                        <h4>
                            {(state.hasDefaultDataRequestTypes) ?
                                <>The same CDR permissions will apply. To change the CDR permissions, please click <Link to={props.basePath + '/consent/data-request'}
                                    state={{
                                        skipDataDeletionStep: true
                                    }}
                                >here</Link></> :
                                <>
                                    { props.isTrustedAdviser ? "illion Open Data Solutions" : props.generalSettingsConfig?.principalName } will securely connect with your bank: <em>{props.currentDataHolderSelection?.title}</em>
                                </>
                            }
                        </h4>
                        <p>{props.isTrustedAdviser
                            ? `By selecting 'Continue' you will be securely transferred to ${props.currentDataHolderSelection?.title}.`
                            : `By selecting 'Continue' you will be securely transferred to the ${props.currentDataHolderSelection?.title}'s website.`
                        }</p>
                        <p>You may authenticate through your bank's standard process. You don’t need to provide your password to share CDR data.
                            Your institution will provide you with a one-time password, or a similar authentication method, in the next step.
                        </p>
                        <Grid container spacing={1}>
                            <Grid item xs={3} className="align-self-center">
                                { props.isTrustedAdviser ?
                                    <img src="/images/logo-illion-circle.svg" alt="" style={{ maxWidth: "200px" }} /> :
                                    props.principalLogoUrl ? <img alt="" src={props.principalLogoUrl} /> : <img src="/images/logo-illion-circle.svg" alt="" style={{ maxWidth: "200px" }} />
                                }
                            </Grid>
                            <Grid item xs={5} className="align-self-center">
                                <img alt="" src="/images/bank-connection.svg" />
                            </Grid>
                            <Grid item xs={4} className="align-self-center">
                                {props.currentDataHolderSelection &&
                                    <img src={props.currentDataHolderSelection.logo} alt={props.currentDataHolderSelection.title} />
                                }
                            </Grid>
                        </Grid>
                        <ButtonBlock className="text-align-center">
                            <Button onClick={handleGoBack} variant={"outlined"} color={"secondary"}>Cancel</Button>
                            <Button onClick={handleContinue} disabled={disableContinueButton} variant={"contained"} color={"secondary"}>{continueButtonLabel}</Button>
                        </ButtonBlock>
                    </Container>
                </PrimaryBlock>
            </main>
        </div>

        {!props.isTrustedAdviser && <Footer generalSettingsConfig={props.generalSettingsConfig} isTrustedAdvisor={props.isTrustedAdviser} />}

    </div>;
}

export default DataHolderConnectConfirmation;
