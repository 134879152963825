import React, { useState } from "react";
import { ChromePicker } from "react-color";

import { TextField } from "@mui/material";

interface CustomColorPickerProps {
	color?: string;
	onChangeComplete?: (color: any, event: any) => void;
}

const CustomColorPicker = ({
	color = "#000000",
	onChangeComplete = undefined
}: CustomColorPickerProps) => {
	const [hex, setHex] = useState(color);
	const [showPicker, setShowPicker] = useState(false);

	const handleClick = () => {
		setShowPicker(!showPicker);
	};

	const handleClose = () => {
		setShowPicker(false);
	};

	const handleChange = (color: any, event: any) => {
		setHex(color.hex);
		if (onChangeComplete !== undefined) onChangeComplete(color, event);
	};

	return <div className="custom-color-picker">
		<div id="color-field" onClick={handleClick}>
			<TextField id="color-hex" value={hex} placeholder="#000000" aria-readonly />
			<div id="color-displayer-wrap">
				<div id="color-displayer" style={{ backgroundColor: hex }} />
			</div>
		</div>
		{showPicker && <div id="picker">
			<div id="overlay" onClick={handleClose} />
			<ChromePicker color={hex} onChangeComplete={handleChange} />
		</div>}
	</div>;
}

export default CustomColorPicker;
