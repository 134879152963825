import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Container } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import {isClientManager, isAdmin} from "../../helpers/AuthUserHelper";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import Configuration from "../models/Configuration";
import UploadFile from "../models/UploadFile";
import ViewEditConfiguration from "./ViewEditConfiguration";

interface PrincipalConfigurationProps
{
    adminObApi: OpenBankingPlatformAPIInterface;
    basePath: string,
    currentUser?: CurrentUser,
}

interface PrincipalConfigurationState {
    configurations: Array<Configuration>;
    principalId?: number;
    isLoading: boolean;
}

const PrincipalConfiguration = (props: PrincipalConfigurationProps) => {
    const [state, setState] = useState<PrincipalConfigurationState>({
        configurations: [],
        isLoading: false
    });

    const navigate = useNavigate();

    let { id } = useParams<{ id: any }>();

    // If this the current user is client manager (i.e. has an associated account ID)
    // then just load the page for the account they manage but without exposing the account ID
    // on the URL path
    if (!id && props.currentUser?.account_id) {
        id = props.currentUser.account_id
    }

    const accountId = parseInt(id);

    // eslint-disable-next-line
    const getPrincipalAdminConfigurationList = useCallback(props.adminObApi.getPrincipalAdminConfigurationListByAccount, []);
    // eslint-disable-next-line
    const getPrincipalAdminConfiguration = useCallback(props.adminObApi.getConfigurationByPrincipal, []);
    useEffect(() => {
            (async () => {
                    try {
                        if (isClientManager(props.currentUser?.role_id) && props.currentUser?.principal_id) {
                            const loadedConfiguration = await getPrincipalAdminConfiguration(props.currentUser?.principal_id);
                            const newConfigSet: Configuration = loadedConfiguration.principal_admin_config_fields;
                            newConfigSet.principal_id = loadedConfiguration.principal_id;
                            const newConfigurationArray:Array<Configuration> = [];
                            newConfigurationArray.push(newConfigSet);
                            setState((state) => ({
                                ...state,
                                configurations: newConfigurationArray,
                                isLoading: false
                            }));
                        } else {
                            //if (accountId) {
                                // @ts-ignore
                                var useAccountId = isAdmin(props.currentUser?.role_id) ? accountId : undefined;
                                const loadedConfigurationList = await getPrincipalAdminConfigurationList(useAccountId);
                                const newConfigurationArray = loadedConfigurationList.map((loadedConfiguration) => {
                                    const newConfigSet: Configuration = loadedConfiguration.principal_admin_config_fields;
                                    newConfigSet.principal_id = loadedConfiguration.principal_id;
                                    return newConfigSet;
                                });
                                setState((state) => ({
                                    ...state,
                                    configurations: newConfigurationArray,
                                    isLoading: false,
                                }));
                            //}
                        }

                    } catch (error) {
                        /*
                        setState((state) => ({
                            ...state,
                            isLoading: false,
                        }));*/
                        adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                    }
            })();
        },
        //eslint-disable-next-line
        []
    )

    // eslint-disable-next-line
    const updateExistingConfiguration = useCallback(props.adminObApi.updateExistingConfiguration, []);
    const handleUpdateConfiguration = (configurationId: number, newConfiguration: Configuration, fileUploads?: Array<UploadFile>) => {
        if (!state.configurations) {
            return;
        }
        (async () => {
            const newConfigurations = state.configurations?.map((configuration) =>
                (
                    (configuration.id === configurationId)
                        ? newConfiguration
                        : configuration
                )
            );
            setState({
                ...state,
                isLoading: true,
                configurations: newConfigurations,
            })
            await updateExistingConfiguration(newConfiguration, newConfiguration.principal_id);
            setState({
                ...state,
                isLoading: false,
                configurations: newConfigurations,
            })

            if (fileUploads) {
                handleFilesUpload(fileUploads);
            }

            window.location.reload();
        })();

    };

    const handleFilesUpload = (uploadFiles: Array<UploadFile>) => {
        uploadFiles.map((uploadFile) => {
            let formData = new FormData();
            formData.append('file', uploadFile.file);
            formData.append('path', uploadFile.path);
            handleUploadFile(formData, uploadFile.name);
            return uploadFile;
        });
    }

    //eslint-disable-next-line
    const saveUploadedFile = useCallback(props.adminObApi.saveUploadFile, []);
    const handleUploadFile = (data: FormData, id: string) => {
        (async (data, id) => {
            try {
                await saveUploadedFile(data)
            } catch (error) {
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })(data, id);
    }


    return <Container>
        {(state.configurations.length > 0) ?
            <ViewEditConfiguration
                configurations={state.configurations}
                onUpdateConfiguration={handleUpdateConfiguration}
                basePath={props.basePath}
                adminObApi={props.adminObApi}
                currentUser={props.currentUser}
                accountId={accountId}
                isLoading={state.isLoading}
            />
            : <LoadingSpinner position={"fixed"} overlay />
        }

    </Container>;
}

export default PrincipalConfiguration;