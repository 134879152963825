import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Link, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import LoadingSpinner from "../../components/LoadingSpinner";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import BillingModelConfigurationCard from "../components/BillingModelConfigurationCard";
import PrincipalBillingConfiguration from "../models/PrincipalBillingConfiguration";

interface BillingModelConfigurationProps {
    api: OpenBankingPlatformAPIInterface;
    basePath: string;
    currentUser?: CurrentUser;
}

interface BillingModelConfigurationState {
    isLoading: boolean;
    billingModelCategory: string;
    billingModelSelection?: string;
    principal_name?: string;
    principal_reference_code?: string;
    config?: PrincipalBillingConfiguration;
}

const BillingModelConfiguration = (props: BillingModelConfigurationProps) => {
    const [state, setState] = useState<BillingModelConfigurationState>({
        billingModelCategory: "bank",
        billingModelSelection: "BillingModelPerBank2",
        isLoading: false
    });

    const { id } = useParams<{ id: any }>();

    const navigate = useNavigate();

    //eslint-disable-next-line
    const getBillingConfiguration = useCallback(props.api.getBillingConfiguration, []);
    useEffect(() => {
        (async () => {
            try {
                setState({
                    ...state,
                    isLoading: true
                })
                const config = await getBillingConfiguration(id);
                setState({
                    ...state,
                    config: config.billing_config_fields,
                    principal_name: config.principal_name,
                    principal_reference_code: config.principal_reference_code,
                    billingModelSelection: config.billing_config_fields?.selectedBillingModelType,
                    billingModelCategory: getBillingModelCategory(config.billing_config_fields?.selectedBillingModelType),
                    isLoading: false
                })
            } catch (error) {

            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*
    useEffect(() => {
        console.log(state.config);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.config]);
    */

    const getBillingModelCategory = (billingModelType?: string) => {
        if (billingModelType !== undefined) {
            switch (billingModelType) {
                case 'BillingModelPerBank2':
                case 'BillingModelPerBank4':
                case 'BillingModelPerBank21':
                case 'BillingModelPerBank51':
                    return 'bank';
                case 'BillingModelHybrid5':
                case 'BillingModelHybrid6':
                case 'BillingModelHybrid11':
                    return 'hybrid';
                case 'BillingModelPerCustomer3':
                    return 'customer';
                default:
                    return '';
            }
        }

        return '';
    }

    const onBillingModelUpdate = (billingConfig: PrincipalBillingConfiguration) => {
        if (state.config) {
            setState({
                ...state,
                config: billingConfig,
                billingModelSelection: billingConfig?.selectedBillingModelType
            });
        }
    }

    // eslint-disable-next-line
    const updateBillingConfiguration = useCallback(props.api.updateBillingConfiguration, []);
    const handleSave = () => {
        (async () => {
            if (state.config) {
                try {
                    setState({
                        ...state,
                        isLoading: true
                    })
                    await updateBillingConfiguration(state.config, id);
                    setState({
                        ...state,
                        isLoading: false
                    })
                } catch (error) {

                }
            }

            /*
            setState({
                ...state,
                config: newConfigurations,
            })*/
        })();
    }


    const handleGoBack = () => {
        //navigate(-1);
        navigate(`${props.basePath}/invoicing/${id}`);
    };



    return <>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
            </Grid>
        </Grid>

        <div className={"mt-50"}>
            <h2><strong>Configure Billing Model</strong></h2>

            {(state.isLoading) ?
                <LoadingSpinner loadingText={""} /> :

                <>
                    <h3>Principal: {state.principal_name} ({state.principal_reference_code})</h3>

                    <Grid container className={"form-group"} spacing={4}>
                        <Grid item xs={12} sm={1} md={1} className={"align-self-center"}>
                        <label>Category</label>
                        </Grid>
                        <Grid item xs={12} sm={9} md={6}>
                            <FormControl variant="outlined" className="w-40">
                                <Select
                                    id="billing_model_category"
                                    name="billing_model_category"
                                    value={state.billingModelCategory}
                                    onChange={(event) => {
                                    var selectedCategory = event.target.value as string;
                                    setState({
                                        ...state,
                                        billingModelCategory: selectedCategory,
                                        billingModelSelection: ""
                                    })

                                }}
                                >
                                    <MenuItem value={"bank"}>Per Bank</MenuItem>
                                    <MenuItem value={"customer"}>Per Customer</MenuItem>
                                    <MenuItem value={"hybrid"}>Hybrid</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Box mb={2}>
                    <h4>Select a Billing Model</h4>
                    </Box>

                    <Grid container className={"form-group"} spacing={4}>
                        <Grid item xs={12} sm={3} md={2}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="Billing Model"
                                name="billingmodel"
                                value={state.billingModelSelection}
                                onChange={(event) => {
                                    const newBillingModelConfiguration: PrincipalBillingConfiguration = {
                                        ...state,
                                        selectedBillingModelType: event.target.value
                                    };

                                    onBillingModelUpdate(newBillingModelConfiguration);
                                    /*
                                    setState({
                                        ...state,
                                        billingModelSelection: event.target.value
                                    });*/
                                }}
                            >
                                {(state.billingModelCategory === 'bank') ?
                                    <>
                                        <FormControlLabel value="BillingModelPerBank2" control={<Radio />} label="Model 2" />
                                        <FormControlLabel value="BillingModelPerBank4" control={<Radio />} label="Model 4" />
                                        <FormControlLabel value="BillingModelPerBank51" control={<Radio />} label="Model 5.1" />
                                        <FormControlLabel value="BillingModelPerBank21" control={<Radio />} label="Model 2.1" />
                                    </>
                                    : ""}

                                {(state.billingModelCategory === 'customer') ?
                                    <>
                                        <FormControlLabel value="BillingModelPerCustomer3" control={<Radio />} label="Model 3" />
                                    </>
                                    : ""}

                                {(state.billingModelCategory === 'hybrid') ?
                                    <>
                                        <FormControlLabel value="BillingModelHybrid5" control={<Radio />} label="Model 5" />
                                        <FormControlLabel value="BillingModelHybrid6" control={<Radio />} label="Model 6" />
                                        <FormControlLabel value="BillingModelHybrid11" control={<Radio />} label="Model 1.1" />
                                    </>
                                    : ""}
                            </RadioGroup>
                        </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={9} md={7}>
                            <BillingModelConfigurationCard
                                billingModelSelection={state.billingModelSelection}
                                modelConfiguration={state.config}
                                onBillingModelUpdate={onBillingModelUpdate}
                            />
                        </Grid>
                    </Grid>
                </>
            }
        </div>

        <Button variant={"contained"} color={"secondary"} onClick={handleSave}>Save</Button>
    </>
}

export default BillingModelConfiguration;