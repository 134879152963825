import { convertToRaw, EditorState } from "draft-js";
import moment from "moment";
import React, { ChangeEvent, MutableRefObject, useCallback, useEffect, useRef, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import {Box, Button, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography} from "@mui/material";

import ButtonBlock from "../../components/ButtonBlock";
import SwitchWithLabel from "../../components/SwitchWithLabel";
import CurrentUser from "../../models/CurrentUser";
import PreConsent from "../../models/PreConsent";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import Configuration from "../models/Configuration";
import UploadFile from "../models/UploadFile";
import EditableRichText from "./EditableRichText";
import EditableText from "./EditableText";
import FileItem from "./FileItem";
import LightTooltip from "./LightTooltip";
import {isAdminOrClientManager} from "../../helpers/AuthUserHelper";
import ModalPopUpData from "../../models/ModalPopUpData";
import modalPopUpContents from "../../popups/ModalPopUpDataHelper";
import Popup from "../../popups/Popup";
import {
    checkIfUploadedFileHasImageMimeType,
    checkIfUploadedFileSizeWithinLimit
} from "../../helpers/InputFieldValidationHelper";
import ImagePreviews from "../models/ImagePreviews";

interface EditPreConsentSettingsProps
{
    basePath: string,
    configuration: Configuration,
    mode: "edit" | "view",
    onConfigurationUpdate?: (newConfiguration: Configuration, mode: 'view' | 'edit', viewScrollToTop: boolean, fileUploads?: Array<UploadFile>, imagePreviews?: ImagePreviews) => void,
    onCancel?: () => void,
    currentUser?: CurrentUser,
    api: OpenBankingPlatformAPIInterface,
    imagePreviews?: ImagePreviews
}

interface PreConsentSettingsState
{
    preConsentCopy: PreConsent;
    pvpKeyVisualFileURL?: string;
    pvpKeyVisualPreviewURL?: string;
    pvpFeature1ImageURL?: string;
    pvpFeature1ImagePreviewURL?: string;
    pvpFeature2ImageURL?: string;
    pvpFeature2ImagePreviewURL?: string;
    pvpFeature3ImageURL?: string;
    pvpFeature3ImagePreviewURL?: string;
    cdrSupportingImageFileURL?: string;
    cdrSupportingImagePreviewURL?: string;
    cdrFeature1ImageURL?: string;
    cdrFeature1ImagePreviewURL?: string;
    cdrFeature2ImageURL?: string;
    cdrFeature2ImagePreviewURL?: string;
    cdrFeature3ImageURL?: string;
    cdrFeature3ImagePreviewURL?: string;
    fileUploads: Array<UploadFile>;
}

const ConfigurationPreConsentSettings = (props: EditPreConsentSettingsProps) => {
    const pvpFileUploadInputs = Array<MutableRefObject<HTMLInputElement>>();
    const cdrFileUploadInputs = Array<MutableRefObject<HTMLInputElement>>();
    
    pvpFileUploadInputs.push(useRef() as MutableRefObject<HTMLInputElement>);
    pvpFileUploadInputs.push(useRef() as MutableRefObject<HTMLInputElement>);
    pvpFileUploadInputs.push(useRef() as MutableRefObject<HTMLInputElement>);
    pvpFileUploadInputs.push(useRef() as MutableRefObject<HTMLInputElement>);

    cdrFileUploadInputs.push(useRef() as MutableRefObject<HTMLInputElement>);
    cdrFileUploadInputs.push(useRef() as MutableRefObject<HTMLInputElement>);
    cdrFileUploadInputs.push(useRef() as MutableRefObject<HTMLInputElement>);
    cdrFileUploadInputs.push(useRef() as MutableRefObject<HTMLInputElement>);

    const editMode = props.mode === "edit" && isAdminOrClientManager(props.currentUser?.role_id);
    const bgClass = editMode ? "background-grey" : "";
    
    const [state, setState] = useState<PreConsentSettingsState>({
        preConsentCopy: {...props.configuration.preConsent},
        pvpKeyVisualFileURL: "",
        pvpKeyVisualPreviewURL: "",
        pvpFeature1ImageURL: "",
        pvpFeature1ImagePreviewURL: "",
        pvpFeature2ImageURL: "",
        pvpFeature2ImagePreviewURL: "",
        pvpFeature3ImageURL: "",
        pvpFeature3ImagePreviewURL: "",
        cdrSupportingImageFileURL: "",
        cdrSupportingImagePreviewURL: "",
        cdrFeature1ImageURL: "",
        cdrFeature1ImagePreviewURL: "",
        cdrFeature2ImageURL: "",
        cdrFeature2ImagePreviewURL: "",
        cdrFeature3ImageURL: "",
        cdrFeature3ImagePreviewURL: "",
        fileUploads: []
    });

    useEffect(() => {
        setState((state) => ({
            ...state,
            preConsentCopy: {...props.configuration.preConsent}
        }))
    }, [props.mode, props.configuration.preConsent]);

    const [open, setOpen] = React.useState(false);

    const openPopup = (prop: ModalPopUpData) => {
        setOpen(true);
    };

    const closePopup = () => {
        setOpen(false);
    };

    const [open2, setOpen2] = React.useState(false);

    const openPopup2 = (prop: ModalPopUpData) => {
        setOpen2(true);
    };

    const closePopup2 = () => {
        setOpen2(false);
    };

    const modalPopupsData = modalPopUpContents(
        props.configuration.generalSettings,
        props.configuration.preConsent,
        props.configuration.consent,
        '',
        ''
    );

    const invalidImageUploadPopup = () => {
        const invalidimagepopupopupdatas = modalPopupsData[3];
        openPopup(invalidimagepopupopupdatas);
    }

    const maxFileUploadSizePopup = () => {
        const maxfileuploadsizepopupopupdatas = modalPopupsData[3];
        openPopup2(maxfileuploadsizepopupopupdatas);
    }

    const setPreConsentSettings = (newPreConsentSettings: PreConsent) => {
        setState({
            ...state,
            preConsentCopy: newPreConsentSettings,
        });
    }

    const getPVPFeatureImage = (i: Number) => {
      if(i === 1) return preConsent.pvpFeature2Image;
      if(i === 2) return preConsent.pvpFeature3Image;
      return preConsent.pvpFeature1Image;
    }

    const getPVPFeatureImageURL = (i: Number) => {
      if(i === 1) return state.pvpFeature2ImageURL;
      if(i === 2) return state.pvpFeature3ImageURL;
      return state.pvpFeature1ImageURL;
    }

    const getPVPFeatureImagePreviewURL = (i: Number) => {
        if (props.imagePreviews) {
            if(i === 1) return props.imagePreviews.pvpFeature2ImagePreviewURL;
            if(i === 2) return props.imagePreviews.pvpFeature3ImagePreviewURL;
            return props.imagePreviews.pvpFeature1ImagePreviewURL;
        }

      if(i === 1) return state.pvpFeature2ImagePreviewURL;
      if(i === 2) return state.pvpFeature3ImagePreviewURL;
      return state.pvpFeature1ImagePreviewURL;
    }

    const getPVPFeatureImageUploadDate = (i: Number) => {
      if(i === 1) return preConsent.pvpFeature2ImageUploadDate;
      if(i === 2) return preConsent.pvpFeature3ImageUploadDate;
      return preConsent.pvpFeature1ImageUploadDate;
    }

    const getPVPFeatureText = (i: Number) => {
      if(i === 1) return preConsent.pvpFeature2Text;
      if(i === 2) return preConsent.pvpFeature3Text;
      return preConsent.pvpFeature1Text;
    }

    const handlePVPFeatureTextChange = (i: Number, value: string) => {
      if(i === 1)
        setPreConsentSettings({...preConsent, pvpFeature2Text: value});
      else if(i === 2)
        setPreConsentSettings({...preConsent, pvpFeature3Text: value});
      else
        setPreConsentSettings({...preConsent, pvpFeature1Text: value});
    }

    const handlePVPImageUploadCapture = (i: number, event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) {
            return;
        }

        var file = event.target.files[0];
        const objectUrl = URL.createObjectURL(file);

        var newPreConsentSettings:PreConsent = {...preConsent};
        const now = moment().format("DD/MM/YYYY");
        var uploadFile:UploadFile;

        checkIfUploadedFileHasImageMimeType(file, function(isValidImage:boolean) {
            if (!checkIfUploadedFileSizeWithinLimit(file)) {
                maxFileUploadSizePopup();
                return;
            }

            if (!isValidImage) {
                invalidImageUploadPopup();
            } else {
                switch(i) {
                    case 0:
                        newPreConsentSettings = {...preConsent, pvpFeature1Image: file.name, pvpFeature1ImageUploadDate: now};
                        setState({...state, preConsentCopy: newPreConsentSettings, pvpFeature1ImagePreviewURL: objectUrl});
                        uploadFile = {
                            name: `pvpImage` + i.toString(),
                            path: `/principal-config-images/pvp-feature-image-1/${props.configuration.generalSettings.principalId}`,
                            file: file
                        };
                        state.fileUploads.push(uploadFile);
                        break;

                    case 1:
                        newPreConsentSettings = {...preConsent, pvpFeature2Image: file.name, pvpFeature2ImageUploadDate: now};
                        setState({...state, preConsentCopy: newPreConsentSettings, pvpFeature2ImagePreviewURL: objectUrl});
                        uploadFile = {
                            name: `pvpImage` + i.toString(),
                            path: `/principal-config-images/pvp-feature-image-2/${props.configuration.generalSettings.principalId}`,
                            file: file
                        };
                        state.fileUploads.push(uploadFile);
                        break;

                    case 2:
                        newPreConsentSettings = {...preConsent, pvpFeature3Image: file.name, pvpFeature3ImageUploadDate: now};
                        setState({...state, preConsentCopy: newPreConsentSettings, pvpFeature3ImagePreviewURL: objectUrl});
                        uploadFile = {
                            name: `pvpImage` + i.toString(),
                            path: `/principal-config-images/pvp-feature-image-3/${props.configuration.generalSettings.principalId}`,
                            file: file
                        };
                        state.fileUploads.push(uploadFile);
                        break;

                    default:
                        newPreConsentSettings = {...preConsent, pvpKeyVisualFileName: file.name, pvpKeyVisualFileUploadDate: now};
                        setState({...state, preConsentCopy: newPreConsentSettings, pvpKeyVisualPreviewURL: objectUrl});
                        uploadFile = {
                            name: `pvpImage` + i.toString(),
                            path: `/principal-config-images/pvp-key-visual-image/${props.configuration.generalSettings.principalId}`,
                            file: file
                        };
                        state.fileUploads.push(uploadFile);
                        break;
                }
            }
        });
    }

    const getCDRFeatureImage = (i: Number) => {
      if(i === 1) return preConsent.cdrFeature2Image;
      if(i === 2) return preConsent.cdrFeature3Image;
      return preConsent.cdrFeature1Image;
    }

    const getCDRFeatureImageURL = (i: Number) => {
      if(i === 1) return state.cdrFeature2ImageURL;
      if(i === 2) return state.cdrFeature3ImageURL;
      return state.cdrFeature1ImageURL;
    }

    const getCDRFeatureImagePreviewURL = (i: Number) => {
        if (props.imagePreviews) {
            if(i === 1) return props.imagePreviews.cdrFeature2ImagePreviewURL;
            if(i === 2) return props.imagePreviews.cdrFeature3ImagePreviewURL;
            return props.imagePreviews.cdrFeature1ImagePreviewURL;
        }

      if(i === 1) return state.cdrFeature2ImagePreviewURL;
      if(i === 2) return state.cdrFeature3ImagePreviewURL;
      return state.cdrFeature1ImagePreviewURL;
    }

    const getCDRFeatureImageUploadDate = (i: Number) => {
      if(i === 1) return preConsent.cdrFeature2ImageUploadDate;
      if(i === 2) return preConsent.cdrFeature3ImageUploadDate;
      return preConsent.cdrFeature1ImageUploadDate;
    }

    const getCDRFeatureText = (i: Number) => {
      if(i === 1) return preConsent.cdrFeature2Text;
      if(i === 2) return preConsent.cdrFeature3Text;
      return preConsent.cdrFeature1Text;
    }

    const handleCDRFeatureTextChange = (i: Number, value: string) => {
      if(i === 1)
        setPreConsentSettings({...preConsent, cdrFeature2Text: value});
      else if(i === 2)
        setPreConsentSettings({...preConsent, cdrFeature3Text: value});
      else
        setPreConsentSettings({...preConsent, cdrFeature1Text: value});
    }
    
    const handleCDRImageUploadCapture = (i: number, event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files === null) {
            return;
        }
        var file = event.target.files[0];
        const objectUrl = URL.createObjectURL(file);
        const now = moment().format("DD/MM/YYYY");

        var newPreConsentSettings:PreConsent = {...preConsent};
        var uploadFile:UploadFile;

        checkIfUploadedFileHasImageMimeType(file, function(isValidImage:boolean) {
            if (!checkIfUploadedFileSizeWithinLimit(file)) {
                maxFileUploadSizePopup();
                return;
            }

            if (!isValidImage) {
                invalidImageUploadPopup();
            } else {
                switch(i) {
                    case 0:
                        newPreConsentSettings = {...preConsent, cdrFeature1Image: file.name, cdrFeature1ImageUploadDate: now};
                        setState({...state, preConsentCopy: newPreConsentSettings, cdrFeature1ImagePreviewURL: objectUrl});
                        uploadFile = {
                            name: `cdrImage` + i.toString(),
                            path: `/principal-config-images/cdr-feature-image-1/${props.configuration.generalSettings.principalId}`,
                            file: file
                        };
                        state.fileUploads.push(uploadFile);
                        break;

                    case 1:
                        newPreConsentSettings = {...preConsent, cdrFeature2Image: file.name, cdrFeature2ImageUploadDate: now};
                        setState({...state, preConsentCopy: newPreConsentSettings, cdrFeature2ImagePreviewURL: objectUrl});
                        uploadFile = {
                            name: `cdrImage` + i.toString(),
                            path: `/principal-config-images/cdr-feature-image-2/${props.configuration.generalSettings.principalId}`,
                            file: file
                        };
                        state.fileUploads.push(uploadFile);
                        break;

                    case 2:
                        newPreConsentSettings = {...preConsent, cdrFeature3Image: file.name, cdrFeature3ImageUploadDate: now};
                        setState({...state, preConsentCopy: newPreConsentSettings, cdrFeature3ImagePreviewURL: objectUrl});
                        uploadFile = {
                            name: `cdrImage` + i.toString(),
                            path: `/principal-config-images/cdr-feature-image-3/${props.configuration.generalSettings.principalId}`,
                            file: file
                        };
                        state.fileUploads.push(uploadFile);
                        break;

                    default:
                        newPreConsentSettings = {...preConsent, cdrSupportingImageFileName: file.name, cdrSupportingImageFileUploadDate: now};
                        setState({...state, preConsentCopy: newPreConsentSettings, cdrSupportingImagePreviewURL: objectUrl});
                        uploadFile = {
                            name: `cdrImage` + i.toString(),
                            path: `/principal-config-images/cdr-supporting-image/${props.configuration.generalSettings.principalId}`,
                            file: file
                        };
                        state.fileUploads.push(uploadFile);
                        break;
                }
            }
        });
    }
    
    const onEditorStateChange = (editorState: EditorState) => {
      setPreConsentSettings({
        ...preConsent,
        dataHolderSelectionGuideContent: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      })
    };

    const handleSave = () => {
        const newConfiguration: Configuration = {
            ...props.configuration,
            preConsent: state.preConsentCopy,
        }
        var imagePreviews = {
            pvpKeyVisualPreviewURL: state.pvpKeyVisualPreviewURL,
            pvpFeature1ImagePreviewURL: state.pvpFeature1ImagePreviewURL,
            pvpFeature2ImagePreviewURL: state.pvpFeature2ImagePreviewURL,
            pvpFeature3ImagePreviewURL: state.pvpFeature3ImagePreviewURL,
            cdrSupportingImagePreviewURL: state.cdrSupportingImagePreviewURL,
            cdrFeature1ImagePreviewURL: state.cdrFeature1ImagePreviewURL,
            cdrFeature2ImagePreviewURL: state.cdrFeature2ImagePreviewURL,
            cdrFeature3ImagePreviewURL: state.cdrFeature3ImagePreviewURL,
        };
        props.onConfigurationUpdate && props.onConfigurationUpdate(newConfiguration, 'view', true, state.fileUploads, imagePreviews);
        setState({
            ...state,
            fileUploads: []
        });
    };

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    }

    useEffect(() => {
        (async() => {
            var imageUrlBase = `/principal-config-images`;
            var principalId = props.configuration.generalSettings.principalId;

            if (props.configuration.preConsent.pvpKeyVisualFileName) {
                displayUploadedImage('pvpKeyVisualFileName', imageUrlBase + `/pvp-key-visual-image/${principalId}/${props.configuration.preConsent.pvpKeyVisualFileName}`);
            }
            if (props.configuration.preConsent.pvpFeature1Image) {
                displayUploadedImage('pvpFeature1Image', imageUrlBase + `/pvp-feature-image-1/${principalId}/${props.configuration.preConsent.pvpFeature1Image}`);
            }
            if (props.configuration.preConsent.pvpFeature2Image) {
                displayUploadedImage('pvpFeature2Image', imageUrlBase + `/pvp-feature-image-2/${principalId}/${props.configuration.preConsent.pvpFeature2Image}`);
            }
            if (props.configuration.preConsent.pvpFeature3Image) {
                displayUploadedImage('pvpFeature3Image', imageUrlBase + `/pvp-feature-image-3/${principalId}/${props.configuration.preConsent.pvpFeature3Image}`);
            }

            if (props.configuration.preConsent.cdrSupportingImageFileName) {
                displayUploadedImage('cdrSupportingImageFileName', imageUrlBase + `/cdr-supporting-image/${principalId}/${props.configuration.preConsent.cdrSupportingImageFileName}`);
            }
            if (props.configuration.preConsent.cdrFeature1Image) {
                displayUploadedImage('cdrFeature1Image', imageUrlBase + `/cdr-feature-image-1/${principalId}/${props.configuration.preConsent.cdrFeature1Image}`);
            }
            if (props.configuration.preConsent.cdrFeature2Image) {
                displayUploadedImage('cdrFeature2Image', imageUrlBase + `/cdr-feature-image-2/${principalId}/${props.configuration.preConsent.cdrFeature2Image}`);
            }
            if (props.configuration.preConsent.cdrFeature3Image) {
                displayUploadedImage('cdrFeature3Image', imageUrlBase + `/cdr-feature-image-3/${principalId}/${props.configuration.preConsent.cdrFeature3Image}`);
            }
        })();
        //eslint-disable-next-line
    }, [
        props.configuration.preConsent.pvpKeyVisualFileName,
        props.configuration.preConsent.pvpFeature1Image,
        props.configuration.preConsent.pvpFeature2Image,
        props.configuration.preConsent.pvpFeature3Image,
        props.configuration.preConsent.cdrSupportingImageFileName,
        props.configuration.preConsent.cdrFeature1Image,
        props.configuration.preConsent.cdrFeature2Image,
        props.configuration.preConsent.cdrFeature3Image
    ]);

    //eslint-disable-next-line
    const getFile = useCallback(props.api.getFile, []);
    const displayUploadedImage = (imageType:string, imageUrl:string) => {
        (async() => {
                try {
                    const data = await getFile(imageUrl);
                    setUploadedImageUrl(imageType, data)
                } catch (error) {
                }
        })();
    }

    const setUploadedImageUrl = (imageType:string, data:any) => {
        switch (imageType) {
            case 'pvpKeyVisualFileName':
                setState((state) => ({
                    ...state,
                    pvpKeyVisualFileURL: URL.createObjectURL(data)
                }))
                break;
            case 'pvpFeature1Image':
                setState((state) => ({
                    ...state,
                    pvpFeature1ImageURL: URL.createObjectURL(data)
                }))
                break;
            case 'pvpFeature2Image':
                setState((state) => ({
                    ...state,
                    pvpFeature2ImageURL: URL.createObjectURL(data)
                }))
                break;
            case 'pvpFeature3Image':
                setState((state) => ({
                    ...state,
                    pvpFeature3ImageURL: URL.createObjectURL(data)
                }))
                break;
            case 'cdrSupportingImageFileName':
                setState((state) => ({
                    ...state,
                    cdrSupportingImageFileURL: URL.createObjectURL(data)
                }))
                break;
            case 'cdrFeature1Image':
                setState((state) => ({
                    ...state,
                    cdrFeature1ImageURL: URL.createObjectURL(data)
                }))
                break;
            case 'cdrFeature2Image':
                setState((state) => ({
                    ...state,
                    cdrFeature2ImageURL: URL.createObjectURL(data)
                }))
                break;
            case 'cdrFeature3Image':
                setState((state) => ({
                    ...state,
                    cdrFeature3ImageURL: URL.createObjectURL(data)
                }))
                break;
        }
    }

    const preConsent = state.preConsentCopy;
    const form =
        <form className={"admin-form pre-consent-form mt-50"} autoComplete={"off"}>
            <>
              <div className={"mt-50"} key={"group-1"}>
                <Box mb={2}>
                  <h4>Product Value Proposition</h4>
                </Box>

                <Grid container className={"mt-30"}>
                      <Grid item xs={12} sm={4} md={3}>
                          <label>Product Value Proposition Page</label>
                      </Grid>
                      <Grid item xs={12} sm={7} md={5}>
                          <Grid item md={"auto"} className={"grid-file-input"}>
                              <SwitchWithLabel
                                  checked={preConsent.inclPreConsentStage}
                                  disabled={!editMode}
                                  onChange={value => {
                                      setPreConsentSettings({...preConsent, inclPreConsentStage: value})
                                  }}>

                                  {editMode ? "Include" : preConsent.inclPreConsentStage ? "Include" : "Exclude"}
                              </SwitchWithLabel>
                          </Grid>
                      </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Key Visual<LightTooltip
                      title={<img className="tooltip-image" src="/images/admin/pre-consent/Key_visual.png" alt="" />}
                      placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel
                        checked={preConsent.inclKeyVisual}
                        disabled={!editMode}
                        onChange={value => {
                            setPreConsentSettings({...preConsent, inclKeyVisual: value})
                        }}>
                          {editMode ? "Include" : preConsent.inclKeyVisual ? "Include" : "Exclude"}
                      </SwitchWithLabel>
                    </FormControl>
                    <Grid item md={"auto"} className={"grid-file-input"}>
                      {editMode ?
                        <>
                          <div className={"file-item-wrap"}>
                            <Box className={"details-box"}>
                              <FileItem type={"image"} name={preConsent.pvpKeyVisualFileName} url={props.imagePreviews?.pvpKeyVisualPreviewURL || state.pvpKeyVisualPreviewURL || state.pvpKeyVisualFileURL}/>
                            </Box>
                            {preConsent.pvpKeyVisualFileUploadDate && <div className={"date"}>Uploaded {preConsent.pvpKeyVisualFileUploadDate}</div>}
                          </div>
                          <Box mt={2} className={"input-file-box"}>
                              <input
                                  color="primary"
                                  accept="image/png,image/jpeg"
                                  type="file"
                                  onChange={e => {
                                      handlePVPImageUploadCapture(3, e);
                                      e.target.value = '';
                                  }}
                                  id="pvp-keyvisual-upload"
                                  style={{ display: 'none' }}
                                  ref={pvpFileUploadInputs[3]}
                              />
                              <a href={"/#"} onClick={(e) => {
                                  e.preventDefault();
                                  if(editMode) pvpFileUploadInputs[3].current.click();
                              }}>
                                  Upload new
                              </a>
                          </Box>
                        </>
                        :
                        <div className={"file-item-wrap"}>
                          <Box className={"details-box"}>
                            <FileItem type={"image"} name={preConsent.pvpKeyVisualFileName} url={props.imagePreviews?.pvpKeyVisualPreviewURL ||state.pvpKeyVisualPreviewURL || state.pvpKeyVisualFileURL}/>
                          </Box>
                          {preConsent.pvpKeyVisualFileUploadDate && <div className={"date"}>Uploaded {preConsent.pvpKeyVisualFileUploadDate}</div>}
                        </div>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Key Text<LightTooltip
                      title={<img className="tooltip-image" src="/images/admin/pre-consent/Key_text.png" alt="" />}
                      placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel
                        checked={preConsent.inclKeyText}
                        disabled={!editMode}
                        onChange={value => {
                            setPreConsentSettings({...preConsent, inclKeyText: value})
                        }}>
                          {editMode ? "Include" : preConsent.inclKeyText ? "Include" : "Exclude"}
                      </SwitchWithLabel>
                    </FormControl>
                    <Box className={`details-box ${bgClass}`}>
                      <Box>
                        <label>Main Heading<LightTooltip title="Main heading for key text" placement="right-start"
                                                          arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          editMode={editMode}
                          name={"mainHeading"}
                          value={preConsent.keyTextHeading}
                          eventHandlers={{
                              onChange: (e: any) => {
                                  setPreConsentSettings({...preConsent, keyTextHeading: e.target.value})
                              }
                          }}
                        />
                      </Box>
                      <Box mt={4}>
                        <label>Main Text<LightTooltip title="Main text for key text" placement="right-start" arrow><InfoIcon
                          color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          textArea
                          editMode={editMode}
                          name={"mainText"}
                          value={preConsent.keyText}
                          eventHandlers={{
                              onChange: (e: any) => {
                                  setPreConsentSettings({...preConsent, keyText: e.target.value})
                              }
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Call to Action Text<LightTooltip
                      title={<img className="tooltip-image" src="/images/admin/pre-consent/Call_to_action_text.png" alt="" />}
                      placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5} className={bgClass}>
                    <EditableText
                      editMode={editMode}
                      name={"ctaText"}
                      value={preConsent.ctaText}
                      eventHandlers={{
                          onChange: (e: any) => {
                              setPreConsentSettings({...preConsent, ctaText: e.target.value});
                          }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Feature Heading<LightTooltip
                      title={<img className="tooltip-image" src="/images/admin/pre-consent/Feature_heading.png" alt="" />}
                      placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5} className={bgClass}>
                    <EditableText
                      editMode={editMode}
                      name={"featureHeading"}
                      value={preConsent.pvpFeatureHeading}
                      eventHandlers={{
                          onChange: (e: any) => {
                              setPreConsentSettings({...preConsent, pvpFeatureHeading: e.target.value});
                          }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Number of Features<LightTooltip
                      title={<img className="tooltip-image" src="/images/admin/pre-consent/Feature_columns.jpg" alt="" />}
                      placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    {editMode ?
                      <FormControl variant="outlined" className="w-100">
                        <Select
                          name="numberOfFeatures"
                          value={preConsent.pvpNumberOfFeatures}
                          onChange={(e: SelectChangeEvent<number>) => {
                              setPreConsentSettings({
                                  ...preConsent,
                                  pvpNumberOfFeatures: e.target.value as number
                              })
                          }}
                        >
                          <MenuItem value={0}>0</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                        </Select>
                      </FormControl>
                      :
                      preConsent.pvpNumberOfFeatures
                    }
                  </Grid>
                </Grid>
                {preConsent.pvpNumberOfFeatures !== undefined && ([...Array(preConsent.pvpNumberOfFeatures)].map((_, i) =>
                  <>
                    <Grid container className={"mt-30"} key={"ic"+i}>
                      <Grid item xs={12} sm={4} md={3}>
                        <label>Feature {i+1} Image<LightTooltip
                          title={<img className="tooltip-image" src={`/images/admin/pre-consent/Feature_${i+1}_image.png`} alt="" />}
                          placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                      </Grid>
                      <Grid item xs={12} sm={7} md={5} className={"grid-file-input"}>
                        {editMode ?
                          <>
                            <div className={"file-item-wrap"}>
                              <Box className={"details-box"}>
                                <FileItem type={"image"} name={getPVPFeatureImage(i)} url={getPVPFeatureImagePreviewURL(i) || getPVPFeatureImageURL(i)}/>
                              </Box>
                              {getPVPFeatureImageUploadDate(i) && <div className="date">{getPVPFeatureImageUploadDate(i)}</div>}
                            </div>
                            <Box mt={2} className={"input-file-box"}>
                              <input
                                  color="primary"
                                  accept="image/x-png,image/gif,image/jpeg"
                                  type="file"
                                  onChange={e => {
                                      handlePVPImageUploadCapture(i, e);
                                      e.target.value = '';
                                  }}
                                  style={{ display: 'none' }}
                                  ref={pvpFileUploadInputs[i]}
                              />
                              <a href={"/#"} onClick={(e) => {
                                  e.preventDefault();
                                  if(editMode) pvpFileUploadInputs[i].current.click();
                              }}>
                                  Upload new
                              </a>
                            </Box>
                          </>
                          :
                          <div className={"file-item-wrap"}>
                            <Box className={"details-box"}>
                              <FileItem type={"image"} name={getPVPFeatureImage(i)} url={getPVPFeatureImagePreviewURL(i) || getPVPFeatureImageURL(i)}/>
                            </Box>
                            {getPVPFeatureImageUploadDate(i) && <div className="date">{getPVPFeatureImageUploadDate(i)}</div>}
                          </div>
                        }
                      </Grid>
                    </Grid>
                    <Grid container className={"mt-30"} key={"tc"+i}>
                      <Grid item xs={12} sm={4} md={3}>
                        <label>Feature {i+1} Text<LightTooltip
                          title={<img className="tooltip-image" src={`/images/admin/pre-consent/Feature_${i+1}_text.png`} alt="" />}
                          placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                      </Grid>
                      <Grid item xs={12} sm={7} md={5} className={bgClass}>
                        <EditableText
                          editMode={editMode}
                          name={`feature${i+1}Text`}
                          value={getPVPFeatureText(i)}
                          eventHandlers={{
                              onChange: (e: any) => { handlePVPFeatureTextChange(i, e.target.value) }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                ))}
              </div>
              
              <div className={"mt-50"} key={"group-2"}>
                <Box mb={2}>
                  <h4>Data Holder Selection</h4>
                </Box>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Data Holder Selection Heading<LightTooltip
                      title={<img className="tooltip-image" src="/images/admin/pre-consent/Data_holder_selection_heading.png" alt="" />}
                      placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5} className={bgClass}>
                    <EditableText
                      editMode={editMode}
                      name={"dataHolderSelectionHeading"}
                      value={preConsent.dataHolderSelectionHeading}
                      eventHandlers={{
                          onChange: (e: any) => {
                            setPreConsentSettings({...preConsent, dataHolderSelectionHeading: e.target.value})
                          }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Data Holder Selection Guide Heading<LightTooltip
                      title={<img className="tooltip-image" src="/images/admin/pre-consent/Data_holder_selection_guide_heading.png" alt="" />}
                      placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5} className={bgClass}>
                    <EditableText
                      editMode={editMode}
                      name={"dataHolderSelectionGuideHeading"}
                      value={preConsent.dataHolderSelectionGuideHeading}
                      eventHandlers={{
                          onChange: (e: any) => {
                            setPreConsentSettings({...preConsent, dataHolderSelectionGuideHeading: e.target.value})
                          }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Data Holder Selection Guide Content<LightTooltip
                      title={<img className="tooltip-image" src="/images/admin/pre-consent/Data_holder_selection_guide_content.png" alt="" />}
                      placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <EditableRichText
                      editMode={editMode}
                      value={props.configuration.preConsent.dataHolderSelectionGuideContent}
                      onChange={onEditorStateChange}
                    />
                  </Grid>
                </Grid>

                  <Grid container className={"mt-30"}>
                      <Grid item xs={12} sm={4} md={3}>
                          <label>Data Holder Types To Include</label>
                      </Grid>
                      <Grid item xs={12} sm={7} md={5}>
                          {editMode ?
                              <FormControl variant="outlined" className="w-100">
                                  <Select
                                      name="dataHolderTypesToInclude"
                                      value={preConsent.dataHolderTypesToInclude || "all"}
                                      onChange={(e: SelectChangeEvent) => {
                                          setPreConsentSettings({...preConsent, dataHolderTypesToInclude: e.target.value})
                                      }}
                                  >
                                      <MenuItem value={"all"}>All</MenuItem>
                                      <MenuItem value={"cdr"}>CDR</MenuItem>
                                      <MenuItem value={"non-cdr"}>Non-CDR</MenuItem>
                                  </Select>
                              </FormControl>
                              : <>{(preConsent.dataHolderTypesToInclude) ? preConsent.dataHolderTypesToInclude : "All"}</>
                          }
                      </Grid>
                  </Grid>

              </div>
              
              <div className={"mt-50"} key={"group-3"}>
                <Box mb={2}>
                  <h4>Consumer Data Right Value Proposition &amp; Branding</h4>
                </Box>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Supporting Image<LightTooltip
                      title={<img className="tooltip-image" src="/images/admin/pre-consent/Supporting_image.png" alt="" />}
                      placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel 
                        checked={preConsent.includeCdrSupportingImage}
                        disabled={!editMode}
                        onChange={value => {
                          setPreConsentSettings({...preConsent, includeCdrSupportingImage: value})
                        }}
                      >

                      {editMode ? "Include" : preConsent.includeCdrSupportingImage ? "Include" : "Exclude"}
                      </SwitchWithLabel>
                    </FormControl>
                    <Grid item md={"auto"} className={"grid-file-input"}>
                      {editMode ?
                        <>
                          <div className={"file-item-wrap"}>
                            <Box className={"details-box"}>
                              <FileItem type={"image"} name={preConsent.cdrSupportingImageFileName} url={props.imagePreviews?.cdrSupportingImagePreviewURL || state.cdrSupportingImagePreviewURL || state.cdrSupportingImageFileURL}/>
                            </Box>
                            {preConsent.cdrSupportingImageFileUploadDate && <div className={"date"}>Uploaded {preConsent.cdrSupportingImageFileUploadDate}</div>}
                          </div>
                          <Box mt={2} className={"input-file-box"}>
                              <input
                                  color="primary"
                                  accept="image/x-png,image/gif,image/jpeg"
                                  type="file"
                                  onChange={e => {
                                      handleCDRImageUploadCapture(3, e);
                                      e.target.value = '';
                                  }}
                                  id="pvp-keyvisual-upload"
                                  style={{ display: 'none' }}
                                  ref={cdrFileUploadInputs[3]}
                              />
                              <a href={"/#"} onClick={(e) => {
                                  e.preventDefault();
                                  if(editMode) cdrFileUploadInputs[3].current.click();
                              }}>
                                  Upload new
                              </a>
                          </Box>
                        </>
                        :
                        <div className={"file-item-wrap"}>
                          <Box className={"details-box"}>
                            <FileItem type={"image"} name={preConsent.cdrSupportingImageFileName} url={props.imagePreviews?.cdrSupportingImagePreviewURL || state.cdrSupportingImagePreviewURL || state.cdrSupportingImageFileURL}/>
                          </Box>
                          {preConsent.cdrSupportingImageFileUploadDate && <div className={"date"}>Uploaded {preConsent.cdrSupportingImageFileUploadDate}</div>}
                        </div>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Key Text<LightTooltip
                      title={<img className="tooltip-image" src="/images/admin/pre-consent/Key_text_cdr.png" alt="" />}
                      placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel 
                        checked={preConsent.includeCdrKeyText}
                        disabled={!editMode}
                        onChange={value => {
                          setPreConsentSettings({...preConsent, includeCdrKeyText: value})
                        }}
                      >

                      {editMode ? "Include" : preConsent.includeCdrKeyText ? "Include" : "Exclude"}
                      </SwitchWithLabel>
                    </FormControl>
                    <Box className={`details-box ${bgClass}`}>
                      <Box>
                        <label>Heading<LightTooltip title="Consumer data right value proposition &amp; branding key text heading" placement="right-start"
                                                     arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          editMode={editMode}
                          name={"mainHeading"}
                          value={preConsent.cdrKeyTextHeading}
                          wrapper={"div"}
                          eventHandlers={{
                            onChange: (e: any) => {
                                setPreConsentSettings({...preConsent, cdrKeyTextHeading: e.target.value});
                            }
                          }}
                        />
                      </Box>
                      <Box mt={4}>
                        <label>Main Text<LightTooltip title="Consumer data right value proposition &amp; branding key text main text" placement="right-start" arrow><InfoIcon
                          color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          textArea
                          editMode={editMode}
                          name={"mainHeading"}
                          minRows={8}
                          value={preConsent.cdrKeyTextMainText}
                          wrapper={"div"}
                          eventHandlers={{
                            onChange: (e: any) => {
                                setPreConsentSettings({...preConsent, cdrKeyTextMainText: e.target.value});
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>CDR Value Proposition &amp; Information<LightTooltip
                      title={<img className="tooltip-image" src="/images/admin/pre-consent/CDR_value_proposition_information.png" alt="" />}
                      placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel 
                        checked={preConsent.includeCdrVpInformation}
                        disabled={!editMode}
                        onChange={value => {
                          setPreConsentSettings({...preConsent, includeCdrVpInformation: value})
                        }}
                      >
                      {editMode ? "Include" : preConsent.includeCdrVpInformation ? "Include" : "Exclude"}
                      </SwitchWithLabel>
                    </FormControl>
                    <Box className={`details-box ${bgClass}`}>
                      <Box>
                        <label>Heading<LightTooltip title="CDR Value Proposition &amp; Information heading" placement="right-start"
                                                     arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          editMode={editMode}
                          name={"cdrInformationHeading"}
                          value={preConsent.cdrVpInformationHeading}
                          eventHandlers={{
                            onChange: (e: any) => {
                                setPreConsentSettings({...preConsent, cdrVpInformationHeading: e.target.value});
                            }
                          }}
                        />
                      </Box>
                      <Box mt={4}>
                        <label>Main Text<LightTooltip title="CDR Value Proposition &amp; Information main text" placement="right-start" arrow><InfoIcon
                          color={"secondary"}/></LightTooltip></label>
                        <EditableText
                          textArea
                          editMode={editMode}
                          name={"cdrInformationText"}
                          value={preConsent.cdrVpInformationMainText}
                          eventHandlers={{
                            onChange: (e: any) => {
                                setPreConsentSettings({...preConsent, cdrVpInformationMainText: e.target.value});
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Link to further verification and info<LightTooltip title="Link to an ACCC-provided URL to verify a ADR’s accreditation"
                                                                               placement="right-start" arrow><InfoIcon
                      color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel 
                        checked={preConsent.includeLinkToFurtherVerification}
                        disabled={!editMode}
                        onChange={value => {
                          setPreConsentSettings({...preConsent, includeLinkToFurtherVerification: value})
                        }}
                      >
                      {editMode ? "Include" : preConsent.includeLinkToFurtherVerification ? "Include" : "Exclude"}
                      </SwitchWithLabel>
                    </FormControl>
                    <EditableText
                      editMode={editMode}
                      name={"cdrPolicyUrl"}
                      value={preConsent.linkToFurtherVerification}
                      eventHandlers={{
                        onChange: (e: any) => {
                            setPreConsentSettings({...preConsent, linkToFurtherVerification: e.target.value});
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Key CDR Features &amp; Information<LightTooltip title="Important CDR information consumer should be aware of"
                                                                            placement="right-start" arrow><InfoIcon
                      color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    <FormControl>
                      <SwitchWithLabel 
                        checked={preConsent.includeKeyFeaturesAndInformation}
                        disabled={!editMode}
                        onChange={value => {
                          setPreConsentSettings({...preConsent, includeKeyFeaturesAndInformation: value})
                        }}
                      >
                      {editMode ? "Include" : preConsent.includeKeyFeaturesAndInformation ? "Include" : "Exclude"}
                      </SwitchWithLabel>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Feature Heading<LightTooltip
                      title={<img className="tooltip-image" src="/images/admin/pre-consent/Feature_heading_cdr.png" alt="" />}
                      placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5} className={bgClass}>
                    <EditableText
                      editMode={editMode}
                      name={"featureHeading"}
                      value={preConsent.cdrFeatureHeading}
                      eventHandlers={{
                          onChange: (e: any) => {
                            setPreConsentSettings({...preConsent, cdrFeatureHeading: e.target.value})
                          }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container className={"mt-30"}>
                  <Grid item xs={12} sm={4} md={3}>
                    <label>Number of Features<LightTooltip
                      title={<img className="tooltip-image" src="/images/admin/pre-consent/Feature_columns.jpg" alt="" />}
                      placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={7} md={5}>
                    {editMode ?
                      <FormControl variant="outlined" className="w-100">
                        <Select
                          name="numberOfFeatures"
                          value={preConsent.cdrNumberOfFeatures}
                          onChange={(e: SelectChangeEvent<number>) => {
                              setPreConsentSettings({...preConsent, cdrNumberOfFeatures: e.target.value as number})
                          }}
                        >
                          <MenuItem value={0}>0</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                        </Select>
                      </FormControl>
                      :
                      preConsent.cdrNumberOfFeatures
                    }
                  </Grid>
                </Grid>
                {preConsent.cdrNumberOfFeatures !== undefined && ([...Array(preConsent.cdrNumberOfFeatures)].map((_, i) =>
                <>
                  <Grid container className={"mt-30"} key={"ic"+i}>
                    <Grid item xs={12} sm={4} md={3}>
                      <label>Feature {i+1} Image<LightTooltip
                        title={<img className="tooltip-image" src={`/images/admin/pre-consent/Feature_${i+1}_image_cdr.png`} alt="" />}
                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5} className={"grid-file-input"}>
                      {editMode ?
                        <>
                          <div className={"file-item-wrap"}>
                            <Box className={"details-box"}>
                              <FileItem type={"image"} name={getCDRFeatureImage(i)} url={getCDRFeatureImagePreviewURL(i) || getCDRFeatureImageURL(i)}/>
                            </Box>
                            {getCDRFeatureImageUploadDate(i) && <div className={"date"}>Uploaded {getCDRFeatureImageUploadDate(i)}</div>}
                          </div>
                          <Box mt={2} className={"input-file-box"}>
                            <input
                                color="primary"
                                accept="image/x-png,image/gif,image/jpeg"
                                type="file"
                                onChange={e => {
                                    handleCDRImageUploadCapture(i, e);
                                    e.target.value = '';
                                }}
                                style={{ display: 'none', }}
                                ref={cdrFileUploadInputs[i]}
                            />
                            <a href={"/#"} 
                                onClick={(e) => {
                                  e.preventDefault();
                                  if(editMode) cdrFileUploadInputs[i].current.click();
                                }}
                            >
                                Upload new
                            </a>
                          </Box>
                        </>
                        :
                        <div className={"file-item-wrap"}>
                          <Box className={"details-box"}>
                              <FileItem type={"image"} name={getCDRFeatureImage(i)} url={getCDRFeatureImagePreviewURL(i) || getCDRFeatureImageURL(i)}/>
                          </Box>
                          {getCDRFeatureImageUploadDate(i) && <div className={"date"}>Uploaded {getCDRFeatureImageUploadDate(i)}</div>}
                        </div>
                      }
                    </Grid>
                  </Grid>
                  <Grid container className={"mt-30"} key={"tc" + i}>
                    <Grid item xs={12} sm={4} md={3}>
                      <label>Feature {i+1} Text<LightTooltip
                        title={<img className="tooltip-image" src={`/images/admin/pre-consent/Feature_${i+1}_text_cdr.png`} alt="" />}
                        placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5} className={bgClass}>
                      <EditableText
                        editMode={editMode}
                        name={`feature${i+1}Text`}
                        value={getCDRFeatureText(i)}
                        eventHandlers={{
                            onChange: (e: any) => { handleCDRFeatureTextChange(i, e.target.value) }
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
                ))}
              </div>
            </>

            {editMode &&
              <Grid container className={"mt-20"}>
                <Grid item xs={12}>
                  <ButtonBlock className={"text-align-center"}>
                    <Button onClick={() => handleCancel()} variant={"outlined"} color={"secondary"}>Cancel</Button>
                    <Button onClick={() => handleSave()} variant={"contained"} color={"secondary"}>Save</Button>
                  </ButtonBlock>
                </Grid>
              </Grid>
            }
        </form>;

    return <>
        <h2 className={"page-title"}>Pre-Consent Configuration</h2>
        <h5 className="text-large">{props.configuration.generalSettings.principalName}</h5>

        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={11} md={8}>
                The pre-consent stage consists of a general onboarding experience and takes place prior to the Consent
                Flow. Consumer trust is critical to CDR adoption. Trust SHOULD be built prior to an ADR requesting CDR
                data, and when this occurs depends on whether or not the consumer has a pre-existing relationship with
                the ADR.
            </Grid>
        </Grid>

        {form}

        {/* Image validation popup */}
        <Popup
            key={"image-validation-error-popup"}
            open={open}
            onClose={closePopup}
            buttons={<Button onClick={closePopup} variant={"contained"} color={"secondary"}>Close</Button>}
        >
            <Typography color={"primary"}>
                <h2>Invalid Image File</h2>
                <p>Image to be uploaded must either be a JPG or PNG file.</p>
            </Typography>
        </Popup>

        {/* Image validation popup */}
        <Popup
            key={"image-validation-error-popup-2"}
            open={open2}
            onClose={closePopup2}
            buttons={<Button onClick={closePopup2} variant={"contained"} color={"secondary"}>Close</Button>}
        >
            <Typography color={"primary"}>
                <h2>Max Upload File Size Exceeded</h2>
                <p>File uploaded must be less than 5MB in size.</p>
            </Typography>
        </Popup>
    </>;
}

export default ConfigurationPreConsentSettings;