import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from "@mui/material";

import ButtonBlock from "../components/ButtonBlock";
import ConsentDetailsHeader from "../components/ConsentDetailsHeader";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LinkButton from "../components/LinkButton";
import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import ConsentListItemData from "../models/ConsentListItemData";
import GeneralSettings from "../models/GeneralSettings";
import DataCluster from "../openbankingplatform/models/DataCluster";
import OpenBankingPlatformAPI from "../openbankingplatform/OpenBankingPlatformAPI";
import { sanitize } from "../helpers/HtmlSanitizerHelper";

interface WithdrawConsentConfirmationProps {
	basePath: string;
	principalName?: string;
	api: OpenBankingPlatformAPI;
	dashboardPath: string;
	generalSettingsConfig?: GeneralSettings;
	principalLogoUrl?: string;
	headerBgImageUrl?: string;
	loadPrincipalConfiguration: (principal_id?: number) => void;
	footerText?: string;
	isTrustedAdviser?: boolean;
}

interface ConsentDetailsState {
	consentDetails?: ConsentListItemData;
	dataClusters: Array<DataCluster>;
	sharingStartAt?: string;
	sharingEndAt?: string;
	consentStatus: string;
	delete_my_data_after_used: boolean;
}

const WithdrawConsentConfirmation = (props: WithdrawConsentConfirmationProps) => {
	const { id } = useParams<{ id: any }>();

	const [state, setState] = useState<ConsentDetailsState>({
		dataClusters: [],
		consentStatus: '',
		delete_my_data_after_used: false
	});

	const location = useLocation();
	useEffect(() => {
		const consentDetails = location.state as ConsentListItemData;
		setState((state) => ({
			...state,
			consentDetails: consentDetails,
			sharingStartAt: consentDetails.sharing_start_at,
			sharingEndAt: consentDetails.sharing_end_at,
			consentStatus: consentDetails.status,
			delete_my_data_after_used: consentDetails.delete_my_data_after_used
		}))
	}, [location]);

	const navigate = useNavigate();

	//eslint-disable-next-line
	const updateConsent = useCallback(props.api.updateConsent, []);
	//eslint-disable-next-line
	const withdrawConsent = useCallback(props.api.withdrawConsentById, []);
	const handleWithdrawConsent = () => {
		//Save and send to server
		(async () => {
			try {
				await updateConsent(id, state.delete_my_data_after_used);
				await withdrawConsent(id);
				navigate(props.dashboardPath + `/consent-details/${id}/withdraw-success`);
			} catch (error) {
				consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
			}
		})();
	};

	const handleShowHistory = () => {
		navigate(props.dashboardPath + `/consent-history/${id}`, {
			state: state.consentDetails
		});
	}

	// Reset to default configured principal on dashboard load
	useEffect(() => {
		(async () => {
			await props.loadPrincipalConfiguration(0);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <div className={"page-wrapper withdraw-consent-confirmation"}>
		<div className={"page-top"}>
			<Header
				generalSettings={props.generalSettingsConfig}
				principalLogoUrl={props.principalLogoUrl}
				headerBgImageUrl={props.headerBgImageUrl}
				isTrustedAdviser={props.isTrustedAdviser}
			/>
			<main>
				<Container maxWidth={"lg"}>
					<Breadcrumbs aria-label="breadcrumb">
						<Link color="inherit" href="/#" onClick={(e: any) => { e.preventDefault(); navigate(props.dashboardPath); }}>Dashboard</Link>
						<Link color="inherit" href="/#" onClick={(e: any) => { e.preventDefault(); navigate(props.dashboardPath + `/consent-details/${id}`); }}>{sanitize(state.consentDetails?.institution_name)}</Link>
						<span>Stop sharing</span>
					</Breadcrumbs>

					<ConsentDetailsHeader
						basePath={props.basePath}
						consentDetails={state.consentDetails}
						consentStatus={state.consentStatus}
						handleShowHistory={handleShowHistory}
						api={props.api}
					/>

					<Box mb={2}>
						<Link color={"secondary"} className={"go-back-link"} href={props.dashboardPath + `/consent-details/${id}/withdraw`}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
					</Box>

					<Grid container spacing={5}>
						<Grid item xs={12} md={6}>
							<Box mt={2}>
								<h2>Stop sharing</h2>
								<Typography component={'div'} color={"secondary"}><h4 className={"mt-20 font-weight-normal"}>Impacts to your service</h4></Typography>
								<p>We will stop collecting and using the data you shared with us below.</p>

								<p>Without this information we will be limited in our ability to help you track your budget.</p>
							</Box>
							<Box mt={4}>
								<h6>Direct debits and scheduled payments</h6>
								<p>If you stop sharing these details we will no longer be able to identify how much money you have been spending and the amount of regular payments you make.</p>

							</Box>
							<Box mt={4}>
								<h6>Transaction details</h6>
								<p>If you stop sharing these details we will no longer be able to identify how much money you have been spending.</p>
							</Box>
						</Grid>
					</Grid>

					<Box mt={2} mb={6}>
						<Typography component={'div'} color={"secondary"}><h4 className={"font-weight-normal"}>Do you want to stop sharing?</h4></Typography>
						<Grid container spacing={4}>
							<Grid item xs={12} md={6}>
								<ButtonBlock className={"text-align-right text-align-center-xs mt-10"}>
									<LinkButton targetlocation={props.dashboardPath + `/consent-details/${id}`} variant={"outlined"} color={"secondary"}>No, go back</LinkButton>
									<Button onClick={handleWithdrawConsent} variant={"contained"} color={"secondary"}>Yes, stop sharing</Button>
								</ButtonBlock>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</main>
		</div>

		<Footer generalSettingsConfig={props.generalSettingsConfig} isTrustedAdvisor={props.isTrustedAdviser} />

	</div>;
}

export default WithdrawConsentConfirmation;