import "../styles/Admin.css";

import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { AccountBalance, AccountBox, Lock, ManageAccounts, PeopleAlt, Receipt, Report, Summarize, Settings, Security, HelpCenter } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Button, Container, Menu, MenuItem } from "@mui/material";

import { isClientManager, isAdmin, isAdminOrClientManager, isAdminOrMortgageBroker } from "../../helpers/AuthUserHelper";
import CurrentUser from "../../models/CurrentUser";

interface AdminHeaderProps {
    loggedIn: boolean;
    basePath: string;
    title?: string;
    showMenu?: boolean;
    showNavigation?: boolean;
    onLogout: (() => void);
    currentUser?: CurrentUser;
    apiBaseUrl: string;
}

const AdminHeader = (props: AdminHeaderProps) => {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const welcomeName = props.currentUser?.name ? props.currentUser.name : '';

    return <>
        <header className="admin-header">
            <Container maxWidth={"lg"} className={"top-bar"}>
                <Link id="logo" to={props.basePath}><img src={"/images/logo-ods-white.png"} alt="" /></Link>
                {props.loggedIn && props.showMenu && props.showNavigation &&
                    <div className={"admin-menu-bar"}>
                        {(isAdminOrClientManager(props.currentUser?.role_id)) ?
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                                Manage{anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </Button> : ""}
                        <Menu
                            className={"admin-menu"}
                            anchorEl={anchorEl}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >

                            {(isAdmin(props.currentUser?.role_id) || isClientManager(props.currentUser?.role_id)) ?
                                <MenuItem onClick={() => {
                                    handleMenuClose();
                                    navigate(props.basePath + `/users-list`);
                                }}><PeopleAlt />&nbsp; Manage Users</MenuItem>
                                : ""}

                            {isAdmin(props.currentUser?.role_id) ?
                                <MenuItem onClick={() => {
                                    handleMenuClose();
                                    navigate(props.basePath + `/accounts-list`);
                                }}><ManageAccounts />&nbsp; Manage Accounts</MenuItem>
                                : ""}

                            {isAdmin(props.currentUser?.role_id) ?
                                <MenuItem onClick={() => {
                                    handleMenuClose();
                                    navigate(props.basePath + `/consents-list`);
                                }}><Lock />&nbsp; Manage Consents</MenuItem>
                                : ""}

                            {isAdmin(props.currentUser?.role_id) ?
                                <MenuItem onClick={() => {
                                    handleMenuClose();
                                    navigate(props.basePath + `/invoicing-list`);
                                }}><Receipt />&nbsp; Manage Invoices</MenuItem>
                                : ""}

                            {isAdminOrMortgageBroker(props.currentUser?.role_id) ?
                                <MenuItem onClick={() => {
                                    handleMenuClose();
                                    navigate(props.basePath + `/error-logs-list`);
                                }}><Report />&nbsp; Errors Reporting</MenuItem>
                                : ""}

                            {isClientManager(props.currentUser?.role_id) ?
                                <MenuItem onClick={() => {
                                    handleMenuClose();
                                    navigate(props.basePath + `/account`);
                                }}><AccountBox />&nbsp; Manage Account</MenuItem>
                                : ""}

                            {isAdmin(props.currentUser?.role_id) ?
                                <MenuItem onClick={() => {
                                    handleMenuClose();
                                    navigate(props.basePath + `/cdr-to-ddc-bank-mappings`);
                                }}><AccountBalance />&nbsp; Manage Bank Mappings</MenuItem>
                                : ""}

                            {isAdmin(props.currentUser?.role_id) ?
                                <MenuItem onClick={() => {
                                    handleMenuClose();
                                    navigate(props.basePath + `/general-settings`);
                                }}><Settings />&nbsp; System Settings</MenuItem>
                                : ""}

                            {isAdmin(props.currentUser?.role_id) ?
                                <MenuItem onClick={() => {
                                    handleMenuClose();
                                    window.open(props.apiBaseUrl + `/api/documentation`, '_blank', 'noreferrer')
                                }}><HelpCenter />&nbsp; Documentation</MenuItem>
                                : ""}

                            {isAdmin(props.currentUser?.role_id) ?
                                <MenuItem onClick={() => {
                                    handleMenuClose();
                                    navigate(props.basePath + `/brokers-list`);
                                }}><ManageAccounts />&nbsp; Manage Brokers</MenuItem>
                                : ""}

                            {isAdmin(props.currentUser?.role_id) ?
                                <MenuItem onClick={() => {
                                    handleMenuClose();
                                    navigate(props.basePath + `/mtls-certificates-list`);
                                }}><Security />&nbsp; Manage MTLS Certificates</MenuItem>
                                : ""}

                            {isAdmin(props.currentUser?.role_id) ?
                                <MenuItem onClick={() => {
                                    handleMenuClose();
                                    navigate(props.basePath + `/cdr-consumer-reporting`);
                                }}><Summarize />&nbsp; CDR Consumer Reporting</MenuItem>
                                : ""}

                            {isAdmin(props.currentUser?.role_id) ?
                                <MenuItem onClick={() => {
                                    handleMenuClose();
                                    navigate(props.basePath + `/cdr-clients-certificates-list`);
                                }}><Security />&nbsp; Manage CDR Client Certificates</MenuItem>
                                : ""}
                        </Menu>

                        {(props.currentUser) ? <span>Welcome {welcomeName}</span> : ''}
                        {(props.currentUser) ? <Link to={props.basePath + "/login"} color={"#fff"} onClick={(e: any) => { e.preventDefault(); props.onLogout(); }}>Log out</Link> : ''}
                    </div>
                }
            </Container>
        </header>
        <div className={"navigation-container"}>
            {(props.showNavigation)
                ? <div className="text-secondary">
                    {props.title && <h2 className={"page-title"}>{props.title}</h2>}
                </div>
                : ""
            }
        </div>
    </>
}

export default AdminHeader;
