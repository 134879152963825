import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchIcon from "@mui/icons-material/Search";
import { Button, FormControl, Grid, Hidden, InputBase, Link, MenuItem, Select, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import PrimaryBlock from "../../components/PrimaryBlock";
import { isAdmin } from "../../helpers/AuthUserHelper";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import ErrorLog from "../models/ErrorLog";

interface ErrorsListState {
    data: Array<ErrorLog>;
    /*
    dateFrom?: Date;
    dateTo?: Date;
    */
    searchSection: string;
    searchKeyword: string;
    offset: number;
    currentPage: number;
    pageCount: number;
}

interface ErrorLogsListProps {
    api: OpenBankingPlatformAPIInterface;
    userDetailsPath: string;
    hasApiError?: boolean;
    currentUser?: CurrentUser;
    basePath: string;
}


const ErrorLogsList = (props: ErrorLogsListProps) => {

    let perPage: number = 10;
    const DATE_FORMAT = "YYYY-MM-DD HH:mm:00 ZZ";
    const [state, setState] = useState<ErrorsListState>({
        data: [],
        /*
        dateFrom: moment().subtract(1, 'day').format(DATE_FORMAT),
        dateTo: moment().format(DATE_FORMAT),*/
        searchSection: 'customer_name',
        searchKeyword: '',
        offset: 0,
        currentPage: 0,
        pageCount: 0,
    });

    //const [selectedFromDate, handleFromDateChange] = useState<MaterialUiPickersDate | null>(moment());
    //const [selectedToDate, handleToDateChange] = useState<MaterialUiPickersDate | null>(moment());

    const [selectedFromDate, handleFromDateChange] = useState<Date | null>();
    const [selectedToDate, handleToDateChange] = useState<Date | null>();

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`${props.basePath}/home`);
    };

    const handlePageClick = (e: any) => {
        const selectedPage = e.selected;
        const newOffset = selectedPage * perPage;
        setState((state)=>({
            ...state,
            currentPage: selectedPage,
            offset: newOffset,
        }));
    }

    const checkDateRange = () => {
        /*
        let newDateTo = state.dateTo;
        let newDateFrom = state.dateFrom;
        if (state.dateTo === "") {
            newDateTo = (state.dateFrom === "") ? moment().format(DATE_FORMAT) : moment(newDateFrom).add(1, 'day').format(DATE_FORMAT); 
        }
        if (state.dateFrom === "") newDateFrom = moment(newDateTo).subtract(1, 'day').format(DATE_FORMAT);
        setState((state)=>({
            ...state,
            dateFrom: newDateFrom,
            dateTo: newDateTo,
        }))*/
        /*
        setState((state)=>({
            ...state,
            dateFrom: new Date(),
            dateTo: new Date(),
        }))*/
    }

    const doSearch = () => {
        checkDateRange();
        (async () => {
            try {
                let fromDate = selectedFromDate ? moment(selectedFromDate).format(DATE_FORMAT) : undefined;
                let toDate = selectedToDate ? moment(selectedToDate).format(DATE_FORMAT) : undefined;
                const loadedLogs = await getErrorLogs(state.currentPage+1, state.searchKeyword, state.searchSection, fromDate, toDate);
                perPage = loadedLogs.pagination;
                setState((state)=>({
                    ...state,
                    data: loadedLogs.dataset,
                    pageCount: Math.ceil(loadedLogs.total / perPage),
                }))
                if (state.currentPage > Math.ceil(loadedLogs.total / perPage)-1) {
                        setState((state)=>({
                            ...state,
                            currentPage: 0,
                        }));
                    }
            } catch (error) {
                /*
                // TODO Uncomment this code when fallback is removed
                setState((state)=>({
                    ...state,
                    apiHasReturnedAnError: true,
                }));
                navigate("/token-error");*/
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }

    useEffect(() => {
        doSearch();

        handleFromDateChange(null);
        handleToDateChange(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.currentPage]);

    //eslint-disable-next-line
    const getErrorLogs = useCallback(props.api.getErrorLogsList, []);

    return <div className="error-logs-list">
        {(isAdmin(props.currentUser?.role_id)) ?
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                </Grid>
            </Grid>
            : "" }
        <PrimaryBlock>
            <h2>Errors Reporting</h2>
            <Grid container spacing={2} mb={4}>
                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            label="From"
                            inputFormat="dd/MM/yyyy HH:mm"
                            renderInput={(props) => <TextField {...props} />}
                            value={selectedFromDate}
                            onChange={handleFromDateChange}
                            onError={console.log}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            label="To"
                            inputFormat="dd/MM/yyyy HH:mm"
                            renderInput={(props) => <TextField {...props} />}
                            value={selectedToDate}
                            onChange={handleToDateChange}
                            onError={console.log}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <Grid container className={"filters-bar"}>
                        <Grid item xs={6}>
                            <InputBase
                                fullWidth
                                id={"keyword-input"}
                                placeholder={"Enter search"}
                                inputProps={{ "aria-label": "Enter search" }}
                                onChange={event => {
                                console.log(event.target.value);
                                    setState({ ...state, searchKeyword: event.target.value.toLowerCase() });
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" className="w-100">
                                <Select
                                    id="section"
                                    name="section"
                                    value={state.searchSection}
                                    onChange={event => { setState({ ...state, searchSection: event.target.value as string}); }} >
                                    <MenuItem value={"customer_name"}>Customer Name</MenuItem>
                                    <MenuItem value={"customer_reference"}>Customer Reference</MenuItem>
                                    <MenuItem value={"principal"}>Principal</MenuItem>
                                    <MenuItem value={"data_holder"}>Data Holder</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <Button id={"search-btn"} variant={"contained"} color={"secondary"} 
                                disabled={state.searchKeyword.length < 3 && state.searchKeyword.length > 0}
                                onClick={() => doSearch()}
                            >
                                <SearchIcon/>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className={"institution-list"}>
                <Hidden mdDown>
                    <Grid container className={"table-head"}>
                        <Grid item xs={1}>Code</Grid>
                        <Grid item xs={4}>Message</Grid>
                        <Grid item xs={2}>Customer</Grid>
                        <Grid item xs={2}>Principal</Grid>
                        <Grid item xs={2}>Data Holder</Grid>
                        <Grid item xs={1}>Created</Grid>
                    </Grid>
                </Hidden>
                {state.data && state.data.map((item, i) => (
                    <Grid container className={"logs-list-item"} mt={2} key={i}>
                        <Grid item xs={1}>{item.error_code}</Grid>
                        <Grid item xs={4}>
                            {item.error_message}
                            {(item.uuid) ? <div className={"comments"}>Error ID: {item.uuid}</div> : ''}
                        </Grid>
                        <Grid item xs={2}>{(item.customer) ? item.customer : ''}</Grid>
                        <Grid item xs={2}>{(item.principal) ?  item.principal : ''}</Grid>
                        <Grid item xs={2}>{(item.data_holder) ? item.data_holder : ''}</Grid>
                        <Grid item xs={1}>{moment.utc(item.timestamp).local().format('YYYY-MM-DD hh:mm:ss A')}</Grid>
                    </Grid>
                ))}
                {state.pageCount > 1 &&
                    <ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        pageCount={state.pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                }
                <div className={"comments"}>Notes: number of fetched records is limited to 10,000.</div>
            </div>
        </PrimaryBlock>
    </div>
}

export default ErrorLogsList;