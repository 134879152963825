import React, { useEffect, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import {Box, Button, Container, Grid} from "@mui/material";

import SwitchWithLabel from "../../components/SwitchWithLabel";
import CurrentUser from "../../models/CurrentUser";
import Configuration from "../models/Configuration";
import LightTooltip from "./LightTooltip";
import DashboardSettings from "../../models/DashboardSettings";
import ButtonBlock from "../../components/ButtonBlock";

interface EditDashboardSettingsProps {
    basePath: string,
    configuration: Configuration,
    mode: "edit" | "view",
    onConfigurationUpdate?: (newConfiguration: Configuration, mode: 'view' | 'edit') => void,
    onCancel?: () => void,
    currentUser?: CurrentUser,
}

interface DashboardSettingsState
{
    dashboardCopy: DashboardSettings;
}

const ConfigurationDashboardSettings = (props: EditDashboardSettingsProps) => {
    const editMode = (props.mode === "edit");

    const [state, setState] = useState<DashboardSettingsState>({
        dashboardCopy: { ...props.configuration.dashboard },
    });

    useEffect(() => {
        setState((state) => ({
            ...state,
            dashboardCopy: { ...props.configuration.dashboard }
        }))
    }, [props.mode, props.configuration.dashboard]);


    const setDashboardSettings = (newDashboardSetting: DashboardSettings) => {
        setState({
            ...state,
            dashboardCopy: newDashboardSetting,
        });
    }

    const handleSave = () => {
        const newConfiguration: Configuration = {
            ...props.configuration,
            dashboard: state.dashboardCopy,
        }

        props.onConfigurationUpdate && props.onConfigurationUpdate(newConfiguration, 'view');
    };

    const handleCancel = () => {
        props.onCancel && props.onCancel();
    }

    const dasboard = state.dashboardCopy;
    const form =
        <form className={"admin-form pre-consent-form mt-50"} autoComplete={"off"}>
            <div>
                <Box mb={2}>
                    <h4>Display Connect Institution Button</h4>
                </Box>
                <Grid container className={"mt-30"}>
                    <Grid item xs={12} sm={4} md={3}>
                        <label>Display Connect Institution Button<LightTooltip
                            title={<img className="tooltip-image" src="/images/admin/consent/Active_consent_duration_explanatory_text.png" alt="" />}
                            placement="right-start" arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                    </Grid>
                    <Grid item xs={12} sm={7} md={5}>
                        <SwitchWithLabel
                            checked={dasboard.includeConnectInstitutionButton??false}
                            disabled={!editMode}
                            onChange={value => {
                                setDashboardSettings({...dasboard, includeConnectInstitutionButton: value})
                            }}>
                            {editMode ? "Include" : dasboard.includeConnectInstitutionButton ? "Include" : "Exclude"}
                        </SwitchWithLabel>
                    </Grid>
                </Grid>
            </div>

            {editMode &&
            <Grid container className={"mt-20"}>
                <Grid item xs={12}>
                    <ButtonBlock className={"text-align-center"}>
                        <Button onClick={() => handleCancel()} variant={"outlined"} color={"secondary"}>Cancel</Button>
                        <Button onClick={() => handleSave()} variant={"contained"} color={"secondary"}>Save</Button>
                    </ButtonBlock>
                </Grid>
            </Grid>
            }
        </form>;

    return <Container>
        <h2 className={"page-title"}>Consumer Dashboard Configurations</h2>
        <h5 className="text-large">{props.configuration.generalSettings.principalName}</h5>

        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={11} md={8}>
                The Consumer Dashboard display all consents established by the consumer (active or withdrawn).
            </Grid>
        </Grid>

        {form}
    </Container>
}

export default ConfigurationDashboardSettings;