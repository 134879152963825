import React from "react";
import PrincipalBillingConfiguration from "../../models/PrincipalBillingConfiguration";
import {Grid} from "@mui/material";
import LightTooltip from "../LightTooltip";
import InfoIcon from "@mui/icons-material/Info";
import EditableText from "../EditableText";
import BillingModelPerCustomer3 from "../../models/billingmodels/BillingModelPerCustomer3";

interface BillingModelPerCustomer3ConfigProps {
    modelConfiguration?: PrincipalBillingConfiguration;
    onBillingModelUpdate: (config: PrincipalBillingConfiguration) => void;
}

const BillingModelPerCustomer3Config = (props: BillingModelPerCustomer3ConfigProps) => {
    const billingModelType = 'BillingModelPerCustomer3';

    const activeCustomerPerMonthCost = props.modelConfiguration?.billingModelPerCustomer3?.active_customer_per_month_cost;

    return <>
        <h4>Model 3</h4>

        <Grid container className={"mt-10"}>
            <Grid item xs={12} sm={7} md={5}>
                <label>Active customer<LightTooltip title="Active customer" placement="right-start"
                                                           arrow><InfoIcon
                    color={"secondary"}/></LightTooltip></label>
            </Grid>
            <Grid item xs={12} sm={4} md={3} className={"background-grey"}>
                <EditableText
                    type={"number"}
                    editMode={true}
                    name={"active_customer_per_month_cost"}
                    value={activeCustomerPerMonthCost !== undefined ? activeCustomerPerMonthCost as unknown as string : ""}
                    eventHandlers={{
                        onChange: (e: any) => {
                            const newBillingModel: BillingModelPerCustomer3 = {
                                ...props.modelConfiguration?.billingModelPerCustomer3,
                                active_customer_per_month_cost: e.target.value
                            }
                            const newBillingModelConfiguration: PrincipalBillingConfiguration = {
                                ...props.modelConfiguration,
                                selectedBillingModelType: billingModelType,
                                billingModelPerCustomer3: newBillingModel
                            };
                            props.onBillingModelUpdate(newBillingModelConfiguration);
                        }
                    }}
                />
            </Grid>
        </Grid>
    </>
}

export default BillingModelPerCustomer3Config;