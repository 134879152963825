import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Link, TextField } from "@mui/material";

import PrimaryBlock from "../../components/PrimaryBlock";
import { isAdmin } from "../../helpers/AuthUserHelper";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";

interface SystemSettingsProps {
    api: OpenBankingPlatformAPIInterface;
    userDetailsPath: string;
    hasApiError?: boolean;
    currentUser?: CurrentUser;
    basePath: string;
}

interface SettingsProps {
    id: number;
    name: string;
    caption: string;
    value: string;
}

const EditSystemSettings = (props: SystemSettingsProps) => {

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(`${props.basePath}/home`);
    };

    const [settingsData, setSettingsData] = useState<Array<SettingsProps>>([]);

    const handleChange = (newSettings: SettingsProps) => {
        const updatedObj = settingsData.map((item) =>
            item.id === newSettings.id ? newSettings : item
        );
        setSettingsData(updatedObj)
    }

    //eslint-disable-next-line
    const updateSettings = useCallback(props.api.updateSystemSettings, []);
    const handleSettingsSave = () => {
        (async () => {
            await Promise.all(settingsData.map(async (item): Promise<SettingsProps> => {
                await updateSettings(item.name, item.value);
                return item;
            }));
        })();
    }

    //eslint-disable-next-line
    const getSettings = useCallback(props.api.getSystemSettings, []);
    useEffect(() => {
        (async () => {
            try {
                const data = await getSettings();
                setSettingsData(data);
            } catch (error) {
                //navigate("/token-error");
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
        },
        //eslint-disable-next-line
        []
    )

    return <div className="edit-general-settings">
        {(isAdmin(props.currentUser?.role_id)) ?
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                </Grid>
            </Grid>
            : "" }
        <PrimaryBlock>
            <h2>System Settings</h2>
            <form className={"admin-form user-settings-form"} autoComplete={"off"}>
                {settingsData && settingsData.map((item:SettingsProps, i) => {
                    return (
                        <Grid container className={"form-group"} key={i}>
                            <Grid item xs={12} sm={4} md={3} className={"align-self-center"}>
                                <label>{item.caption}</label>
                            </Grid>
                            <Grid item xs={12} sm={7} md={5}>
                                <TextField
                                    required
                                    variant="outlined"
                                    id="name-input"
                                    name="name-input"
                                    className="w-100"
                                    placeholder="Name"
                                    value={item.value}
                                    onChange={(event) => handleChange({
                                        ...item,
                                        value: event.target.value
                                    })}
                                />
                            </Grid>
                        </Grid>
                    )
                })}
                <Button variant={"contained"} color={"secondary"} onClick={handleSettingsSave}>Save</Button>
            </form>

        </PrimaryBlock>
    </div>
}

export default EditSystemSettings;