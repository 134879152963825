import {Container, Link} from "@mui/material";

import React from "react";
import moment from "moment";

const AdminFooter = () => {
    return (
        <footer className="admin-footer">
            <Container maxWidth={"lg"}>
                Powered by <Link color={"inherit"} underline={"always"} href="https://illion.opendatasolutions.com">illion.opendatasolutions.com</Link> &nbsp; &copy; illion Open Data Solutions {moment().local().format('YYYY')}.
            </Container>
        </footer>
    )
}

export default AdminFooter;
