import React, { PropsWithChildren } from "react";

import { Box, useTheme } from "@mui/material";

interface HighlightBlockProps {
    className?: string;
    children?: React.ReactNode;
}

const HighlightBlock = (props: PropsWithChildren<HighlightBlockProps>) => {
    const theme = useTheme();
    const style = {
        infoBackground: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText
        }
    };
    
    return <Box component={"div"} className={`${props.className} highlight-block`} sx={style.infoBackground}>{props.children}</Box>;
}

export default HighlightBlock;
