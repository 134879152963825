interface Font {
    fontFamily: string;
    type: "System font" | "Google font";
}

export const supportedFonts: Array<Font> = [
    {
        fontFamily: "Arial",
        type: "System font"
    },
    {
        fontFamily: "Courier New",
        type: "System font"
    },
    {
        fontFamily: "Georgia",
        type: "System font"
    },
    {
        fontFamily: "Garamond",
        type: "Google font",
    },
    {
        fontFamily: "Merriweather",
        type: "Google font",
    },
    {
        fontFamily: "Montserrat",
        type: "Google font",
    },
    {
        fontFamily: "Mulish",
        type: "Google font",
    },
    {
        fontFamily: "Nunito Sans",
        type: "Google font",
    },
    {
        fontFamily: "Open Sans",
        type: "Google font",
    },
    {
        fontFamily: "Oswald",
        type: "Google font",
    },
    {
        fontFamily: "Poppins",
        type: "Google font",
    },
    {
        fontFamily: "PT Sans",
        type: "Google font",
    },
    {
        fontFamily: "PT Serif",
        type: "Google font",
    },
    {
        fontFamily: "Source Sans",
        type: "Google font",
    },
    {
        fontFamily: "Corinthia",
        type: "Google font",
    }
];

export function isSupportedGoogleFont(fontFamily: string) {
    const name = fontFamily.trim().toLowerCase();
    let result = false;
    supportedFonts.forEach(item => {
        if(item.fontFamily.toLowerCase() === name && item.type === "Google font") {
            result = true;
        }
    })
    return result;
};