import React from "react";
import PrincipalBillingConfiguration from "../models/PrincipalBillingConfiguration";
import BillingModelPerBank2Config from "./billingmodels/BillingModelPerBank2Config";
import BillingModelPerBank4Config from "./billingmodels/BillingModelPerBank4Config";
import BillingModelPerBank21Config from "./billingmodels/BillingModelPerBank21Config";
import BillingModelPerBank51Config from "./billingmodels/BillingModelPerBank51Config";
import BillingModelPerCustomer3Config from "./billingmodels/BillingModelPerCustomer3Config";
import BillingModelHybrid5Config from "./billingmodels/BillingModelHybrid5Config";
import BillingModelHybrid6Config from "./billingmodels/BillingModelHybrid6Config";
import BillingModelHybrid11Config from "./billingmodels/BillingModelHybrid11Config";


interface BillingModelConfigurationCardProps {
    billingModelSelection?: string;
    modelConfiguration?:  PrincipalBillingConfiguration;
    onBillingModelUpdate: (config: PrincipalBillingConfiguration) => void;
}


const BillingModelConfigurationCard = (props: BillingModelConfigurationCardProps) => {

    return <>
        {(props.billingModelSelection === "BillingModelPerBank2") ?
            <><BillingModelPerBank2Config
                modelConfiguration={props.modelConfiguration}
                onBillingModelUpdate={props.onBillingModelUpdate}
            /></>
        : ""}
        {(props.billingModelSelection === "BillingModelPerBank4") ?
            <><BillingModelPerBank4Config
                modelConfiguration={props.modelConfiguration}
                onBillingModelUpdate={props.onBillingModelUpdate}
            /></>
            : ""}
        {(props.billingModelSelection === "BillingModelPerBank21") ?
            <><BillingModelPerBank21Config
                modelConfiguration={props.modelConfiguration}
                onBillingModelUpdate={props.onBillingModelUpdate}
            /></>
            : ""}
        {(props.billingModelSelection === "BillingModelPerBank51") ?
            <><BillingModelPerBank51Config
                modelConfiguration={props.modelConfiguration}
                onBillingModelUpdate={props.onBillingModelUpdate}
            /></>
            : ""}

        {(props.billingModelSelection === "BillingModelPerCustomer3") ?
            <><BillingModelPerCustomer3Config
                modelConfiguration={props.modelConfiguration}
                onBillingModelUpdate={props.onBillingModelUpdate}
            /></>
            : ""}

        {(props.billingModelSelection === "BillingModelHybrid5") ?
            <><BillingModelHybrid5Config
                modelConfiguration={props.modelConfiguration}
                onBillingModelUpdate={props.onBillingModelUpdate}
            /></>
            : ""}
        {(props.billingModelSelection === "BillingModelHybrid6") ?
            <><BillingModelHybrid6Config
                modelConfiguration={props.modelConfiguration}
                onBillingModelUpdate={props.onBillingModelUpdate}
            /></>
            : ""}
        {(props.billingModelSelection === "BillingModelHybrid11") ?
            <><BillingModelHybrid11Config
                modelConfiguration={props.modelConfiguration}
                onBillingModelUpdate={props.onBillingModelUpdate}
            /></>
            : ""}
    </>
}

export default BillingModelConfigurationCard;