import React, { useCallback, useEffect, useState } from "react";
import { matchPath } from "react-router";
import { Route, Routes as RouterSwitch, useNavigate, useParams, useLocation } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import ConfigurationDashboard from "../components/ConfigurationDashboard";
import Configuration from "../models/Configuration";
import UploadFile from "../models/UploadFile";
import CreateConfiguration from "./CreateConfiguration";
import ViewEditConfiguration from "./ViewEditConfiguration";
import {
    isClientManager,
    isDeveloper,
    isStandardUser,
    isAdmin,
    isAdminOrClientManager
} from "../../helpers/AuthUserHelper";
import NotFound from "./NotFound";
import ImagePreviews from "../models/ImagePreviews";

interface UpdateAccountProps
{
    adminObApi: OpenBankingPlatformAPIInterface;
    basePath: string,
    currentUser?: CurrentUser,
}

interface UpdateAccountState {
    backgroundOperationInProgress: boolean;
    configurations: Array<Configuration>;
    loggedIn: boolean;
    isLoading: boolean;
    loginError: boolean;
    isLoadingConfiguration: boolean;
    accountFound: boolean;
    isTrustedAdvisor: boolean;
    imagePreviews?: ImagePreviews;
}

const UpdateAccount = (props: UpdateAccountProps) => {
    let { id } = useParams<{ id: any }>();

    // If this the current user is client manager (i.e. has an associated account ID)
    // then just load the page for the account they manage but without exposing the account ID
    // on the URL path
    if (!id && props.currentUser?.account_id) {
        id = props.currentUser.account_id
    }

    const accountId = parseInt(id);

    const [state, setState] = useState<UpdateAccountState>({
        configurations: [],
        backgroundOperationInProgress: false,
        loggedIn: false,
        isLoading: true,
        loginError: false,
        isLoadingConfiguration: false,
        isTrustedAdvisor: false,
        accountFound: true,
    });

    const navigate = useNavigate();
    const location = useLocation();

    const [mtlsCerts, setMtlsCerts] = useState<Array<{ name: string; identifier: number; }>>([]);

    //eslint-disable-next-line
    const getMtlsCertificates = useCallback(props.adminObApi.listMtlsCertificates, []);
    useEffect(() => {
            (async() => {
                if (isAdmin(props.currentUser?.role_id)) {
                    try {
                        const loadedMtlsCertificates = await getMtlsCertificates();
                        setMtlsCerts(loadedMtlsCertificates.map((el) => ({ name: el.name, identifier: el.id})));
                    } catch (error) {
                        adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
                    }
                }
            })();
        },
        //eslint-disable-next-line
        []
    )

    // eslint-disable-next-line
    const getPrincipalAdminConfigurationList = useCallback(props.adminObApi.getPrincipalAdminConfigurationListByAccount, []);
    const refreshConfigurationList = () => {
        (async () => {
            try {
                //if (id !== undefined) {
                    // @ts-ignore
                    var useAccountId = isAdmin(props.currentUser?.role_id) ? accountId : undefined;
                    const loadedConfigurationList = await getPrincipalAdminConfigurationList(useAccountId);
                    const newConfigurationArray = loadedConfigurationList.map((loadedConfiguration) => {
                        const newConfigSet: Configuration = loadedConfiguration.principal_admin_config_fields;
                        newConfigSet.principal_id = loadedConfiguration.principal_id;
                        return newConfigSet;
                    });
                    setState((state) => ({
                        ...state,
                        configurations: newConfigurationArray,
                        isLoading: false,
                    }));
                //}
            } catch (error) {
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }

    const loadAccountView = () => {
        if (props.currentUser) {
            const currentRoutePath = location.pathname;
            var match1 = matchPath(`${props.basePath}/account/configuration`, currentRoutePath);
            var match2 = matchPath(`${props.basePath}/account/configuration/:id`, currentRoutePath);
            var match3 = matchPath(`${props.basePath}/account`, currentRoutePath);
            if (isAdmin(props.currentUser?.role_id)) {
                // @ts-ignore
                if (location.state && location.state.isNewAccount) {
                    return;
                }
                refreshConfigurationList();
                setState({
                    ...state,
                    accountFound: true
                })

            } else if (isClientManager(props.currentUser?.role_id)) {
                if (match1 || match2 || match3) {
                    refreshConfigurationList();
                    setState({
                        ...state,
                        accountFound: true
                    });
                } else {
                    setState({
                        ...state,
                        accountFound: false
                    });
                }
            } else if (isStandardUser(props.currentUser.role_id) || isDeveloper(props.currentUser.role_id)) {
                if (match2 || match3) {
                    refreshConfigurationList();
                    setState({
                        ...state,
                        accountFound: true
                    });
                } else {
                    setState({
                        ...state,
                        accountFound: false
                    });
                }
            } else {
                navigate(`${props.basePath}/error`);
            }
        }
    }

    useEffect(() => {
        loadAccountView();
    },
        //eslint-disable-next-line
        [location.key])

    useEffect(() => {
            loadAccountView();
            },
        //eslint-disable-next-line
        [props.currentUser]
    )
    
    useEffect(() => {
        if (location.state && "isTrustedAdvisor" in (location.state as any)) {
            setState((prevState) => ({
                ...prevState,
                isTrustedAdvisor: (location.state as any).isTrustedAdvisor as boolean
            }));
        }
    }, [location.state]);

    // eslint-disable-next-line
    const updateExistingConfiguration = useCallback(props.adminObApi.updateExistingConfiguration, []);
    const handleUpdateConfiguration = (configurationId: number, newConfiguration: Configuration, fileUploads?: Array<UploadFile>, imagePreviews?: ImagePreviews) => {
        if (!state.configurations) {
            return;
        }
        (async () => {
            setState({
                ...state,
                isLoadingConfiguration: true,
            })
            const updatedConfiguration = await updateExistingConfiguration(newConfiguration, newConfiguration.principal_id);

            const newConfigurations = state.configurations?.map((configuration) =>
                (
                    (configuration.id === configurationId)
                        ? updatedConfiguration
                        : configuration
                )
            );

            setState({
                ...state,
                isLoadingConfiguration: false,
                configurations: newConfigurations,
                imagePreviews: imagePreviews
            });

            if (fileUploads) {
                handleFilesUpload(fileUploads);
            }
        })();
    };

    const handleFilesUpload = (uploadFiles: Array<UploadFile>) => {
        let formData = new FormData();
        uploadFiles.map((uploadFile) => {
            formData.append(uploadFile.name + 'Path', uploadFile.path);
            formData.append(uploadFile.name + 'File', uploadFile.file);
            return uploadFile;
        });
        handleUploadFile(formData);
    }

    //eslint-disable-next-line
    const saveUploadedFile = useCallback(props.adminObApi.saveUploadFile, []);
    const handleUploadFile = (data: FormData) => {
        (async (data) => {
            try {
                await saveUploadedFile(data)
            } catch (error) {
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })(data);
    }

    // eslint-disable-next-line
    const createNewConfiguration = useCallback(props.adminObApi.createNewConfiguration, []);
    const handleCreateConfiguration = (name: string, description: string, accountId?: number) => {
        setState({
            ...state,
            backgroundOperationInProgress: true,
        });

        (async () => {
            const newConfiguration = await props.adminObApi.createConfiguration(0, name, description, state.isTrustedAdvisor);

            if (!state.configurations) {
                return;
            }

            const newConfigurations = state.configurations.slice();
            //temporarily set new id for config and may be removed when real api implemented if id is wrong
            newConfiguration.id = newConfigurations.length;
            const loadedNewConfiguration = await createNewConfiguration(newConfiguration, accountId);
            newConfigurations.push(loadedNewConfiguration);

            setState({
                ...state,
                configurations: newConfigurations,
                backgroundOperationInProgress: false,
            });
            const newId = newConfiguration.id;

            if (isAdmin(props.currentUser?.role_id)) {
                navigate(`${props.basePath}/account/${accountId}/configuration/${newId}`);
            } else {
                navigate(`${props.basePath}/account/configuration/${newId}`);
            }
        })();
    };

    return <>
        {(state.accountFound) ?
            <div>
            {(state.configurations) ?
                <RouterSwitch>
                    <Route path={"/"} element={
                        <ConfigurationDashboard
                            adminObApi={props.adminObApi}
                            accountId={accountId}
                            configurations={state.configurations}
                            isLoading={state.isLoading}
                            basePath={props.basePath}
                            currentUser={props.currentUser}
                        />
                    } />

                    {(isAdminOrClientManager(props.currentUser?.role_id)) &&
                        <Route path={"configuration"} element={
                            <CreateConfiguration
                                adminObApi={props.adminObApi}
                                configurations={state.configurations}
                                accountId={accountId}
                                onCreateConfiguration={handleCreateConfiguration}
                                currentUser={props.currentUser}
                                basePath={props.basePath}
                            />
                        } />
                    }

                    {(state.configurations.length > 0) &&
                    <Route path={"configuration/:configId"} element={
                        <ViewEditConfiguration
                            configurations={state.configurations}
                            onUpdateConfiguration={handleUpdateConfiguration}
                            refreshConfigurationList={refreshConfigurationList}
                            basePath={props.basePath}
                            adminObApi={props.adminObApi}
                            currentUser={props.currentUser}
                            accountId={id}
                            isLoading={state.isLoadingConfiguration}
                            mtlsCertOptions={mtlsCerts}
                            imagePreviews={state.imagePreviews}
                        />
                    } />
                    }
                </RouterSwitch>
                : <React.Fragment><LoadingSpinner position={"fixed"} overlay /></React.Fragment>
            }
            </div> : <NotFound />
        }
    </>;
}

export default UpdateAccount;
