import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PrimaryBlock from "../components/PrimaryBlock";
import DataHolder from "../models/DataHolder";

interface ConsentApiAuthRedirectProps {
    api: any;
    dataHolderBrand?: DataHolder;
    consentExists?: boolean;
    apiAuthBasePath: string;
    basePath: string;
}

const ConsentApiAuthRedirect = (props: ConsentApiAuthRedirectProps) => {
    const bankfeedsBaseUrl = process.env.REACT_APP_BANKFEEDS_API_ENDPOINT_URL;
    const [iframeUrl, setIframeUrl] = useState('');
    const [callbackCompleted, setCallbackCompleted] = useState(false);
    const navigate = useNavigate();

    //eslint-disable-next-line
    const getInitialiseAuth = useCallback(props.api.getInitialiseAuth, []);
    //eslint-disable-next-line
    const getInitialisePAR = useCallback(props.api.getInitialisePAR, []);
    useEffect(() => {
        (async () => {
            if (props.dataHolderBrand) {
                sessionStorage.setItem('dataHolderBrand', JSON.stringify(props.dataHolderBrand));
                try {
                    //Call api based on consentExists property
                    //const loadedParRequest = props.consentExists ? await getInitialisePAR(props.dataHolderBrand?.id) : await getInitialiseAuth(props.dataHolderBrand?.id, {is_multibank: true});
                    const loadedParRequest = await getInitialisePAR(props.dataHolderBrand?.id);
                    if(loadedParRequest.is_cdr){
                        const authoriseUrl = loadedParRequest.authorization_endpoint+'?'+loadedParRequest.request_str;
                        window.location.assign(authoriseUrl);
                    }else{
                        navigate(props.apiAuthBasePath, {
                            state: {
                                iframeUrl: bankfeedsBaseUrl + loadedParRequest.iframeUrl,
                            }
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        })()
        //eslint-disable-next-line
    }, []);

    // Scroll to the top of the page once
    useEffect(() => {
        window.scrollTo(0, 0);
        window.parent.postMessage({ action: "OB_SCROLL_TO_TOP" }, "*");
    }, []);

    const location = useLocation();
    useEffect(() => {
        // @ts-ignore
        if(location.state && location.state.iframeUrl){
            // @ts-ignore
            setIframeUrl(location.state.iframeUrl);
        }
    }, [location]);

    useEffect(() => {
        const handleIframeTask = (e:any) => {
            if (e.origin !== process.env.REACT_APP_BANKFEEDS_API_ENDPOINT_URL) {
                return; 
            } else {
                if (!callbackCompleted) {
                    setCallbackCompleted(true);
                    const consentData = JSON.parse(e.data);
                
                    if(consentData.status === 'COMPLETE'){
                        navigate(props.basePath + "/consent/success", {
                            state: {
                                consentData: consentData.data.data.standardised_data,
                                consentId: consentData.data.data.consent_id,
                            }
                        })
                    }
                }
            }
        };

        window.addEventListener('message', handleIframeTask);
    });

    if(!iframeUrl){
        return <></>
    }

    return <div className={"page-wrapper bank-feeds-request"}>
                <div className={"page-top"}>
                    <main>
                        <PrimaryBlock>               
                            <h2>Connect to online banking via illion BankStatements</h2>
                            <iframe title={"BankFeeds"} src={iframeUrl} width={"100%"} height={640} style={{ border: "none", borderWidth: 0 }}></iframe>
                        </PrimaryBlock>
                    </main>
                </div>
            </div>
}

export default ConsentApiAuthRedirect;