import React, { PropsWithChildren, ReactElement } from "react";

import { Box, useTheme } from "@mui/material";

interface MessageBoxProps {
    icon: ReactElement;
    background: "red" | "green" | "grey";
    className?: string;
    children?: React.ReactNode;
}

const MessageBox = (props: PropsWithChildren<MessageBoxProps>) => {
    const theme = useTheme();
    const styles = {
        "red": {
            backgroundColor: "#F4C3C3",
            color: "#BA0000"
        },
        "green": {
            backgroundColor: theme.palette.success.main,
            color: theme.palette.success.contrastText
        },
        "grey": {
            backgroundColor: "#0000001A",
            color: "#000000"
        },
        text: {
            backgroundColor: "none"
        }
    };
    
    return <Box className={`message-box ${props.className ?? ""}`} sx={styles[props.background]}>
        <Box component={"div"} id="icon" sx={[styles[props.background], styles.text]}>{props.icon}</Box>
        {props.children}
    </Box>
}

export default MessageBox;