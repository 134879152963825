import React from "react";
import {sanitize} from "../helpers/HtmlSanitizerHelper";
import GeneralSettings from "../models/GeneralSettings";
import {hasRichTextSpecifiedForField} from "../helpers/InputFieldValidationHelper";
import EditableRichText from "../admin/components/EditableRichText";
import { Property } from 'csstype';
import { Box } from "@mui/material";

interface FooterProps {
    bottomFixed?: string;
    generalSettingsConfig?: GeneralSettings;
    isTrustedAdvisor?: boolean;
}
const Footer = (props: FooterProps) => {
    const footerBottomClass = props.bottomFixed === "true" ? "footer-bottom" : "footer-custom";

    const footerTextAlignment = props.generalSettingsConfig?.footerTextAlignment ?? 'center';

    const getTextAlign = (align: string):Property.TextAlign => {
        var output = 'center';
        switch (align) {
            case 'left':
                output = 'left';
                break;
            case 'center':
                output = 'center';
                break;
            case 'right':
                output = 'right';
                break;
        }

        return output as Property.TextAlign;
    }
    
    const divStyle = { 
                        textAlign: getTextAlign(footerTextAlignment),  
                        backgroundColor: props.generalSettingsConfig?.footerBackgroundColor ?? 'transparent',
                        color: props.generalSettingsConfig?.footerTextColor,
                        fontFamily: props.generalSettingsConfig?.footerFontFamily
                     };
                        

    return <div className={"footer page-bottom"} style={divStyle}>
            <Box>
                {props.isTrustedAdvisor
                    ? <img src="/images/logo-illion-bank-statements.png" alt="" style={{ maxWidth: "200px", maxHeight: "45px", marginBottom: "15px", marginTop: "2em" }} />
                    : <img src="/images/logo-illion.png" alt="" style={{ maxWidth: "200px", maxHeight: "45px", marginBottom: "20px", marginTop: "2em" }} />
                }
            </Box>
            <footer className={`footer ${footerBottomClass}`}>
                {(props.generalSettingsConfig?.footerText && hasRichTextSpecifiedForField(props.generalSettingsConfig?.footerText)) ?
                    <EditableRichText
                        editMode={false}
                        value={props.generalSettingsConfig.footerText}
                    /> :
                    (props.isTrustedAdvisor
                        ? <>
                            <a href="https://bankstatements.com.au/about/privacy">Privacy Policy</a> |{" "}
                            <a href="https://www.illion.com.au/consumer-data-right-policy/">Consumer Data Right Policy</a> |{" "} 
                            <a href="https://www.illion.com.au/terms-of-use/">Terms of Use</a> |{" "}
                            <a href="https://bankstatements.com.au/about/security">Security</a><br/>
                            &copy; Copyright illion Australia Pty Ltd is the owner of illion BankStatements<br/>
                            ABN 95006399677
                        </>
                        : <>
                            Please read our <a href={props.generalSettingsConfig?.cdrPolicyURL}>Consumer Data Right policy</a> for some information on how {sanitize(props.generalSettingsConfig?.principalName)} handles your data.
                        </>
                    )
                }
            </footer>
        </div>;
}

export default Footer;
