import React from 'react';
import { Grid }  from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import LightTooltip from "./LightTooltip";
import CustomColorPicker from './CustomColorPicker';

const ColorPickerComponent = (
    { 
        title, 
        tooltipImageSrc, 
        color, 
        isCustomColor, 
        onColorChange, 
    }:{
        title: string,
        tooltipImageSrc?: string,
        color?: string,
        isCustomColor?: boolean,
        onColorChange: (color: any) => void
        }) => (
    <Grid container className={"form-group"}>
        <Grid item xs={12} sm={6} className={"align-self-center"}>
            <label>{title}<LightTooltip
                title={<img className="tooltip-image" src={tooltipImageSrc} alt="" />}
                placement="right-start" arrow><InfoIcon color={"secondary"} />
                </LightTooltip>
            </label>
        </Grid>
        <Grid item xs={12} sm={6} className={"colorpicker-wrap"}>
            {(isCustomColor) ? 
                <CustomColorPicker
                    color={color}
                    onChangeComplete={onColorChange}
                />
                : color
            }
        </Grid>
    </Grid>
);

export default ColorPickerComponent;