import React, { PropsWithChildren } from "react";

import { Container } from "@mui/material";

interface PrimaryBlockProps {
    className?: string;
    children?: React.ReactNode;
}

const PrimaryBlock = (props: PropsWithChildren<PrimaryBlockProps>) => {
    return <div className={`primary-block ${props.className || ""}`}>
        <Container>
            <>
                {props.children}
            </>
        </Container>
    </div>
}

export default PrimaryBlock;
