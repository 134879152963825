import { createTheme, Shadows, ThemeOptions } from "@mui/material/styles";

import { blendColor, LightMode } from "./helpers/ThemeHelper";
import GeneralSettings from "./models/GeneralSettings";
import { defaultTheme } from "./Theme";

interface ThemeBuilderProps {
    theme: string;
    generalSettings?: GeneralSettings;
}

const ThemeBuilder = (props: ThemeBuilderProps) => {
    if (props.theme !== "Custom" || !props.generalSettings) return createTheme(defaultTheme);

    const headingFontFamily = props.generalSettings?.headingFontFamily ?? "Nunito Sans";
    const bodyTextFontFamily = props.generalSettings?.bodyTextFontFamily ?? "Nunito Sans";
    const footerFontFamily = props.generalSettings?.footerFontFamily ?? "Nunito Sans";

    const backgroundColor = props.generalSettings?.backgroundColor ?? LightMode.backgroundColor;
    const textColor = props.generalSettings?.textColor ?? LightMode.textColor;

    const footerBackgroundColor = props.generalSettings.footerBackgroundColor ?? LightMode.backgroundColor;
    const footerTextColor = props.generalSettings.footerTextColor ?? LightMode.textColor;
    const footerTextAlignment = props.generalSettings.footerTextAlignment ?? 'center';

    const primaryColor = props.generalSettings?.primaryColor ?? LightMode.primaryColor;
    const primaryBGTextColor = props.generalSettings?.primaryBGTextColor ?? LightMode.primaryBGTextColor;
    
    const secondaryColor = props.generalSettings?.secondaryColor ?? LightMode.secondaryColor;
    const secondaryBGTextColor = props.generalSettings?.secondaryBGTextColor ?? LightMode.secondaryBGTextColor;
    
    const tertiaryColor = props.generalSettings?.tertiaryColor ?? LightMode.tertiaryColor;
    const tertiaryBGTextColor = props.generalSettings?.tertiaryBGTextColor ?? LightMode.tertiaryBGTextColor;
    
    const successColor = props.generalSettings?.successColor ?? LightMode.successColor;
    const successBGTextColor = props.generalSettings?.successBGTextColor ?? LightMode.successBGTextColor;

    const primaryButtonTextStyle = props.generalSettings.primaryButtonTextStyle ?? primaryBGTextColor;
    const inverseButtonTextStyle = props.generalSettings.inverseButtonTextStyle ?? secondaryBGTextColor;
    const inverseButtonStyle = props.generalSettings.inverseButtonStyle ?? secondaryBGTextColor; 
    const inverseBorderColor = primaryColor; /*inverseButtonStyle === 'transparent' ? primaryColor : 'transparent';*/

    const buttonHoverEffect = props.generalSettings.buttonHoverEffect;
    const primaryButtonHoverBGColor = blendColor(primaryColor, buttonHoverEffect);
    const inverseButtonHoverBGColor = inverseButtonStyle !== 'transparent' ? blendColor(inverseButtonStyle, buttonHoverEffect) : 'transparent';
    const inverseButtonHoverBorderColor = blendColor(primaryColor, buttonHoverEffect); /*inverseButtonStyle === 'transparent' ? blendColor(primaryColor, buttonHoverEffect) : 'transparent';*/

    const linkHoverEffect = props.generalSettings.linkHoverEffect;
    const linkHoverColor = blendColor(primaryColor, linkHoverEffect);

    const customTheme: ThemeOptions = {
        typography: {
            fontFamily: `"${bodyTextFontFamily}", Helvetica, Arial, sans-serif !important`,
            fontSize: 15,
    
            button: {
                fontSize: 15,
                fontWeight: 700,
                textTransform: "none"
            }
        },
        palette: {
            primary: {
                main: backgroundColor,
                contrastText: textColor
            },
            secondary: {
                main: primaryColor,
                contrastText: primaryBGTextColor
            },
            info: {
                main: secondaryColor,
                contrastText: secondaryBGTextColor
            },
            warning: {
                main: tertiaryColor,
                contrastText: tertiaryBGTextColor
            },
            success: {
                main: successColor,
                contrastText: successBGTextColor
            },
            background: {
                default: backgroundColor,
                paper: secondaryColor
            }
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        fontFamily: `"${bodyTextFontFamily}", Helvetica, Arial, sans-serif !important`,
                        fontSize: 15,
                        color: textColor
                    },
                    h1: {
                        fontFamily: `"${headingFontFamily}", Helvetica, Arial, sans-serif`,
                        fontWeight: 500
                    },
                    h2: {
                        fontWeight: 400
                    },
                    h3: {
                        fontWeight: 700
                    },
                    h4: {
                        fontWeight: 700
                    },
                    h5: {
                        fontWeight: 400
                    },
                    h6: {
                        fontWeight: 700
                    },
                    a: {
                        color: "inherit",
                        textDecoration: "underline",
        
                        "&:hover, &:active": {
                            color: linkHoverColor
                        }
                    },
                    ".MuiButton-containedSecondary": {
                        color: primaryButtonTextStyle,
        
                        "&:hover, &:active": {
                            backgroundColor: primaryButtonHoverBGColor
                        }
                    },
                    ".MuiButton-outlinedSecondary": {
                        color: inverseButtonTextStyle,
                        backgroundColor: inverseButtonStyle,
                        borderColor: inverseBorderColor,
        
                        "&:hover, &:active": {
                            backgroundColor: inverseButtonHoverBGColor,
                            borderColor: inverseButtonHoverBorderColor
                        }
                    },
                    ".MuiButton-contained.Mui-disabled": {
                        color: primaryBGTextColor,
                        backgroundColor: primaryColor,
                        border: "none",
                        opacity: 0.33
                    },
                    ".pagination li a": {
                        "&:hover, &:active": {
                            borderColor: primaryColor
                        }
                    },
                    ".pagination li.active a": {
                        color: primaryBGTextColor,
                        backgroundColor: primaryColor
                    },
                    ".loading-spinner": {
                        color: textColor
                    },
                    ".footer": {
                        fontFamily: `"${footerFontFamily}", sans-serif  !important`,
                        backgroundColor: `${footerBackgroundColor}  !important`,
                        color: `${footerTextColor}  !important`,
                        fontSize: `10pt  !important`,
                        textAlign: `${footerTextAlignment}  !important`,
                        padding: `15px 20px`
                    }
                }
            }
        },
    }

    const theme = createTheme(customTheme);
    theme.shadows = theme.shadows.map(() => 'none') as Shadows;
    return theme;
}

export default ThemeBuilder;