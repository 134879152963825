import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import OpenBankingPlatformAPIInterface from "../openbankingplatform/OpenBankingPlatformAPIInterface";
import { Button, ButtonProps, FormControl, Grid, MenuItem, Select } from "@mui/material";
import Principal from "../admin/models/Principal";
import { adminRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import Popup from "../popups/Popup";
import ButtonBlock from "./ButtonBlock";

interface LinkButtonProps extends ButtonProps {    
    api: OpenBankingPlatformAPIInterface;
    children?: React.ReactNode;
    accountid?: any;
    basepath: string;
};

const ExportBrokersCSV = (props: PropsWithChildren<LinkButtonProps>) => {
    
    const [open, setOpen] = React.useState(false);
    const openPopup = () => {
        setOpen(true);
    };
    const closePopup = () => {
        setPrincipalId('0');
        setOpen(false);
    };

    const [principals, setPrincipals] = useState<Principal[]>();
    const [principalId, setPrincipalId] = useState('0');

    //eslint-disable-next-line
    const exportBrokers = useCallback(props.api.doBrokersExport, []);

    const getCSVData = async () => {
        await exportBrokers(props.accountid, principalId).then(response => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `brokers-list-exported.csv`
            );
            document.body.appendChild(link);
            link.click();
            // Clean up and remove the link
            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }
            setOpen(false);
        });
    };

    const handleOpenPopup = () => {
        openPopup();
    }

    const navigate = useNavigate();

    // eslint-disable-next-line
    const getPrincipals = useCallback(props.api.getPrincipalsByAccountId, []);
    useEffect(() => {
        (async () => {
            try {    
                const loadedPrincipals = await getPrincipals(props.accountid);
                setPrincipals(loadedPrincipals);
            } catch (error) {
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basepath);
            }
        })();
    },
    //eslint-disable-next-line
    [props.accountid]);

    const handleExport = (e: any) => {
        getCSVData();
    }

    const allMandatoryFieldsArePopulated = principalId !== '0';

    return (
        <>
            <Button {...props} onClick={handleOpenPopup}>Export Brokers List</Button>

            <Popup
                heading={"Export Brokers List"}
                open={open}
                onClose={closePopup}
                textColor={'#000000'}
                buttons={
                    <ButtonBlock>
                        <Button onClick={closePopup} variant={"outlined"} color={"secondary"}>Back</Button>        
                        <Button onClick={handleExport} variant={"contained"} color={"secondary"} disabled={!allMandatoryFieldsArePopulated}>Export</Button>
                    </ButtonBlock>
                }
                >
                    <Grid container className={"form-group"}>
                        <Grid item className={"align-self-center"}>
                            <label>Principal</label>
                        </Grid>
                        <FormControl variant="outlined" className="w-100">
                            <Select
                                id="name-input"
                                name="name-input"
                                defaultValue={0}
                                onChange={(event) => {
                                    setPrincipalId(''+event.target.value);
                                }}>
                                <MenuItem value={0}>- Please select -</MenuItem>
                                {principals && principals.map((item, i) => (
                                    <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
            </Popup>
        </>
    );

}

export default ExportBrokersCSV;


