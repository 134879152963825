import React from "react";
import { Box, Grid } from "@mui/material";
import DeveloperTools from "../../models/DeveloperTools";

interface ViewDeveloperToolsSettingsProps
{
    developerTools: DeveloperTools,
}

const ViewDeveloperToolsSettings = (props: ViewDeveloperToolsSettingsProps) => {

    return <>

        <Grid container className={"mt-50"}>
            <Box mb={2}>
                <h4>Consumer Consent Flow Errors</h4>
            </Box>
        </Grid>
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={4} md={3}>
                <label>Consumer consent Session timed out</label>
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
            {props.developerTools.incConsumerConsentSessionTimedOut ? "Include" : "Exclude"}
            </Grid>
        </Grid>
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={4} md={3}>
                <label>Cannot load consumer consent flow when accessing the one time link due to expiry of the link token</label>
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
            {props.developerTools.incCannotLoadConsumerConsentFlow ? "Include" : "Exclude"}
            </Grid>
        </Grid>
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={4} md={3}>
            <label>Unexpected error encountered during the Authorisation process with the bank failed/errored</label>
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
            {props.developerTools.incUnexpectedErrorEncounteredDuringTheAuth ? "Include" : "Exclude"}
            </Grid>
        </Grid>
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={4} md={3}>
            <label>Unknown Error</label>
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
            {props.developerTools.incUnknownError ? "Include" : "Exclude"}
            </Grid>
        </Grid>

        <Grid container className={"mt-50"}>
            <Box mb={2}>
                <h4>Open Banking API Errors</h4>
            </Box>
        </Grid>
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={4} md={3}>
            <label>CDR service unavailable (for data retrieval)</label>
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
            {props.developerTools.incCdrServiceUnavailable ? "Include" : "Exclude"}
            </Grid>
        </Grid>
        <Grid container className={"mt-30"}>
            <Grid item xs={12} sm={4} md={3}>
            <label>ODS service unavailable (for data retrieval)</label>
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
            {props.developerTools.incOdsServiceUnavailable ? "Include" : "Exclude"}
            </Grid>
        </Grid>

    </>
}    

export default ViewDeveloperToolsSettings;