import React, { cloneElement, PropsWithChildren } from "react";

import { Box, Container, useTheme } from "@mui/material";

import ButtonBlock from "./ButtonBlock";

interface SecondaryBlockProps {
    header?: JSX.Element | string;
    ctaButtons?: Array<JSX.Element>;
    displayItemsConnected?: boolean;
    children?: React.ReactNode;
    textAlign: string;
}

const SecondaryBlock = (props: PropsWithChildren<SecondaryBlockProps>) => {
    const theme = useTheme();
    const style = {
        secondaryBlock: {
            background: theme.palette.info.main,
            color: theme.palette.info.contrastText,
            textAlign: props.textAlign
        }
    };
        
    return <Box component="div" className={"secondary-block"} sx={style.secondaryBlock}>
        <Container>
            {props.header && 
                <h2>{props.header}</h2>
            }
            {props.children &&
                <div className={"content"}>
                    {props.children}
                </div>
            }
            {props.ctaButtons &&
                <div className={"cta"}>
                    <ButtonBlock>{props.ctaButtons.map((button, i) => cloneElement(button, {key: i}))}</ButtonBlock>
                </div>
            }
        </Container>
    </Box>

}

export default SecondaryBlock;