import React from "react";
import { Link } from "react-router-dom";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";

interface BrokersListItemProps {
    id: number;
    code?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    mobile?: string;
    registration_number?: string;
    account_name?: string;
    principal_name?: string;
    is_active?: boolean;
    brokerDetailsPath: string;
    isSuperAdmin?:boolean;
}

const BrokersListItem = (props: BrokersListItemProps) => {

    return <Grid container className={"consent-list-item"}>
        <Grid item xs={props.isSuperAdmin ? 2 : 4}>
            {props.last_name} {props.first_name}
            <br/><small>{props.email}</small>
            <br/><small>{props.code}</small>
        </Grid>
        <Grid item xs={2}>{props.mobile}</Grid>
        <Grid item xs={2}>{props.registration_number}</Grid>
        {props.isSuperAdmin && <Grid item xs={2}>{props.account_name}</Grid>}
        <Grid item xs={2}>{props.principal_name}</Grid>
        <Grid item xs={2}><Link color={"secondary"} to={props.brokerDetailsPath+ `/${props.id}`}><b><u>View details</u> <FontAwesomeIcon icon={faChevronRight} /></b></Link></Grid>
    </Grid>
}

export default BrokersListItem;


