import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import { Button, ButtonProps } from "@mui/material";

interface LinkButtonProps extends ButtonProps {
    targetlocation: string;
    children?: React.ReactNode;
    params?: any;
};

const LinkButton = (props: PropsWithChildren<LinkButtonProps>) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (props.params) {
            navigate(props.targetlocation, props.params);
        } else {
            navigate(props.targetlocation);
        }
    }

    return <Button {...props} onClick={handleClick}>
        {props.children}
    </Button>

}

export default LinkButton;


