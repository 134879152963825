import React from "react";
import PrincipalBillingConfiguration from "../../models/PrincipalBillingConfiguration";
import {Grid} from "@mui/material";
import LightTooltip from "../LightTooltip";
import InfoIcon from "@mui/icons-material/Info";
import EditableText from "../EditableText";
import BillingModelHybrid11 from "../../models/billingmodels/BillingModelHybrid11";

interface BillingModelHybrid11ConfigProps {
    modelConfiguration?: PrincipalBillingConfiguration;
    onBillingModelUpdate: (config: PrincipalBillingConfiguration) => void;
}

const BillingModelHybrid11Config = (props: BillingModelHybrid11ConfigProps) => {
    const billingModelType = 'BillingModelHybrid11';

    const connectInstitutionCost = props.modelConfiguration?.billingModelHybrid11?.connect_user_and_first_time_data_pull_cost;
    const firstTimeRefreshPerMonthCost = props.modelConfiguration?.billingModelHybrid11?.first_time_refresh_per_month_cost;

    return <>
        <h4>Model 1.1</h4>

        <Grid container className={"mt-10"}>
            <Grid item xs={12} sm={7} md={5}>
                <label>Connect an institution / First time data pull<LightTooltip title="Connect an institution" placement="right-start"
                                                   arrow><InfoIcon
                    color={"secondary"}/></LightTooltip></label>
            </Grid>
            <Grid item xs={12} sm={4} md={3} className={"background-grey"}>
                <EditableText
                    type={"number"}
                    editMode={true}
                    name={"connect_institution_cost"}
                    value={connectInstitutionCost !== undefined ? connectInstitutionCost as unknown as string : ""}
                    eventHandlers={{
                        onChange: (e: any) => {
                            const newBillingModel: BillingModelHybrid11 = {
                                ...props.modelConfiguration?.billingModelHybrid11,
                                connect_user_and_first_time_data_pull_cost: e.target.value
                            }
                            const newBillingModelConfiguration: PrincipalBillingConfiguration = {
                                ...props.modelConfiguration,
                                selectedBillingModelType: billingModelType,
                                billingModelHybrid11: newBillingModel
                            };
                            props.onBillingModelUpdate(newBillingModelConfiguration);
                        }
                    }}
                />
            </Grid>
        </Grid>
        <Grid container className={"mt-20"}>
            <Grid item xs={12} sm={7} md={5}>
                <label>First time refresh (per month)<LightTooltip title="First time refresh (per month)" placement="right-start"
                                                       arrow><InfoIcon
                    color={"secondary"}/></LightTooltip></label>
            </Grid>
            <Grid item xs={12} sm={4} md={3} className={"background-grey"}>
                <EditableText
                    type={"number"}
                    editMode={true}
                    name={"first_time_refresh_per_month_cost"}
                    value={firstTimeRefreshPerMonthCost !== undefined ? firstTimeRefreshPerMonthCost as unknown as string : ""}
                    eventHandlers={{
                        onChange: (e: any) => {
                            const newBillingModel: BillingModelHybrid11 = {
                                ...props.modelConfiguration?.billingModelHybrid11,
                                first_time_refresh_per_month_cost: e.target.value
                            }
                            const newBillingModelConfiguration: PrincipalBillingConfiguration = {
                                ...props.modelConfiguration,
                                selectedBillingModelType: billingModelType,
                                billingModelHybrid11: newBillingModel
                            };
                            props.onBillingModelUpdate(newBillingModelConfiguration);
                        }
                    }}
                />
            </Grid>
        </Grid>
    </>
}

export default BillingModelHybrid11Config;