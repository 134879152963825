import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Button, Container, Grid } from "@mui/material";

import ButtonBlock from "../components/ButtonBlock";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoadingSpinner from "../components/LoadingSpinner";
//import MessageBox from "../components/MessageBox";
import PrimaryBlock from "../components/PrimaryBlock";
import { consentRedirectByErrorResponseStatus } from "../helpers/RedirectHelper";
import DataHolder from "../models/DataHolder";
import GeneralSettings from "../models/GeneralSettings";
import PostConsentData from "../models/PostConsentData";
import Consent from "../models/Consent";
//import {hasRichTextSpecifiedForField} from "../helpers/InputFieldValidationHelper";
//import EditableRichText from "../admin/components/EditableRichText";


interface SuccessProps {
	basePath: string;
	currentDataHolderSelection: DataHolder;
	dashboardPath: string;
	connectAnotherDataHolderPath: string;
	onRedirectBack: (storedDataHolderBrand: DataHolder) => void;
	generalSettingsConfig?: GeneralSettings;
	consentConfig?: Consent;
	api: any;
	principalLogoUrl?: string;
	headerBgImageUrl?: string;
	loadPrincipalConfiguration: (principal_id?: number, skip_change?: boolean) => void;
	footerText?: string;
	isTrustedAdviser?: boolean;
}

interface SuccessState {
	downloadPdfData?: any;
	consentData?: PostConsentData;
}

const Success = (props: SuccessProps) => {
	const getRedirectionUrl = useCallback(async (type: any) => props.api.getRedirectionUrl(type), [props.api]);

	const [statePdf, setStatePdf] = useState<SuccessState>({});
	const navigate = useNavigate();
	const [loadScreen, setLoadScreen] = useState(false);
	const [consentId, setConsentId] = useState('');
	const [isInsight, setIsInsight] = useState(false);

	//Extract url parameters
	const params = new URLSearchParams(window.location.hash.replace("#", "?"));
	// Common parameters
	const state = params.get('state') ?? '';
	// Success parameters
	const code = params.get('code') ?? '';
	const id_token = params.get('id_token') ?? '';
	const access_token = params.get('access_token') ?? '';
	// Error parameters
	const error = params.get('error') ?? '';
	const error_description = params.get('error_description') ?? '';
	const error_uri = params.get('error_uri') ?? '';

	//Data holder selection storage
	const dataHolderBrand = sessionStorage.getItem('dataHolderBrand') ?? JSON.stringify(props.currentDataHolderSelection);
	const storedDataHolderBrand: DataHolder = JSON.parse(dataHolderBrand);
	//const isTrustedAdviserSession: boolean = sessionStorage.getItem('isTrustedAdviserSession') === 'true' ?? false;
	const isTrustedAdviserSession = props.isTrustedAdviser;

	const postConsentData = {
		'code': code,
		'id_token': id_token,
		'state': state,
		'access_token': access_token,
		'error': error,
		'error_description': error_description,
		'error_uri': error_uri,
		'data_holder_brand_identifier': storedDataHolderBrand.id,
		'isMultibankSession': false,
		'isTrustedAdviserSession': isTrustedAdviserSession
	};

	const location = useLocation();

	//eslint-disable-next-line 
	const savePostConsentToken = useCallback(props.api.savePostConsentToken, []);
	useEffect(() => {
		/**
		 * TODO: validate the value of the state parameter against the one generated and stored
		 * for the initial auth request before we allow the rest of the processing to proceed
		 */
		//const consentId = location.state as string;
		//if (consentId) {
		//setConsentId(consentId);
		//setLoadScreen(true);
		if (location.state) {
			// @ts-ignore
			const consent = location.state.consentData as PostConsentData;
			setConsentId(consent.consent_uuid);
			setIsInsight(consent.is_insight);
			setLoadScreen(false);

			if (typeof window !== "undefined" && window.top !== window.self && props.generalSettingsConfig?.callbackURL) {
				// Call back to the parent of the current window (if hosted in an iframe)
				// so that the principal can utilise it to update session state of the
				// consent lodged by the customer within their application
				window.parent.postMessage({
					message: "Success",
					value: consent.standardised_data || consent
				}, props.generalSettingsConfig.callbackURL)
			}

			// Then perform a redirection (if the redirectURL is provided in the Principal config)
			if (props.generalSettingsConfig?.provideConsentViaApi && props.generalSettingsConfig?.redirectURL) {
				const redirectAfterSeconds = parseInt(props.generalSettingsConfig?.redirectAfterSeconds || "0") * 1000;
				if (props.generalSettingsConfig?.enhancedRedirection?.enableForSuccess) {
					(async () => {
						try {
							// Make API request to set the redirection URL
							const redirectionUrl = await getRedirectionUrl('success');
							if (redirectionUrl.url) {
								if (redirectAfterSeconds && redirectAfterSeconds > 0) {
									setTimeout((successUrl) => {
										window.location.href = successUrl;
									}, redirectAfterSeconds, redirectionUrl.url);
								} else {
									window.location.href = redirectionUrl.url;
								}
							}
						} catch (error) {
							if (redirectAfterSeconds > 0) {
								setTimeout((pr: any) => {
									(window as any).top.location.href = pr.generalSettingsConfig.redirectURL;
								}, redirectAfterSeconds, props);
							} else {
								(window as any).top.location.href = props.generalSettingsConfig?.redirectURL;
							}
						}
					})();
				} else {
					if (redirectAfterSeconds > 0) {
						setTimeout((pr: any) => {
							(window as any).top.location.href = pr.generalSettingsConfig.redirectURL;
						}, redirectAfterSeconds, props);
					} else {
						(window as any).top.location.href = props.generalSettingsConfig?.redirectURL;
					}
				}
			} else {
				setLoadScreen(true);
			}

			/*
			if (props.generalSettingsConfig?.callbackURL) {

				// Call back to the parent of the current window (if hosted in an iframe)
				// so that the principal can utilise it to update session state of the
				// consent lodged by the customer within their application
				window.parent.postMessage({
					message: "Success",
					value: consent
				}, props.generalSettingsConfig.callbackURL)

				// Then perform a redirection (if the redirectURL is provided in the Principal config)
				if (props.generalSettingsConfig?.redirectURL) {
					setTimeout((pr: any) => {
						window.top.location.href = pr.generalSettingsConfig.redirectURL;
					}, 5000, props);
				}
			} else {
				setLoadScreen(true);
			}*/

		} else {
			// Update the document title using the browser API
			props.onRedirectBack(storedDataHolderBrand);
			(async () => {
				if (storedDataHolderBrand.is_cdr) {
					try {
						//Save all tokens
						const consentData = await savePostConsentToken(postConsentData);
						setConsentId(consentData.consent_uuid);
						setLoadScreen(true);
						navigate(props.basePath + `/consent/success`, {
							state: {
								consentData: consentData
							}
						})
					} catch (error) {
						if ((error as any).response.data.source === 'openid') {
							navigate(props.connectAnotherDataHolderPath, {
								state: {
									showNotice: true
								}
							});
							return;
						}
						consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
					}
				} else {
					setLoadScreen(true);
				}
			})();
		}
	},
		// eslint-disable-next-line
		[location]
	);

	// Scroll to the top of the page once
    useEffect(() => {
        window.scrollTo(0, 0);
        window.parent.postMessage({ action: "OB_SCROLL_TO_TOP" }, "*");
    }, []);

	//Handle connect to another institution
	/*const handleConnectToOtherInstitution = () => {
		navigate(props.connectAnotherDataHolderPath);
	};*/


	//eslint-disable-next-line 	
	const getDownloadPdfData = useCallback(props.api.getDownloadPdf, []);
	const handleDownloadConsentPdf = (consent_uuid: string, consent_type: number) => {
		//Send to server
		(async (consent_uuid, consent_type) => {
			try {
				const pdfData = await getDownloadPdfData(consent_uuid, consent_type);
				setStatePdf(state => ({
					...state,
					downloadPdfData: pdfData,
				}));
			} catch (error) {
				consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
			}
		})(consent_uuid, consent_type);
	};

	useEffect(() => {
		if (statePdf.downloadPdfData) {
			let blob = new Blob([statePdf.downloadPdfData], { type: 'application/pdf' });
			let url = window.URL.createObjectURL(blob);
			let link = document.createElement('a');
			link.setAttribute('download', 'consent.pdf');
			link.href = window.URL.createObjectURL(blob);
			link.click();
			window.URL.revokeObjectURL(url);
		}
	}, [statePdf.downloadPdfData]);

	const handleVisitDashboardClick = () => {
		(async () => {
			await props.loadPrincipalConfiguration(0);
			navigate(props.dashboardPath);
		})();
	}

	//eslint-disable-next-line
	const finishConsentSession = useCallback(props.api.finishConsentSession, []);
	const handleFinishSession = () => {
		//Send to server
		(async () => {
			try {
				await finishConsentSession();
				navigate(props.basePath + `/consent/session-finished`, {
					state: {
						isInsight: isInsight
					}
				});
			} catch (error) {
				consentRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
			}
		})();
	}

	return <div className={"page-wrapper success-page"}>
		<div className={"page-top"}>
			<main>
				<Header
					generalSettings={props.generalSettingsConfig}
					principalLogoUrl={props.principalLogoUrl}
					headerBgImageUrl={props.headerBgImageUrl}
					isTrustedAdviser={props.isTrustedAdviser}
				/>
				{(loadScreen)
					?
					<PrimaryBlock>
						<Container maxWidth={"sm"} className="text-align-center">
							<div className={"multibank-confirmation"}>
								<h2>Institutions you have connected to</h2>
								<p>You have successfully shared data from your {props.isTrustedAdviser ? "institution" : "bank"}. Please see below for detailed information.</p>
							</div>

							<div className="multibank-selection text-align-left">
								<Grid container className="institution-container institution-card">
									<Grid className="institution-logo" item xs={2}>
										<img src={storedDataHolderBrand.logo} alt="" />
									</Grid>
									<Grid className="institution-name" item xs={9}>
										<p>{storedDataHolderBrand.title}</p>
									</Grid>
									<Grid className="cdr-logo text-right" item xs={1}>
										{(storedDataHolderBrand.is_cdr) ?
										<img src={"/images/icon-cdr.png"} alt="" />
										: ""}
									</Grid>
								</Grid>

								<div className="mt-2">
									You have successfully consented to share your {storedDataHolderBrand.title} data with {props.isTrustedAdviser ? props.generalSettingsConfig?.principalName || 'us' : 'us'}.{storedDataHolderBrand.is_cdr
									? (props.isTrustedAdviser
										? " We will email you a link to your Data Sharing Dashboard where you can view your consent. You may also download your confirmation of consent below."
										: " We will email you a CDR Receipt, and a link to your Consumer Dashboard. You can also download your CDR Receipt below."
									  )
									: ""}

									{(storedDataHolderBrand.is_cdr) ?
										<div className="mt-3">
											<FontAwesomeIcon icon={faDownload} />&nbsp;
											<a href='/#' style={{ color: "inherit" }} onClick={(e: any) => { e.preventDefault(); handleDownloadConsentPdf(consentId, 1); }} >Download confirmation of consent</a>
										</div>
										: ""
									}
								</div>
							</div>

							{(props.generalSettingsConfig?.provideConsentViaApi && props.generalSettingsConfig?.redirectURL) ? "" :
								<Box my={5}>
									<ButtonBlock className="alignItemsCenter">
										<Button onClick={handleVisitDashboardClick} variant={"contained"} color={"secondary"}>Visit Dashboard</Button>
										<Button onClick={handleFinishSession} variant={"contained"} color={"secondary"}>Finish</Button>
									</ButtonBlock>
								</Box>
							}

						</Container>
					</PrimaryBlock>
					: <LoadingSpinner position={"fixed"} overlay />
				}
			</main>
		</div>

		{!props.isTrustedAdviser && <Footer generalSettingsConfig={props.generalSettingsConfig} isTrustedAdvisor={props.isTrustedAdviser} />}

	</div>;
}

Success.defaultProps = {
	currentDataHolderSelection: { id: "", logo: "", title: "Bank Name" }
}

export default Success;