import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Grid, Typography} from "@mui/material";

import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";

//import {adminRedirectByResponseStatus} from "../../helpers/RedirectHelper";

interface ConsentsListItemProps {
    basePath: string;
    id?: number;
    uuid?: string;
    is_cdr?: boolean;
    personal_details?: string;
    principal?: string
    data_holder?: string;
    confirmation_pdf?: string;
    withdrawal_pdf?: string;
    status?: string;
    created_at?: string;
    consentDetailsPath: string;
    api: OpenBankingPlatformAPIInterface;
}

const ConsentsListItem = (props: ConsentsListItemProps) => {
    //const navigate = useNavigate();

    const [PdfData, setPdfData]: any = useState();

    //eslint-disable-next-line
    const getDownloadPdfData = useCallback(props.api.getDownloadPdf, []);
    const handleDownloadConsentPdf = (consent_uuid: string, consent_type: number) => {
        //Send to server
        (async (consent_uuid, consent_type) => {
            try {
                const pdfData = await getDownloadPdfData(consent_uuid, consent_type)
                setPdfData(pdfData)
            } catch (error) {
                console.log(error);
                //adminRedirectByResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })(consent_uuid, consent_type);
    }

    useEffect(() => {
        if (PdfData) {
            let blob = new Blob([PdfData], { type: 'application/pdf' });
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.setAttribute('download', 'consent.pdf');
            link.href = window.URL.createObjectURL(blob);
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }, [PdfData]);
 
    return <Grid container className={"consent-list-item"}>
        <Grid item xs={1}>{props.id}</Grid>
        <Grid item xs={2}>{props.personal_details}</Grid>
        <Grid item xs={2}>{props.principal} </Grid>
        <Grid item xs={2}>
            {props.data_holder}
            <div>{props.is_cdr ? <img className={"consent-list-item-is-cdr"} src={"/images/icon-cdr.png"} alt="" /> : ""}</div>
        </Grid>
        <Grid item xs={1}>
            {props.id && props.is_cdr ?
            <>
                <Typography component={"span"} color={"secondary"}><a href="/#" onClick={(e) => {e.preventDefault(); handleDownloadConsentPdf(props?.uuid as string, 1);}} >Download</a></Typography>
            </> : ''}
        </Grid>
        <Grid item xs={1}>
            {props.id && props.status === 'Withdrawn' && props.is_cdr ?
                <>
                    <Typography component={"span"} color={"secondary"}><a href="/#" onClick={(e) => {e.preventDefault(); handleDownloadConsentPdf(props?.uuid as string, 2);}} >Download</a></Typography>
                </> : ''}
        </Grid>
        <Grid item xs={1}>{props.status}</Grid>
        <Grid item xs={1}>{props.created_at}</Grid>
        <Grid item xs={1}> 
            {props.is_cdr ?
                <>
                    <Link color={"secondary"} to={props.consentDetailsPath+ `/${props.uuid}`}><b><u>View details</u> <FontAwesomeIcon icon={faChevronRight} /></b></Link>
                </> : ''}
        </Grid>
    </Grid>
}

export default ConsentsListItem;


