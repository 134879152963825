import React from "react";

import {Link} from "react-router-dom";
import TabItemDescription from "../models/TabItemDescription";
import CurrentUser from "../../models/CurrentUser";
import {isClientManagerOrStandardUser, isAdmin} from "../../helpers/AuthUserHelper";

interface TabsProps {
    tabs: Array<TabItemDescription>;
    basePath: string;
    backPath: string;
    activeTabIndex: number;
    configurationId: number;
    accountId: number;
    currentUser?: CurrentUser,
    onTabChange?: (newTabIndex: number) => void;
}

const ViewEditConfigurationTabs = (props: TabsProps) => {
    return <div className={"activeTabOverride-bar"}>
        <ul className={"configuration-tabs"}>
            {(isAdmin(props.currentUser?.role_id)) ?
                <li><Link to={props.backPath + "/account/" + props.accountId}>&lt; Return</Link></li>
                : ""}

            {(isClientManagerOrStandardUser(props.currentUser?.role_id)) ?
                <li><Link to={props.backPath + "/account/"}>&lt; Return</Link></li>
                : ""}

            {props.tabs.map((tabItemData, index) => {
                return <li key={index} className={(index === props.activeTabIndex) ? "active" : ""}>
                    <div onClick={() => {props.onTabChange && props.onTabChange(index)}}>{tabItemData.text}</div>
                </li>;
            })}
        </ul>
    </div>
}

export default ViewEditConfigurationTabs;