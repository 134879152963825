import { ContentBlock, ContentState, EditorState, KeyBindingUtil, RichUtils } from "draft-js";
import React from "react";

export const linkStrategy = (contentBlock: ContentBlock, callback: any, contentState: ContentState) => {
	contentBlock.findEntityRanges(character => {
		const entityKey = character.getEntity();
		return (
			entityKey !== null &&
			contentState.getEntity(entityKey).getType() === "LINK"
		);
	}, callback);
};

export const Link = (props: any) => {
	const { contentState, entityKey } = props;
	const { url } = contentState.getEntity(entityKey).getData();
	return (
		<a
			className="draft-js-link"
			href={url}
			rel="noopener noreferrer"
			target="_blank"
			aria-label={url}
		>
			{props.children}
		</a>
	);
};

const addLinkPluginPlugin = {
	keyBindingFn(event: any, { getEditorState }: any) {
		const editorState = getEditorState();
		const selection = editorState.getSelection();
		if (selection.isCollapsed()) {
			return;
		}
		if (KeyBindingUtil.hasCommandModifier(event) && event.which === 75) {
			return "add-link";
		}
	},

	handleKeyCommand(command: any, editorState: EditorState, { setEditorState }: any) {
		if (command !== "add-link") {
			return "not-handled";
		}

		const selection = editorState.getSelection();
		const url = window.prompt("Please enter a URL:", "http");
		if (!url) {
			setEditorState(RichUtils.toggleLink(editorState, selection, null));
			return "handled";
		}
		// eslint-disable-next-line
		const pattern = new RegExp('^(https?:\\/\\/)?'+'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+'((\\d{1,3}\\.){3}\\d{1,3}))'+'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+'(\\?[;&a-z\\d%_.~+=-]*)?'+'(\\#[-a-z\\d_]*)?$','i');
		if (!!pattern.test(url)) {
			const contentWithEntity = editorState.getCurrentContent().createEntity("LINK", "MUTABLE", { url });
			/*const newEditorState = EditorState.push(
			  editorState,
			  contentWithEntity,
			  "apply-entity"
			);*/
			const entityKey = contentWithEntity.getLastCreatedEntityKey();
			setEditorState(RichUtils.toggleLink(editorState, selection, entityKey));
		}
		return "handled";
	},
	
    decorators: [
		{
			strategy: linkStrategy,
			component: Link
		}
	]
};

export default addLinkPluginPlugin;
