import * as EmailValidator from 'email-validator';
import {sanitize} from "./HtmlSanitizerHelper";

export const PASSWORD_EMPTY = 'PASSWORD_EMPTY';
export const PASSWORD_WHITESPACE = 'PASSWORD_WHITESPACE';
export const PASSWORD_STRENGTH_POOR = 'PASSWORD_STRENGTH_POOR';
export const PASSWORD_STRENGTH_WEAK = 'PASSWORD_STRENGTH_WEAK';
export const PASSWORD_STRENGTH_STRONG = 'PASSWORD_STRENGTH_STRONG';

export const MAX_FILE_UPLOAD_SIZE = 5120; // 5MB

export function validateIfTargetsAreValidEmails(targetString:string) {
    const targets = targetString.split(";");
    let invalidTargets:string[] = [];

    for (let i = 0; i < targets.length; i++) {
        let email = targets[i].trim();
        if (email === '') {
            continue;
        }

        if (!EmailValidator.validate(email)) {
            invalidTargets.push(email);
        }
    }
    return invalidTargets;
}

export function validateIfTargetsAreValidUrls(targetString:string) {
    const targets = targetString.split(";");
    let invalidTargets:string[] = [];

    for (let i = 0; i < targets.length; i++) {
        let urlString = targets[i].trim();

        if (urlString === '') {
            continue;
        }

        if (urlString.includes(",")) {
            invalidTargets.push(urlString);
        }

        try {
            new URL(urlString);
            continue;
        } catch (_) {
            invalidTargets.push(urlString);
        }
    }

    return invalidTargets;
}

export function validateIfTargetsAreValidEmailsOrUrls(targetString:string) {
    const targets = targetString.split(";");
    let invalidTargets:string[] = [];

    for (let i = 0; i < targets.length; i++) {
        let targetString = targets[i].trim();

        if (targetString === '') {
            continue;
        }

        if (EmailValidator.validate(targetString)) {
            continue;
        }

        if (targetString.endsWith(",")) {
            invalidTargets.push(targetString);
        }

        try {
            new URL(targetString);
            continue;
        } catch (_) {

        }

        invalidTargets.push(targetString);
    }

    return invalidTargets;
}

export function checkPasswordStrength(password?:string) {
    const poorRegExp = /[A-Za-z]/;
    const weakRegExp = /(?=.*?[0-9])/;;
    const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
    const whitespaceRegExp = /^$|\s+/;

    var passwordStrength = PASSWORD_STRENGTH_POOR;

    if (password === undefined) {
        return PASSWORD_EMPTY;
    } else {
        const passwordLength = password.length;

        if (password === '') {
            return PASSWORD_EMPTY;
        }

        const poorPassword= poorRegExp.test(password);
        const weakPassword= weakRegExp.test(password);
        const strongPassword= strongRegExp.test(password);
        const whiteSpace= whitespaceRegExp.test(password);

        if (whiteSpace) {
            return PASSWORD_WHITESPACE;
        }

        // to check poor password
        if (passwordLength <= 7 && (poorPassword || weakPassword || strongPassword))
        {
            passwordStrength = PASSWORD_STRENGTH_POOR;
        }

        // to check weak password
        if (passwordLength>= 8 && poorPassword && (weakPassword || strongPassword))
        {
            passwordStrength = PASSWORD_STRENGTH_WEAK;
        }

        // to check strong Password
        if(passwordLength >= 10 && (poorPassword && weakPassword) && strongPassword)
        {
            passwordStrength = PASSWORD_STRENGTH_STRONG;
        }
    }

    return passwordStrength;
}

/**
 * This is a helper function to check if a file being uploaded has valid MIME type by checking
 * the signature of the file in the first bytes of the file
 *
 * This is adapted from the example in:
 * https://newdevzone.com/posts/how-to-check-file-mime-type-with-javascript-before-upload
 *
 * @param mimes
 * @param file
 * @param callback
 */
export function checkFileHasValidMimeType(mimes:any, file:File, callback:(flag:boolean) => void) {
    function check(bytes:any, mime:any) {
        for (var i = 0, l = mime.mask.length; i < l; ++i) {
            if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
                return false;
            }
        }
        return true;
    }

    var blob = file.slice(0, 4); //read the first 4 bytes of the file

    var reader = new FileReader();
    reader.onloadend = function(e) {
        // @ts-ignore
        if (e.target.readyState === FileReader.DONE) {
            // @ts-ignore
            var bytes = new Uint8Array(e.target.result);

            for (var i=0, l = mimes.length; i<l; ++i) {
                if (check(bytes, mimes[i])) {
                    return callback(true);
                }
            }

            return callback(false);
        }
    };
    reader.readAsArrayBuffer(blob);
}

export function checkIfUploadedFileHasImageMimeType(file:File, callback:(flag:boolean) => void) {
    // List of known image mimes that Open Banking supports
    var mimes = [
        {
            mime: 'image/jpeg',
            pattern: [0xFF, 0xD8, 0xFF],
            mask: [0xFF, 0xFF, 0xFF],
        },
        {
            mime: 'image/png',
            pattern: [0x89, 0x50, 0x4E, 0x47],
            mask: [0xFF, 0xFF, 0xFF, 0xFF],
        }
        // you can expand this list @see https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
    ];

    // Check if the file contains the supported mimes
    checkFileHasValidMimeType(mimes, file, callback);
}

/**
 * This checks whether an uploaded file contains a valid signature for the purported file type
 * by looking at the first 4 bytes of the file. This is a different implementation of the function
 * above used primarily to validate PDF files
 *
 * This is adapted from the example in:
 * https://stackoverflow.com/a/71320737
 *
 * @param pattern
 * @param file
 * @param callback
 */
export function checkFileHasValidSignaturePattern(pattern:string, file: File, callback:(flag:boolean) => void) {
    const headerBytes = file.slice(0, 4); // Read the first 4 bytes of the file
    const fileReader = new FileReader();
    fileReader.onloadend = (e: ProgressEvent<FileReader>) => {
        const arr = new Uint8Array(e?.target?.result as ArrayBufferLike).subarray(
            0,
            4,
        );
        let header = '';
        for (let i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
        }

        if (header === pattern) {
            return callback(true);
        }

        return callback(false);
    };
    fileReader.readAsArrayBuffer(headerBytes);
}

/**
 * Check if the uploaded has a valid PDF byte signature pattern
 *
 * @param file
 * @param callback
 */
export function checkIfUploadedFileHasValidPdfSignature(file:File, callback:(flag:boolean) => void) {
    const pdfBytePattern = "25504446"; // https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
    checkFileHasValidSignaturePattern(pdfBytePattern, file, callback)
}

/**
 * Check if there is rich text consent contained in the field
 * @param field
 */
export function hasRichTextSpecifiedForField(field?: string) {
    if (field) {
        var json = JSON.parse(sanitize(field));
        if (!Array.isArray(json.blocks)) {
            return false
        }
        if (Array.isArray(json.blocks) && json.blocks.length === 1) {
            if (Object.keys(json.blocks[0].text).length > 0 || Object.keys(json.blocks[0].data).length > 0) {
                return true;
            }
            return false;
        }
        return true;
    }
    return false;
}

export function checkIfUploadedFileSizeWithinLimit(file:File) {
    const fileSize = file.size / 1024;
    if (fileSize > MAX_FILE_UPLOAD_SIZE) {
        return false;
    }

    return true;
}