import React, { PropsWithChildren } from "react";

import { Button, Dialog, DialogActions, DialogContent, useTheme } from "@mui/material";

interface CancellationPopupProps {
    heading?: string;
    open?: boolean;
    onYes?: () => void;
    onNo?: () => void;
    children?: React.ReactNode;
}

const CancellationPopup = (props: PropsWithChildren<CancellationPopupProps>) => {
    const theme = useTheme();
    const style = {
        dialogContent: {
            color: theme.palette.info.contrastText
        }
    };

    return (
        <Dialog maxWidth={"sm"} open={props.open || false} onClose={props.onNo} PaperProps={{elevation: undefined}}>
            <DialogContent sx={style.dialogContent}>
                <h2>{props.heading}</h2>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onYes} variant={"contained"} color={"secondary"}>Yes</Button>
                <Button onClick={props.onNo} variant={"outlined"} color={"secondary"}>No</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CancellationPopup;
