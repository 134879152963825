import React from "react";
import {Grid} from "@mui/material";
import moment from "moment";

interface BillingLogItemProps {
    id?: number;
    customer_identifier?: string;
    customer_name?: string;
    institution?: string;
    institution_type?: string;
    event?: string;
    timestamp?: string;
}


const BillingLogItem = (props: BillingLogItemProps) => {
    return <Grid container className={"consent-list-item"}>
        <Grid item xs={1}>{props.id}</Grid>
        <Grid item xs={2}>{props.customer_identifier}</Grid>
        <Grid item xs={2}>{props.customer_name}</Grid>
        <Grid item xs={2}>{props.institution}</Grid>
        <Grid item xs={1}>{props.institution_type}</Grid>
        <Grid item xs={2}>{props.event}</Grid>
        <Grid item xs={2}>
            {moment.utc(props.timestamp).local().format('DD MMM YYYY')}<br/>
            {moment.utc(props.timestamp).local().format('h:mm:ss a')}
        </Grid>
    </Grid>
}

export default BillingLogItem;