import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoIcon from "@mui/icons-material/Info";
import { Box, FormControl, Grid, Hidden, Link, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import LinkButton from "../../components/LinkButton";
import LoadingSpinner from "../../components/LoadingSpinner";
import { isAdmin } from "../../helpers/AuthUserHelper";
import { adminRedirectByErrorResponseStatus } from "../../helpers/RedirectHelper";
import CurrentUser from "../../models/CurrentUser";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";
import BillingLogItem from "../components/BillingLogItem";
import LightTooltip from "../components/LightTooltip";
import BillingLog from "../models/BillingLog";
import PrincipalInvoicingDetails from "../models/PrincipalInvoicingDetails";

interface InvoicingDetailsProps {
    api: OpenBankingPlatformAPIInterface;
    basePath: string;
    currentUser?: CurrentUser;
}

interface InvoicingDetailsState {
    invoicingStatistics?: PrincipalInvoicingDetails;
    selectedInvoiceMonth: string;
    isLoading: boolean;
    billingActivity?: Array<BillingLog>;
    currentPage: number;
    pageCount: number;
    isBillingLogLoading: boolean;
}

const InvoicingDetails = (props: InvoicingDetailsProps) => {
    let perPage: number = 10;

    const { id } = useParams<{ id: any }>();

    const navigate = useNavigate();

    const DATE_FORMAT = "YYYY-MM-DD HH:mm:00 ZZ";

    const [invStats, setInvStats] = useState<InvoicingDetailsState>({
        selectedInvoiceMonth: moment().startOf('month').format('YYYY-MM'),
        isLoading: true,
        isBillingLogLoading: false,

        currentPage: 0,
        pageCount: 0
    })

    const datePickerStart = moment('2020-01-01');
    const datePickerEnd = moment(); //today

    const getInvoiceMonthSelectionValues = () => {
        var monthValues = [];
        while (datePickerEnd > datePickerStart || datePickerStart.format('M') === datePickerEnd.format('M')) {
            monthValues.push({
                id: datePickerStart.format('YYYY-MM'),
                value: datePickerStart.format('MMM YYYY')
            })
            datePickerStart.add(1,'month');
        }
        return monthValues.reverse();
    }

    const monthValues = getInvoiceMonthSelectionValues();

    //eslint-disable-next-line
    const getPrincipalInvoicingDetails = useCallback(props.api.getPrincipalInvoicingDetails, []);
    useEffect(() => {
        var fromDate = moment().startOf('month').format(DATE_FORMAT);
        var toDate = moment().endOf('month').format(DATE_FORMAT);
        //var toDate = moment().format(DATE_FORMAT);

        getInvoicingDetailsForPeriod(fromDate, toDate);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=> {
        var fromDate = moment(invStats.selectedInvoiceMonth, 'YYYY-MM').startOf('month').format(DATE_FORMAT);
        var toDate = moment(invStats.selectedInvoiceMonth, 'YYYY-MM').endOf('month').format(DATE_FORMAT);

        loadBillingLogs(fromDate, toDate);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[invStats.currentPage]);

    const handleGoBack = () => {
        //navigate(-1);
        navigate(`${props.basePath}/invoicing-list`);
    };

    const getInvoicingDetailsForPeriod = (fromDate:string, toDate:string) => {
        (async () => {
            try {
                setInvStats((invStats)=> ({
                    ...invStats,
                    isLoading: true
                }))
                const loadedDetails = await getPrincipalInvoicingDetails(id, fromDate, toDate);
                setInvStats((invStats)=> ({
                    ...invStats,
                    invoicingStatistics: loadedDetails,
                    isLoading: false,
                }))
            } catch (error) {
                adminRedirectByErrorResponseStatus(navigate, (error as any).response, props.basePath);
            }
        })();
    }

    //eslint-disable-next-line
    const getPrincipalBillingLogs = useCallback(props.api.getBillingLogsByPrincipal, []);
    const loadBillingLogs = (fromDate:string, toDate:string) => {
        (async () => {
            try {
                setInvStats((invStats)=>({
                    ...invStats,
                    isBillingLogLoading: true,
                }));
                const loadedBillingLogs = await getPrincipalBillingLogs(invStats.currentPage+1, id, fromDate, toDate);
                perPage = loadedBillingLogs.pagination;
                setInvStats((invStats)=>({
                    ...invStats,
                    billingActivity: loadedBillingLogs.dataset,
                    isBillingLogLoading: false,
                    pageCount: Math.ceil(loadedBillingLogs.total / perPage),
                }));
                if (invStats.currentPage > Math.ceil(loadedBillingLogs.total / perPage)-1) {
                    setInvStats((invStats)=>({
                        ...invStats,
                        currentPage: 0,
                    }));
                }
            } catch (error) {
            }
        })();
    }

    const handlePageClick = (e: any) => {
        const selectedPage = e.selected;
        const newOffset = selectedPage * perPage;
        setInvStats((invStats)=>({
            ...invStats,
            currentPage: selectedPage,
            offset: newOffset,
        }));
    }

    return <>
        {(invStats.isLoading) ?
            <LoadingSpinner loadingText={""} /> :
            <>
                {(isAdmin(props.currentUser?.role_id)) ?
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Link color={"secondary"} className={"go-back-link"} href="/#" onClick={(e : any) => {e.preventDefault(); handleGoBack(); }}><FontAwesomeIcon size={"sm"} icon={faArrowLeft} />Back</Link>
                        </Grid>
                    </Grid>
                    : "" }

                <h2><strong>Manage Invoices</strong></h2>

                <h5>Principal: {invStats.invoicingStatistics?.principal_name} ({invStats.invoicingStatistics?.principal_reference_code})</h5>

                <div className={"mt-50"}>
                    <Box mb={2}>
                        <h4>Select a Month:</h4>

                        <Grid container className={"form-group"} spacing={4}>
                            <Grid item xs={12} sm={2} md={1} className={"align-self-center"}>
                                <label>Month <LightTooltip title="Month" placement="right-start"
                                                           arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                            </Grid>
                            <Grid item xs={12} sm={10} md={6}>
                                <FormControl variant="outlined" className="w-100">
                                    <Select
                                        fullWidth
                                        id="status"
                                        name="status"
                                        value={invStats.selectedInvoiceMonth}
                                        /*value={props.generalSettings.provideConsentViaApi?props.generalSettings.provideConsentViaApi:0}*/
                                        onChange={(event: SelectChangeEvent) => {
                                            var selectedInvoiceMonth = event.target.value as string;
                                            setInvStats({
                                                ...invStats,
                                                selectedInvoiceMonth: selectedInvoiceMonth
                                            });
                                            var fromDate = moment(selectedInvoiceMonth, 'YYYY-MM').startOf('month').format(DATE_FORMAT);
                                            var toDate = moment(selectedInvoiceMonth, 'YYYY-MM').endOf('month').format(DATE_FORMAT);
                                            getInvoicingDetailsForPeriod(fromDate, toDate);
                                            loadBillingLogs(fromDate, toDate);
                                        }}
                                        style={{maxWidth: 300}}
                                    >
                                        {monthValues.map((item, i) => {
                                            return <MenuItem key={i} value={item.id}>{item.value}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <LinkButton targetlocation={props.basePath + "/billing-configuration/" + id} variant={"contained"} color={"secondary"}>Configure Billing</LinkButton>
                            </Grid>
                        </Grid>
                    </Box>
                </div>

                <div className={"mt-50"}>
                    <Box mb={2}>
                        <h4>Invoicing for current month ({moment(invStats.selectedInvoiceMonth, 'YYYY-MM').format('MMM YYYY')})</h4>
                    </Box>

                    {(invStats.invoicingStatistics && invStats.invoicingStatistics.invoice_details?.length > 0) ?
                        <>
                            <Box mb={2}>
                                <div><strong>{(invStats.invoicingStatistics.billing_model)}</strong></div>
                            </Box>

                            <div className={"institution-list paginated-table"}>
                                <Hidden mdDown>
                                    <Grid container className={"table-head"}>
                                        <Grid item xs={4}>Activity</Grid>
                                        <Grid item xs={2}>Count</Grid>
                                        <Grid item xs={3}>Rate (ex. GST)</Grid>
                                        <Grid item xs={3}>Total Cost (ex. GST)</Grid>
                                    </Grid>
                                    {(invStats.invoicingStatistics.invoice_details.map((item, i) =>
                                        <Grid container className={"consent-list-item"} key={i}>
                                            <Grid item xs={4}>{item.name}</Grid>
                                            <Grid item xs={2}>{item.count}</Grid>
                                            <Grid item xs={3}><NumberFormat value={item.rate} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2}/></Grid>
                                            <Grid item xs={3}><NumberFormat value={item.total} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2}/></Grid>
                                        </Grid>
                                    ))}
                                </Hidden>
                            </div>
                        </> :
                        <div>Billing has not been configured for this Principal yet.</div>
                    }
                </div>

                <div className={"mt-50"}>

                    <Box mb={2}>
                        <h4>Activity for current month ({moment(invStats.selectedInvoiceMonth, 'YYYY-MM').format('MMM YYYY')})</h4>
                    </Box>

                        {invStats.isBillingLogLoading &&
                        <LoadingSpinner loadingText={""} />
                        }

                        {!invStats.isBillingLogLoading && invStats.billingActivity  ?
                            <>
                            {invStats.billingActivity.length > 0 ?
                               <>
                                    <div className={"institution-list paginated-table"}>
                                        <Hidden mdDown>
                                            <Grid container className={"table-head"}>
                                                <Grid item xs={1}>ID</Grid>
                                                <Grid item xs={2}>Customer Reference</Grid>
                                                <Grid item xs={2}>Customer Name</Grid>
                                                <Grid item xs={2}>Institution</Grid>
                                                <Grid item xs={1}>Type</Grid>
                                                <Grid item xs={2}>Event</Grid>
                                                <Grid item xs={2}>Time</Grid>
                                            </Grid>
                                        </Hidden>
                                        {invStats.billingActivity.map((item, i) => {
                                            return <BillingLogItem key={i} {...item} />
                                        })}
                                    </div>
                               </> : <div>No activity for this month</div>
                            }
                            </> : ""
                        }
                        {invStats.pageCount > 1 &&
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            pageCount={invStats.pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                        }
                </div>

                <div className={"mt-50"}>
                    <Box mb={2}>
                        <h4>Statistics for current month ({moment(invStats.selectedInvoiceMonth, 'YYYY-MM').format('MMM YYYY')})</h4>
                    </Box>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={5} md={4} className={"align-self-center"}>
                            <label>Active Customers <LightTooltip title="Active Customers" placement="right-start"
                                                                  arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {invStats.invoicingStatistics?.active_customer_count}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={5} md={4} className={"align-self-center"}>
                            <label>New Customers <LightTooltip title="New Customers" placement="right-start"
                                                               arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {invStats.invoicingStatistics?.new_customer_count}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={5} md={4} className={"align-self-center"}>
                            <label>Accounts Details Retrieved <LightTooltip title="Accounts Details Retrieved" placement="right-start"
                                                                            arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {invStats.invoicingStatistics?.account_details_retrieval_count}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={5} md={4} className={"align-self-center"}>
                            <label>Account Transactions Retrieved <LightTooltip title="Account Transactions Retrieved" placement="right-start"
                                                                                arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {invStats.invoicingStatistics?.account_transactions_retrieval_count}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={5} md={4} className={"align-self-center"}>
                            <label>Client Details Accessed <LightTooltip title="Client Details Accessed" placement="right-start"
                                                                         arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {invStats.invoicingStatistics?.client_details_retrieval_count}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={5} md={4} className={"align-self-center"}>
                            <label>Number of First Time Refreshes in the Period <LightTooltip title="Number of First Time Refreshes in the Period" placement="right-start"
                                                                                              arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {invStats.invoicingStatistics?.customer_first_time_refresh_count}
                        </Grid>
                    </Grid>
                    <Grid container className={"form-group"}>
                        <Grid item xs={12} sm={5} md={4} className={"align-self-center"}>
                            <label>Number of Subsequent Refreshes in the Period <LightTooltip title="Number of Subsequent Refreshes in the Period" placement="right-start"
                                                                                              arrow><InfoIcon color={"secondary"}/></LightTooltip></label>
                        </Grid>
                        <Grid item xs={12} sm={7} md={5}>
                            {invStats.invoicingStatistics?.customer_subsequent_refresh_count}
                        </Grid>
                    </Grid>
                </div>
            </>
        }
    </>
}

export default InvoicingDetails;