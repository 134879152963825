import Broker from "../admin/models/Broker";

export function getTrustedAdviserBrokerName(broker?: Broker) {
    return broker?.first_name + " " + broker?.last_name;
}

export function getTrustedAdviserBrokerSubType(broker?: Broker) {
    switch (broker?.sub_type) {
        case 'ACL':
            return 'Australian Credit License';
        case 'ACR':
            return 'Australian Credit Representative';
    }

    return '';
}

export function getTrustedAdviserBrokerNumber(broker?: Broker) {
    switch (broker?.sub_type) {
        case 'ACL':
            return broker?.acl_credit_license_number;
        case 'ACR':
            return broker?.acr_credit_representative_number;
    }

    return '';
}