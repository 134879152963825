import React, {useCallback, useState} from "react";
import OpenBankingPlatformAPIInterface from "../openbankingplatform/OpenBankingPlatformAPIInterface";
import LoadingSpinner from "./LoadingSpinner";
import {Box, Button, TextField} from "@mui/material";
import * as EmailValidator from "email-validator";

interface EmailRecoverDashboardLinkForTrustedAdvisersProps {
    obApi: OpenBankingPlatformAPIInterface;
    basePath: string;
    brokerCode: string;
}

const EmailRecoverDashboardLinkForTrustedAdvisers = (props: EmailRecoverDashboardLinkForTrustedAdvisersProps) => {
    const [state, setState] = useState<any>({
        email: '',
        sendRecoverDashboardEmailErrorMsg: '',
        sendRecoverDashboardEmailMessage: '',
        isLoading: false,
        emailSent: false
    });

    const validateEmail = (newEmail?: string) => {
        if (newEmail) {
            return EmailValidator.validate(newEmail);
        }
        return false;
    }
    const hasValidEmail = validateEmail(state.email);


    //eslint-disable-next-line
    const getCsrfToken = useCallback(props.obApi.getCsrfToken, []);
    // eslint-disable-next-line
    const sendRecoverDashboardEmail = useCallback(props.obApi.sendTrustedAdviserRecoverDashboardEmail, []);
    const handleSendRecoverDashboardEmail = () => {
        (async() => {
            try {
                setState({
                    ...state,
                    isLoading: true,
                });
                await getCsrfToken();
                const recoverDashboardResponse = await sendRecoverDashboardEmail(state.email, props.brokerCode);
                if(recoverDashboardResponse.status === 'success'){
                    setState({
                        ...state,
                        isLoading: false,
                        sendRecoverDashboardEmailMessage: 'An email with a new link to the consumer dashboard has been sent to the email address.',
                        emailSent: true
                    });
                }/*else{
                    setState({
                        ...state,
                        isLoading: false,
                        sendResetPasswordEmailError: forgotPasswordResponse
                    });
                }*/
            } catch (error) {
                setState({
                    ...state,
                    isLoading: false,
                    sendRecoverDashboardEmailErrorMsg: ''
                })
            }
        })();
    }

    const allMandatoryFieldsArePopulated = state.email !== '';

    return <>
        {state.isLoading ? <LoadingSpinner loadingText={""} /> :
            <>
                <div className="trusted-adviser-recover-dashboard">

                    {state.emailSent ?
                        <div>
                            <div>If the email address is correct, the system will send you a new link to your consumer dashboard.</div>
                            <br/>
                            <div>If you do not receive an email with this, please contact our PAC team on 13 23 33 or email  odssupport@illion.com.au</div>
                        </div> :
                        <>
                            <div>
                                <p>This link has expired. If you wish to view your consumer dashboard then please enter your email address:</p>
                            </div>

                            <div>
                                <Box mt={4} component="form">
                                    <TextField
                                        fullWidth
                                        required
                                        variant="outlined"
                                        name="username-input"
                                        id="username-input"
                                        placeholder="Your Email Address"
                                        value={state.email}
                                        onChange={(event) => {
                                            setState({
                                                ...state,
                                                email: event.target.value
                                            })
                                        }}
                                    />
                                    <div style={{ color: "#006AFF" }}>
                                        <span>{state.sendRecoverDashboardEmailMessage}</span>
                                        <span className={"error-text"}>{state.sendRecoverDashboardEmailErrorMsg}</span>
                                    </div>

                                    <br />

                                    <Button variant={"contained"} color={"secondary"} onClick={handleSendRecoverDashboardEmail} disabled={!allMandatoryFieldsArePopulated || !hasValidEmail}>Send</Button>
                                </Box>
                            </div>
                        </>
                    }
                </div>
            </>
        }
    </>
}

export default EmailRecoverDashboardLinkForTrustedAdvisers;