import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Box, Button, TextField } from "@mui/material";

import LoadingSpinner from "../../components/LoadingSpinner";
import { checkPasswordStrength, PASSWORD_EMPTY, PASSWORD_STRENGTH_POOR, PASSWORD_STRENGTH_STRONG, PASSWORD_STRENGTH_WEAK } from "../../helpers/InputFieldValidationHelper";
import OpenBankingPlatformAPIInterface from "../../openbankingplatform/OpenBankingPlatformAPIInterface";

interface ResetPasswordProps {
    adminObApi: OpenBankingPlatformAPIInterface;
    basePath: string;
}

const ResetPassword = (props: ResetPasswordProps) => {
    const [state, setState] = useState<any>({
        email: '',
        password: '',
        confirm_password: '',
        resetPasswordErrorMsg: '',
        resetPasswordMessage: '',
        isLoading: true,
        isResetPasswordInProgress: false,
        passwordStrengthIndicator: ''
    });

    const location = useLocation();
    const navigate = useNavigate();

    const { token } = useParams<{ token: any }>();

    // @ts-ignore
    const params = location.state.params;
    const email = new URLSearchParams(params).get('email') as string;
    const reset_reason = new URLSearchParams(params).get('reason') as string;

    //eslint-disable-next-line
    const getCsrfToken = useCallback(props.adminObApi.getCsrfToken, []);

    //eslint-disable-next-line
    const validateResetPasswordToken = useCallback(props.adminObApi.doAdminValidateTokenForResetPassword, []);
    useEffect(() => {
        (async() => {
            try {
                await getCsrfToken();
                const validateResetPasswordTokenResponse = await validateResetPasswordToken(token, email);
                setState({
                    ...state,
                    isLoading: false,
                    email: validateResetPasswordTokenResponse.email
                })
            } catch (error) {
                setState({
                    ...state,
                    isLoading: false,
                    resetPasswordErrorMsg: (error as any).response.data.data.error
                })
            }
        })();
        // eslint-disable-next-line
    }, []);

    const passwordStrength = checkPasswordStrength(state.password);
    const allMandatoryFieldsArePopulated = state.password !== '' && state.confirm_password !== '' && state.password === state.confirm_password;
    const isPasswordStrengthSufficient = (passwordStrength:string) => {
        return (passwordStrength === PASSWORD_EMPTY) || passwordStrength === PASSWORD_STRENGTH_STRONG;
    }
    const isPasswordStrongEnough = isPasswordStrengthSufficient(passwordStrength);

    useEffect(()=> {
        refreshPasswordStrengthIndicator(checkPasswordStrength(state.password));

    }, //eslint-disable-next-line
        [state.password]);

    const refreshPasswordStrengthIndicator = (passwordStrength:string) => {
        switch (passwordStrength) {
            case PASSWORD_STRENGTH_POOR:
            case PASSWORD_STRENGTH_WEAK:
                setState({
                    ...state,
                    passwordStrengthIndicator: 'The password must be at least 10 characters long and contain at least 1 upper case (a-z) and 1 lower case character (A-Z), 1 numeric character (0-9), and 1 special character (#, ?, !, @, $, %, ^, &, *, -).'
                });
                break;
            case PASSWORD_STRENGTH_STRONG:
            default:
                setState({
                    ...state,
                    passwordStrengthIndicator: ''
                });
                break;
        }
    }


    //const resetPasswordErrorMsg = props.resetPasswordError ? props.resetPasswordMessage : '';

    //eslint-disable-next-line
    const resetPassword = useCallback(props.adminObApi.doAdminResetPassword, []);
    const handleResetPassword = () => {
        (async() => {
            try {
                setState({
                    ...state,
                    isResetPasswordInProgress: true,
                })
                await resetPassword(token, state.email, state.password, state.confirm_password);
                navigate(props.basePath + "/login", {
                    state: {
                        loginStatusMessage: "Password has been successfully reset. Please login with your new password.",
                    }
                });
                //navigate(props.basePath + "/login");
            } catch (error) {
                setState({
                    ...state,
                    isResetPasswordInProgress: false,
                    resetPasswordErrorMsg: (error as any).response.data.data.error
                });
            }
        })();
    }

    if (state.isLoading) {
        return <LoadingSpinner position={"fixed"} overlay />
    }

    if (state.resetPasswordErrorMsg !== '') {
        return <div className="admin-login">
            <div className="error-text">{state.resetPasswordErrorMsg}</div>
            <div style={{ paddingTop: '20px' }}>Please try the <Link to={props.basePath + "/forgot-password"}>Forgot Password?</Link> option again.</div>
        </div>
    }

    return (
        <div className="admin-login">
            <h2><strong>Reset Password</strong></h2>
            {reset_reason && <p className={"error-text"}>{reset_reason}</p>}
            {state.isResetPasswordInProgress ? <LoadingSpinner loadingText={""} /> :
                <>
                    <span>{state.resetPasswordMessage}</span>
                    <span className={"error-text"}>{state.resetPasswordErrorMsg}</span>
                    <span style={{ color: 'red', fontSize: '10pt', width: '250px' }}>{state.passwordStrengthIndicator}</span>

                    <Box
                        mt={4}
                        component="form"
                    >
                        <TextField
                            required
                            variant="outlined"
                            id="password-input"
                            name="password-input"
                            className="w-100"
                            placeholder="Password"
                            type="password"
                            /*value={userDetails.password}*/
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    password: event.target.value
                                });
                            }}
                        />

                        <TextField
                            required
                            variant="outlined"
                            id="password-confirm-input"
                            name="password-confirm-input"
                            className="w-100"
                            placeholder="Confirm Password"
                            type="password"
                            /*value={userDetails.password}*/
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    confirm_password: event.target.value
                                })
                            }}

                        />

                        <Button variant={"contained"} color={"secondary"} onClick={handleResetPassword} disabled={!allMandatoryFieldsArePopulated || !isPasswordStrongEnough}>Reset Password</Button>
                    </Box>
                </>
            }
        </div>);
}

export default ResetPassword;